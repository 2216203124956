import React, { useState } from 'react';
import { APIEndpoint ,DFPAPIEndpoint} from "../../../utils/axios";
import { DataGrid, EditModal, UploadButton, UploadModal } from "../../../common";
import { fetchCulture, onDownloadXlsFile, translationLength } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import LoadingGIF from '../../../assets/loadingIn.gif';
import usePermissions from '../../../utils/userPermissions';
const Columns = [
  { field: 'code', headerName: 'Distributor Code',flex:1, sortable: true, filter: true,suppressSizeToFit:true, resizable:true,minWidth: 125 },
  { field: 'name', headerName: 'Name 1',flex:1, sortable: true, suppressSizeToFit:true, resizable:true,minWidth: 135,filter: true,tooltipField: 'name',  valueGetter: (params) => {
    if (params.data&&params.data.name) {
       return params.data.name.length>21? params.data.name.slice(0,22)+"...": params.data.name;
    }
    return null;
  } },
  { field: 'name2', headerName: 'Name 2',flex:1, sortable: true, suppressSizeToFit:true, resizable:true,minWidth: 125,filter: true,tooltipField: 'name2',  valueGetter: (params) => {
    if (params.data&&params.data.name2) {
       return params.data.name2.length>21? params.data.name2.slice(0,22)+"...": params.data.name2;
    }
    return null;
  } },
  { field: 'mobile', headerName: 'Mobile', flex:1,sortable: true,suppressSizeToFit:true, resizable:true,minWidth: 100, filter: true },
  { field: 'email', headerName: 'Email',flex:1, sortable: true, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 195,tooltipField: 'email',  valueGetter: (params) => {
    if (params.data&&params.data.email) {
       return params.data.email.length>21? params.data.email.slice(0,22)+"...": params.data.email;
    }
    return null;
  }, },
  { field: 'address', headerName: 'Address',flex:1, sortable: true, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 165,tooltipField: 'address',  valueGetter: (params) => {
    if (params.data&&params.data.address) {
       return params.data.address.length>21? params.data.address.slice(0,22)+"...": params.data.address;
    }
    return null;
  } },
  { field: 'gstin', headerName: 'GSTIN',flex:1, sortable: true,suppressSizeToFit:true, resizable:true, minWidth: 125,filter: true },
  // { field: '', headerName: '', sortable: true, filter: true},
];
const AddFormField = [
  { label: 'Name 1', attribute: 'name', type: 'text', required: true, },
  { label: 'Name 2', attribute: 'name2', type: 'text', required: true },
  { label: 'Distributor Code', attribute: 'code', type: 'text', required: true },
  { label: 'Email', attribute: 'email', type: 'email', required: true },
  { label: 'Mobile', attribute: 'mobile', type: 'text' },
  { label: 'PAN', attribute: 'pan', type: 'text', required: true },
  { label: 'GSTIN', attribute: 'gstin', type: 'text', required: true },
  { label: 'Address', attribute: 'address', type: 'textarea',rows:4 },
  { label: 'Street 1', attribute: 'street1', type: 'text', },
  { label: 'Street 2', attribute: 'street2', type: 'text', },
  { label: 'Street 3', attribute: 'street3', type: 'text', },
  { label: 'Postal Code', attribute: 'postalCode', type: 'text', required: true, },
  { label: 'City', attribute: 'city', type: 'text', required: true, },
  { label: 'State', attribute: 'stateCode', type: 'select', required: true, options: [] },
  { label: 'Country', attribute: 'country', type: 'text', required: true, disabled: true, initialValue: 'IN' },
]

export const DistributorPage = (props) => {
  const [state, setState] = useState({})
  const { t } = useTranslation();
  const [countryDetails,setCountryDetails]=useState({});
  const [statesIN, setStatesIN] = useState([]);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [isDownloading, setIsDownloading] = useState(false);
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const { hasPermission: createBpa } = usePermissions('create', "configuration.fsc.bpa");
  const { hasPermission: editBpa } = usePermissions('edit', "configuration.fsc.bpa");
  const { hasPermission: uploadBpa } = usePermissions('upload', "configuration.fsc.bpa");
  const { hasPermission: downloadBpa } = usePermissions('download', "configuration.fsc.bpa");

  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

  React.useEffect(() => {
   const  fetchCountryData=async()=>{
    try {
      const res = await fetchCulture();
      if (res) {
        setCountryDetails(res);
      }
    } catch (error) {
      console.error('Error fetching country data:', error);
    }
    }
    fetchCountryData();
  }, []);

  React.useEffect(() => {
if(Object.keys(countryDetails).length){
  const statesMod = countryDetails.stateCodes.map((obj, index) => ({
    key: index + 1,
    label: obj.name,
    value: obj.code,
  }));
  setStatesIN(statesMod);

}
  }, [countryDetails]);

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };
  const fetchDistributors = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    const res = await APIEndpoint.post('distributors/process', filterData);
    return res;
  }
  const handleModalClose = () => {
    setModalFormStatusObj({text:''})
    setState((_) => {
      return { ..._, showAddForm: false, editRow: null }
    })
  }
  const handleAddDistributor = () => {
    setState((_) => {
      return { ..._, showAddForm: createBpa?true :false}
    })
  }
  const handleReset = () => {
    setState((_) => {
      return { ..._, resetDataSource: true, showAddForm: false, editRow: null };
    })
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }
  const handleAddDistributorSubmit = async (e, values) => {
    let isValid = true;
    if (values != null) {
      isValid = AddFormField.every(((field) => {
        if (
          field.required && (
            (Boolean(state.editRow) && !(values[field.attribute] || state.editRow[field.attribute])) ||
            (!Boolean(state.editRow) && !values[field.attribute] && field.attribute !== 'country')
          )
        ) {
          return false;
        }
        return true
      }));
      if (isValid) {
        const {countryCode}=countryDetails
        values.country =countryCode ;
        try {
          let res;
          if (Boolean(state.editRow)) {
            values = { ...state.editRow, ...values };
            res = await APIEndpoint.post('distributors/update/' + state.editRow.id, values);
          } else {
            res = await APIEndpoint.post('distributors/new', values);
          }
          if (res.success) {
            handleReset()
          }
          alert(res.msg);
        } catch (error) {
          // alert(error.data.msg);
          let {data}=JSON.parse(error.message);
          setModalFormStatusObj({error:data.msg})
        }
      }
    }
  }
  const handleRowAction = async (e, action, row) => {
    try {
      const res = await APIEndpoint.get('distributors/' + row.itemId);
      setState((_) => {
        return { ..._, editRow: res.result }
      })
    } catch (error) {
      alert(error.data.msg);
    }
  }
  const handleUploadDistributor = async (e) => {
    const formData = new FormData()
    formData.append('file', e.target.files[0]);
    return APIEndpoint.post('distributors/master/upload', formData, { 'Content-Type': "multipart/form-data" })
  }
  const onDownloadSampleFile = async (e) => {
    onDownloadXlsFile('samples/downloads/Distributor_Master_Upload_Sample', 'Distributor_Master_Upload_Sample')
  }

  const onUploadClose = () => {
    setUploadedFile([]);
    setUploadFileStatusBlock('');
    setState((_) => ({ ..._, showUpload: false }))
  };

  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', undefined);
    formData.append('stores', []);
    formData.append('warehouses', []);
    formData.append('file', file);

    try {
      await DOMAIN.post('distributors/master/upload', formData);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      setState( (_prev) => {
        return { ..._prev,resetDataSource:true}
      });
      setUploadedFile([]);
    }
    catch(error) {
      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400 && data.errors) {
            data.errors.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
      } else if(status===0 && !data){
        msgContent += `Error occurred while uploading Distributors!<br>Please try again.`;
    }
      else {
        msgContent += `Error occurred while uploading Distributors!<br>Please fix the errors and re-upload.`;
      }

      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
};

  const onDownloadDistributors=async()=>{
    let header={
      'Accept':'text/csv'
    }
    try {
      setIsDownloading(true)
    const res= await APIEndpoint.post(`downloads/distributorMaster`,{reportType: "Distributors"},header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        const dowId = APIEndpoint.get(`downloads/status/${res.id}`);
        dowId.then( async (val)=> {
          if(val.status==="completed"){
           await APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`).then((ItemMasterDwnld) => {
              const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', val.fileName+'.csv'); //or any other extension
              document.body.appendChild(link);
              link.click();
              setIsDownloading(false)
          });

          }
        })
        }, 4000);
    }
    }
      catch (res) {
        console.log(res.message)
        setIsDownloading(false)
      }
  }

  function handleChange(e,updated) {
    setModalFormStatusObj({text:""})
  }

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
    {( uploadBpa && downloadBpa) ? <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
        <UploadButton
          onClick={() => setState((_) => ({ ..._, showUpload: true }))}
          label={<span><u>{t('pages.pagesContent.distributors.uploadBtn')}</u></span>} />
        {/* <DownloadButton
          downloadLink='downloads/distributorMaster'
          label={t('pages.pagesContent.distributors.downloadBtn')} /> */}
        <button className='download dropdownStyle1-TextMedium' onClick={onDownloadDistributors}><span>{isDownloading?<img src={LoadingGIF} alt='loading' className='cell-loading' />:<img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />}<u className={`download__banner_${isDownloading ? "active" : ""}`}>{isDownloading ? t('filters.downloadingMaster'):t('filters.downloadDistributor')}</u></span></button>
      </div>:<></>}
      <DataGrid
        columnResize
        showDefaultFilters
        className='full-flex'
        columns={Columns}
        getRows={fetchDistributors}
        pageType={false}
        resetDataSource={state.resetDataSource}
        addButton={{
          title: t('pages.pagesContent.distributors.addButtonTitle'),
          onClick: handleAddDistributor
        }}
        actions={[
          { detail: editBpa?[{ src: Edit2, value: "edit" }]:[] }
        ]}
        onAction={handleRowAction}
      />
      {
        Boolean(state.showAddForm || state.editRow) &&
        <EditModal
          title={t('pages.pagesContent.distributors.' + (Boolean(state.editRow) ? 'editModalTitle' : 'addModalTitle'))}
          onClose={handleModalClose}
          onSubmit={handleAddDistributorSubmit}
          modalFormStatusObj={modalFormStatusObj}
          onChange={handleChange}
          fields={AddFormField.map((elt) => {
            return {
              ...elt,
              initialValue: Boolean(state.editRow) ? state.editRow[elt.attribute] : (elt.initialValue || ''),
              options: elt.attribute === 'stateCode' ? [{ label: t('selectState'), value: '' }, ...statesIN] : []
            }
          })}
        />
      }
      {
        state.showUpload &&
        <UploadModal
          modelTitle={t('pages.pagesContent.distributors.uploadBtn')}
          onUploadClose={onUploadClose}
          onDownloadSampleFile={onDownloadSampleFile}
          uploadedFile = { uploadedFile }
          onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
          uploadFileStatusBlock = { uploadFileStatusBlock }
        />
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}