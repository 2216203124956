import React, { useEffect, useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import { FilterBodyTemplate } from "../../../utils/configs";
import { DataGrid,Loading, Input, NGODatePicker, EditModal } from "../../../common";
import {translationLength} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import usePermissions from '../../../utils/userPermissions';
import CustomSelect from '../../../common/CustomSelect';

const Columns = [
  { field: 'currencyCode', headerName: 'pages.cashRegisterContent.cashDrawer.tableColumns.currencyCode', sortable: true, filter: false,suppressSizeToFit:true,resizable:true },
  { field: 'currencyName', headerName: 'pages.cashRegisterContent.cashDrawer.tableColumns.currencyName', sortable: true, filter: false,suppressSizeToFit:true,resizable:true},
  { field: 'openingBalance', headerName: 'pages.cashRegisterContent.cashDrawer.tableColumns.openingBalance', cellStyle: params => {
    if (params && params?.data?.openingBalance>0) {
    return {color: '#04AA6D'};
    }
    else if((params && params.data?.openingBalance<0) || (params && params.data?.openingBalance<params.data?.closingBalance)){
    return {color:" #d9534f"};
    }else if(params&& params.data?.closingBalance===params.data?.openingBalance){
      return {color: '#04AA6D'};
    }
    else{
      return null;
    }
}, sortable: true, filter: false,suppressSizeToFit:true,resizable:true},
  { field: 'deposit', headerName: 'pages.cashRegisterContent.cashDrawer.tableColumns.deposit', sortable: true, filter: true,suppressSizeToFit:true,resizable:true},
  { field: 'bankTransfer', headerName: 'pages.cashRegisterContent.cashDrawer.tableColumns.bankTransfer', sortable: true, filter: false,suppressSizeToFit:true,resizable:true },
  { field: 'variance', headerName: 'pages.cashRegisterContent.cashDrawer.tableColumns.variance', sortable: true, filter: false,suppressSizeToFit:true,resizable:true },
  { field: 'closingBalance', headerName: 'pages.cashRegisterContent.cashDrawer.tableColumns.closingBalance',  cellStyle: params => {
  if ((params && params.data?.closingBalance<0) ||(params && params.data?.closingBalance<params.data?.openingBalance)) {
  return {color:" #d9534f"};
  }
  else if(params&& params.data?.closingBalance>params.data?.openingBalance){
  return {color: '#04AA6D'};
  }else if(params&& params.data?.closingBalance===params.data?.openingBalance){
  return {color: '#04AA6D'};
  }
  else{
  return null;
  }
},sortable: true, filter: false,minWidth:330,suppressSizeToFit:true,resizable:true}
]
const FormFields = [
  { label: 'Airport', attribute: 'airportId', type: 'selectSearch2', required: true },
]

export const CashDrawersPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ cashDrawerList: null, data: null, showCreateDialog: false,modalFormStatusObj:{text:''} });
  const [filterDate, setFilterData] = useState(new Date());
  const [cashDrawer, setCashDrawer] = useState('');
  const [resetDataSource, setResetDataSource] = useState(false)
  const [noResultsMessage, setNoResultsMessage] = useState("Please select a cash drawer and date")
  const { hasPermission: createCashDrawers } = usePermissions('create', "configuration.fsc.cash-drawers");


  useEffect(() => {
    fetchCashDrawersList();
    fetchAirports();
  }, [])
  useEffect(() => {
    if (filterDate && cashDrawer) {
      setResetDataSource(!resetDataSource)
    }else{
      setNoResultsMessage("Please select a cash drawer and date")
      setResetDataSource(!resetDataSource)
    }
  }, [filterDate, cashDrawer])

  const fetchCashDrawersList = async () => {
    try {
       const res = await APIEndpoint.get('cashRegister/cashDrawers');
    const list = (res.result || []).map((_) => ({ value: _.id, label: _.value }));
    list.unshift({ label: t('pages.cashRegisterContent.cashDrawer.selectPlaceholder'), value: '' })
    setState((_) => ({ ..._, cashDrawerList: list }))
    } catch (error) {

    }

  }
  const fetchCashBalance = async () => {
    const body = { ...FilterBodyTemplate, filters: [] };
    body.filters = [{ date: filterDate }, { cashDrawerId: cashDrawer }]
    body.limit = 200;
    const res = await APIEndpoint.post('cashRegister/balance', body);
    if(res.count == 0){
      setNoResultsMessage("No Data");
    }
    return res
    // setState((_) => ({ ..._, data: res.items }))
  }
  const fetchAirports = async () => {
    try {
      const res = await APIEndpoint.get('airports');
    const list = (res.items || []).map((_) => ({ value: _.id, label: `${_.locationCode}(${_.locationName})` }));
    list.unshift({ label: t('pages.pagesContent.airports.selectPlaceholder'), value: '' })
    setState((_) => ({ ..._, airports: list }))
    } catch (error) {

    }

  }
  const handleCreateNewDrawer = async (e, values) => {
    try {
       const res = await APIEndpoint.post('cashRegister/cashDrawers', values);
         if (res) {
          setState((_) => ({ ..._,modalFormStatusObj:{text:res.message} }))
          fetchCashDrawersList()
       }
       setTimeout(() => {
        setState((_) => ({ ..._, showCreateDialog: false,modalFormStatusObj:{text:''}}))
      }, 2000)
    } catch (error) {
        if(error.message) {
          const responseData = JSON.parse(error.message);
          const errorMessage = responseData?.data?.message;
          setState((_) => ({ ..._,modalFormStatusObj:{error:errorMessage} }))
        } else{
          setState((_) => ({ ..._,modalFormStatusObj:{text:'Failed to create new drawer'} }))
        }
    }
    }

  return (
    <div className="flexCol width100 ag-theme-alpine-dark cash-reg">
      {
        state.cashDrawerList === null ?
          <Loading />
          :
          <div className='flexCol full-flex'>
            <div className='flexRow alignItemsCenter justifyContentSpaceBetween'>
              <div className='flexRow alignItemsCenter'>
         {createCashDrawers?   <button className='add-item-button'
                  onClick={() => {
                    fetchAirports();
                    setState((_) => ({ ..._, showCreateDialog: true }))
                  }}>
                  {t('pages.cashRegisterContent.cashDrawer.addCashDrawer')}
                </button>:<></>}
                <div className='margLeft6'>
                <CustomSelect
                  options={state.cashDrawerList}
                  placeHolder="Search cashdrawer"
                  onChange={(e) => {
                    setCashDrawer(e?.value|| "");
                  }}
                />
                </div>
                {/* <Input
                  type='select'
                  className='filter-cash'
                  value={cashDrawer!==null?cashDrawer:''}
                  options={state.cashDrawerList}
                  onChange={(e) => {
                    setCashDrawer(e.target.value);
                  }}
                /> */}
              </div>
              <NGODatePicker
                value={filterDate}
                maxDate={new Date()}
                onChange={(e) => {
                  setFilterData(e.target.value);
                }} />
            </div>
            
              <DataGrid
                columnResize
                showDefaultFilters={false}
                enableGridSearch={false}
                className='full-flex'
                columns={Columns}
                getRows={fetchCashBalance}
                resetDataSource={resetDataSource}
                noResultsMessage={noResultsMessage}
              />

          </div>
      }
      {
        Boolean(state.showCreateDialog) && Boolean(state.airports) && (
          <EditModal
            buttonLabel="Create"
            className='create-drawer'
            title={t('pages.cashRegisterContent.cashDrawer.newDrawerTitle')}
            onClose={() => {
              setState((_) => ({ ..._, showCreateDialog: false,modalFormStatusObj:{text:''} }))
            }}
            modalFormStatusObj={state?.modalFormStatusObj}
            showFieldsInColumn={true}
            onSubmit={handleCreateNewDrawer}
            fields={FormFields.map((_) => {
              return { ..._, options: state.airports }
            })}
          />
        )
      }
    {
    t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
    }
    </div>
  )
}