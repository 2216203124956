/* eslint-disable react-hooks/exhaustive-deps */
import React,{useEffect, useState} from 'react'
import '../../../assets/styles/modal.scss';
import '../../../assets/styles/styles.scss';
import closeIcon from '../../../images/icons/Icon2.png';
import employees from '../../../assets/employees.svg';
import deleteIcon from '../../../assets/icons/deleteRole.svg'
import Select from 'react-select';
import AllMultiSelect from "../../../../src/common/AllSelectCheckbox.jsx";
import { components } from "react-select";
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import { useTranslation } from 'react-i18next';


export const Roles = (props) => {
const [optionSelected,setOptionSelected]=useState(null);
const [uploadProgress,setUploadProgress]=useState({status:"Uploading",percentage:40});
const [fileSize,setFileSize]=useState(0);

  const { t } = useTranslation();
  const preCompile = {
    chooseFilePlaceholder: t('filters.chooseXlsFile'),
    browse:t('filters.browse'),
    sampleFile:t('filters.sampleFile'),
    assnRole:t('pages.inventoryContent.itemMaster.assnRoles'),
    noRolesAssn:t('pages.inventoryContent.itemMaster.noRolesAssn'),
    selRole:t('pages.inventoryContent.itemMaster.selRoles'),
    noRolesSel:t('pages.inventoryContent.itemMaster.noRoleSel'),
    caution:t('pages.inventoryContent.itemMaster.uploadCaution'),
    roleMand:t('pages.inventoryContent.itemMaster.uploadRoleFieldMsg'),
    roleRequirement:t('pages.inventoryContent.itemMaster.roleRequirement'),
    role:t('pages.inventoryContent.itemMaster.role'),
    roleAdd:t('pages.inventoryContent.itemMaster.roleAdd')
  }

  useEffect(() => {
    if(props.uploadedFile && props.uploadedFile?.size>0){
       let fileSizeUpload=props.uploadedFile?.size/(1024*1024);
       setFileSize(parseFloat((fileSizeUpload * uploadProgress.percentage)/100).toFixed(4));
    }
  }, [uploadProgress.percentage,props.uploadedFile])

  useEffect(() => {
   preCompile.selRole=t('pages.inventoryContent.itemMaster.selRoles');
  }, [t])
  useEffect(() => {
    if(optionSelected&&optionSelected?.length){
     if( optionSelected.filter(opt=>opt.value==='*').length){
      setOptionSelected([{key:null,label:`All ${props.subSelection.length} selected`,value:`All ${props.subSelection.length} selected`}])
     }
    }
 }, [optionSelected])

 useEffect(()=>{
props.setSubSelChoice([])
 },[props.subSelection])

 useEffect(()=>{
  props.setSubSelChoice([])
  setOptionSelected(null)
   },[props.selectedOptions])


   useEffect(() => {
    let interval;

    if (props.uploadFileStatusBlock && props.uploadedFile && props.uploadedFile?.size>0) {
        const statusBlock = props.uploadFileStatusBlock;

        const parser = new DOMParser();
        const doc = parser.parseFromString(statusBlock, 'text/html');

        const errorElementsWithClass = doc.querySelectorAll('.listErrorMessagesCls');
        const errorMessagesWithClass = Array.from(errorElementsWithClass).map(p => p.textContent.trim());

        const errorElementsWithoutClass = doc.querySelectorAll('p:not([class])');
        const errorMessagesWithoutClass = Array.from(errorElementsWithoutClass).map(p => p.textContent.trim());
        const allErrorMessages = [...errorMessagesWithClass, ...errorMessagesWithoutClass];
     
        if (doc.querySelectorAll('.listErrorMessagesCls').length) {
            setUploadProgress({ status: "Error", percentage: 100 });
            if (interval) clearInterval(interval);
            return;
        }

        if (allErrorMessages.some(message => message.includes('File Imported Successfully')) ) {
            setUploadProgress({ status: "Success", percentage: 100 });
            if (interval) clearInterval(interval);
            return;
        }

        if (allErrorMessages.some(message => message.includes('Uploading...'))) {
          if (interval) clearInterval(interval);
          setUploadProgress({status:"Uploading",percentage:40})
          interval = setInterval(() => {
              setUploadProgress(prev => {
                  if (prev.percentage < 85) {
                      return { ...prev, percentage: prev.percentage + Math.floor(Math.random() * (15 - 10 + 1)) + 10 };
                  } else {
                      clearInterval(interval);
                      return prev;
                  }
              });
          }, 3500);

      }
    }

    return () => {
        if (interval) clearInterval(interval);
    };
}, [props.uploadFileStatusBlock,props.uploadedFile]);


  const [roleArray,setRoleArray]=useState([])
  let fileName =  preCompile.chooseFilePlaceholder;
  if ( props.uploadedFile ) {
    if (Array.isArray(props.uploadedFile) ) {
      if ( props.uploadedFile.length === 0 ) {

      }
    } else {
      fileName = props.uploadedFile.name;
    }
  }

  let isError = false,
  updateStatusText="";
 if (props.modalFormStatusObj) {
   if (('text' in props.modalFormStatusObj)) {
     updateStatusText= props.modalFormStatusObj.text;
   }
   if (('error' in props.modalFormStatusObj)) {
     isError = props.modalFormStatusObj.error;
   }
 }
  useEffect(() => {
    setRoleArray(props.fields);
  }, [props.fields])
  useEffect(() => {
    getButtonStatus();
  }, [props.roleOptions,props.subSelChoice,props.subSelection])

  useEffect(() => {
  if(props.selectedOptions){
  props.onChange(props.selectedOptions);
  props.setRoleOptions(props.selectedOptions.value)
  }
  }, [props.selectedOptions])


    const getButtonStatus=()=>{
    if(props.roleOptions&&!Boolean(props.roleOptions.length)){
    return true
    }
    if(props.roleOptions&&Boolean(props.roleOptions.length)&&!Boolean(props.subSelection.length)){
    return false
    }
    if(props.roleOptions&&Boolean(props.roleOptions.length)&&Boolean(props.subSelChoice.length)&&Boolean(props.subSelection.length)){
    return false
    }
    if(props.roleOptions&&Boolean(props.roleOptions.length)&&!Boolean(props.subSelChoice.length)&&Boolean(props.subSelection.length)){
    return true
    }
    }
    const onClickRemoveRole=(resType,id)=>{
    const filterArr=roleArray.map(rm=>{
    if(rm.resources!==undefined && rm.resourceType===resType){
    const resInd = rm.resources.findIndex((obj) => obj.id === id);
    if(resInd>=0){
    rm.resources.splice(resInd, 1);
    }
    }
    return rm;
    })
    props.setRoleItems(filterArr);
    }



      const Option = props => {
      return (
      <div>
      <components.Option {...props}>
      <input
      type="checkbox"
      checked={props.isSelected}
      onChange={() => null}
      />{" "}
      <label>{props.label}</label>
      </components.Option>
      </div>
      );
      };

      const MultiValue = props => (
      <components.MultiValue {...props}>
      <span>{props.data.label}</span>
      </components.MultiValue>
      );


  const handleChange = selected => {
    setOptionSelected(selected)
    props.setSubSelChoice(selected)

  };
 const onClickDelete=(deleteId)=>{
 const resArr=roleArray.filter(rl=>rl.role.id!==deleteId);
 props.setRoleItems(resArr)
 }

const showUserRoles=()=>{
 const result=roleArray.map((r)=>{
  const {role}=r;
  if(r?.resourceType==="Account"){
    return(
      <div  className="form-group" key={role.id}>
              <ul className='list-group' style={{padding: "0 15px"}}>
                <div className='role_card'>
              <div>
                {<p className='role_name'><li className='list-group-item-role'key={role.id} >{role.name}<br/><br/>
                {role.description}
                </li>
                </p>
                }
                </div>
                {roleArray.length&&<span className='remove_role_icon'>
                <button onClick ={()=>onClickDelete(role.id)} className='mt-4 remove-button-roles'>
                <img  className="icon-size-role" alt='deleteIcon' src={deleteIcon} />
                </button>
                </span>}
                </div>
                </ul>
              </div>
    )}

    else if(r?.resourceType!=="Account" && r.resources.length>0){
      return(<div  className="form-group" style={{overflow:'auto'}} key={role.id}>
        <ul className='list-group' style={{padding: "0 15px"}}>
        <div className='role_card' key={role.id}>
        <div>
        <div className='role_name'> {r.resources.length?<h6 className="subheading-text-medium-title fontLight margLeft8">{role.name}</h6>:<></>}</div>
        {r.resources.length?<h4 className="subheading-text-small-title fontLight margLeft8">{role.description}</h4>:<></>}
        {r.resources.map((_,i) =>
        <li className='list-group-item-role'style={{display:"inline"}} key={_.id} >
         {i%7===0&&i>0? <><button className='customize-button-role'>
         <div className="button-content-roles">
    {_.name}
    <span className='role_close_icon'>
      <img className="roleModal__remove_icon" alt='Close' src={closeIcon} onClick={() => onClickRemoveRole(r.resourceType, _.id)} />
    </span>
  </div>
          </button><br/></>:<button className='customize-button-role'>
          <div className="button-content-roles" key={_.id}>
          {_.name}
          <span className='role_close_icon'>
          <img className="roleModal__remove_icon" alt='Close' src={closeIcon} onClick={() => onClickRemoveRole(r.resourceType, _.id)} />
          </span>
          </div>
          </button>}
          </li>)}
        </div>
        {roleArray.length&&r.resources.length?<span className='remove_role_icon'>
        <button onClick ={()=>onClickDelete(role.id)} className='mt-4 remove-button-roles'>
                <img  className="icon-size-role" alt='deleteIcon' src={deleteIcon} />
              </button>
          </span>:<></>}
          </div>
      </ul>
      </div>)
        }else{
          return null;
        }
 }
 )

return result

}
const OnClickAddRole=()=>{
  props.onAddRole();
  props.setSelectedOptions([]);
}
const getOptions=(arr)=>{
  return arr.filter(a=>a.value);
}

  return (
    <div className="overlay" role="dialog">
    <div className="dialog sm-scrollbar">
      <div className="dialog__content ng-grid-right">
        <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
        <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
        <hr />
        <label className="small-body-text-regular fontRegular req">{t('pages.inventoryContent.itemMaster.roleAdd')} </label>
        {props.title==="Upload Employees"? <h6 className="subheading-text-medium-title sub-heading-title-req fontLight text-danger ">{preCompile.caution}</h6>:<></>}

        <div  id="addRole" className="dialog__body_container flexRow flexWrap">
          <>
          <div className={`dialog__container_item input-container margTop8`}>
            <div className={`dialog__description`}>
            <label className={`sub-heading-title-req req`}>{t('pages.inventoryContent.itemMaster.role')}</label>

            <Select
                defaultValue={props.selectedOptions}
                value={props.roleNames.filter(function(option) {
                  return props.selectedOptions&&props.selectedOptions.value===option.value
                })}
                onChange={props.setSelectedOptions}
                options={getOptions(props.roleNames)}
                styles={ {
                  input: provided => ({
                    ...provided,
                    color: '#fff'
                  })
                ,
                control: (defaultStyles) => ({
                  ...defaultStyles,
                  backgroundColor: "#162E3E",
                  width: '15rem',
                  boxShadow: "none",
                  padding:'0.1rem',
                  margin: '10px 10px 10px 0px'
                })
              }}
                isSearchable
                isClearable
                placeholder="Search/Select Role"
              />
        {props.title==="Upload Employees" && !props.roleOptions? <h6 className="small-body-text-regular-label-upload text-danger">{preCompile.roleMand}</h6>:<></>}

            </div>
          </div>

       { props.subSelection.length>0&&
       <div className={`dialog__container_item input-container margTop8`}>
         <div className={`dialog__description`}>
            <label  className={`sub-heading-title-req req`}>{props.subMenuHead}</label>
        <AllMultiSelect
        options={getOptions(props.subSelection)}
        defaultValue={[]}
        isMulti
        styles={ {
          input: provided => ({
            ...provided,
            color: '#fff'
          })
        ,
        control: (defaultStyles) => ({
          ...defaultStyles,
          backgroundColor: "#162E3E",
          width: '15rem',
          padding:'0.1rem',
          margin: '10px 10px 10px 0px',
          boxShadow: "none",
        })
      }
      }
        isSearchable
        isClearable
        components={{ Option, MultiValue }}
        onChange={handleChange}
        allowSelectAll={true}
        value={optionSelected}
        placeholder={`Select ${props.subMenuHead}`}

      />
      </div>
      </div>
       }
       <button id='roleBtn' disabled={getButtonStatus()} className={`add-role-button`} onClick={()=>OnClickAddRole()}>+ <img src={employees} alt="" className='menuitemicon' /></button>
         </>
        </div>
<>
{props.modelTitle!=='Upload Employees'?<h6 className="subheading-text-medium-title sub-heading-title-req fontLight">{preCompile.assnRole}</h6>:<h6 className="subheading-text-medium-title sub-heading-title-req fontLight">{preCompile.selRole}</h6>}
          {showUserRoles()}
            {!props.addRoles&&!props.fields?.length?<h6 className="subheading-text-medium-title sub-heading-title-req fontLight">{props.modelTitle!=="Upload Employees"?preCompile.noRolesAssn:preCompile.noRolesSel}</h6>:<></>}
</>

{props.modelTitle==='Upload Employees'?<div className="dialog__content">
            {props.warehouse?.length&&props.warehouseUpload?.length? <div className="dialog__body_container flexRow mt-4 button-align justifyContentFlexEnd flexWrap">
            <div className="file-upload input-group col-md-8 col-xs-12">
              <span className="btn btn-primary btn-file">
              <label htmlFor="file-upload">{preCompile.browse}</label>
              <input id="file-upload" type="file" name="file" data-url="/api/inventory/upload" onChange={ props.onUploadFileChangeHandler }></input>
              </span>
              <input type="text" className="form-control" placeholder={ fileName } readOnly=""></input>
            </div>

            <div className="col-md-4 col-xs-12">
              <button type="button" onClick={props.onDownloadSampleFile} className="btn btn-info" style={{ color: "white" }}>
              <span><img className="icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span>
                 {preCompile.sampleFile}
              </button>
            </div>
          </div>:<></>}
          {!props.warehouse?.length&&!props.warehouseUpload?.length? <div className="dialog__body_container flexRow mt-4 button-align justifyContentFlexEnd flexWrap">
            <div className="file-upload input-group col-md-8 col-xs-12">
              <span className="btn btn-primary btn-file">
              <label htmlFor="file-upload">{preCompile.browse}</label>
              <input id="file-upload" type="file" name="file" data-url="/api/inventory/upload" onChange={ props.onUploadFileChangeHandler }></input>
              </span>
              <input type="text" className="form-control" placeholder={ fileName } readOnly=""></input>
            </div>

            <div className="col-md-4 col-xs-12">
              <button type="button" onClick={props.onDownloadSampleFile} className="btn btn-info" style={{ color: "white" }}>
              <span><img className="icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span>
                 {preCompile.sampleFile}
              </button>
            </div>
          </div>:<></>}
          { props.uploadFileStatusBlock && props.uploadedFile && props.uploadedFile?.size>0 ? (
          <>
             <div className="dialog__footer">
             <div className={uploadProgress.status !== 'Error'?"meter animate":"meter red"}>
             <span style={{ width: `${uploadProgress.percentage}%` }}>
              <span
              className='flexRow justifyContentCenter alignItemsCenter'
              style={{ color: uploadProgress.status !== 'Error' ? "#fff" : '#c10e0e' }}
              >
              {uploadProgress.status !== 'Error' ? (
                  uploadProgress.status !== 'Success' ? (
                      `${uploadProgress.status} ${fileSize}MB of ${parseFloat(props.uploadedFile?.size / (1024 * 1024)).toFixed(2)}MB ${uploadProgress.percentage}%`
                  ) : (
                      uploadProgress.status
                  )
              ) : (
                  uploadProgress.status
              )}
              </span>
              </span>
                      </div>
                <div dangerouslySetInnerHTML={{ __html: props.uploadFileStatusBlock }}></div>
              </div>
          </>) : null}
        </div>:null}

        {Boolean(updateStatusText) ?
          <>
            <div className={ "dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
              {updateStatusText}
            </div>
          </> : null }

      </div>
     {props.title!=="Upload Employees"? <div className="dialog__footer flexCol alignItemsCenter">
      <button className="done-button" onClick={props.onSubmit}>Save changes</button>
      </div>:<></>}
    </div>
  </div>
  );
}

export const RoleModal = (props) => {
const [selectedOptions,setSelectedOptions]=useState([]);
  const onClose=()=>{
    props.onRoleModalClose();
  }
  const onSubmit = (e) => {
    e.preventDefault();
    props.onRoleSubmit();
    removeContainerOverlay();
  }


  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };
  return (
    <Roles
      title={props.modelTitle}
      onClose={onClose}
      roleNames={props.roleNames}
      roleOptions={props.roleOptions}
      onRoleChange={props.onRoleChange}
      onRoleSubmit={props.onRoleSubmit}
      onAddRole={props.onAddRole}
      addRoles={props.addRoles}
      onChange={props.onChange}
      modalFormStatusObj={props.modalFormStatusObj}
      setRoleOptions={props.setRoleOptions}
      setRoleNames={props.setRoleNames}
      setAssignRoles={props.setAssignRoles}
      subSelChoice={props.subSelChoice}
      setSubSelChoice={props.setSubSelChoice}
      subSelection={props.subSelection}
      addFields={props.addFields}
      selectedOptions={selectedOptions}
      setSelectedOptions={setSelectedOptions}
      subMenuHead={props.subMenuHead}
      empRole={props.empRole}
      fields={props.fields}
      setRoleItems={props.setRoleItems}
      onSubmit={(e) => onSubmit(e)}
      modelTitle={props.modelTitle}
      onUploadClose={props.onUploadClose}
      onDownloadSampleFile={props.onDownloadSampleFile}
      uploadedFile = { props.uploadedFile }
      onUploadFileChangeHandler = { (e) =>props.onUploadFileChangeHandler(e) }
      uploadFileStatusBlock = { props.uploadFileStatusBlock }
      />
  )
}