/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */


import React, { useState,useEffect ,useRef} from 'react';
import { useTranslation } from 'react-i18next';
import { PieChart, BarCharts, ListViewGridComponent, LineChart } from '../../../../components/dashboard';
import { ShowChartFullScr,Loading } from '../../../../common';
import { columnDefs } from '../../../../utils/configs';
import {options_flights} from '../../../../components/dashboard/BarCharts.tsx'
import timeVector from '../../../../assets/icons/time-vector.svg';
import totalOrders from '../../../../assets/icons/total-orders.svg';
import bar from '../../../../assets/icons/bar.svg'
import LoadingGIF from '../../../../assets/loading.gif'
import monitor from '../../../../assets/icons/monitor.svg'
import widgetIcon from '../../../../assets/icons/widget.svg';
import rubic from '../../../../assets/icons/rubic.svg';
import totalSales from '../../../../assets/icons/total-sales.svg';
import bookmarkIcon from '../../../../assets/icons/bookmark.svg';
import salesGraphIcon from '../../../../assets/icons/sales-growth-chart.svg';
import incrementIcon from '../../../../assets/icons/increment-arrow.svg';
import decrementIcon from '../../../../assets/icons/decrement-arrow.svg';
import pieChartIcon from '../../../../assets/icons/pi-chart-sales.svg';
import closeIcon from '../../../../images/icons/Icon2.png';
import usePermissions from '../../../../utils/userPermissions';
import { APIEndpoint } from '../../../../utils/axios';
import '../../../../assets/styles/styles.scss';
import { updateButtonTxtStatus,translationLength,getSalesPeriod,fetchCulture} from '../../../../utils/commonFunctions';

export const SalesOverviewNewPage = (props) => {
    const { t } = useTranslation();
    const [ sidebar, setSidebar ] = useState(false);
    const [ chartDetails, setChartDetails ]=useState({ show:false, label:"", type:"", data: [], options: [] });
    const [ customizeWidget, setCustomizeWidget ]=useState(false);
    const [warehouseArr,setWareHouseArr]=useState([]);;
    const dragItem = useRef(null);
    const dragOverItem =useRef(null);
    const [userWidgetDetails,setUserWidgetDetails]=useState({});
    const [ dataCards, setDataCards] = useState([]);
    const [ widgets, setWidgets ] = useState([]);
    const [countryDetails,setCountryDetails]=useState({});
    const [ userWidgetIds, setUserWidgetIds ] = useState([]);
    const [ notAddedWidgets, setNotAddedWidgets ] = useState([]);
    const [filteredWidgetData,setFilteredWidgetData]=useState([]);
    const [ filtersChartZoom, setFiltersChartZoom ] = useState('');
    const [allWidgetDetails,setAllWidgetDetails]=useState([]);
    const [isLoading,setIsLoading]=useState(true);
    const [resetWidgetIds,setResetWidgetIds]=useState([])
    const [listViewColumns, setListViewColumn]=useState(columnDefs);
    const sType = localStorage.getItem('sType');

    const { hasPermission: createWidgets } = usePermissions('create', 'widgets');
    const { hasPermission: viewWidgets } = usePermissions('viewDetails', 'widgets');
    const { hasPermission: saveWidgets } = usePermissions('save', 'widgets');

        useEffect( () => {
        getUserWidgetList();
        initialWidgetSettings();
        setListViewColumn(columnDefs);
    }, []);

    useEffect(() => {
        if(Object.keys(userWidgetDetails).length>0){
        initialWidgetSettings();
         const {widgets}=userWidgetDetails;
         let dataArray=widgets.filter(s=>s.type==='card')
         setDataCards(dataArray)
        }
    }, [userWidgetDetails,customizeWidget])


        useEffect(() => {
            if(Object.keys(userWidgetDetails).length>0){
                removeCardWidgets(widgets,true);
                }
            }, [customizeWidget])

        useEffect(() => {
            if(filteredWidgetData){
                setWidgets(widgets.map(obj => {
                    if (obj.widgetId ===filteredWidgetData.widgetId ) {
                      return {...filteredWidgetData};
                    }
                    return obj;
                  }));
            }

        }, [filteredWidgetData])

        useEffect(() => {
            if(userWidgetIds.includes(11) &&!warehouseArr.length){
                getWarehouses();
            }
        }, [notAddedWidgets,userWidgetIds,allWidgetDetails,widgets])



    const getUserWidgetList=async()=>{
        setIsLoading(true);
        try {
            const [resp, culture] = await Promise.all([
                APIEndpoint.get('portal/v1/user/13/widgets'),
                fetchCulture()
              ]);
            if(resp.success && culture){
                setUserWidgetDetails(resp.data);
                removeCardWidgets(resp.data.widgets,true);
                setCountryDetails(culture);
                getAllWidgetLists();
                setIsLoading(false);
            }
        }
        catch (error) {
            setIsLoading(false);
            console.log("Error occured while fetching user widget list:", error.message );
        }

    }

    const getAllWidgetLists=async()=>{

        try {
             const resp = await APIEndpoint.get(`portal/v1/widget/list/short`);
            if(resp.success){
                setAllWidgetDetails(removeCardWidgets(resp.data,false));
            }
            }
            catch (error) {
                console.log("Error::::::",error)
            }

    }

    const removeCardWidgets=(wGt,def)=>{
        const modWidgets=wGt.filter(wt=>(wt.widgetId!==12 && wt.widgetId!==13))
        if(modWidgets.length>0 && def){
            setWidgets(modWidgets.map(obj => {
                obj.selected = true;
                return obj
              }
              ));
        }
        return modWidgets;
    }

    const initialWidgetSettings = () => {
        if(Object.keys(userWidgetDetails).length>0&&allWidgetDetails&&widgets){
        setUserWidgetIds(userWidgetDetails.widgetIds);
        setNotAddedWidgets(allWidgetDetails.map( _ => {
                if ( !widgets.find( a => a.widgetId === _.widgetId) ) {
                    _.selected = false;
                }
                else {
                    _.selected = true;
                }
                return _;
            })) ;
        }
        displayNotAddedWidgets(notAddedWidgets);

    };

    const getWarehouses = async () => {
        try {
         const resp = await APIEndpoint.get('warehouses');
        if (resp.result.length > 0) {
            setWareHouseArr(resp.result);
        }
        } catch (error) {

        }

}

   const customizeView = () => {
        setCustomizeWidget(!customizeWidget);
        initialWidgetSettings();
        getUserWidgetList();
    }

    const handleSort = () => {
        let _componentData = [ ...userWidgetIds ]

        const temp = _componentData[dragItem.current];
        _componentData[dragItem.current] = _componentData[dragOverItem.current];
        _componentData[dragOverItem.current] = temp;

        dragItem.current = null
        dragOverItem.current = null
        setUserWidgetIds(_componentData)
    }
    const  onSelectCheckbox = (e) => {
        const widgetId = parseInt(e.target.id);
        setUserWidgetIds([ ...userWidgetIds,widgetId ]);
        setResetWidgetIds((_)=>{
            if(_.includes(widgetId)){
                return _.filter((id) => id !== widgetId);
            }else{
                return[..._,widgetId]
            }
           });
           const selWid=[...widgets, ...notAddedWidgets.filter(_ =>  {
                if ( _.widgetId === widgetId ) {
                    _.selected = true;
                }
                return _;
            })]
            setWidgets(selWid.reduce((acc, curr) => {
                if (!acc.find((item) => item.widgetId === curr.widgetId)) {
                  acc.push(curr);
                }
                return acc;
              }, []));
            setNotAddedWidgets([ ...notAddedWidgets.filter(_ =>
                _.widgetId!==widgetId)]);
    }
    const  onSelectCheckboxRemove = (e) => {
        const widgetId = parseInt(e.target.id);
        setResetWidgetIds((_)=>{
            if(_.includes(widgetId)){
                return _.filter((id) => id !== widgetId);
            }else{
                return[..._,widgetId]
            }
           });
        setUserWidgetIds([ ...userWidgetIds.filter(_ => _ !== widgetId ) ]);
        setWidgets([...widgets.filter(_ => _.widgetId!==widgetId)])
        setNotAddedWidgets([...allWidgetDetails.filter(_ =>
            {
                if ( _.widgetId === widgetId ) {
                    _.selected = false;
                }
                return _;
            }) ]);
    }

    const onCloseView=()=>{
        setChartDetails((_prev) => {
            return { ..._prev, show: false }
          })
    }
    const onCloseCard=()=>{
        setChartDetails((_prev) => {
            return { ..._prev, showCard: false }
          })
    }

    const onSalesViewClick=(label,data,options,salesData)=>{
        // handleZoom(label,data,options,salesData);
    }

    const showSidebar = () => {
        setSidebar(!sidebar);
    }

    const resetWidgetView = (e) => {
    if(resetWidgetIds.length){
    let updatedUserWidgetIds = [...userWidgetIds];
    let updatedWidgetNew = [...allWidgetDetails];
    let updatedRemovedWidgetNew = [...allWidgetDetails];

    resetWidgetIds.forEach((wId) => {
      if (!updatedUserWidgetIds.includes(wId)) {
        updatedUserWidgetIds.push(wId);
      } else {
        const index = updatedUserWidgetIds.indexOf(wId);
        if (index !== -1) {
          updatedUserWidgetIds.splice(index, 1);
        }
      }
    });

   setUserWidgetIds(updatedUserWidgetIds);

updatedWidgetNew = updatedWidgetNew.map((widg) => {
    if (resetWidgetIds.includes(widg.widgetId)) {
      const isWidgetInUser = userWidgetIds.includes(widg.widgetId);
      widg.selected = isWidgetInUser ? true : false;
    }
    return widg;
  });

  updatedRemovedWidgetNew = updatedRemovedWidgetNew.map((widg) => {
    if (resetWidgetIds.includes(widg.widgetId)) {
      const isWidgetInUser = userWidgetIds.includes(widg.widgetId);
      widg.selected = isWidgetInUser ? false : true;
    }
    return widg;
  });

setNotAddedWidgets(updatedRemovedWidgetNew);
setWidgets(updatedWidgetNew);

 setResetWidgetIds([])
 updateButtonTxtStatus(true, e, 'Resetting...', true);
 updateButtonTxtStatus(true, e, 'Reset', false, { defaultButtonText: 'Reset' });
}
    };

    const updateWidgetView = (e) => {
        updateButtonTxtStatus(true, e, 'Applying...', true);
        setResetWidgetIds([])
        const employeeId = 13;
        // Call the API with present UserWidgetIds
        APIEndpoint.post('portal/v1/user/' + employeeId + '/widgets', {
            viewId: 1,
            widgetIds: userWidgetIds
        }).then( resp => {
            updateButtonTxtStatus(true, e, 'Applied', false, { defaultButtonText: 'Apply' });
            getUserWidgetList();
            getAllWidgetLists();
            setSidebar(!sidebar);
            setCustomizeWidget(!customizeWidget)

        })
        .catch( err => {
            updateButtonTxtStatus(false, e, 'Failed!', false, { defaultButtonText: 'Apply' });
        });
    }

    const setListViewData = (data) => {
        let res;
        if(data){
          res = listViewColumns.map((c,i)=>{
            const field = Object.keys(data)[i]
            return {...c,field: field, headerName: getHeader(field)}
          })
        //   res = listViewColumns.map( (c,i) => i===0 ? c : (i = Object.keys(data)[i-1], {...c, field: i, headerName: getHeader(i)}));
        }
        return res;
    }

   const getHeader= (s)=>{
        if(s){
            const unCamelCase = s[0].toUpperCase() + s.substring(1).replace(/([A-Z])/g, r => ` ${r}`)
            return unCamelCase;
        }
    }

  const modifyListHeader=(header)=>{
        if(header && header.itemId){
         delete header.itemId;
         return header
        }
        else if(header && header.id){
         delete header.id;
         return header
        }else {
         return header
        }
  }

  const displayNotAddedWidgets=(n)=>{
    let res= n.filter(_=>!_.selected).map((_, index) => {
        return (
            <li className='nav-menu-components text-inactive' key={ index }>
                <div className='checkbox__dashboard'>
                    <input
                        id={ _.widgetId}
                        name={"important"+ _.widgetId}
                        type='checkbox'
                        checked=""
                        onChange={() => {}}
                        className='floatLeft'
                        onClick={(e) => onSelectCheckbox(e)}
                    />
                </div>
                <div id={"important-span" + _.widgetId} className='nav-menu-components-title  floatLeft mt-2'>{ _.widgetName}</div>
            </li>
        );
    })

    return res;
  }

const getFiltersList=(w)=>{
    const {filters}=w;
    let wName={},
     wHouse="",
     weekMonth="";
    if(filters&&filters?.warehouseId){
            wName=warehouseArr.find(w=>w.id===filters.appliedFilters?.warehouseId)
            wHouse=wName?.value?wName?.value:"";
    }
    if(filters&&filters.sortBy)
    {
        weekMonth=filters.sortBy?filters.sortBy:"";
    }
    if(wName)
    {
        wName=wName?wName:{};
    }

    return [wHouse,weekMonth,wName]
  }
//   const checkRoleBasedAccess=async()=>{
//     try {
//     return  await fetchAccessDetails(sType,'portal.menu.dashboard','view')
//     } catch (error) {
//         return false;
//     }
//   }
//   const handleAccessCheck = async () => {
//     const access = await checkRoleBasedAccess();
//    return access;
// };
// const runAccessCheck = async () => {
//     const hasAccess = await handleAccessCheck();
//     if (hasAccess) {
//         console.log("User has access to the dashboard.");
//         // Proceed with the action that requires access
//     } else {
//         console.log("User does not have access to the dashboard.");
//         // Handle lack of access (e.g., show a message, redirect, etc.)
//     }
// };

// console.log("cdscsd",runAccessCheck())
  const barWidgetAmountRep=(w)=>{
    const formatCount = (value) => {
        if (Math.abs(value) >= 1e6) {
          return (value / 1e6).toFixed(0) + 'M';
        } else if (Math.abs(value) >= 1e3) {
          return (value / 1e3).toFixed(0) + 'K';
        } else {
          return value.toFixed(0);
        }
      };
    if (w && w.options && (w.chartType === 'bar' || w.chartType ==='line'|| w.chartType ==='pie')) {
        w.options.scales.y.ticks.callback = function (value) {
              return formatCount(value);
          };

          return w;
        }

      return w;
  }

    const handleZoom=(label,data,options=null,salesData=null)=>{
        switch (label) {
            case 'saleReport':
            setChartDetails({show:true,showCard:false,label,type:"pie",data,options})
            break;
            case 'daily':
            setChartDetails({show:true,showCard:false,label,type:"bar",data,options})
            break;
            case 'listView':
            setChartDetails({show:true,showCard:false,label:label,type:"list",data:data,options:options})
            break;
            default:
            setChartDetails({show:false,showCard:true,label,type:"cards",data,options,salesData})
            break;
        }
    }

    const salesAndOrders=(items)=>{
        // eslint-disable-next-line array-callback-return
        const res=items.map((_,index)=>{
    if(_.widgetKey==='orderSalesCurrentFinancialYear'){
    const {salesConsolidated}=_.datasets
           return(
           <>
                <div className="col-lg-3 card-container-sales-header card-sales" onClick={()=>onSalesViewClick('totalSales',_.data,_.datasets.options,_.datasets)} key={index + '-1fincial-year-sales'}>
                <div className="card-content2">
                    <div>
                        {t('pages.dashboardContent.salesOverview.totalSales')}
                        {salesConsolidated.delta>0?<div className="dashboard-header-increment floatRight">
                            <img className="mb-1" alt='inc' src={incrementIcon} />
                            {salesConsolidated.delta}%
                        </div>:<div className="dashboard-header-decrement floatRight">
                        <img className="mb-1" alt='dec' src={decrementIcon} />
                        {salesConsolidated.delta}%
                        </div>}
                    </div>
                    <div className="h6">{0}</div>
                </div>
                <div className='dashboard-header-footer'>
                    <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={totalSales} />
                    <div className='header-footer-text mt-3'>
                        <img className="dashboard-header-icon" alt='Time' src={timeVector} /> {t('pages.dashboardContent.salesOverview.beginning')} - {getSalesPeriod(salesConsolidated.startDate)}
                    </div>
                </div>
            </div>
            <div className="col-lg-3 card-container-sales-header card-sales" onClick={()=>onSalesViewClick('totalOrders',_.data,_.datasets.options,_.datasets)}  key={index + '-2fincial-year-orders'}>
                <div className="card-content2">
                    <div>{t('pages.dashboardContent.salesOverview.totalOrders')}
                    {salesConsolidated.delta>0?<div className="dashboard-header-increment floatRight">
                            <img className="mb-1" alt='inc' src={incrementIcon} />
                            {salesConsolidated.delta}%
                        </div>:<div className="dashboard-header-decrement floatRight">
                        <img className="mb-1" alt='dec' src={decrementIcon} />
                        {salesConsolidated.delta}%
                        </div>}
                        </div>
                    <div className="h6"> {0}</div>
                </div>
                <div className='dashboard-header-footer'>
                    <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={totalOrders} />
                    <div className='header-footer-text mt-3'>
                        <img className="dashboard-header-icon" alt='Time' src={timeVector} /> {t('pages.dashboardContent.salesOverview.beginning')} - {getSalesPeriod(salesConsolidated.startDate)}
                    </div>
                </div>
            </div>
      </>

      )
}
else if(_.widgetKey==='currentMonthAvgSalesOrders'){
    const {salesConsolidated,orders,sales}=_.datasets
    return(
    <>
        <div className="col-lg-3 card-container-sales-header card-sales" onClick={()=>onSalesViewClick('currMonthSales',_.data,_.datasets.options,_.datasets)}  key={index + '-month-sales'}>
            <div className="card-content2">
                <div>{t('pages.dashboardContent.salesOverview.currentMonthSales')}
                {salesConsolidated.delta>0?<div className="dashboard-header-increment floatRight">
                            <img className="mb-1" alt='inc' src={incrementIcon} />
                        {salesConsolidated.delta}%
                        </div>:<div className="dashboard-header-decrement floatRight">
                        <img className="mb-1" alt='dec' src={decrementIcon} />
                        {salesConsolidated.delta}%
                        </div>}
                </div>
                <div className="h6"> {sales}</div>
            </div>
            <div className='dashboard-header-footer'>
                <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={salesGraphIcon} />
                <div className='header-footer-text mt-3'>
                    <img className="dashboard-header-icon" alt='Time' src={timeVector} /> {t('pages.dashboardContent.salesOverview.beginning')} - {getSalesPeriod(salesConsolidated.startDate)}
                </div>
            </div>
            </div>
            <div className="col-lg-3 card-container-sales-header card-sales" onClick={()=>onSalesViewClick('currMonthOrders',_.data,_.datasets.options,_.datasets)}  key={index + '-month-orders'}>
                <div className="card-content2">
                    <div>{t('pages.dashboardContent.salesOverview.currentMonthOrders')}
                    {salesConsolidated.delta>0?<div className="dashboard-header-increment floatRight">
                            <img className="mb-1" alt='inc' src={incrementIcon} />
                            {salesConsolidated.delta}%
                        </div>:<div className="dashboard-header-decrement floatRight">
                        <img className="mb-1" alt='dec' src={decrementIcon} />
                        {salesConsolidated.delta}%
                        </div>}
                        </div>
                    <div className="h6"> {orders}</div>
                </div>
                <div className='dashboard-header-footer'>
                    <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={pieChartIcon} />
                    <div className='header-footer-text mt-3'>
                        <img className="dashboard-header-icon" alt='Time' src={timeVector} /> {t('pages.dashboardContent.salesOverview.beginning')} - {getSalesPeriod(salesConsolidated.startDate)}
                    </div>
                </div>
            </div>
    </>
    )
    }
    else if(_.widgetKey==='noOfCashDrawersOrStations'){
        return( <><div className="col-lg-3 card-container-sales-header card-sales"  key={index + '-3fincial-year-orders'}>
        <div className="card-content2">
            <div>{t('pages.dashboardContent.salesOverview.cashDrawer')}

                </div>
            <div className="h6">{_.datasets.noOfCashDrawers}</div>
        </div>
        <div className='dashboard-header-footer'>
        <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={salesGraphIcon} />

        </div>
    </div></>  )
    }

    else if(_.widgetKey==='currentMonthAvgSalesPerSectorPerDay'){
        return( <><div className="col-lg-3 card-container-sales-header card-sales"  key={index + '-4fincial-year-orders'}>
        <div className="card-content2">
            <div>{t('pages.dashboardContent.salesOverview.currentMonthSalesAvg')}

                </div>
                <div className="h6">
  {(countryDetails && countryDetails.currency && countryDetails.currency.symbol) ? countryDetails.currency.symbol : t('pages.dashboardContent.salesOverview.rs')}:{_.datasets.sales}</div>
        </div>
        <div className='dashboard-header-footer'>
        <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={totalOrders} />

        </div>
    </div>
    <div className="col-lg-3 card-container-sales-header card-sales"  key={index + '-5fincial-year-orders'}>
        <div className="card-content2">
            <div>{t('pages.dashboardContent.salesOverview.currentMonthSalesAvg')}

                </div>
            <div className="h6">{_.datasets.orders}</div>
        </div>
        <div className='dashboard-header-footer'>
        <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={totalOrders} />

        </div>
    </div>
    <div className="col-lg-3 card-container-sales-header card-sales"  key={index + '-6fincial-year-orders'}>
        <div className="card-content2">
            <div>{t('pages.dashboardContent.salesOverview.itemSoldInt')}
            <div className="h6">
  {(countryDetails && countryDetails.currency && countryDetails.currency.symbol)? countryDetails.currency.symbol: t('pages.dashboardContent.salesOverview.rs')}: {0}</div>
                </div>
        </div>
        <div className='dashboard-header-footer'>
        <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={bar} />

        </div>
    </div>
    <div className="col-lg-3 card-container-sales-header card-sales"  key={index + '-7fincial-year-orders'}>
        <div className="card-content2">
            <div>{t('pages.dashboardContent.salesOverview.itemSoldDom')}
            <div className="h6"> {0}</div>
                </div>
        </div>
        <div className='dashboard-header-footer'>
        <img className="dashboard-header-main-icon floatRight pb-1" alt='Current Month Avg Orders' src={monitor} />
        </div>
    </div>
    </>  )
    }
          })
          return res;
    }

    return (
        <>
          <div className="container-fluid dashboard-summary-container sm-scrollbar" id="dashboard-summary-container">
                <div className="row flexRow">
                    <div>
                        <nav className={sidebar ? 'nav-menu active' : 'nav-menu '}>
                            <div className='nav-menu-items widgets-side-menu-container sm-scrollbar'>
                                <div className='nav-menu-header'>
                                    <div className='nav-menu-header-title'>{t('pages.dashboardContent.dashboard.widgetMenuBar.customizeWidget')}<img className="modal__close_icon floatRight mt-1" style={{cursor:'pointer'}} alt='Close' src={closeIcon} onClick={showSidebar} /></div>
                                </div>

                                <div>
                                   { widgets.length>1?<div className='nav-menu-subtitle'>{t('pages.dashboardContent.dashboard.widgetMenuBar.dragToReorder')}</div>:<></>}
                                    <ul className='nav-menu-components-box'>
                                    {
                                        userWidgetIds
                                        .map(( _, index) => {
                                            const widget = widgets.find(w => w.widgetId === _ );
                                            let data = null;
                                            if (widget) {
                                                data = (
                                                    <li className='nav-menu-components active-text'
                                                        key={ index }
                                                        draggable
                                                        style={{cursor:'pointer'}}
                                                        onDragStart={(e) => (dragItem.current = index)}
                                                        onDragEnter={(e) => (dragOverItem.current = index)}
                                                        onDragEnd={handleSort}
                                                        onDragOver={(e) => e.preventDefault()}
                                                    >
                                                        <div className='checkbox__dashboard'>
                                                            <input
                                                            id={widget.widgetId}
                                                            name={"important"+widget.widgetId}
                                                            type='checkbox'
                                                            checked={true}
                                                            onChange={() => {}}
                                                            className='floatLeft'
                                                            onClick={(e) => onSelectCheckboxRemove(e)}
                                                            />
                                                        </div>
                                                        <div id={"important-span"+widget.widgetId} className='nav-menu-components-title floatLeft mt-2'>{widget.widgetName}</div>
                                                        <div className='drag-reorder-widgets'>
                                                            <img className="icon" alt='widgets' src={rubic} />
                                                        </div>
                                                    </li>
                                                )
                                            }
                                            return data;
                                        })
                                    }
                                    </ul>
                                </div>

                                <div className='mb-4'>
                                    {notAddedWidgets.some(s=>s.selected===false)&&<div className='nav-menu-subtitle'>{t('pages.dashboardContent.dashboard.widgetMenuBar.selectToAdd')}</div>}
                                    <ul className='nav-menu-components-box'>
                                      {displayNotAddedWidgets(notAddedWidgets)}
                                    </ul>
                                </div>
                                <hr />
                                <div className="dialog__footer flexRow justifyContentSpaceEvenly mt-4">
                                    <button className={!resetWidgetIds.length?'reset-button-disabled':'reset-button'} disabled={!resetWidgetIds.length} onClick={resetWidgetView}>{t('pages.dashboardContent.dashboard.widgetMenuBar.reset')}</button>
                                  { saveWidgets ? <button className="apply-button" onClick={updateWidgetView}>{t('pages.dashboardContent.dashboard.widgetMenuBar.apply')}</button>:<></>}
                                </div>

                            </div>
                        </nav>
                    </div>

                    <div className="dashboard-header width100">
                        <div className="dashboard-header-title">
                            {/* <div className="card-heading-text">{t('pages.dashboardContent.dashboard.header.title')}</div> */}
                        </div>
                        <div className="dashboard-header-button flexRow justifyContentFlexEnd alignItemsCenter margRight">
                        {!customizeWidget && createWidgets && <button  disabled={!(isLoading===false&&allWidgetDetails.length>0)} className='customize-button' onClick={customizeView}>
                            {!(isLoading===false&&allWidgetDetails.length>0)?<div className='flexRow justifyContentCenter alignItemsCenter'><img src={LoadingGIF}style={{margLeft:'5px',width:'25px',height:'25px'}} alt="Loading" /><span>Loading <div className='loading-dots'>
                                <span className='loaderdots-button' style={{'--d':'200ms'}}></span>
                                <span className='loaderdots-button' style={{'--d':'400ms'}}></span>
                                <span className='loaderdots-button' style={{'--d':'600ms'}}></span>
                                </div></span></div>:<><img className="icon" alt='widgets' src={widgetIcon} />
                            {t('pages.dashboardContent.dashboard.header.customize')}</>}
                            </button>}

                            {customizeWidget&&<><button className='dashboard-button-main' onClick={customizeView}>
                                {t('pages.dashboardContent.dashboard.header.cancel')}
                            </button>

                            {false&&<button className='dashboard-button'>
                                <img className="icon" alt='widgets' src={bookmarkIcon} />
                                {t('pages.dashboardContent.dashboard.header.saveView')}
                            </button>}

                            <button className='flex justifyContentCenter alignItemsCenter widget-button-main' onClick={showSidebar}>
                            <img className="icon" alt='widgets' src={widgetIcon} />
                            {t('pages.dashboardContent.dashboard.header.widgets')}
                            </button></>}
                        </div>
                    </div>

                    <div className='scroll-container sm-scrollbar'>
                    { viewWidgets && salesAndOrders(dataCards)}
                    </div>
                </div>


                <div style={{marginTop:'1.1rem'}} className="row flexRow">
                    {
                       userWidgetIds && userWidgetIds.map(( _, index) => {
                            const widget = widgets.find(w => w.widgetId === _ );
                            const widgetAmount=barWidgetAmountRep(widget);
                            let data = null;
                            if (widget && viewWidgets) {
                                if(widget.chartType === 'pie'){
                                    data = (
                                        <div className='col-sm-1 col-md-2 col-lg-3 card-container3' key={widget.id}>
                                            <div className="card-content2 marginHalf">
                                                <PieChart chartLabel='saleReport' data={widgetAmount} setFilteredWidgetData={setFilteredWidgetData} filtersPie={widget.filters} setFiltersChartZoom={setFiltersChartZoom} handleZoom={handleZoom} />
                                            </div>
                                        </div>
                                    )
                                }
                                else if(widget.chartType === 'bar'){
                                    data = (
                                    <div className='col-sm-1 col-md-2 col-lg-3 card-container3' key={widget.id}>
                                        <div className="card-content2 marginHalf">
                                             <BarCharts chartLabel='daily'  handleZoom={handleZoom} setFilteredWidgetData={setFilteredWidgetData} setFiltersChartZoom={setFiltersChartZoom} filtersBar={widget.filters} data={widgetAmount} options={widget.options? widget.options: options_flights}/>

                                        </div>
                                    </div>
                                    )
                                }
                                else if(widget.type === 'listView'){
                                    data = (
                                        <div className="col-sm-1 col-md-2 col-lg-3 card-container4 sm-scrollbar" key={widget.id}>
                                            <div className="card-content2 listGridSection marginHalf">
                                                <ListViewGridComponent
                                                    id={widget?.widgetId}
                                                    title={widget?.widgetName}
                                                    filtersList={getFiltersList(widget)}
                                                    setFilteredWidgetData={setFilteredWidgetData}
                                                    warehouseArr={warehouseArr}
                                                    rowsData={ widget?.datasets[0].items}
                                                    columnDefs={ setListViewData(modifyListHeader(widget?.datasets[0].items[0])) }
                                                    handleZoom={handleZoom}
                                                    chartLabel='listView'
                                                />
                                            </div>
                                        </div>
                                    )
                                }
                                else if(widget.chartType === 'line'){
                                    data = (
                                        <React.Fragment key={widget.id}> <LineChart
                                            setFilteredWidgetData={setFilteredWidgetData}
                                            id={widget?.widgetId}
                                            filtersLine={widget.filters}
                                            title={widget?.widgetName} legends={widget?.legends}
                                            labels={widget?.labels} datasets={widgetAmount?.datasets}
                                        /></React.Fragment>

                                    )
                                }

                            }
                            return data;
                        })
                    }

                    {!Object.keys(userWidgetDetails).length?<>
                        <div className="selectWidgetDashboard flexCol justifyContentSpaceEvenly alignItemsCenter full-flex">
                            <Loading size={"61px"} className={"custom-loading"} />
                            <span style={{ fontSize: "1rem" }}>Loading
                            <div className='loading-dots'>
                                <span className='loaderdots' style={{'--d':'200ms'}}></span>
                                <span className='loaderdots' style={{'--d':'400ms'}}></span>
                                <span className='loaderdots' style={{'--d':'600ms'}}></span>
                                </div></span>
                        </div>
                    </>:!widgets.length? <>
                        <div className="selectWidgetDashboard flexRow justifyContentSpaceEvenly alignItemsCenter">
                            <p className="alert_error">{t('pages.dashboardContent.dashboard.message.addWidget')}</p>
                        </div>
                    </>:<></>}

                </div>


                </div>
                {
                    chartDetails.show && viewWidgets && <ShowChartFullScr onClose={onCloseView} filtersChartZoom={filtersChartZoom} setFiltersChartZoom={setFiltersChartZoom} chartType={chartDetails.type} chartZoom={chartDetails.show} chartData={chartDetails.data} chartOptions={chartDetails.options}/>
                }
                {
                    chartDetails.showCard && viewWidgets && <ShowChartFullScr onClose={onCloseCard} chartType={chartDetails.type} chartZoom={chartDetails.show} chartData={chartDetails.data} chartLabel={chartDetails.label} chartOptions={chartDetails.options} salesData={chartDetails.salesData}/>
                }
                {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }

        </>

    );
}

