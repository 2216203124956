
/* eslint-disable no-unused-vars */
import React ,{useState,useEffect}from 'react'
import {HOST_SERVER } from '../utils/configs';
import '../assets/styles/modal.scss'
import '../assets/styles/styles.scss'
import { CsvModal} from "./CsvModal";
import { NGODatePicker } from "./DatePicker";
import SelectSearch from "react-select-search";
import AllMultiSelect from "../../src/common/AllSelectCheckbox.jsx";
import { components } from "react-select";
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';


const InputContainer = (props) => {
  const { type, containerClassName } = props;

  return <>
    { (type === "hidden") ?
      props.children
    :
      <div className={`flexCol justifyContentSpaceEvenly ${containerClassName || ''}`}>
        {props.children}
      </div>
    }
    </>;
}


const Option = props => {
  return (
  <div>
  <components.Option {...props}>
  <input
  type="checkbox"
  checked={props.isSelected}
  onChange={() => null}
  />{" "}
  <label>{props.label}</label>
  </components.Option>
  </div>
  );
  };

  const MultiValue = props => (
  <components.MultiValue {...props}>
  <span>{props.data.label}</span>
  </components.MultiValue>
  );

export const Input = (props) => {

  const [gstSelected, setGstSelected] = useState({inclusiveGst:false,exclusiveGst:false});
  const [selectedFile, setSelectedFile] = useState()
  const [selectSearchOptions, setSelectSearchOptions] = useState([])
  const [selectAllValues, setSelectAllValues] = useState([])
  const [selectOptions, setSelectOptions] = useState(selectSearchOptions)
  const [preview, setPreview] = useState({});
  const { type, id, label, className,inpClassName="", required,title, tipInfo, info, note, data , ...rest} = props;
  const [itemCheck, setItemCheck] = useState(typeof data === 'boolean' ? data :null);
  const [categoryOptions, setCategoryOptions] = useState([]);
  const [isValidImgUrl, setIsValidImgUrl] = useState(true)

  useEffect(() => {
    if(props.attribute==="subCategory"){
      setCategoryOptions( props.options.map((opt)=>{
        opt.value=opt.value1
        return opt
      }))
    }
    else{
      setCategoryOptions(props.options)
    }
  }, [props])

  useEffect( () => {

    setSelectSearchOptions(props.options);
  }, [props] );


    useEffect( () => {
    if((props.attribute==='regions'||props.attribute==='hsn' || props.attribute==='warehouses')&& props.data && props.type==='rcSelectAll'){
   setSelectAllValues(props.options.filter(item =>
      props.data.find(filterItem =>
        filterItem.value === item.value ||
        filterItem.key === item.key||
        filterItem === item.value||
        filterItem.name=== item.value
    )
    ))
    }
    }, [props.attribute, props.data, props.options, props.type] );

  useEffect(() => {
    if (!selectedFile) {
      setPreview(undefined)
      return
    }

    const objectUrl = URL.createObjectURL(selectedFile)
    setPreview(objectUrl)
    return () => URL.revokeObjectURL(objectUrl)
  }, [selectedFile])

  const handleChangeCheck=(e)=>{
    const {checked}=e.target;
    setItemCheck(checked);
  }
  const onDeleteFile = e => {
    document.getElementById('upload-img').value = "";
    setSelectedFile(undefined)
  }

  const onSelectFile = async(e) => {
    let [file] = e.target.files

    if (!e.target.files || e.target.files.length === 0) {
      setSelectedFile(undefined)
      return
    }
    setSelectedFile(e.target.files[0])
    try {
      const dimensions = await imageDimensions(e,file)
      console.info(dimensions)
    } catch(error) {
      console.error(error)
    }

  }


  const getOptions=(arr)=>{
    return arr.filter(a=>a.value);
  }

  const handleSelectChange=(selected)=>{
    const arrSel=selected?.filter(sl=>sl.value!=="*");
    setSelectOptions(null,arrSel)
    setSelectAllValues(arrSel)
    props.onChange(null,arrSel,null)
  }

  const handleGstSelections = (e) => {
    const { name, checked } = e.target;

    const updatedState = {
      [name]: checked,
      ...(name === 'inclusiveGst' ? { exclusiveGst: !checked } : {}),
      ...(name === 'exclusiveGst' ? { inclusiveGst: !checked } : {}),
    };
    setGstSelected((prev) => {
      props.onChange(e, updatedState,null);
      return updatedState;
    });
  };

  const imageDimensions = (e,file)=>
    new Promise((resolve, reject) => {
      const img = new Image()
      img.onload = () => {
          const { naturalWidth: width, naturalHeight: height } = img
          if(height !== 100 || width !== 100){
            setPreview(undefined)
            document.getElementById('upload-img').value = "";
            alert("Please select a png image with dimensions exactly equal to 100 x 100 pixels")
            reject('There was some problem with the image.')
          }else{
            resolve({ width, height })
          }
          props.onChange(e,{},file)
      }
      img.onerror = () => {
          reject('There was some problem with the image.')
      }
      img.src = URL.createObjectURL(file)
  })


  const displayInputs = () => {
    if (type === 'hidden') {
      return <input
        id={id || ''}
        type={type || 'text'}
        className='edit-input'
        {...rest}
      />
    }

    else if (type === 'textarea') {
      return (
      <>
        <div className={`${className || ''} dialog__container_item input-container`}>
          <div className={`dialog__description`}>
            <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
            <textarea
              className={`${Boolean(props.rows) ? 'edit-input' :'edit-input-textarea'}`}
              required={ required ? true : false }
              {...rest}
            />
          </div>
        </div>
      </>
      );
    }
    else if (type === 'date') {
      return (
      <>
        <div className={`${className || ''} dialog__container_item input-container`}>
          <div className={`dialog__description`}>
            <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
            <NGODatePicker
              id={id || ''}
              required={ required ? true : false }
              style={{width:`${props.width ? props.width :'200px'}`,margin:"0.6rem 0rem",outline:'none',fontSize:14}}
              {...rest}
            />
          </div>
        </div>
      </>
      );
    }

    else if (type === 'select') {
      return (
        <>
          <div className={`${className || ''} dialog__container_item input-container`}>
            <div className={`dialog__description`}>
              <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
              <select
                  id={id || ''}
                  className='select-input'
                  style={rest.style}
                  required={ required ? true : false }
                  {...rest}
              >
              {
                categoryOptions.map((_, index) => {
                    return (
                      <option value={!_.value && _.value!==""?_.label:_.value} key={index}>{_.label}</option>
                    )
                })
              }
            </select>
            </div>
          </div>
        </>
        );
    }
    else if (type === 'selectSearch') {
      return (
        <>
          <div className={`${className || ''} dialog__container_item input-container`}>
            <div className={`dialog__description`}>
              <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
              <SelectSearch
                id={id || ''}
                required={ required ? true : false }
                options={selectSearchOptions} {...rest}
                value={props.value}
              />
            </div>
          </div>
        </>
        );
    }
    else if (type === 'multiSelect') {
      return (
        <>
          <div className={`${className || ''} dialog__container_item input-container`}>
            <div className={`dialog__description`}>
              <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
              <Select
                defaultValue={""}
                value={props.options.filter(function(option) {
                  return props.value&&props.value===option.label
                })}
                onChange={props.onChange}
                options={getOptions(props.options)}
                styles={ {
                  input: provided => ({
                    ...provided,
                    color: '#fff'
                  })
                ,
                control: (defaultStyles) => ({
                  ...defaultStyles,
                  backgroundColor: "#162E3E",
                  width: '13rem',
                  boxShadow: "none",
                  margin :"0.5rem 0rem",
                })
              }}
                isSearchable
                isClearable
                placeholder={`Search ${props.label}`}
              />
            </div>
          </div>
        </>
        );
    }
    else if (type === 'selectSearch2') {
      return (
        <>
          <div className={`${className || ''} dialog__container_item input-container`}>
            <div className={`dialog__description`}>
              <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
              <Select
                defaultValue={""}
                value={props.options.filter(function(option) {
                  return props.value&&props.value===option.value
                })}
                onChange={props.onChange}
                options={getOptions(props.options)}
                styles={ {
                  input: provided => ({
                    ...provided,
                    color: '#fff'
                  })
                ,
                control: (defaultStyles) => ({
                  ...defaultStyles,
                  backgroundColor: "#162E3E",
                  width:`${props.width?props.width:'13rem'}`,
                  boxShadow: "none",
                  margin :"0.5rem 0rem",
                })
              }}
                isSearchable
                isClearable
                placeholder={`Search ${props.label}`}
              />
            </div>
          </div>
        </>
        );
    }
    else if (type === 'createSelect') {
      return (
        <>
          <div className={`${className || ''} dialog__container_item input-container`}>
            <div className={`dialog__description`}>
              <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
              <CreatableSelect
                defaultValue={""}
                value={props.options.filter(function(option) {
                  return props.value&&props.value===option.label
                })}
                onChange={props.onChange}
                options={getOptions(props.options)}
                styles={ {
                  input: provided => ({
                    ...provided,
                    color: '#fff'
                  })
                ,
                control: (defaultStyles) => ({
                  ...defaultStyles,
                  backgroundColor: "#162E3E",
                  width: '13rem',
                  boxShadow: "none",
                })
              }}
                isSearchable
                placeholder={`Search ${props.label}`}
              />
            </div>
          </div>
        </>
        );
    }
    else if (type === 'rcSelectAll') {
      return (
        <>
          <div className={`dialog__container_item input-container margTop8`}>
         <div className={`dialog__description`}>
              <label htmlFor={id}  style={{marginBottom:'0.5rem'}} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
                <AllMultiSelect
                options={selectSearchOptions}
                isMulti
                styles={ {
                input: provided => ({
                ...provided,
                color: '#fff'
                })
                ,
                option: (defaultStyles, state) => ({
                  ...defaultStyles,
                 width:'18rem',
                }),
                control: (defaultStyles) => ({
                ...defaultStyles,
                borderRadius:'3px',
                backgroundColor: "#162E3E",
                width: '12.8rem',
                boxShadow: "none",
                }),
                placeholder: (defaultStyles) => ({
                  ...defaultStyles,
                  textAlign: 'left',
                })
                }
                }
                value={selectAllValues}
                isSearchable
                allowSelectAll={true}
                components={{ Option, MultiValue }}
                 onChange={handleSelectChange}
                placeholder={`${props.label}`}
                />
            </div>
          </div>
        </>
        );
    }
    else if (type === 'checkbox') {
      return (
        <>
        <div className={`${className || ''} dialog__container_item input-container`}>
            <div className={`dialog__description__checkbox`}>
              <input
                  id={id || ''}
                  type={type || 'checkbox'}
                  onClick={handleChangeCheck}
                  checked={itemCheck}
                  value={itemCheck}
                  required={ required ? true : false }
                  {...rest}
                />
                <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':'margTop8'}`} >{label}</label>
            </div>
        </div>
        </>
      );
    }
    else if (type === 'uploadImage') {
      return (
        <div className={`${className || ''} dialog__container_item input-container`}>
          <div className={`dialog__description`}>
            <label htmlFor="upload-img" className={`subheading-text-small-title fontRegular ${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
            <input
              type="file"
              id="upload-img"
              name="img"
              accept="image/png"
              onChange={onSelectFile}
            />
          </div><br></br>
          {data&&!selectedFile&&isValidImgUrl ? <img src={`${HOST_SERVER}/`+data} alt="media" onError={()=>{
            setIsValidImgUrl(false)
          }} /> : ''}
          {selectedFile && preview &&  <div><img src={preview} alt="media"/> <button className="remove-button" onClick={onDeleteFile}>Remove Image</button></div>}
        </div>
      );
    }
    else if (type === 'file') {
      return (
        <>
          <div className={`${className || ''} dialog__container_item input-container`}>
            <div className={`dialog__description`}>
              <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
              <input
                id={id || ''}
                type={type || 'text'}
                className={`edit-input ${inpClassName}`}
                required={ required ? true : false }
                {...rest}
              />
            </div>
            <div className='file_upload_status_container'></div>
          </div>
        </>
      );
    }
    else if (type==='break') {
      return (
        <>
          <div className={`${className || ''} dialog__container_item input-container`}>
            <div className={`dialog__description`}>
              <hr />
            </div>
          </div>
        </>
      )
    }
    else if (type === 'csv'||type ==='csv2'||type ==='csv3'||type ==='csv4'||type ==='csv5'||type ==='csv77') {
      return (
        <div>
          <div className={`${className || ''} dialog__container_item`}>
            <div className={`dialog__description`}>
              <CsvModal tableData={data} type={type} title={title?title:undefined}/>
            </div>
          </div>
        </div>
        );
    }
    else if (type === 'viewPurchaseOrderItems' || type === 'viewGRNDetails' || type === 'viewReverseGRNDetails' || type === 'offloadSummary' || type === 'viewLetterOfIntentItems'
      || type === 'viewWarehouseTransferItems'|| type === 'viewWarehouseTransferGRNDetails' || type === 'viewSalesDetails'||type==='viewMenuPlanner'||type==="viewPassengerDetails") {
      return (
        <div>
          <div className={`${className || ''} dialog__container_item`}>
            <div className={`dialog__description`}>
              <CsvModal tableData={data} action={props.action||null} type={type} />
            </div>
          </div>
        </div>
      );
    }
    else if (type === 'alertMessage') {
      return (
        <div>
          <div className={`${className || ''} dialog__container_item`}>
            <div className={`dialog__description`}>
                <p className='dialog__alertMessage' dangerouslySetInnerHTML={ {__html: label }}></p>
            </div>
          </div>
        </div>
      );
    }
    else if (type === 'alertMessageRemove') {
      return (
        <div>
          <div className={`${className || ''} dialog__container_item`}>
            <div className={`dialog__description`}>
                <p className='dialog_alert-remove' dangerouslySetInnerHTML={ {__html: label }}></p>
            </div>
          </div>
        </div>
      );
    }
    else if (type === 'radio-split') {
        return (
        <>
        <label htmlFor={id} className={`flexRow justifyContentCenter alignItemsCenter ${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`} >{label}</label><br/>

        <div className={`dialog__description__radio margBottom18`}>

        <label className="control control-radio" htmlFor="1">
        <input  id="1"
        type="radio"
        name="inclusiveGst"
        value= {gstSelected.inclusiveGst}
        checked={gstSelected.inclusiveGst}
        onChange={handleGstSelections}
        />
        <div className="control-indicator"></div>
        </label>
        <span className='margRight80'>Include Tax</span>

        <label className="control control-radio" htmlFor="2">
        <input  id="2"
        type="radio"
        name="exclusiveGst"
        value={gstSelected.exclusiveGst}
        checked={gstSelected.exclusiveGst}
        onChange={handleGstSelections}
        />
        <div className="control-indicator"></div>
        </label>
        <span>Exclude Tax</span>

        </div>
        </>
        );
    }

    else {
      const placeholder = rest.placeholder || label;
      return (
        <>
          <div className={`${className || ''} dialog__container_item input-container`}>
            <div className={`${placeholder==='Replenishable' ? 'dialog__description_repl' :'dialog__description'}`}>
              <label htmlFor={id} className={`${Boolean(required) ? 'req' :Boolean(info) ? 'info':''}`}>{label}</label>
              <input
                id={id || ''}
                type={type || 'text'}
                className='edit-input'
                placeholder={placeholder}
                required={ required ? true : false }
                {...rest}
              />
           {note? <label htmlFor={ id || '' } className="small-body-text-regular-subscript fontRegular-subscript">{note}</label>:<></>}
            </div>

          </div>
        </>
      );
    }
  }

  return (
      <InputContainer {...props}>
      {displayInputs()}
    </InputContainer>
  );
}