/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState,useRef,useEffect ,useMemo} from 'react';
import { DataGrid ,DeleteConf, UpdateEmployeeModal} from "../../../common";
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import deleteIcon from '../../../assets/icons/delete.svg';
import suspendIcon from '../../../assets/suspendIcon.svg'
import { onDownloadXlsFile,translationLength } from '../../../utils/commonFunctions';
import Edit2 from "../../../assets/edit2.svg";
import { APIEndpoint, DFPAPIEndpoint } from '../../../utils/axios';
import usePermissions from '../../../utils/userPermissions';
import searchIcon from '../../../assets/icons/search.svg';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import { useTranslation } from 'react-i18next';
import { PageSizes } from '../../../utils/configs';
import {RoleModal}  from '../../employees/inner-components/RoleModal';
import * as ReactDOM from 'react-dom';
import CustomSelect from '../../../common/CustomSelect';
import LoadingGIF from '../../../assets/loadingIn.gif';



const AddEmployeeField = [
  { label: 'Employee Code', attribute: 'employeeCode', type: 'text',required: true, disabled: false },
  { label: 'Name', attribute: 'name', type: 'text',required: true, disabled: false },
  {key: 1, label: 'Department', attribute: 'department', name: 'department', type: 'selectSearch'
        , defaultValue: ''
        , value: ''
        , className: 'select-search-cls'
        , options: []
        , multiple: false
        , search: true
        , autoComplete: 'on'
        , autoFocus: false
        , disabled: false, placeholder: 'Department'
    },
    {key: 2, label: 'Designation', attribute: 'designation', name: 'designation', type: 'selectSearch'
    , defaultValue: ''
    , value: ''
    , className: 'select-search-cls'
    , options: []
    , multiple: false
    , search: true
    , autoComplete: 'on'
    , autoFocus: false
    , disabled: false, placeholder: 'Designation'
},
  { label: 'Email', attribute: 'email', type: 'text', disabled: false },
  { label: 'Mobile', attribute: 'mobile', type: 'text', disabled: false },
  { label: 'Address', attribute: 'address', type: 'textarea', disabled: false,rows:4 },
  { label: 'User name', attribute: 'userName', type: 'text',required: true, disabled: false },
  { label: 'Password', attribute: 'password', type: 'password', required: true, disabled: false },
  { label: 'Re-type Password', attribute: 'passwordConf', type: 'password',required: true, disabled: false },
]
const EditFieldsMenu=[ { label: 'Cabin Crew', attribute: 'cabinCrew', type: 'checkbox', disabled: false },
{ label: 'Cabin Crew Admin', attribute: 'cabinCrewAdmin', type: 'checkbox', disabled: false },
{ label: 'Reset Password', attribute: 'resetPassword', type: 'checkbox', disabled: false }
]

export const CrewRoasterPage = (props) => {
  const [state, setState] = useState({})
  const tableRef = useRef();
  const { t } = useTranslation();
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [roleModal, setRoleModal] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [departments, setDepartments] = useState([]);
  const [remove, setRemove] = useState(false);
  const [delPop,setDelPop]=useState(false);
  const [buttonDisable,setButtonDisable]=useState(true);
  const [delConf,setDelConf]=useState(false);
  const [designations, setDesignations] = useState([]);
  const [selDepartment, setSelDepartment] = useState('');
  const [buttonStat,setButtonStat]=useState(false);
  const [roleNames, setRoleNames] = useState([]);
  const [rolesArray,setRolesArray]=useState([]);
  const [newDeptAdd,setNewDeptAdd]=useState(null);
  const [newDesigAdd,setNewDesigAdd]=useState(null);
  const [subMenuHead, setSubMenuHead] = useState('');
  const [subSelection,setsubSelection] = useState([]);
  const [assignRoles, setAssignRoles] = useState([]);
  const [isDownloading, setIsDownloading] = useState(false);
  const [departmentArr,setDepartmentArr] = useState([]);
  const [designationArr,setDesignationArr] = useState([]);
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [selDesignation, setSelDesignation] = useState('');
  const [roleOptions, setRoleOptions] = useState(null);
  const [searchKey, setSearchKey] = useState('');
  const [empSpec] = useState(true);
  const [submitRoles, setSubmitRoles] = useState([]);
  const [clusterArray,setClusterArray]=useState([]);
  const [storeArray,setStoreArray]=useState([]);
  const [wareHouseArray,setWareHouseArray]=useState([]);
  const [cashDrawerArray,setCashDrawerArray]=useState([]);
  const [actionItems, setActionItems] = useState([]);
  const [editFormFields, setEditFormFields] = useState([...AddEmployeeField,...EditFieldsMenu]);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [, setConfirm] = useState('');
  const [show, setShow] = useState(false);
  const [updatedFormFieldValues, setUpdatedFormFieldValues] = useState({});
  const [editFieldsDepartment,setEditFieldsDepartment]=useState([]);
  const [editFieldsDesignation,setEditFieldsDesignation]=useState([]);
  const [subSelChoice, setSubSelChoice] = useState([]);
  const [roleItems,setRoleItems]=useState([]);
  const [resourceObject,setResourceObject]=useState({});
  const [addRoles, setAddRoles] = useState(false);
  const [roleId,setRoleId]=useState("");
  const [checkbox,setCheckBox]=useState(true)
  const sType = localStorage.getItem('sType');
  const resType=subMenuHead.substring(0, subMenuHead.length - 1);

  const { hasPermission: createRoster } = usePermissions('create', 'crew.roster');
  const { hasPermission: editRoster } = usePermissions('edit', 'crew.roster');
  const { hasPermission: uploadRoster } = usePermissions('upload', 'crew.roster');
  const { hasPermission: downloadRoster } = usePermissions('download', 'crew.roster');
  const { hasPermission: deleteRoster } = usePermissions('delete', 'crew.roster');

  const Columns = [
    { field: 'employeeCode', sortable: true, tooltipField:'employeeCode', suppressSizeToFit:true, resizable:true,checkboxSelection: checkbox, minWidth: 135 },
    { field: 'name', sortable: true, suppressSizeToFit:true, resizable:true,flex:1, minWidth: 225 },
    { field: 'userName', sortable: true, suppressSizeToFit:true, resizable:true, minWidth: 205 },
    {
      field: 'department', minWidth: 205,suppressSizeToFit:true, resizable:true,
      valueGetter: (params) => {
        return params.data?.department?.name;
      }
    },
    {
      field: 'designation', minWidth: 250,suppressSizeToFit:true, resizable:true,
      valueGetter: (params) => {
        return params.data?.designation?.name;
      }
    }
]

  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
  const preCompile = {
    roles:t('selectButtons.selRole'),
    clusters:t('selectButtons.selCluster'),
    stores:t('selectButtons.selStore'),
    warehouses:t('selectButtons.selWarehouse'),
    cashdrawers:t('selectButtons.selCashDraw')
  }
  useEffect( () => {
    preCompile.department = t('selectButtons.selDepartment');
    preCompile.designation = t('selectButtons.selDesignation');
    preCompile.roles=t('selectButtons.selRole');
    fetchDepartment();
    fetchDesignation();
}, [t,roleModal]);



  React.useEffect(() => {
    fetchDepartment();
    fetchDesignation();
  }, [t,state.selected?.action]);

  React.useEffect(() => {
    getSubSelDep();
     }, [subSelection]);

  React.useEffect(() => {
    fetchRoles();
  }, [assignRoles,addRoles]);

  React.useEffect(() => {
 fetchRoleSelection()
  }, [roleOptions]);

  React.useEffect(() => {
    fetchEmployees();
  }, [delConf,delPop]);

  React.useEffect( () => {
    refreshTable();
  }, [selDepartment,selDesignation,tableRef]);

 React.useEffect(() => {
  if(show){
      const timeId = setTimeout(() => {
      setShow(false);
    setModalFormStatusObj({ text: '' });
    if(modalFormStatusObj.text){
      setDelPop(false);
    }
    }, 3000)
return () => {
      clearTimeout(timeId)
    }
  }
  }, [show]);

  const handleReset = () => {
    setState((_) => {
      return { ..._, resetDataSource: true };
    })
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setState((_) => {
        return { ..._, resetDataSource:modalFormStatusObj.length>0?true:false };
      })
      onClose();
    }, 500)
  }

  React.useEffect(() => {
    setActionItems([
            {
                title: '',
                attribute: 'Edit',
            },
            {
              title: '',
              attribute: 'Roles',
          }
        ]);
        setState((_) => {
        return { ..._, resetDataSource: false };
      })
}, []);

const getActionCellIcons = (cellDef, data) => {

  if (cellDef.attribute === 'Edit') {
      return [
          {src: Edit2, value:"EditItem", title: 'Edit'},
      ];
  }

  if (cellDef.attribute === 'Roles' && editRoster) {
    return [
      {src: suspendIcon, value:"Roles", title: 'Edit Roles'},
  ];
  }

  return [];
};
React.useEffect( () => {
  if (updatedFormFieldValues) {

      if ( state.selected?.action === 'editEmployee' || state.selected?.action === 'AddNewEmployee') {
          const extraFields = {};
          if (updatedFormFieldValues.department) {

              const selectedDepartment = departmentArr.find( item => item.name === updatedFormFieldValues.department );
              extraFields.departmentName = selectedDepartment.name;
          }
          setState((_prev) => {
              return { ..._prev, selected: { ..._prev.selected, ...updatedFormFieldValues, ...extraFields } };
          });
      }

  }
}, [updatedFormFieldValues] );

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchEmployees = async (page=1, pageSize=10) => {

    filterData.page = page;
    filterData.limit = pageSize;
    filterData.searchParam = searchKey;
    if (selDepartment&&Object.keys(selDepartment).length) {
      const isItemTypeExists = filterData.filters.find(row => row.department ? true : false);
      if (!isItemTypeExists) {
        filterData.filters.push({department: selDepartment.id});
      }
      // else {
      //   filterData.filters.map( row => {
      //     row.department= selDepartment.name;
      //     return row;
      //   });
      // }
    }
    if (selDesignation&&Object.keys(selDesignation).length) {
      const isItemTypeExists = filterData.filters.find(row => row.designation? true : false);

      if (!isItemTypeExists) {
        filterData.filters.push({designation: selDesignation.id});
      }
      else {
        filterData.filters.push({designation:'62980daa-907a-4adb-bf77-45fa4a863930'});
      }
    }
    try {
      const res = await APIEndpoint.post('employees/process', filterData);
      if(res.count === 0 || res.items.length === 0){
        setCheckBox(false)
        setState((_) => {
          return { ..._, resetDataSource: false};
        })
      }
      else{
        setCheckBox(true)
        setState((_) => {
          return { ..._, resetDataSource: true};
        })
      }
    return res;

    } catch (error) {

    }

  }

  const fetchRoles = async() => {
        try {
           const resp = await APIEndpoint.get(`roles`);
           if(resp.success){
           setRolesArray(resp.result);
       const options = [{key: 0, label: preCompile.roles, value: ''}];
      resp.result.filter( (fieldValue, index) => {
        options.push({key: index+1, label: fieldValue.name, value: fieldValue.name});
        return fieldValue;
      });
      if(options.length){
        setRoleNames(options);
      }

       }
           }
            catch (resp) {
              console.log(resp.msg)
            }
     }

const fetchClusters = async(opt) => {
      try {
         const resp = await APIEndpoint.get(`cluster/all`);
         if(resp.success){
        setClusterArray(resp.result);
     const options = [{key: 0, label: preCompile.clusters, value: ''}];
    resp.result.filter( (fieldValue, index) => {
      options.push({key: index+1, label: fieldValue.clusterName, value: fieldValue.clusterName});
      return fieldValue;
    });
    if(options.length){
      setsubSelection(options)
    }

     }
         }
          catch (resp) {
            console.log(resp.msg)
          }
   }

   const fetchStores = async() => {
    try {
       const resp = await APIEndpoint.get(`stores`);
       if(resp.success){
        setStoreArray(resp.result)
   const options = [{key: 0, label: preCompile.stores, value: ''}];
  resp.result.filter( (fieldValue, index) => {
    options.push({key: index+1, label: fieldValue.value, value: fieldValue.value});
    return fieldValue;
  });
  if(options.length){
    setsubSelection(options)
  }

   }
       }
        catch (resp) {
          console.log(resp.msg)
        }
 }

 const fetchWarehouse = async() => {
  try {
     const resp = await APIEndpoint.get(`warehouses`);
     if(resp.success){
      setWareHouseArray(resp.result)
      const options = [{key: 0, label: preCompile.warehouses, value: ''}];
      resp.result.filter( (fieldValue, index) => {
      options.push({key: index+1, label: fieldValue.value, value: fieldValue.value});
      return fieldValue;
      });
    if(options.length){
    setsubSelection(options);

    }

 }
     }
      catch (resp) {
        console.log(resp.msg)
      }
}

const fetchCashDrawers = async() => {
  try {
     const resp = await APIEndpoint.get(`cashRegister/cashDrawers`);
     if(resp.result){
      setCashDrawerArray(resp.result);
 const options = [{key: 0, label: preCompile.cashdrawers, value: ''}];
resp.result.filter( (fieldValue, index) => {
  options.push({key: index+1, label: fieldValue.value, value: fieldValue.value});
  return fieldValue;
});
if(options.length){
  setsubSelection(options);
}
 }
     }
      catch (resp) {
        console.log(resp.msg)
      }
}
const getSubSelDep=()=>{
  if(subSelection.length){
    switch(subSelection[0]?.label) {
      case "Select Cluster":
        setSubMenuHead( "Clusters")
        break;
      case "Select Store":
        setSubMenuHead ("Stores")
        break;
       case "Select Warehouse":
        setSubMenuHead( "Warehouses")
        break;
       case "Cash Drawers":
        setSubMenuHead( "Cash Drawers")
        break;
      default:
        setSubMenuHead( "");
    }
  }
}

   const fetchRoleSelection=(scope)=>{
    switch(scope) {
      case "Cluster":
      fetchClusters();
        break;
      case "Store":
      fetchStores();
        break;
      case "Warehouse":
      fetchWarehouse();
        break;
        case "CashRegister":
          fetchCashDrawers();
          break;
      default:
        setsubSelection([]);
    }
  }

  const fetchDepartment= async () => {
    try {
         const resp = await APIEndpoint.get('employees/departments');
    if (resp.items.length>0) {
      preCompile.department = t('selectButtons.selDepartment');
      setDepartmentArr(resp.items);
      let editAddFieldsDep=[];
      const options = [{key: 0, label: preCompile.department, value: ''}];
      resp.items.filter( (fieldValue, index) => {
        options.push({key: index+1, label: fieldValue.name, value: fieldValue.name});
        editAddFieldsDep.push({ key: fieldValue.id, name: fieldValue.name, value: fieldValue.name, label: fieldValue.id })
        return fieldValue;
      });

      setEditFieldsDepartment(editAddFieldsDep)
      setDepartments(options);

      if(editFieldsDepartment.length){
         editFormFields.map( row => {
        if (row.attribute === "department" ) {
          row.options = editFieldsDepartment;

        }
        return row;
      });
      AddEmployeeField.map( row => {
        if (row.attribute === "department" ) {
          row.options = editFieldsDepartment;
        }
        return row;
      });
      }


    }
    else {
    }
    } catch (error) {

    }

  };


  const fetchDesignation= async () => {
    try {
    const resp = await APIEndpoint.get('employees/designations');
      if (resp.items.length > 0) {
        preCompile.designation = t('selectButtons.selDesignation');
        setDesignationArr(resp.items);
        let editAddFieldsDesgn=[];
        const options = [{key: 0, label: preCompile.designation, value: ''}];
        resp.items.filter( (fieldValue, index) => {
          options.push({key: index+1, label: fieldValue.name, value: fieldValue.name});
          editAddFieldsDesgn.push({ key: fieldValue.id, name: fieldValue.name, value: fieldValue.name, label: fieldValue.id })
          return fieldValue;
        });
        setEditFieldsDesignation(editAddFieldsDesgn);
        setDesignations(options );
        if(editFieldsDesignation.length){
           editFormFields.map( row => {
          if (row.attribute === "designation" ) {
            row.options = editFieldsDesignation;
          }
          return row;
        });
        AddEmployeeField.map( row => {
          if (row.attribute === "designation" ) {
            row.options = editFieldsDesignation;
          }
          return row;
        });
        }
      }
      else {
        console.log('Log: Error while fetching item types API');
      }
    } catch (error) {

    }

  };
  const refreshTable = () => {
      selDepartment?.name?tableRef.current?.refreshTableHandler(selDepartment?.name): tableRef.current?.refreshTableHandler(selDesignation?.name);
  };



const onAction = async(e, action, row) => {
  fetchDepartment();
  fetchDesignation();
  setRoleId(row.employeeId);
 if(action.value==='EditItem'){
     try {
        const resp = await APIEndpoint.get(`employee/${row.employeeId}`);
        if(resp.result){
        setState((_prev) => {
          return { ..._prev, selected:{...resp.result,department:resp.result.department?.name,designation:resp.result.designation?.name,  action: 'editEmployee' } }
        })
        editFormFields.map( editRow => {
          if (editRow.attribute === 'department' ) {
            editRow.value = row.department!==null?row.department.name:null;
          }

          if (editRow.attribute === 'designation' ) {
            editRow.value = row.designation!==null?row.designation.name:null;
          }
          return editRow;
        });
    }
    setEditFormFields( _prev => {
      return [ ...editFormFields ] ;
        });
        }
         catch (resp) {
           console.log(resp.msg)
         }
 }
else{
  try {
    const resp = await APIEndpoint.get(`employees/${row.employeeId}/roleBindings`);
    if(resp.success){
      setRoleItems(resp.items)
      setAssignRoles(resp.items[0].resources)
}
    }
     catch (resp) {
       console.log(resp.msg)
     }
  setRoleModal(true);
}
  }

  const onClose = () => {
    setNewDeptAdd(null);
    setNewDesigAdd(null);
    setState((_prev) => {
      return { ..._prev, selected: false ,resetDataSource:modalFormStatusObj.text.length>0?true:false}
    })
    setEditFormFields( _prev => {
      return [...AddEmployeeField,...EditFieldsMenu] ;
  });
    if(!showUpload){
      setRoleItems([]);
    }
    setRoleModal(false);
    setShowUpload(false);
    setRoleOptions('');
    setConfirm('');
    setShow(true);
    setAssignRoles([])
    setAddRoles(false)
    setsubSelection("");
    setSubmitRoles([]);
    setModalFormStatusObj({ text: '' });
  }
  const getDepartment=(selectedDepartment)=>{
    if(typeof selectedDepartment ==='object'){
      const dep=departmentArr.find(d=>d.name===selectedDepartment.department)
      if(dep){
        delete dep["__v"];
        delete dep["_id"];
        return dep
      }
      else{
        return {name: selectedDepartment.department, id: null}

      }
    }
    if(typeof selectedDepartment ==='string'){
      const dep=departmentArr.find(d=>d.name===selectedDepartment)
      if(dep){
        delete dep["__v"];
        delete dep["_id"];
        return dep
      }
      else{
        return {name: selectedDepartment.department, id: null}

      }
    }
  }


  const getDesignation=(selectionDesignation)=>{
    if(typeof selectionDesignation==='object'){
    const desn=designationArr.find(d=>d.name===selectionDesignation.designation)
    if(desn){
      delete desn["__v"];
      delete desn["_id"];
      return desn
    }
    else{
      return {name: selectionDesignation.designation, id: null}

    }
    }
    if(typeof selectionDesignation==='string'){
      const desn=designationArr.find(d=>d.name===selectionDesignation)
      if(desn){
        delete desn["__v"];
        delete desn["_id"];
        return desn
      }
      else{
        return {name: selectionDesignation.designation, id: null}

      }
      }
  }

const cleanPayloadEntries=(body,updated)=>{
    for (var propName in body) {
      if (body[propName] === ''|| body['passwordReset']) {
        delete body[propName];
      }

    }
    return body
}

  const onEditSubmit = async (updated) => {
    if(!editRoster){
      return null;
    }
  setModalFormStatusObj({ text: '' });
    const {selected}=state;
    let cabinCr=null,
    cabinCrewAdm=null,
    department,
    designation

    if(updated.department){
      department=getDepartment(updated);
    }
    if(newDeptAdd){
      department={name: newDeptAdd, id: null}
    }

    if(updated.designation){
      designation=getDesignation(updated);
    }
    if(newDesigAdd){
      designation={name: newDesigAdd, id: null}
    }

if(updated && (updated['cabinCrew']!==undefined || updated['cabinCrewAdmin']!==undefined)){
if(updated['cabinCrew']!==undefined ){
  cabinCr=updated['cabinCrew'];
}
if(updated['cabinCrewAdmin']!==undefined ){
  cabinCrewAdm=updated['cabinCrewAdmin'];
}
}

if(selected.action==="AddNewEmployee" && updated && updated.employeeCode && updated.name && updated.password && updated.userName){
  setModalFormStatusObj({ text: '' });
  const body={
    "department": updated.department?{name: updated.department, id: null}:{},
    "designation": updated.designation?{name: updated.designation, id: null}:{},
    "employeeCode": updated.employeeCode?updated.employeeCode:"",
    "name": updated.name?updated.name:"",
    "email": updated.email?updated.email:"",
    "mobile": updated.mobile?updated.mobile:"",
    "userName": updated.userName?updated.userName:"",
    "password": updated.password?updated.password:"",
    "address":updated.address?updated.address:""
}
let payload=cleanPayloadEntries(body);
  try {
          const res = await APIEndpoint.post(`employees`, payload);
          if(res.success){
            setModalFormStatusObj({ text: res.msg });
            setNewDeptAdd(null);
            setNewDesigAdd(null);
            handleReset();
          }

          }
           catch (error) {
            let {data}=JSON.parse(error?.message);
            setModalFormStatusObj({ error: data?.msg });
           }

}
else{
  setModalFormStatusObj({ error:!updated.password?`Password is mandatory*`:`Please fill all the required fields*`});
}

if((selected.action==="editEmployee" && updated !==null) || (selected.action==="editEmployee" && (department|| designation)) || (selected.action==="editEmployee" && updated !==null && (department || designation))){
  setModalFormStatusObj({ text: '' });
  let body
  if(updated.password||updated.passwordConf){
  body={
    "employeeId": selected.employeeId,
    "employeeCode": updated.employeeCode?updated.employeeCode:selected.employeeCode,
    "name": updated.name?updated.name:selected.name,
    "userName":updated.userName?updated.userName:selected.userName,
    "email": updated.email?updated.email:selected.email,
    "mobile": updated.mobile?updated.mobile:selected.mobile,
    "address": updated.address?updated.address:selected.address,
    "designation": designation!==undefined?designation:getDesignation(selected.designation),
    "department":  department!==undefined?department:getDepartment(selected.department),
    "cabinCrew": cabinCr!==null?cabinCr:selected.cabinCrew,
    "cabinCrewAdmin": cabinCrewAdm!==null?cabinCrewAdm:selected.cabinCrewAdmin,
    "passwordReset": true,
    "newPassword": updated.password?updated.password:''
}
try {
  const res = await APIEndpoint.put(`employee/${selected.employeeId}`, body);
  if(res.success){
    setModalFormStatusObj({ text: res.msg });
    setNewDeptAdd(null);
    setNewDesigAdd(null);
    handleReset();
  }

  }
   catch (error) {
    setModalFormStatusObj({ text: error.data.msg });
   }

 }
if(!updated.password||!updated.passwordConf){
  setModalFormStatusObj({ text: '' });
  body={
    "employeeId": selected.employeeId,
    "employeeCode": updated.employeeCode?updated.employeeCode:selected.employeeCode,
    "name": updated.name?updated.name:selected.name,
    "userName": updated.userName?updated.userName:selected.userName,
    "email":  updated.email?updated.email:selected.email,
    "mobile": updated.mobile?updated.mobile:selected.mobile,
    "address": updated.address?updated.address:selected.address,
    "designation": designation!==undefined?designation:getDesignation(selected.designation),
    "department": department!==undefined?department:getDepartment(selected.department),
    "cabinCrew": cabinCr!==null?cabinCr:selected.cabinCrew,
    "cabinCrewAdmin": cabinCrewAdm!==null?cabinCrewAdm:selected.cabinCrewAdmin,
}
try {

  const res = await APIEndpoint.put(`employee/${selected.employeeId}`, body);
  if(res.success){
    setModalFormStatusObj({ text: res.msg });
    setNewDeptAdd(null);
    setNewDesigAdd(null);
    handleReset();
  }

  }
   catch (error) {
    setModalFormStatusObj({ error: error.data.msg });
   }
}

  }
  }
  const handleResetDelete=()=>{
  
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setButtonStat(false);
    setModalFormStatusObj({ text: '' })
    setState((_) => {
      return { ..._, resetDataSource:  !state.resetDataSource };
    })
    setButtonDisable(true);
    setDelConf(false);
    setDelPop(false);
    }, 1500)
}
const onDeleteEmployee=async(emp)=>{
  const empData= {
     "users": emp
 }
   try {
    setButtonStat(true);
   const res= await APIEndpoint.post(`employees/remove`,empData);
  if(res.success){
    setModalFormStatusObj({ text: res.message });
  }
   }
    catch (error) {
      const errMsg=JSON.parse(error.message)
      setModalFormStatusObj({ error: errMsg.data.message });
    }
    handleResetDelete();
 }


  const onContinue=()=>{
    setDelConf(true);
  }
  const onChange=(e)=>{
    if(e &&rolesArray.length>0){
    let r=rolesArray.find((sc)=>sc.name===e.value)
    setSubmitRoles([...submitRoles,{role: r}])
    fetchRoleSelection(r?.scope);
    }
  }
  const onPerLimitChange = (e) => {
    setPerPageLimit(Number(e.target.value));
  };

  const onSearch = (e) => {
      setSearchKey(e.target.value);
  };

  const onDepChange = (selectedDep) => {
    let searchDep=departmentArr?.find(dep=>dep.name===selectedDep?.value)
    setSelDepartment(searchDep?.name?searchDep:"");
  }

  const onDegChange=(selctedDesg)=>{
    let searchDesg=designationArr?.find(des=>des.name===selctedDesg?.value)
    setSelDesignation(searchDesg?.name?searchDesg:"");
  }

  const onAddEmployeeHandler = () => {
    setEditFormFields( _prev => {
      return [ ...editFormFields ] ;
  });

  setUpdatedFormFieldValues({});
      setState( (_prev) => {
        return { ..._prev, selected: { action: 'AddNewEmployee' } };
      });
  }
const onCloseDelete=()=>{
  setDelPop(false);
  setDelConf(false);
}
const onUploadTaxMaster=()=>{
  setShowUpload(true)
}

const onRoleChange=(e)=>{
  if(e.target.value.length>0 &&rolesArray.length>0){
  let r=rolesArray.find((sc)=>sc.name===e.target.value)
  setSubmitRoles([...submitRoles,{role: r}])
  fetchRoleSelection(r?.scope);
  }
}
const onAddRole=(e)=>{


 if(subSelChoice.length && roleOptions){
getRoleresArray(roleOptions,subSelChoice);
 setAddRoles(true);
 }
 if(!subSelChoice.length && roleOptions){
getRoleArray(roleOptions)
 setAddRoles(true);
}
}
const getRoleArray=(r)=>{
  const roleObject=rolesArray.find(_=>_.name===r)
  if(Object.keys(roleObject).length){
    let selObj={resourceType:roleObject.scope,role:roleObject}
    setRoleItems([...roleItems,selObj])
    resetFields();
  }
  return roleObject
}


const updateEditFormValues = async (e, updatedFormObj) => {
  ReactDOM.flushSync( () => {
      if (e) {
          const selectedDepartment = departmentArr.find( dep => dep.name === e);
          const selectedDesignation = designationArr.find( des => des.name === e);


          /** Set to respective fields */
          const updatedEditFields = editFormFields.filter( (field) => {

              if (selectedDepartment || selectedDesignation ) {
                  if ( field.attribute === 'department' ) {
                      field.value = selectedDepartment.label;
                  }
                  else if ( field.attribute === 'designation' ) {
                      field.value = selectedDesignation.value;
                  }
              }

              return field;

          });

          setEditFormFields( _prev => {
              return [ ...updatedEditFields ] ;
          });
      }
  })


};

const onDownloadItemMaster=async()=>{
  let header={
    'Accept':'text/csv'
  }
  try {
    setIsDownloading(true)
    const res= await DOMAIN.post(`employees/activeUsers`,{},header);
    if(res.status==='scheduled'){

      setTimeout(() => {
        setIsDownloading(true)
        const dowId= DOMAIN.get(`downloads/status/${res.id}`);
        dowId.then((val) => {
          if (val.status === "completed") {
              DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                  .then((ItemMasterDwnld) => {
                      const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', 'Active_Users.csv');
                      document.body.appendChild(link);
                      link.click();
                      setIsDownloading(false);
                  })
                  .catch((error) => {

                      console.error('Error downloading CSV:', error);
                      setIsDownloading(false);
                  });
          }
      });
        }, 8000);
    }

    }
       catch (error) {
        setState((_) => {
          return { ..._,message:{text:error?.data?.message||"Something went wrong!"},};
        })
        setTimeout(() => {
          setState((prevState) => ({
            ...prevState,
            message: null,
          }));
        }, 5000);
         setIsDownloading(false)
       }
    }


const getRoleresArray=(roleSel,resourceSel)=>{
 const roleObj= rolesArray.find(_=>_.name===roleSel);

 switch(subMenuHead) {
case "Clusters":
const clustResp= resourceSel.map(rsr=>clusterArray.filter(cls=>cls.clusterName===rsr.value))
if(clustResp.length){
  const clustMod=clustResp.flat().map(item=>{return  {id:item.clusterId,name:item.clusterName}})
const subClustResObj={resourceType:resType,
  resources:clustMod,role:roleObj}
  setResourceObject(subClustResObj)
  if(Object.keys(subClustResObj).length){
    setRoleItems([...roleItems,subClustResObj])
  }
}
setSubSelChoice([]);
    break;
  case "Stores":
 const storeResp= resourceSel.map(rsr=>storeArray.filter(cls=>cls.value===rsr.value))
if(storeResp.length){
  const storeMod=storeResp.flat().map(item=>{return  {id:item.id,name:item.value}})
    const subResStoreObj={resourceType:resType,
      resources:storeMod,role:roleObj}
      setResourceObject(subResStoreObj)
      if(Object.keys(subResStoreObj).length){
        setRoleItems([...roleItems,subResStoreObj])
      }
}
setSubSelChoice([]);

    break;
  case "Warehouses":
      const wareResp= resourceSel.map(rsr=>wareHouseArray.filter(cls=>cls.value===rsr.value))
      if(wareResp.length){
        const wareMod=wareResp.flat().map(item=>{return  {id:item.id,name:item.value}})
        const subResWarehouseObj={resourceType:resType,
          resources:wareMod,role:roleObj}
          setResourceObject(subResWarehouseObj)
          if(Object.keys(subResWarehouseObj).length){
            setRoleItems([...roleItems,subResWarehouseObj])
          }
      }
      setSubSelChoice([]);

    break;
    case "Cash Drawers":
        const cashReg= resourceSel.map(rsr=>cashDrawerArray.filter(cls=>cls.value===rsr.value))
        if(cashReg.length){
          const cashMod=cashReg.flat().map(item=>{return  {id:item.id,name:item.value}})
          const subCashRegObj={resourceType:resType,
            resources:cashMod,role:roleObj}
            setResourceObject(subCashRegObj)
            if(Object.keys(subCashRegObj).length){
              setRoleItems([...roleItems,subCashRegObj])
            }
        }
        setSubSelChoice(null);

      break;
  default:
    setResourceObject({});
setSubSelChoice([]);
}
if(Object.keys(resourceObject).length){
  setRoleItems([...roleItems,resourceObject])
}
resetFields();

}

const resetFields=()=>{
  setResourceObject({});
  setRoleOptions([]);
  setSubSelChoice([]);
}

const onRoleSubmit=async()=>{
  const body={"roleBindings":roleItems}
  try {
    const res= await APIEndpoint.post(`employees/${roleId}/roleBindings`,body);
    if(res.success){
      setModalFormStatusObj({ text: res.msg });
      handleReset();
    }
    }
     catch (error) {
      setModalFormStatusObj({ error: error.data.msg });

     }
}
  const onRemoveEmployee=(employeeId)=>{
    setRemove(true);
    if(employeeId.constructor === Array){
   onDeleteEmployee(employeeId);
    }
  }
  const onDownloadSampleFile = () => {
    onDownloadXlsFile('samples/downloads/Employee_Upload_Sample','Employee_Upload_Sample')
  }
  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');
    const formData = new FormData();
    formData.append('roleBindings', roleItems||[]);
    formData.append('file', file);
    try {
      const file=e.target.files[0];
      setUploadedFile(e.target.files[0]);
      setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');
      const formData = new FormData();
      formData.append('roleBindings', roleItems||[]);
      formData.append('file', file);
      await APIEndpoint.post('employees/upload', formData);
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      setState((_) => {
        return { ..._, resetDataSource: true };
      })
    } catch (error) {
      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400 && data) {
            data.errors.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field?erObj.field:"" + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
      } else if(status===0 && !data){
        msgContent += `Error occurred while uploading employee sheet!<br>Please try again.`;
    }
      else {
        msgContent += `Error occurred while uploading employee sheet!<br>Please fix the errors and re-upload.`;
      }

      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }

};

  let empProps = {
    onRemoveEmployee ,
    setRemove,
    setDelPop,
    setButtonDisable,
    onCloseDelete,
    delConf,
    remove,
    empSpec,
    selDepartment,
    selDesignation
      },
       roleModalProps = {
     onRoleSubmit,
        onRoleChange,
        modalFormStatusObj,
        setSubSelChoice,
        setRoleNames,
        subSelChoice,
        subSelection,
        onChange,
        addRoles,
        setRoleOptions,
        setAssignRoles,
        subMenuHead,
        roleOptions,
        onAddRole,
        setRoleItems,
        roleNames
          },
      delProps = {
        setShow,
        buttonStat,
        show,
        onContinue,
        onCloseDelete,
          }
  return (
    <div className="op-aircraft-container ng-grid-right flexCol full-flex ag-theme-alpine-dark">
     {
       <>
          <div className='flexRow width100 margBot10 margTop8 justifyContentFlexEnd'>

            <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
                     {uploadRoster ? <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadTaxMaster(true)}> <span><img  className="icon-size" alt='uploadItem' src={uploadItemIcon} /><u>{t('filters.uploadEmployees')}</u></span></button>:<></>}
{ downloadRoster ?<button className='download dropdownStyle1-TextMedium' onClick={onDownloadItemMaster}><span>{isDownloading?<img src={LoadingGIF} alt='loading' className='cell-loading' />:<img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />}
              <u className={`download__banner_${isDownloading ? "active" : ""}`}>{isDownloading?t('filters.downloadingMaster'):t('filters.downloadUsers')}</u></span></button>:<></>}

            </div>



        </div>

        <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
          {
          Boolean(state.message) &&
          <div className={`validationMsgDiv error`}>
            {state.message.text}
          </div>
        }
        </div>

        <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
        <div className="flexRow justifyContentFlexStart alignItemsBaseline">
        <button className='saleType-div m-2'>
                {/* <select className='select-item darkBlue-bg' onChange={onDepChange} value={selDepartment?.name} id="saleTypeId">
                    {departments.map( (item) => (
                        <option key={item.key} value={item.value}>{item.label}</option>
                    ))
                    }
                </select> */}
                <CustomSelect
                options={departments}
                placeHolder="Select Department"
                onChange={onDepChange}
              />
                </button>
                <button className='saleType-div m-2'>
                {/* <select className='select-item darkBlue-bg' onChange={onDegChange} value={selDesignation?.name||'Cabin Crew'} id="saleTypeId">
                    {designations.map( (item) => (
                        <option key={item.key} value={item.value}>{item.label||'Cabin Crew'}</option>
                    ))
                    }
                </select> */}
                <CustomSelect
                options={designations}
                placeHolder="Select Designation"
                onChange={onDegChange}
              />
                </button>
        </div>
        <div className="width100 flexRow justifyContentCenter alignItemsCenter">
        <div className="margBot10 underline">
            <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
             <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
            </div>
            </div>
            <div className="margLeftSecRow flexRow justifyContentFlexEnd alignItemsCenter">
  { deleteRoster? <button
            disabled={buttonDisable}
            className="remove-button m-2 flex justifyContentCenter alignItemsCenter"
            onClick={onRemoveEmployee}
          >
              <img className="icon-size removeEmpIcon" alt="deleteIcon" src={deleteIcon} />
            {t("buttons.removeEmployee")}
          </button>:<></>}
        { createRoster?  <button style={{textWrap:"nowrap"}} className="add-emp-button m-2 flex justifyContentCenter alignItemsCenter" onClick={onAddEmployeeHandler}>
            {t("buttons.addEmployee")}
          </button>:<></>}
            </div>
        </div>
        <DataGrid
            className='full-flex'
            page="employee"
            ref={tableRef}
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            {...empProps}
            columnResize={true}
            actions={actionItems}
            onAction={onAction}
            resetDataSource={state.resetDataSource}
            getActionCellIcons={getActionCellIcons}
            onAddEmployeeHandler={() => onAddEmployeeHandler()}
            getRows={fetchEmployees} />
        </>
      }
      {
        state && Boolean(state.selected)&& Boolean(state.selected.action) &&  editRoster && (
        <UpdateEmployeeModal
        type={state.selected.action==='editEmployee'?"editEmployee":'AddNewEmployee'}
        title={state.selected.action==='editEmployee'?"Update Employee":"Add Employee"}
        fields={state.selected.action==='editEmployee'?editFormFields.map((_) => {
              return { ..._, initialValue: state.selected[_.attribute] }
            }):AddEmployeeField.map((_) => {
              return { ..._, initialValue: "" }
              })}
            onChange={ (e, updated) => {
                      updateEditFormValues(e, updated);
                      setUpdatedFormFieldValues(updated)
                  }}
        newDeptAdd={newDeptAdd}
        newDesigAdd={newDesigAdd}
        setNewDeptAdd={setNewDeptAdd}
        setNewDesigAdd={setNewDesigAdd}
        modalFormStatusObj={modalFormStatusObj}
        onSubmit={onEditSubmit}
        onClose={onClose}/>
        )
      }
       {delPop && <DeleteConf  {...delProps} modalFormStatusObj={modalFormStatusObj} description={t('pages.employeePop.delConfirmDialogue')} />}
       {
        roleModal &&
        <RoleModal
        modelTitle={'Add Role'}
        onRoleModalClose={onClose}
        fields={roleItems}
        {...roleModalProps}
        />
      }
       {
        showUpload &&
        <RoleModal
        onRoleModalClose={onClose}
        fields={roleItems}
         {...roleModalProps}
        modelTitle={'Upload Employees'}
        onDownloadSampleFile={onDownloadSampleFile}
        uploadedFile = { uploadedFile }
        onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
        uploadFileStatusBlock = { uploadFileStatusBlock }
        />
      }
      {
        t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}