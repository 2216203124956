import React, { useRef, useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import { DataGrid, EditModal, UploadModal } from "../../../common";
import { onDownloadXlsFile, translationLength } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import { fetchCulture } from '../../../utils/commonFunctions';
import editIcon2 from "../../../assets/edit2.svg";
import searchIcon from '../../../assets/icons/search.svg';
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import usePermissions from '../../../utils/userPermissions';
const valueGetter = (params) => {
  const { colDef, data } = params;
  if (!data) {
    return '';
  }
  switch (colDef.field) {
    case 'hasCateringStation':
      return data.hasCateringStation ? 'Yes' : 'No';
    case 'warehouseCode':
      return data.hasCateringStation ? `${data.warehouseCode} (${data.warehouseName})` : ''
    case 'locationName':
      return `${data.locationName}, ${data.stateName}`;
    default:
      return '';
  }
}

const Columns = [
  { field: 'locationCode', headerName: 'Location Code', sortable: true,suppressSizeToFit:true,flex:1, resizable:true,filter: true,minWidth:150 },
  { field: 'locationName', headerName: 'Location / State',suppressSizeToFit:true, resizable:true,flex:1, sortable: true, tooltipField: 'locationName',valueGetter: (params) => {
    if (params.data&&params.data.locationName) {
       return params.data.locationName.length>21? params.data.locationName.slice(0,22)+"...": params.data.locationName;
    }
    return null;
  } , filter: true,minWidth:150 },
  { field: 'airportName', headerName: 'Airport Name',suppressSizeToFit:true, resizable:true, flex:1,sortable: true, filter: true,tooltipField: 'airportName',valueGetter: (params) => {
    if (params.data&&params.data.airportName) {
       return params.data.airportName.length>21? params.data.airportName.slice(0,22)+"...": params.data.airportName;
    }
    return null;
  },minWidth:230 },
  { field: 'country', headerName: 'Country', sortable: true,suppressSizeToFit:true, resizable:true,flex:1, filter: true,minWidth:150 },
  { field: 'timezone', headerName: 'Time Zone',suppressSizeToFit:true, resizable:true, flex:1,sortable: true, filter: true,tooltipField: 'timezone.name',valueGetter: (params) => {
    if (params.data && params.data.timezone) {
      const timezoneName = params.data.timezone?.name;
      const utcOffset = params.data.timezone?.utcOffsetText;
      return `${timezoneName} (UTC${utcOffset})`;
    }
    return null;
  },minWidth:250},
  { field: 'hasCateringStation', headerName: 'Catering Station', valueGetter: valueGetter, sortable: true,suppressSizeToFit:true, flex:1,resizable:true, filter: true,minWidth:150 },
  { field: 'warehouseCode', headerName: 'Warehouse', valueGetter: valueGetter, sortable: true, suppressSizeToFit:true,flex:1,minWidth:250, resizable:true,filter: true,minWidth:230 },
];

const EditFormField = [
  { label: 'Location Code', attribute: 'locationCode', type: 'text', disabled: true },
  { label: 'Location Name', attribute: 'locationName', type: 'text', disabled: true },
  { label: 'Airport Name', attribute: 'airportName', type: 'text', disabled: true },
  { label: 'State', attribute: 'stateCode', type: 'select', required: true, options: [], disabled: true},
  { label: 'Address', attribute: 'address', type: 'textarea', rows: 4 },
  { label: 'Country', attribute: 'country', type: 'text', disabled: true },
  { label: 'Has Catering Station', attribute: 'hasCateringStation', type: 'checkbox',disabled: true },
];

const EditFormFieldWarehouse = [
  { label: 'Location Code', attribute: 'locationCode', type: 'text', disabled: true },
  { label: 'Location Name', attribute: 'locationName', type: 'text', disabled: true },
  { label: 'Airport Name', attribute: 'airportName', type: 'text', disabled: true },
  { label: 'State', attribute: 'stateCode', type: 'select', required: true, options: [], disabled: true},
  { label: 'Address', attribute: 'address', type: 'textarea', rows: 4 },
  { label: 'Country', attribute: 'country', type: 'text', disabled: true },
  { label: 'Has Catering Station', attribute: 'hasCateringStation', type: 'checkbox',disabled: true },
  { label: 'Warehouses', attribute: 'warehouses', type:"rcSelectAll",options: [] }
]

const AddFormField = [
  { label: 'Location Code', attribute: 'locationCode', type: 'text', required: true, value: null },
  { label: 'Location Name', attribute: 'locationName', type: 'text', required: true, value: null },
  { label: 'Airport Name', attribute: 'airportName', type: 'text', required: true, value: null },
  { label: 'State', attribute: 'stateCode', type: 'select', required: true, options: [] , value: null},
  { label: 'Address', attribute: 'address', type: 'textarea', rows: 4, value: null },
  { label: 'Country', attribute: 'country', type: 'text', required: true, value: null,disabled:true },
  { label: 'Has Catering Station', attribute: 'hasCateringStation', type: 'checkbox',disabled:false },
];

export const AirportsPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({selected:null,country:null});
  const tableRef = useRef();

  const [perPageLimit, setPerPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [modalFormData, setModalFormData] = useState({});
  const [addFormFields,setAddFormFields]=useState(AddFormField);
  const [editFormFieldWarehouse,setEditFormFieldWarehouse]=useState(EditFormFieldWarehouse);
  const [editFieldsNoCatering,setEditFieldsNoCatering]=useState(EditFormField);
  const [updatedFormFieldValues,setUpdatedFormFieldValues]=useState({})
  const [warehouse, setWarehouse] = useState([]);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');

  const { hasPermission: editAirports } = usePermissions('edit', "configuration.fsc.airports");
  const { hasPermission: createAirports  } = usePermissions('create', "configuration.fsc.airports");
  const { hasPermission: uploadAirports  } = usePermissions('upload', "configuration.fsc.airports");

  React.useEffect(() => {
    fetchStates();
    fetchWarehouses();
  }, []);

  React.useEffect(()=>{
    if(!Object.keys(modalFormData).length && state.selected){
      const editOptions = [{ key: 0, label: state.selected.stateName, value: state.selected.stateCode }];
      if(state.selected.hasCateringStation){
        setEditFormFieldWarehouse((prev)=>{
          const updatedFields= prev.map((editRow) => {
              if (editRow.attribute === 'stateCode') {
               editRow.options = editOptions;
             }
         
             if (editRow.attribute === 'warehouses') {
               editRow.options = warehouse;
               editRow.data = state.selected.warehouses.map(wr => ({key: wr.warehouseId,label: wr.warehouseName,value: wr.warehouseCode}));
         
               const css = document.querySelectorAll('#warehouseCode');
               css.forEach((el) => {
                 el.value = state.selected.warehouses.map(
                   (wr) => `${wr.warehouseName} (${wr.warehouseCode})`
                 ).join(', ');
               });
             } 
             return editRow;
           }
          )
          return updatedFields;
         }
         )
      }else{
        setEditFieldsNoCatering((prev)=>{
          const updatedFields= prev.map((editRow) => {
              if (editRow.attribute === 'stateCode') {
               editRow.options = editOptions;
             }     
             return editRow;
           }
          )
          return updatedFields;
         }
         )
      }
    
    } 

  },[state.selected])

  React.useEffect(() => {
    if (!Array.isArray(updatedFormFieldValues) && updatedFormFieldValues && state.selected && state.selected.action === 'AddNewItem') {
      const { locationCode } = updatedFormFieldValues;
      if (locationCode && locationCode.length >= 3) {
        const fetchData = async () => {
          const resp = await fetchAirports(null, null, locationCode);
          if (resp && resp.items && resp.count === 1) {
            const { locationCode, locationName, airportName, country } = resp.items[0];
            setAddFormFields((prev) => {
              const updatedFields = prev.map((editRow) => {
                if (editRow.attribute === 'locationCode') editRow.value = locationCode;
                if (editRow.attribute === 'locationName') editRow.value = locationName;
                if (editRow.attribute === 'airportName') editRow.value = airportName;
                if (editRow.attribute === 'country') editRow.value = country;
                return editRow;
              });
              return updatedFields;
            });
          } else {
            setAddFormFields((prev) => {
              const updatedFields = prev.map((editRow) => {
                if (editRow.attribute === 'locationCode') editRow.value = locationCode || "";
                if (editRow.attribute === 'locationName') editRow.value = updatedFormFieldValues.locationName || "";
                if (editRow.attribute === 'airportName') editRow.value = updatedFormFieldValues.airportName || "";
                if (editRow.attribute === 'country') editRow.value = updatedFormFieldValues.country || "";
                return editRow;
              });
              return updatedFields;
            });
          }
        };
  
        fetchData();
      } else {
        setAddFormFields((prev) => {
          const updatedFields = prev.map((editRow) => {
            if (editRow.attribute === 'locationCode') editRow.value = locationCode || "";
            if (editRow.attribute === 'locationName') editRow.value = updatedFormFieldValues.locationName || "";
            if (editRow.attribute === 'airportName') editRow.value = updatedFormFieldValues.airportName || "";
            if (editRow.attribute === 'country') editRow.value = updatedFormFieldValues.country || "";
            return editRow;
          });
          return updatedFields;
        });
      }
    }
  }, [updatedFormFieldValues, state.selected]);
  

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };
  const fetchAirports = async (page=1, perPage=10,airpCode=false) => {
    if(!airpCode){
        filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
    const res = await APIEndpoint.post('airports/process', filterData);
    return res;
    }
  else{
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = airpCode;
    const res = await APIEndpoint.post('airports/process', filterData);
    return res;
  }
  }

  const onModalFormInputChange = (e, newFormData) => {
    const { name, value } = e.target;
  
    let newUpdates = modalFormData ? { ...modalFormData } : {}
    newUpdates[name] = value;
  
    setModalFormData((_)=>( {...newFormData,..._ }) );
  }


const onWarehouseChange=(add,updated)=>{
  setModalFormStatusObj({ text:''});
  let newUpdates = modalFormData ? { ...modalFormData } : {}
  newUpdates['warehouses']=updated??[];
  setModalFormData((_)=>({..._,...newUpdates}));
if(!add){
  setEditFormFieldWarehouse((prev)=>{
 const updatedFields= prev.map((editRow) => {
    if (editRow.attribute === 'warehouses') {
      editRow.data = [...updated];
      const elements = document.querySelectorAll('#warehouseCode');
      elements.forEach((el) => {
        el.value = [...updated];
      });
    }
    return editRow;
  })


  return updatedFields
}
  )
}else{
  
  setAddFormFields((prev) => {
    const updatedFields = prev.map((editRow) => {
      if (editRow.attribute === 'warehouses') {
        return {
          ...editRow, 
          data: [...updated],
        };
      }
      return editRow;
    });
  
    return updatedFields;
  });
  
}

    
}


  const onAction = (e, action, row) => {
    setState((_prev) => {
      return { ..._prev, selected: row }
    })
  }

  const onClose = () => {
    if(state.selected.hasCateringStation){
      EditFormField.pop();
    }
    setState((_prev) => {
      return { ..._prev, selected: false }
    })
    setAddFormFields(AddFormField);
    setUpdatedFormFieldValues({});
    setModalFormStatusObj({text:''})
    setModalFormData({});
  }

  const refreshTable = () => {
    tableRef.current.refreshTableHandler();
  };


  const onEditSubmit = async (e, updated,editHsn,mode) => {

    if(mode==='Edit Airport' && editAirports){
      if (updated || modalFormData) {
        const body = { ...state.selected,...(updated ? updated : {})};
        if( state.selected.hasCateringStation && modalFormData && modalFormData.warehouses){
          body.warehouseIds = modalFormData.warehouses.map(wh=>wh.key)
        }
        if( state.selected.hasCateringStation && modalFormData.warehouses && !modalFormData.warehouses.length ){
          setModalFormStatusObj({ error: 'Please select a warehouse'});
          return;
        }
        try {    
          const res= await APIEndpoint.put(`airports`, body);
          if(res.success){
          setModalFormStatusObj({ text: res.msg ||'Data Saved Successfully'});
          handleReset();

          }
        }
        catch (error) {
         }
      }else{
        const {id} = state.selected;
        try {
         await APIEndpoint.get(`airports/get/${id}`).then((resp)=>{
        if(resp.success){
          resp.result.warehouseId = [resp.result.warehouseId]
          APIEndpoint.put(`airports`,resp.result).then((resp2)=>{
            if(resp2.success){
          setModalFormStatusObj({ text:'Data Saved'});
    handleReset();
            }
          })
        }
       })
        }
        catch (error) {
         }
      }
    }
    else if(mode==='Create Airport' && createAirports){

      if (updated) {
        const body = {country:state.country,...(updated ? updated : {})};
        if(modalFormData && modalFormData.warehouses){
          body.warehouseIds = modalFormData.warehouses.map(wh=>wh.key);
        }
        try {    
          const res= await APIEndpoint.put(`airports`, body);
          if(res.success){
          setModalFormStatusObj({ text: res.msg ||'Data Saved Successfully'});
          handleReset();

          }
        }
        catch (error) {
          const errMsg=JSON.parse(error.message);
          setModalFormStatusObj({ error: errMsg.data.message || errMsg.data.msg ||'Something went wrong. Please try again after some time.'});
         }
      }
      else{
        setModalFormStatusObj({ error:"Please fill all the required fields!"});
      }


    }else{
      return null;
    }
  };

  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', undefined);
    formData.append('stores', []);
    formData.append('warehouses', []);
    formData.append('file', file);
    try {
      const res = await APIEndpoint.post(`airports/upload`, formData);
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
    } catch (error) {
      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400 && data?.errors) {
            data.errors.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field?erObj.field:"" + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
      } else if(status===0 && !data){
        msgContent += `Error occurred while uploading airports!<br>Please try again.`;
    }
      else {
        msgContent += `Error occurred while uploading airports!<br>Please fix the errors and re-upload.`;
      }

      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
};

  const fetchStates = async () => {
    try {
       const resp = await fetchCulture();
    if (resp) {
      const {stateCodes,countryCode}=resp;
      setState( (_prev) => {
        return { ..._prev, country:countryCode};
      });
      const options = [{key: 0, label: 'Select State', value: ''}];
     stateCodes.filter( (fieldValue, index) => {
        options.push({key: fieldValue.code, label: fieldValue.name, value: fieldValue.code});
        return fieldValue;
      });
      AddFormField.map( editRow => {
        if (editRow.attribute === 'stateCode' ) {
          editRow.options = options;
        }
        if (editRow.attribute === 'country' ) {
          editRow.value = countryCode;
        }
        return editRow;
      });
    } else {
      console.log('Log: Error while fetching states');
    }
    } catch (error) {

    }

  };

  const fetchEditStates = async () => {
    if (!state.selected) return;
  };

  const fetchWarehouses = async () => {
  try {
       const resp = await APIEndpoint.get('warehouses');
    if (resp.result.length > 0) {
      const options = [];
      resp.result.filter( (fieldValue, index) => {
        options.push({key: fieldValue.id, label: fieldValue.value, value: fieldValue.warehouseCode});
        setWarehouse(options);
        return fieldValue;
      });
      EditFormField.map( editRow => {
        if (editRow.attribute === 'warehouses' ) {
          editRow.options = options;
        }
        return editRow;
      });
    } else {
      console.log('Log: Error while fetching warehouses');
    }
  } catch (error) {

  }

  };

  const onChangeAdd = (e) => {
    setModalFormStatusObj({text:''})
    const { name, value,checked} = e.target;
    if( name === 'hasCateringStation'){
      if(checked){
        setAddFormFields((prev) => [...prev,{
          label: 'Warehouses',
          attribute: 'warehouses',
          type: 'rcSelectAll',
          data:[],
          options: warehouse,
        },
      ]);
      }else{
        setAddFormFields((prev) => {
          const removedWh=prev.map((item)=>{
            if(item.attribute==='warehouses'){
              return null;
            }
            return item;
          }).filter(fl=>fl!==null);
          return removedWh;    
        }
        );
      }
     
      
    }
  }


  const onAddItemHandler = () => {
    setState( (_prev) => {
      return { ..._prev, selected: { action: 'AddNewItem' } };
    });
  }

  const onPerLimitChange = (e) => {
    setPerPageLimit(Number(e.target.value));
  };

  const onSearch = (e) => {
      setSearchKey(e.target.value);
  };

  const onUploadClose = () => {
    setUploadedFile([]);
    setUploadFileStatusBlock('');
    setShowUpload(false);
  }

  const onDownloadSampleFile = () => {
    onDownloadXlsFile('samples/downloads/Airports_Upload_Sample','Airports_Upload_Sample')
  }
  const handleReset = () => {
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      refreshTable()
      setModalFormStatusObj({text:''})
      onClose();
    }, 500)
  }
  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
         { uploadAirports?<div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
              <span className='upload dropdownStyle1-TextMedium' onClick={() => setShowUpload(true)}><img  className="icon-size" alt='uploadItem' src={uploadItemIcon} /><u>{t('pages.pagesContent.airports.uploadButtonTitle')}</u></span>
          </div>:<></>}
          <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>

          <div className='width100Menu justifyContentCenter flexRow'>
          <div className="margLeft margBot10 underline">
                  <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
                  <span><input className="search" onChange={onSearch} type="search" placeholder="Search" value={searchKey}/></span>
              </div>
          </div>


             {createAirports? <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
                  <button className="add-item-button" onClick={ onAddItemHandler }>
                  {t('pages.pagesContent.airports.addModalTitleButton')}
                  </button>
                  <button className='item-button'>
                  </button>
              </div>:<></>}
          </div>

          <DataGrid
            ref={tableRef}
            className='full-flex'
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            actions={[{ title: '', detail: editAirports? [{src: editIcon2,value:"EditItem",title:'Edit'}]:[] }]}
            onAction={onAction}
            onAddItemHandler={() => onAddItemHandler()}
            getRows={fetchAirports} />
        </>
      }
       {state.selected ? (
        Object.keys(state.selected).length > 0 && state.selected.action !== "AddNewItem" ? (
          <>
        
            {fetchEditStates() && (
              <EditModal
                title={t('pages.pagesContent.airports.editModalTitle')}
                onClose={onClose}
                buttonLabel="Save"
                modalFormStatusObj={modalFormStatusObj}
                onSubmit={onEditSubmit}
                onChange={ (e,updated) => {
                  setUpdatedFormFieldValues(updated);        
                  if(!e && updated){
                    onWarehouseChange(false,updated)
                  }
                  else {
                      onModalFormInputChange(e, updated);
                  }

              }
          }
                fields={
                  state.selected.hasCateringStation
                    ? editFormFieldWarehouse.map((_) => {
                        return { ..._, initialValue: state.selected[_.attribute] };
                      })
                    : editFieldsNoCatering.map((_) => {
                        return { ..._, initialValue: state.selected[_.attribute] };
                      })
                }
              />
            )}
          </>
        ) : (
            <EditModal
              title={t('pages.pagesContent.airports.addModalTitle')}
              modalFormStatusObj={modalFormStatusObj}
              onClose={onClose}
              buttonLabel="Save"
              onChange={ (e,updated) => {
                setUpdatedFormFieldValues(updated);        
                if(!e && updated){
                  onWarehouseChange(true,updated)
                }
                else {
                    onChangeAdd(e);
                }
              }
               }
              onSubmit={onEditSubmit}
              fields={ addFormFields.map( (elt) => {
                  return {...elt, initialValue: {} }
                })
              }
            />
          )
        )
        : null
      }
       {
        showUpload &&
        <UploadModal
          modelTitle={t('pages.pagesContent.airports.uploadButtonTitle')}
          onUploadClose={onUploadClose}
          onDownloadSampleFile={onDownloadSampleFile}
          onUploadFileChangeHandler={onUploadFileChangeHandler}
          uploadedFile={uploadedFile}
          uploadFileStatusBlock={uploadFileStatusBlock}
        />
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}