

/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */

import React, { useCallback, useEffect, useRef, useState } from 'react'
import { APIEndpoint, DFPAPIEndpoint ,DFPAPIEndpointGalley} from '../../../utils/axios';
import { DataGrid,UploadModal,DeleteConf } from '../../../common';
import { onDownloadXlsFile,translationLength } from '../../../utils/commonFunctions';
import { MIMETypes,HOST_SERVER} from '../../../utils/configs';
import deleteIcon from '../../../assets/icons/deleteRole.svg';
import editIcon from "../../../assets/edit.svg";
import viewIcon from "../../../assets/view.svg"
import plus from '../../../images/icons/plus.png';
import minus from '../../../images/icons/minusgaly.png';
import mealMasterData from "../../../assets/json/meal_master.json";
import { useTranslation } from 'react-i18next';
import Select from 'react-select';
import {CreateMealmastr } from './meal-master-create';
import searchIcon from '../../../assets/icons/search.svg';
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import LoadingGIF from '../../../assets/loadingIn.gif';
import usePermissions from '../../../utils/userPermissions';
const sType = localStorage.getItem('sType');
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

class ImageIndicator {
  init(params) {
    let path;
    const element = document.createElement('span');
    const imageElement = document.createElement('img');
    (params.data && params.data.image) ? path = params.data.image : path = "";
    imageElement.src = `${HOST_SERVER}/${path}`;
    imageElement.width = "35"
    element.appendChild(imageElement);
    this.eGui = element;
  }
  getGui() {
    return this.eGui;
  }
}

const foodType=[{key:0,label:'Select Diet Type',value:''},{key:1,label:'Veg',value:'veg'},
  {key:2,label:'Non-veg',value:'nonVeg'},{key:3,label:'Vegan',value:'vegan'}, {key:4,label:'Lacto-Meal',value:'lactoMeal'},{key:4,label:'Pescetarian',value:'pescetarian'}
]

const mealContent=[{key:1,label:'Appetizer',value:'appetizer'},
  {key:2,label:'Dessert',value:'dessert'},{key:3,label:'Beverages',value:'beverages'}, {key:4,label:'Cutlery',value:'cutlery'},{key:5,label:'Main course',value:'main'},{key:6,label:'Extras',value:'extras'}
]
const mealStatus=[{key:0,label:'Select Status',value:''},{key:1,label:'Active',value:'active'},
  {key:2,label:'Inactive',value:'inactive'}
]


const EditFormFields = [
  {label: 'Meal Name', attribute: 'mealName', type: 'text', disabled: false, required: true },
  {label: 'Meal Type', attribute: 'mealType', type: 'select',options:[],disabled: false, required: true },
  {label: 'Diet', attribute: 'diet', type: 'select',options:foodType,disabled: false, required: true},
  {label: 'Image', attribute: 'image', type: 'uploadImage', disabled: false},
  {label: 'Cabin', attribute: 'cabin', type: 'multiSelect', disabled: false, options: [], required: true },
  {label: 'Tag', attribute: 'tag', type: 'createSelect', disabled: false, options: [], required: true },
  {label: 'Meal Status', attribute: 'status', type: 'multiSelect', disabled: false, options: [], required: true },
  {label: 'Description', attribute: 'description', type: 'textarea', disabled: true,required: true },
  {label: 'Preparation Instructions', attribute: 'preparationInstructions', type: 'textarea', disabled: false}
];


export const MealmasterPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({mealContent:mealContent,mealStatus:mealStatus});
  const tableRef = useRef();
  const [perPageLimit, ] = useState(10);
  const [searchKey, setSearchKey] = useState('');
  const [selItemType, setItemType] = useState('');
  const [category, setCategory] = useState(null);
  const [subCat, setSubCat] = useState([]);
  const [subCatData, setSubCatData] = useState([]);
  const [catData, setCatData] = useState([]);
  const [image, setImage] = useState({});
  const [selectFields, setSelectFields] = useState({unit:'',section:''});
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [fileUpload, setFileUpload] = useState(null)
  const [showUpload, setShowUpload] = useState(false);
  const [isDownloading, setIsDownloading] = useState(false);
  const [editFormFields, setEditFormFields] = useState(EditFormFields);
  const [inputChanged,setInputChanged]=useState(false);
  const [mealmasterExtraReq, setMealMasterExtraReq] = useState([{ id: 1 }]);
  const[itemTypeValue,setItemTypeValue]=useState(null);
  const [show,setShow]=useState(false);
  const [blobFile, setBlobFile] = useState(null)
  const[widthHeight,setWidthHeight]=useState();
  const[base64,setBase64]=useState("");

  const { hasPermission: createmealmMaster } = usePermissions('create', "inventory.meal-master");
  const { hasPermission: editmealMaster } = usePermissions('edit', "inventory.meal-master");
  const { hasPermission: viewmealMaster } = usePermissions('viewDetails',"inventory.meal-master");
  const { hasPermission: downloadmealMaster } = usePermissions('download',"inventory.meal-master");
  const { hasPermission: deletemealMaster } = usePermissions('delete',"inventory.meal-master");
  const { hasPermission: uploadmealMaster } = usePermissions('upload',"inventory.meal-master");

  let invData={},
  itemIdMaster=''

  const preCompile = {
    itemType: t('selectButtons.selItemType'),
    category: t('selectButtons.selCategory'),
    subCategory: t('selectButtons.selSubCategory'),
    unit: t('selectButtons.selUnit'),
    section:t('selectButtons.selSection'),
    uploadTitle:t('pages.inventoryContent.itemMaster.uploadItems')
  }

  useEffect(()=>{
    fetChMealmenuConfig();
    fetchCategoryList();
  },[])

  const fetchSubCategoryList = useCallback(
    async (categoryObj) => {
      setSubCatData([]);
      setSubCat([]);
      const resp = await DOMAIN.get('subcategories/' + categoryObj.key);
      if (resp.result.length > 0||resp.success) {
        const options=[{key:0,label:preCompile.subCategory,value1:"",value:""}];
        resp.result.forEach( (fieldValue, index) => {
            options.push({key: fieldValue.id, label: fieldValue.value,value1: fieldValue.value});
        });
        /**
         * Set to edit form sub category list
         */

        setSubCat( editFormFields.map( editRow => {
          if (editRow.attribute === 'subCategory' ) {
            editRow.options=options;
            editRow.disabled=false;
          }
          return editRow;
        }))

        setSubCatData(options)
      }
      else {
        // const options = [];
        // editFormFields.map( editRow => {
        //   if (editRow.attribute === 'subCategory' ) {
        //     editRow.options = options;
        //   }
        //   setSubCat(editRow)
        //   return editRow;
        // });
      }
    },
    [],
  );



  const fetchCategoryList = useCallback(
    async () => {
      try {
        const resp = await DOMAIN.get('categories');
      if (resp.result.length > 0) {
        const options = [{key: 0, label: preCompile.category, value: ''}];
        resp.result.filter( (fieldValue) => {
          options.push({key: fieldValue.id, label: fieldValue.value, value: fieldValue.value});
          setCatData(options)
          return fieldValue;
        });

        /**
         * Set to edit form category list
         */
         editFormFields.map( row => {
          if (row.attribute === 'category' ) {
            row.options = options;
          }
          if(row.attribute==='vegNonveg'){
            row.options=foodType
          }
          return row;
        });
      } else {
        console.log('Log: Error while fetching categories');
      }
      } catch (error) {

      }


    },
    [DOMAIN, editFormFields, preCompile.category],
  )

  const setImageObj=useCallback(
    ()=>{
      let locS=''
      if(blobFile?.length){
        locS= blobFile.replace("http://localhost:3000", HOST_SERVER);
        setImage({
        imgName: fileUpload?.name,
        imgType: fileUpload?.type,
        imgSize: fileUpload?.size,
        _data: base64,
        width: widthHeight?.width,
        height: widthHeight?.height,
        localSrc: locS
    })
      }
    },
    [base64, blobFile, fileUpload, widthHeight],
  )
  function renameKeys(obj, keyMap) {
    return Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [keyMap[key] || key, value])
    );
  }

  const keyMap = {
    "id": "mealTypeId",
    "label": "mealType",
    "brands": "items",
  };

  const  fetChMealmenuConfig=useCallback(async()=>{
    try{
  const [res,resItemMaster] = await Promise.all([
    DFPAPIEndpointGalley.get('galley/mealMenuPlan/configs'),
    DFPAPIEndpoint.post('master/process',filterData),
  ]);
  if(res.data&&resItemMaster.items){
    const {items}=resItemMaster
    const newListMeals = res.data.mealTypes.list.slice();
    const newObject =[ {id:'beverages',label:'Beverages',brands:[]},{id:'food',label:'Food',brands:[]},{id:'liquor',label:'Liquor',brands:[]},{id:'refreshments',label:'Refreshments',brands:[]}];
    newObject.forEach(item=>{
      newListMeals.push(item);
    })
    const modResponses = newListMeals.map(mlt => {
      switch (mlt.id) {
        case 'appetizer':
          mlt.brands = items?.filter((fl) => (fl.category === 'Appetizers'))
          .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          if(!mlt.brands.length){
            mlt.brands = items?.filter((fl) => (fl.category === 'Appetizers'))
            .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          }
          break;
        case 'main':
          mlt.brands =items?.filter((fl) =>(fl.category === 'Gourmet')|| (fl.category === 'Food')|| (fl.category === 'Refreshments'))
          .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          if(!mlt.brands.length){
            mlt.brands = items?.filter((fl) => ((fl.category !== 'Liquor')))
            .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          }
          break;
        case 'dessert':
          mlt.brands = items?.filter((fl) => (fl.category === 'Dessert'))
          .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          if(!mlt.brands.length){
            mlt.brands = items?.filter((fl) => ((fl.category === 'Dessert')))
            .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
          }
          break;
          case 'food':
            mlt.brands = items?.filter((fl) => (fl.category === 'Food'))
            .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
            if(!mlt.brands.length){
              mlt.brands = items?.filter((fl) => ((fl.category === 'Food')))
              .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
            }
            break;
            case 'liquor':
              mlt.brands = items?.filter((fl) => (fl.category === 'Liquor'))
              .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
              if(!mlt.brands.length){
                mlt.brands = items?.filter((fl) => ((fl.category === 'Liquor')))
                .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
              }
              break;
              case 'refreshments':
                mlt.brands = items?.filter((fl) => (fl.category === 'Refreshments'))
                .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
                if(!mlt.brands.length){
                  mlt.brands = items?.filter((fl) => ((fl.category === 'Refreshments')))
                  .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
                }
                break;
          case 'beverages':
            mlt.brands = items?.filter((fl) => fl.category === 'Beverage')
            .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
            if(!mlt.brands.length){
              mlt.brands = items?.filter((fl) => ((fl.category === 'Beverage')))
              .map((fl) => ({ value: fl.itemName, id: fl.itemId }));
            }
            break;
          default:
            break;
      }
      return mlt;
    });

    const renamedData = { menuSettings: modResponses?.map(item => renameKeys(item, keyMap)).map(data=>{
      return{
      mealTypeId: data.mealTypeId,
      mealType: data.mealType,
      items:(data.mealTypeId==='appetizer'||data.mealTypeId==='dessert'||data.mealTypeId==='main'||data.mealTypeId==='beverages')?data.items.map(item => ({
          itemName: item.value,
          itemId: item.id
      })):data.items.map(item => ({
        itemName: item.label,
        itemId: item.value
    }))}})};


    const options = [];
    res.data.classCodes?.list?.map( (fieldValue, index) => {
      options.push({key: index+1, label: fieldValue.label, value: fieldValue.systemCode});
      return fieldValue;
    })
    const optionsMt = [];
    res.data.mealTypeGroups.list?.map( (fieldValue, index) => {
      optionsMt.push({key: index+1, label: fieldValue.label, value: fieldValue.id});
      return fieldValue;
    })
    const optionsSct = [];
    res.data.sectorTypes.list?.map( (fieldValue, index) => {
      optionsSct.push({key: index+1, label: fieldValue.label, value: fieldValue.id});
      return fieldValue;
    })
    editFormFields.map( row => {
      if(row.attribute==='cabin'){
        row.options=options
      }
      return row;
    });
      setState(prevState => ({
        ...prevState,
        mealMenuClass:options,
        mealMasterOptions:renamedData.menuSettings,
        mealMenuCatergory: res.data.mealCategory,
        mealTypesGroup:optionsMt,
        sectorTypes:optionsSct,
      }));
  }

    }
    catch(err){

    }
    },[])


  useEffect( () => {
    if (category) {
      fetchSubCategoryList(category);
    }
  }, [category, fetchSubCategoryList]);

  const setSector = (data,type) => {
    if(type==='configureGalley'){
      setState( (_prev) => {
        return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
      });
    }
    else if(type==='editAircraft'){
      setState((_) => {
        return { ..._, resetDataSource: false, selected: { data,action: 'editAircraftType' },edit:true};
      })
    }
   else if(type==='deleteAircraft'){
    setState((_) => {
      return { ..._, resetDataSource: false,selected:data,delete:true ,deleteProg:false,edit:false};
    })
   }
   else{
    setState( (_prev) => {
      return { ..._prev, selected: {...data ,action: type},resetDataSource:false}
    });
   }
  };

  function actionMealmaster(props){
    return (
      props.data?
      <>
      <div className='flexRow margTop8'>
     { viewmealMaster?<div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='edit' onClick={(e) => setSector(props.data, 'editAircraft')} src={viewIcon}/>
        <span className="tooltip-action">View</span>
        </div>:<></>}
    { editmealMaster?    <div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='edit' onClick={(e) => setSector(props.data, 'editAircraft')} src={editIcon}/>
        <span className="tooltip-action">Edit</span>
        </div>:<></>}
        {deletemealMaster?<div className='icon-aircraft-container'>
        <img className='aircraft-actions' alt='delete' onClick={(e) => setSector(props.data, 'deleteAircraft')} src={deleteIcon}/>
        <span className="tooltip-action">Delete</span>
        </div>:<></>}
      </div>

      </> : <></>
    );
  }

  const Columns = [
    { field: 'id',headerName: 'Meal Id', sortable: true,flex:1, suppressSizeToFit:true, resizable:true, minWidth: 155 },
    { field: 'mealName',headerName: 'Meal Name', sortable: true, flex:1,suppressSizeToFit:true, resizable:true, minWidth: 200},
    { field: 'mealType', headerName: 'Meal Type',sortable: true,flex:1, suppressSizeToFit:true, resizable:true, minWidth: 135 },
    { field: 'diet',headerName: 'Diet',flex:1, minWidth: 175,sortable: true,suppressSizeToFit:true, resizable:true, },
    { field: 'imageUrl',headerName: 'Image', cellRenderer: ImageIndicator, sortable: true,flex:1,suppressSizeToFit:true,resizable:true, minWidth: 175 },
    { field: 'cabin', headerName: 'Cabin',sortable: true,flex:1,  suppressSizeToFit:true, resizable:true,minWidth: 125 },
    {
      headerName:'Actions',
      pinned:'right',
       autoResize:true,
        width:170,
        resizable: false,
        suppressSizeToFit:true, sortable: true,
        cellRenderer: actionMealmaster,
        valueGetter: function(params) {
          return params.sectors;
        }
      }
  ];

  const onAction = async (e, action, selectedRowData) => {
    itemIdMaster=selectedRowData.itemId;
    setItemTypeValue(selectedRowData?.itemType)
    const resp = await DOMAIN.get(`inventory/items/${itemIdMaster}`);
    if (resp.success) {
       invData=resp.result;
      invData.category=resp.result.category.value;
      invData.subCategory=resp.result.subCategory.value;
      if(invData){
        setState( (_prev) => {
          return { ..._prev, selected: {...invData,  action: 'editItem' },resetDataSource:false}
        });
      }
    }


    else {
      console.log('Log: Error while fetching item types API');
    }
    /**
     * Set to edit form
     */

     editFormFields.map( editRow => {
      if (editRow.attribute === 'category') {
        editRow.value = selectedRowData.category;
        const selCategoryObj = editRow.options.find(row => row.value === selectedRowData.category);
        if (selCategoryObj) {
          //
          setCategory(selCategoryObj);
        }
      }
      return editRow;
    });

    EditFormFields.map( editRow => {
      if (editRow.attribute === 'itemType' ) {
        editRow.initialValue = selectedRowData.itemType;
      }
      if (editRow.attribute === 'itemCode') {
        editRow.disabled = false;
      }
      if (editRow.attribute === 'category' ) {
        editRow.initialValue =  invData.category;

      }
      if (editRow.attribute === 'subCategory' ) {
        editRow.initialValue =  selectedRowData.subCategory;
        editRow.value =  selectedRowData.subCategory;
        editRow.disabled=false
      }

      return editRow;
    });
    setSubCat(editFormFields.map( editRow => {
      if (editRow.attribute === "subCategory") {
        editRow.initialValue =  selectedRowData.subCategory;
        editRow.value =  selectedRowData.subCategory;
        editRow.disabled=false
      }
      return editRow;
    }))
  };
  const onFieldChangeHandler = (e) => {
    const {name,value}=e.target;

    if(name==='unit'){
      setSelectFields({...selectFields,unit:value})
    }
    if(name==='section'){
      setSelectFields({...selectFields,section:value})
    }
    if (name === 'category') {
      const selCategory = value;
      /**
       * Set to edit form
       */
       editFormFields.map( editRow => {
        if (editRow.attribute === name) {
          editRow.value = selCategory;
          const selCategoryObj = editRow.options.find(row => row.value === selCategory);
          if (selCategoryObj) {
            //
            setCategory(selCategoryObj);
          }
        }
        return editRow;
      });
    }
    if (name === 'subCategory') {
      const selSubCategory = value;
      /**
       * Set to edit form
       */

       setSubCat(editFormFields.map( editRow => {
        if (editRow.attribute === "subCategory") {
          editRow.value = selSubCategory;
        }
        return editRow;
      }))
    }
  };
  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false,resetDataSource:true }
    });
    editFormFields.forEach((items)=>{
    if(items.value||items.initialValue){
        delete items["value"];
        delete items["initialValue"];
      }
    })
    setModalFormStatusObj({text:''});
    fetchCategoryList();
    setItemTypeValue(null);
    setSubCat([]);
    invData={};
    setImage({});
    setSubCatData([]);
    setCategory(null);
    // resetImageSelection();
  }

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchItemMaster = async (page=1, perPage=10) => {
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
    if (selItemType) {
      const isItemTypeExists = filterData.filters.find(row => row.itemType ? true : false);
      if (!isItemTypeExists) {
        filterData.filters.push({ itemType: selItemType });
      } else {
        filterData.filters.map( row => {
          row.itemType = selItemType;
          return row;
        });
      }
    }
    const res = {count:mealMasterData.length,items:mealMasterData}
    return res;
  }

const onDownloadItemMaster=async()=>{
  if(isDownloading) return;
  let header={
    'Accept':'text/csv'
  }
    try {
      setIsDownloading(true)
    const res= await DOMAIN.post(`downloads/itemMaster`,{},header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        const dowId = DOMAIN.get(`downloads/status/${res.id}`);
        dowId.then((val) => {
          if (val.status === "completed") {
              DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                  .then((ItemMasterDwnld) => {
                      const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                      const link = document.createElement('a');
                      link.href = url;
                      link.setAttribute('download', val.fileName + '.csv');
                      document.body.appendChild(link);
                      link.click();
                      setIsDownloading(false);
                  })
                  .catch((error) => {

                      console.error('Error downloading CSV:', error);
                      setIsDownloading(false);
                  });
          }
      });
        }, 8000);
    }
    }
     catch (error) {
      setState((_) => {
        return { ..._,message:{text:error?.data?.message},};
      })
      setTimeout(() => {
        setState((prevState) => ({
          ...prevState,
          message: null,
        }));
      }, 5000);
       setIsDownloading(false)
     }
  }
 const getCatSubCatObj=(obj=null,stateVal=null)=>{
let catObj={},
   subCatObj={}
   if(obj&&obj.category){
    catObj=catData.find(c=>c.label===obj.category);
    if(catObj){
     fetchSubCategoryList(catObj)
    }
   }
     if(obj&&!obj.category){
     catObj=catData.find(c=>c.label===stateVal.category);
    if(catObj){
     fetchSubCategoryList(catObj)
    }
   }
if(obj&&!obj.subCategory){
         subCatObj=subCatData.find(c=>c.label===stateVal.subCategory);
   }
   if(obj&&obj.subCategory && subCatData.length){
    subCatObj=subCatData.find(c=>c.label===obj.subCategory);
   }
   if(obj===null&& stateVal){
    catObj=catData.find(c=>c.label===stateVal.category);
   subCatObj=subCatData.find(c=>c.label===stateVal.subCategory);
    if(catObj){
     fetchSubCategoryList(catObj)
    }
   }
return removeKeys(catObj,subCatObj)
 }
const removeKeys=(catObj,subObj)=>{
  const modifiedCatObj = { ...catObj };
  const modifiedSubObj = { ...subObj };

  if (modifiedCatObj && modifiedSubObj) {
    delete modifiedCatObj['label'];
    modifiedCatObj['id'] = modifiedCatObj['key'];
    delete modifiedCatObj['key'];

    delete modifiedSubObj['label'];
    modifiedSubObj['id'] = modifiedSubObj['key'];
    delete modifiedSubObj['key'];
  }
  return [catObj,subObj]
}
 const imageDimension=(imgData)=>{
  let i = new Image();
  i.onload = function(){
  setWidthHeight({width:i.width,height:i.height})
  };
  i.src = imgData;

}
const handleMealOptions=(type)=>{

  let options=[]

if(state&&state?.mealMasterOptions){
  const {mealMasterOptions}=state;
  mealMasterOptions.forEach((item,index)=>{
    if(item.mealTypeId.includes(type)){
      item.items.forEach(it=>{
        options.push({key:index,label:it.itemName,value:it.itemId})
      })
    }else{
      item.items.forEach(it=>{
        options.push({key:index,label:it.itemName,value:it.itemId})
      })
    }
  })
}
return options

}
const encodeImageFileAsURL=(element)=> {
  setBlobFile(URL.createObjectURL(element))
  setFileUpload(element)
  const reader = new FileReader();
  reader.onloadend = () => {
      imageDimension(reader.result);
      setBase64(reader.result);
  };
  reader.readAsDataURL(element);
}

const newCustomStyles = {

  control: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: "#162E3E",
    padding: "3px",
    border: "0.4px solid #FFFFFF",
    boxShadow: "none",
    PointerEvent: 'none',
  })
};
const handleAddTypes = (mealCatergory,edit=null) => {
  setInputChanged(true);
    let newId;
        newId = mealmasterExtraReq[mealmasterExtraReq.length-1];
        setMealMasterExtraReq([...mealmasterExtraReq, { id: newId['id']+1 }]);
  };
  const handleRemoveTypes = (mId) => {
    setInputChanged(true);
      const updatedMealReq = mealmasterExtraReq.filter(set => set.id !== mId);
      setMealMasterExtraReq(updatedMealReq);
    };


const generateAddntnMeals = (mealSetId) => (
  <div key={mealSetId} className={'flexRow justifyContentSpaceBetween'}>


    <div className='width100 flexRow alignItemsCenter margTop8'>


<div className={`dialog__container_item input-container `} style={{margin:'0% 3% 0% 0%'}}>
            <div className={`dialog__description`} style={{marginBottom:'0',minHeight:'42px',margin: '1rem 0rem 0rem 0rem'}}>

              <input
                type={'text'}
                className='edit-input'
                placeholder={'Name'}
                // required={ required ? true : false }
                // {...rest}
              />
            </div>

          </div>
          <div className='width100  flexRow' style={{margin:'1% 3% 0% 0%'}}>
                    <Select
          // defaultValue={state[stateKey+mealSetId] ? state[stateKey+mealSetId] : []}
          options={handleMealOptions("custom")}
          // placeholder={`Select ${mealType}`}
          // value={handleMealOptions(preCompile.contBreakFast, preCompile[mealType]).filter(function (option) {
          //   return state[stateKey+mealSetId] && state[stateKey+mealSetId].find(sc => sc.label === option.label)
          // })}
          // onChange={(selectedOption)=>selectHandler(selectedOption,stateKey+mealSetId)}
          styles={newCustomStyles}
          isSearchable={true}
          isMulti={true}
          className='selectMenu-meals'
        />
    {mealSetId===1 && (
    <img src={plus} className="menuplanner-icons" style={{margin:'1.5% 0% 0% 3%'}} alt="add" onClick={handleAddTypes} />
    )}
    {(mealSetId !== 1) && (
    <img src={minus} className="menuplanner-icons" style={{margin:'1.5% 0% 0%3%'}} alt="remove" onClick={()=>handleRemoveTypes(mealSetId)} />
    )}
          </div>


    </div>

  </div>
);


const resetImageSelection=()=>{
  setImage({})
  setState( (_prev) => {
    return {..._prev,selected: {
      ...state.selected,
      image:null
    }};
  });
  setBlobFile(null);
  setFileUpload(null);
 }

const getItemChecked=(updated,option,stateSel=false)=>{
if(updated[option]===true || updated[option]===false){
 return updated[option];
 }
 else{
 return stateSel
}
}
const handleReset = () => {
  setState((_) => {
    return { ..._, resetDataSource: true };
  })
  let timerId = setTimeout(() => {
    clearTimeout(timerId);
    setState((_) => {
      return { ..._, resetDataSource: false };
    })
    onClose();
  }, 800)
}

  const onEditSubmit = async (e, updated) => {
    const {selected}=state;
    if (Boolean(updated) && state.selected?.action==='editItem') {
      let [cat,subCat]= getCatSubCatObj(updated,state.selected)
        const bodyItemMaster={
          "itemName": updated.itemName?updated.itemName:selected.itemName,
          "itemId": selected.itemId,
          "itemCode": updated.itemCode?updated.itemCode:selected.itemCode,
          "itemType": updated.itemType?updated.itemType:selected.itemType,
          "ssrCode": updated.ssrCode?updated.ssrCode:selected.ssrCode,
          'hsn':updated.hsn?updated.hsn:selected.hsn,
          "barcode": updated.barcode?updated.barcode:selected.barcode,
          "category": cat,
          "subCategory": subCat,
          "description":updated.description?updated.description:selected.description,
          "unit": selectFields.unit?selectFields.unit:selected.unit,
          "baseUnit": updated.baseUnit?updated.baseUnit:selected.baseUnit,
          "section": selectFields.section?selectFields.section:selected.section,
          "colorCode": updated.colorCode?updated.colorCode:selected.colorCode,
          "replenishable":updated.replenishable?updated.replenishable:selected.replenishable,
          "image": Boolean(Object.keys(image).length)?image:!selected.image?null:selected.image,
          "domestic": getItemChecked(updated,'domestic',selected.domestic),
          "international": getItemChecked(updated,'international',selected.international),
          "bob": getItemChecked(updated,'bob',selected.bob),
          "corporate": getItemChecked(updated,'corporate',selected.corporate),
          "webMeal": getItemChecked(updated,'webMeal',selected.webMeal),
          "perishable": getItemChecked(updated,'perishable',selected.perishable),
          "saleable": getItemChecked(updated,'saleable',selected.saleable),
          "beverage": getItemChecked(updated,'beverage',selected.beverage),
          "merchandise": getItemChecked(updated,'merchandise',selected.merchandise),
          "stationary": getItemChecked(updated,'stationary',selected.stationary),
          "groupMeal": getItemChecked(updated,'groupMeal',selected.groupMeal),
          "crewMeal": getItemChecked(updated,'crewMeal',selected.crewMeal),
          "liquor": getItemChecked(updated,'liquor',selected.liquor),
          "dutyFree": getItemChecked(updated,'dutyFree',selected.dutyFree),
      }
      try {
      const res= await DOMAIN.put(`inventory/items/${selected.itemId}`, bodyItemMaster);
      if(res.success){
        setModalFormStatusObj({ text: res.msg })
        handleReset();
      }
        }
        catch (error) {
        const errObj = JSON.parse(error.message)
        setModalFormStatusObj({ error: errObj.data.msg })
        }
    }
    if (updated===null && state.selected?.action==='editItem') {
     let [cat,subCat]= getCatSubCatObj(updated,state.selected)
     let bodyItemMaster={...state.selected, image: Boolean(Object.keys(image).length)?image:!selected.image?null:selected.image};
     delete bodyItemMaster['action'];
     bodyItemMaster.category=cat;
     bodyItemMaster.subCategory=subCat;
      try {
      const res= await DOMAIN.put(`inventory/items/${bodyItemMaster.itemId}`, bodyItemMaster);
      if(res.success){
        setModalFormStatusObj({ text: res.msg })
        handleReset();
      }
        }
        catch (error) {
        setModalFormStatusObj({ text: error.data })
        }
    }
    if (Boolean(updated) && state.selected?.action==='addNewItem') {
      let [cat,subCat]= getCatSubCatObj(updated,state.selected)
      const bodyItemMaster={
        "itemType": updated.itemType,
        "ssrCode": updated.ssrCode?updated.ssrCode:selected.ssrCode,
        "category": cat,
        "subCategory": subCat,
        "itemCode": updated.itemCode,
        "itemName": updated.itemName,
        "description":updated.description?updated.description:'',
        "unit":selectFields.unit?selectFields.unit:'',
        "image":Boolean(Object.keys(image).length)?image:!selected.image?null:null,
        "domestic":getItemChecked(updated,'domestic',false),
        "international": getItemChecked(updated,'international',false),
        "bob": getItemChecked(updated,'bob',false),
        "corporate": getItemChecked(updated,'corporate',false),
        "webMeal": getItemChecked(updated,'webMeal',false),
        "perishable": getItemChecked(updated,'perishable',''),
        "saleable": getItemChecked(updated,'saleable',''),
        "beverage": getItemChecked(updated,'beverage',''),
        "merchandise": getItemChecked(updated,'merchandise',''),
        "stationary": getItemChecked(updated,'stationary',''),
        "groupMeal": getItemChecked(updated,'groupMeal',''),
        "crewMeal": getItemChecked(updated,'crewMeal',''),
        "liquor": getItemChecked(updated,'liquor',''),
        "dutyFree": getItemChecked(updated,'dutyFree',''),
    }
      try {
      const res= await DOMAIN.post(`inventory/items`, bodyItemMaster);
      if(res.success){
        setModalFormStatusObj({ text: res.msg })
        handleReset();
      }
      if(!res.success){
        setModalFormStatusObj({ text: res.message })
        handleReset();
      }
        }
        catch (error) {
        const errObj = JSON.parse(error.message)
        setModalFormStatusObj({ error: errObj.data.msg })

        }
    }
    else if(state.selected?.action==='addNewItem'){
      setModalFormStatusObj({ error: "Please fill all mandatory fields*" })
    }

  };
  const onContinue=()=>{
    setState( (_prev) => {
      return { ..._prev, selected:false,delete:false };
    });
  }

  const onCloseDelete=()=>{
    setState( (_prev) => {
      return { ..._prev, selected:false,delete:false };
    });
  }

  let     delProps = {
    setShow,
    show,
    onContinue,
    onCloseDelete,
      }
  const onAddItemHandler = () => {
    setState( (_prev) => {
      return { ..._prev, selected: { action: 'addNewItem' } };
    });

    /**
     * Set to edit form
     */
     editFormFields.forEach( editRow => {

      if(editRow.attribute === 'category'){
        editRow.value="";
        editRow.initialValue="";
      }
      if(editRow.attribute === 'category'){
        editRow.value="";
        editRow.initialValue="";
      }
      if(editRow.attribute === 'subCategory'){
        editRow.options=[{key: 0, label: preCompile.subCategory, value1: '',value: ''}];
        editRow.disabled=true;
        editRow.initialValue=""
      }
      return editRow;
    });
  }
  // const onPerLimitChange = (e) => {
  //   setPerPageLimit(Number(e.target.value));
  // };

  const onSearch = (e) => {
      setSearchKey(e.target.value);
  };

  const onItemTypeChange = (e) => {
      setItemType(e.target.value);
  }
  const onUploadTaxMaster=()=>{
    setShowUpload(true)

  }
  const handleModalCancel=()=>{
    setState((prev)=>({...prev,selected:false}))
      }

  const onUploadClose = () => {
    setUploadedFile([]);
    setShowUpload(false);
    setUploadFileStatusBlock('');
  }
  const onDownloadSampleFile = () => {
    onDownloadXlsFile('samples/downloads/Item_Master_Upload_Sample','Item_Master_Upload_Sample')
  }
  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', undefined);
    formData.append('stores', []);
    formData.append('warehouses', []);
    formData.append('file', file);

    try {
      await DOMAIN.post('inventory/upload', formData);
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      setState( (_prev) => {
        return { ..._prev,resetDataSource:true}
      });

    }
    catch(error) {
      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400) {
        const errors = data.errors.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
      }
      else {
        msgContent += 'Error occured while upload items sheet!';
      }

      console.log('Log: Error occured while upload items sheet!', status, data);

      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
};

  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
     {uploadmealMaster?  <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadTaxMaster(true)}> <span><img  className="icon-size mt-1" alt='uploadItem' src={uploadItemIcon} /><u>{t('filters.uploadItems')}</u></span></button>:<></>}
           {downloadmealMaster? <button className='download dropdownStyle1-TextMedium' onClick={onDownloadItemMaster}><span>{isDownloading?<img src={LoadingGIF} alt='loading' className='cell-loading' />:<img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />}<u className={`download__banner_${isDownloading ? "active" : ""}`}>{isDownloading ? t('filters.downloadingMaster'):t('filters.downloadMaster')}</u></span></button>:<></>}

          </div>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
          {
          Boolean(state.message) &&
          <div className={`validationMsgDiv error`}>
            {state.message.text}
          </div>
        }
          </div>
{    createmealmMaster?<div className='flexRow width100 margBot10'>

              <div className="width100 flexRow justifyContentFlexEnd alignItemsCenter">
              <div className="underline margTop8 m-2">
                  <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                  <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={searchKey}/></span>
              </div>
              <span className='margRight89'></span>
                  <button className="add-item-button m-2" onClick={ onAddItemHandler }>
                  {"+ Create"}
                  </button>
              </div>
          </div>
:<></>}
          <DataGrid
            ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            selItemType={selItemType}
            getRows={fetchItemMaster}
            rowSelection="multiple"
            onAction={onAction}
            onAddItemHandler={() => onAddItemHandler()}
            columnResize={true}
            resetDataSource={state.resetDataSource}
          />
        </>
      }
      {
        state && Boolean(state.selected) && !state.delete ? (
          <CreateMealmastr
          key={state.selected?.action}
          title={ `Create Meal`}
          onClose={onClose}
          onSubmit={handleModalCancel}
          menuItems={state}
          fields={ editFormFields.map( (elt) => {
                return {...elt, initialValue:""}
              })
          }

          />)

        : null
      }
       {state.delete && deletemealMaster &&<DeleteConf  {...delProps} modalFormStatusObj={modalFormStatusObj} description={`Are you sure you want to delete Meal master ${state.selected?.id} ?`} />}

       {
        showUpload &&
        <UploadModal
        modelTitle={preCompile.uploadTitle}
        onUploadClose={onUploadClose}
        onDownloadSampleFile={onDownloadSampleFile}
        uploadedFile = { uploadedFile }
        onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
        uploadFileStatusBlock = { uploadFileStatusBlock }
        allowedExtensions={ MIMETypes.excel }
        />
      }
      {
      t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
      }
    </div>
  )
}