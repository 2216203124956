import React from 'react';
import LoadingGIF from '../assets/loading.gif';

export const Loading = (props) => {
  const isLabel = Boolean(props.label);
  const imgStyle = {
    width: props.size || '50px',
    height: props.size || '50px',
  };

  const LoaderShade = () => (
    <div className="loadframe">
      <span className="loader-1"></span>
    </div>
  );

  return (
    <div className={`${isLabel ? 'flexRow' : 'flexRow'} justifyContentCenter alignItemsCenter full-flex ${props.className || ''}`}>

      {imgStyle.width === '60px' ? (
        <img src={LoadingGIF} className="page-loading" style={imgStyle} alt="Loading" />
      ) : (
        <LoaderShade />
      )}

      {isLabel && <label>{props.label}</label>}
    </div>
  );
};
