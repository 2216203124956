/* eslint-disable react-hooks/exhaustive-deps */


import React,{useState,useEffect,useRef,useCallback} from 'react'
import { useParams,useLocation} from 'react-router-dom';
import { APIEndpoint, DFPAPIEndpoint } from '../../../../utils/axios';
import { ORDER_SALE_TYPE_LIST_DYNAMIC } from '../../../../utils/configs';
import { DataGrid,Modal,Loading,EditModal} from '../../../../common';
import { translationLength, truncateString} from '../../../../utils/commonFunctions';
import LoadingIn from '../../../../assets/loadingIn.gif';
import { IoArrowForwardOutline } from "react-icons/io5";
import OrderListing from '../renderers/orderListing';
import SalesSummary from '../renderers/salesSummary';
import deposit from "../../../../assets/deposit.svg";
import { useNavigate } from "react-router-dom";

import { useTranslation } from 'react-i18next';
import {EditContainer} from "./editContainer";
import {Schedulecontainer} from "./schedulecontainer";
import {GeneralContainer} from "./generalContainer";
import {EventLogContainer} from "./eventLogContainer";
import { PiAirplaneTiltFill } from "react-icons/pi";
import moment from 'moment';
import { MdEdit } from "react-icons/md";
import { SlCalender } from "react-icons/sl";
import SectorInstanceContainer from './sectorInstanceContainer';
import { OrderCreateIsolate } from './order-create-isolate';


export const ContainerIsolate=()=> {
  const urlParams = useParams();
  const {id,name}=urlParams;
  const location = useLocation();
  const { orderIdOffload } = location.state || {};

  const { t } = useTranslation();
  const tableRef = useRef();
  const navigate = useNavigate();

const [instance,setInstance]=useState({})
const [state, setState] = useState({emptInst:false,showOrders:false,resetDataSource:false,disabled:false,deleted:false,selected:false,selected2:false});
const [activeTab,setActiveTab]=useState(4);
const [sectors, setSectors] = useState([]);;
const [isOpen, setIsOpen] = useState(false);
const [reload,setReload]=useState(false);
const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
const [EditFieldsCess, setEditFieldsCess] = useState([]);
const [ modalTitle, setModalTitle ] = useState('');
const [sectorIsolateData, setSectorIsolateData] = useState({});
const [orderId,setOrderId]=useState("");
const [navigateInstanceId,setNavigateInstanceId]=useState(null);
const [documentEditId,setDocumentEditId]=useState(null);
const [saleTypeCheck,setSaleTypeCheck]=useState(false);
const [lockInstance,setLockInstance]=useState(false);
const [modalCustomButtons,setModalCustomButtons]=useState([]);
const [status, setStatus] = useState("");
const [editDetailsContainer,setEditDetailsContainer]=useState({});
const [sorting,setSorting]=useState(false);
const [paymentType, setPaymentType] = useState("");
const [searchKeyOrders, setSearchKeyOrders] = useState("");
const [saleType, setSaleType] = useState("");
let updatedEditFields = [];
const stateRef = useRef(state);

useEffect(() => {
  const handlePopState = (event) => {
    if (event && event.state) {
     navigate('/sales/crewreported');
    }
  };
  window.addEventListener('popstate', handlePopState);
  return () => {
    window.removeEventListener('popstate', handlePopState);
  };
}, [navigate]);

useEffect(() => {
  stateRef.current = state;
}, [state]);

const sType = localStorage.getItem('sType');
const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

useEffect(() => {
  if (location && instance && instance.state==='locked') {
    releaseLock()
  }
  if(orderIdOffload){
    setNavigateInstanceId(orderIdOffload);
  }
}, [location]);

useEffect(() => {

  if(state && state.error){
  setTimeout(() => {
    setState((_)=>({..._,error:false}))
  }, 3500);
  }
}, [state]);

useEffect(() => {
  sectorSearchFilters()
}, [status,paymentType,saleType,saleTypeCheck,searchKeyOrders])

const sectorSearchFilters=()=>{
let filtersSec=[];
  const filterData={
    documentId:documentEditId,
    page: 1,
    limit: 10,
    filters: [
      {
        sectorId: orderId, sortBySaletype: saleTypeCheck
      },

  ],
    source:"editDocument"

}

const filterConditions = [

{ condition: status, filter:  {
    "status": status
}},
{ condition: saleType, filter:   {
  "saleType": saleType
}},
{ condition: paymentType, filter: {
  "paymentType": paymentType
} },
{ condition: searchKeyOrders, filter: {
  "searchKey": searchKeyOrders
} }
];

filterConditions.forEach(({ condition, filter }) => {

if (condition) {
  filtersSec.push(filter);
  }
}
);
if(filtersSec.length){
  filterData.filters = [...filterData.filters,...filtersSec];
}
else{
filterData.filters = [...filterData.filters];
}
fetchSectorLists(filterData);

}

const releaseLock = async () => {
  const payload=
  {
    "isolateId": id
}
try {
const res = await APIEndpoint.post(`sandboxing/containerIsolates/editDocument/releaseLock`,payload);
if(res){

}
} catch (error) {

}
};


const  fetchSectorLists=async(fil=null)=>{
  let filterData;
  if(!fil){
filterData={
  documentId:documentEditId,
    page: 1,
    limit: 10,
    filters: [
        {
          sectorId: orderId, sortBySaletype: saleTypeCheck
        },

    ],
    source:"editDocument"

}
  }else{

    filterData=fil
  }

try {
  const res = await APIEndpoint.post('sandboxing/isolatedOrders/listing', filterData);
  if(res){
    setState((prev)=>({...prev,selected2:{...prev.selected2,data:res?.items}}))


  }

} catch (error) {

}
};



const paymentTypeDist=[
  { field: 'paymentType',headerName: 'Payment Type',valueGetter: (params) => {
    if (params.data&&params.data.paymentType) {
       return params.data.paymentType==='none'?"SSR":params.data.paymentType.charAt(0).toUpperCase()+params.data.paymentType.slice(1)
    }
    return null;
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'totalNumberOfOrders',headerName: 'Number of Orders', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'amount',headerName: 'Amount', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
]

const saleTypeDist=[
  { field: 'saleType',headerName: 'Sale Type', sortable: true,flex:1, filter: true,suppressSizeToFit:true,valueGetter: (params) => {
    if (params.data&&params.data.saleType) {
       return ORDER_SALE_TYPE_LIST_DYNAMIC.find(ord=>ord.key===params.data.saleType)?.value
    }
    return null;
  }, resizable:true, minWidth: 155 },
  { field: 'totalNumberOfOrders',headerName: 'Number Of Orders', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'amount',headerName: 'Amount', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
]
const currencyDist=[
  { field: 'code',headerName: 'Currency', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'totalNumberOfOrders',headerName: 'Number of Orders', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'amount',headerName: 'Amount', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
]

const TransferIn=[
  { field: 'itemCode',headerName: 'Item Code', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'itemName',headerName: 'Item Name', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  },minWidth: 155 },
  { field: 'quantity',headerName: 'Quantity', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'unit',headerName: 'Unit', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
]

const Uplifts=[
  { field: 'itemCode',headerName: 'Item Code', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'itemName',headerName: 'Item Name', sortable: true,flex:1, filter: true,suppressSizeToFit:true, tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  },resizable:true, minWidth: 155 },
  { field: 'bob',headerName: 'BOB', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'corporate',headerName: 'Corporate', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'webMeal',headerName: 'Web Meal', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'total',headerName: 'Total', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'unit',headerName: 'Unit', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 }
]

const Offloads=[
  { field: 'itemCode',headerName: 'Item Code', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'itemName',headerName: 'Item Name', sortable: true,flex:1, filter: true,suppressSizeToFit:true, tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  },resizable:true, minWidth: 155 },
  { field: 'quantity',headerName: 'Quantity', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'unit',headerName: 'Unit', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 }
]

 const EditTransferColumns = [
  { label: 'Item Code', type: 'select', labelProp: 'itemCode', attribute: 'itemCode', required: true },
  { label: 'Item Name', type: 'select', labelProp: 'itemName', attribute: 'itemName', required: true },
  { label: 'Quantity', type: 'number', attribute: 'quantity', required: true },
  { label: 'Unit', type: 'string', attribute: 'unit', required: true },
  { label: 'Remarks', type: 'string', attribute: 'remarks', required: false },
];


const orderCancel=[
  { field: 'itemCode',headerName: 'Item Code', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'itemName',headerName: 'Item Name', sortable: true,flex:1, filter: true,suppressSizeToFit:true, tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  },resizable:true, minWidth: 155 },
  { field: 'itemPrice',headerName: 'Item Price', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'quantity',headerName: 'Quantity', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'unit',headerName: 'Unit', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'totalAmount',headerName: 'Total Amount', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },

]

const StockMvmnt=[
  { field: 'itemCode',headerName: 'Item Code', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155,valueGetter:(params)=>truncateString(params?.data.itemCode,22) },
  { field: 'itemName',headerName: 'Item Name', sortable: true,flex:1,tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  }, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 165 },
  { field: 'openingStock',headerName: 'Opening Stock', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'uplift',headerName: 'Uplift', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'transferIn',headerName: 'Transfer In',valueGetter: (params) => {
    if (params.data&&params.data.transferIn) {
       return params.data.transferIn
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'variance',headerName: 'Variance',valueGetter: (params) => {
    if (params.data&&params.data.variance) {
       return params.data.variance
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'wastage',headerName: 'Wastage', valueGetter: (params) => {
    if (params.data&&params.data.wastage) {
       return params.data.wastage
    }else{
      return 0;
    }
  },sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'wastageFromPos',headerName: 'Wastage (from POS)',valueGetter: (params) => {
    if (params.data&&params.data.wastageFromPos) {
       return params.data.wastageFromPos
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'offload',headerName: 'Offload',valueGetter: (params) => {
    if (params.data&&params.data.offload) {
       return params.data.offload
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'order',headerName: 'Order', valueGetter: (params) => {
    if (params.data&&params.data.order) {
       return params.data.order
    }else{
      return 0;
    }
  },sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'transferOut',headerName: 'Transfer Out',valueGetter: (params) => {
    if (params.data&&params.data.transferOut) {
       return params.data.transferOut
    }else{
      return 0;
    }
  }, sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'closingStock',headerName: 'Closing Stock', valueGetter: (params) => {
    if (params.data&&params.data.closingStock) {
       return params.data.closingStock
    }else{
      return 0;
    }
  },sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },
  { field: 'unit',headerName: 'Unit', sortable: true,flex:1, filter: true,suppressSizeToFit:true, resizable:true, minWidth: 155 },

]


const sectorTitle=()=>{
  const {action}=state.selected2?state.selected2:state.selected;
  if(action==='sectorAttachments'||action==='viewEventLogs'){
    const { selected = state.selected2 } = state;
return(<div className='flexRow justifyContentCenter'>
  <div className='flexRow'>
    {action==='sectorAttachments'?<div className='flexRow'>Sector attached to instance<div className='instance-member-name'>{selected.name}</div>
    <button className='small-body-text-regular fontExtrabold key-value btn-sync'>
      <img style={{height:'40px',width:"40px",backgroundColor:"#4c5c81",borderRadius:"4px"}} src={deposit} onClick={()=>setReload(!reload)} alt='refresh-sectors' />
      </button>
    </div>:<div className='flexRow'>Event Log-<div className='instance-member-name'>{state?.selected?state?.selected.data[0]?.target.name:state?.selected2.data[0]?.target.name}</div></div>}

  </div>
</div>)
  } else if(action==='uplifts'){
    const {selected2}=state;
    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Uplift from <div className='instance-member-name'>{selected2.data?.warehouse?.warehouseCode}</div></div>

      </div>
    </div>)
  }
  else if(action==='ordersEditDoc'){
    const {name}=state.data?.transferOutReport?.sector?state.data?.transferOutReport?.sector:state?.flightDetails;
    return(<div className='fexCol justifyContentCenter'>
      <div className='flexRow justifyContentCenter'>
        <div className='flexRow'>Orders - <div className='instance-member-name'>{name||'None'}</div></div>
          <button className='small-body-text-regular fontExtrabold key-value btn-sync'>
          <img style={{height:'40px',width:"40px",backgroundColor:"#4c5c81",borderRadius:"4px"}} src={deposit} onClick={()=>setReload(!reload)} alt='refresh-sectors' />
          </button>
      </div>
      <div className='flexRow justifyContentFlexStart'><div className={`flexRow dialog__description__checkbox`}>
              <input
                  id={''}
                  type={'checkbox'}
                  onClick={()=>setSaleTypeCheck(!saleTypeCheck)}
                  checked={saleTypeCheck}
                  value={saleTypeCheck}
                />
                <label htmlFor={""} style={{fontSize:"1rem",fontWeight:'bold'}}className={`margTop8`} >Sort By SaleType</label>
            </div>
            </div>
    </div>)
  }
  else if(action==='stockMvmnt'){
    const {name}=state.data?.transferOutReport?.sector?state.data?.transferOutReport?.sector:state.selected2.flightDetails;

    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Stock movement -<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else if(action==='offloads'){
    const {name}=state.data?.transferOutReport?.sector?state.data?.transferOutReport?.sector:state.selected2.flightDetails;

    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Offloads -<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else if(action==='wastages'){
    const {name}=state.data?.transferOutReport?.sector?state.data?.transferOutReport?.sector:state.selected2.flightDetails;

    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Wastages -<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else if(action==='transferOut'){
    const {name}=state.data?.transferOutReport?.sector||state.data?.transferReport?.sector;

    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Transfers -<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else if(action==='transferIn'){
    const {name}=state.data?.transferInReport?.sector;


    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Transfers-<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else if(action==='salesSummary'){
    const {name}=state.data?.transferOutReport?.sector;

    return(<div className='flexRow justifyContentCenter'>
      <div className='flexRow'>
        <div className='flexRow'>Sales summary -<div className='instance-member-name'>{name}</div></div>
      </div>
    </div>)
  }
  else{
    return(<></>)
  }

}
const handleOnReset = () => {
  fetchOperationsPanel();

  setTimeout(() => {
    setState((prevState) => ({
      ...prevState,
      deleted: !prevState.deleted,
    }));
    onClose();
  }, 3500);
};

const onClose = () => {
  setModalFormStatusObj({ text: '' });

  setState((prevState) => ({
    ...prevState,
    deleted: !prevState.deleted,
    selected: false,
    disabled: false,
  }));
};


 const handleModalCancelEvent=(e,type=null)=>{
   for(let i in state){
     if(i==='selected2'&&!type){
       delete state[i];
     }
     if(i==='selected3'&&type&&type.length){
      delete state[i];
    }
   }
   setModalFormStatusObj({ text:""});
   setSaleTypeCheck(false);
   setPaymentType("");
   setSaleType("");
   setStatus("");
   setSearchKeyOrders("");
  }
const handleCancelOrder=async(e,dat)=>{
   const {data}=state;

  const payload={
    "isolateId": editDetailsContainer.isolateId,
    "documentId": editDetailsContainer.id,
    "editType": "cancel",
    "sector": {
        "id": data.id,
        "key": data.key,
        "name": data.name
    },
    "orderId": dat.id
}
  try {
    const res = await APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits/modifyOrder`,payload);

    if(res){
    setState((prev)=>({...prev,selected3:false}))
    }
    }
    catch (error) {


    }
}

const handleEditOrder=async(sheetData,dat)=>{

const {data}=state;
const payload={
  "isolateId": editDetailsContainer.isolateId,
  "documentId": editDetailsContainer.id,
  "editType": "modify",
  "sector": {
    "id": data.id,
    "key": data.key,
    "name": data.name
},
"orderId": dat.id,
  "items":  sheetData
}

try {
  const res = await APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits/modifyOrder`,payload);
  if(res){
  setState((prev)=>({...prev,selected3:false}))
  return res;

  }
  }
  catch (error) {
return error

  }


}

const inventoryDetails=async(data,type,editDocDetails=null,obj,offldData=null)=>{
  setOrderId(data.id)
  setDocumentEditId(editDocDetails?.id?editDocDetails?.id:null)
if(type==='uplifts'){
const {id,warehouse}=data?.uplifts[0];
try {
const res = await APIEndpoint.get(`sandboxing/uplifts/${id}`);

if(res){
setState((prev)=>({...prev,data,flightDetails:{...obj,...warehouse},selected2:{flightDetails:{...obj,...warehouse},action:type,data:res?.uplift}}))
}
}
catch (error) {


}
}else if(type==='transferIn'){
const {id}=data?.transferInReport;
try {
  const res = await APIEndpoint.get(`sandboxing/editableInventoryReports/${id}`);
 if(res){
  setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.inventoryReport}}))
 }
}
catch (error) {


}

}else if(type==='salesSummary'){
 const {id}=data;

  if(editDocDetails){
    try {
      const res = await APIEndpoint.get(`sandboxing/isolatedSectors/${id}/salesSummary?documentId=${editDocDetails.id}`);
     if(res){
      setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.salesSummary}}))
     }
    }
    catch (error) {


    }
  }
  else{
    try {
      const res = await APIEndpoint.get(`sandboxing/isolatedSectors/${id}/salesSummary`);
     if(res){
      setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.salesSummary}}))
     }
    }
    catch (error) {


    }
  }


}
else if(type==='transferOut'){
const {id}=data?.transferOutReport||data?.transferReport;

if(editDocDetails){
  try {
    const res = await APIEndpoint.get(`sandboxing/editableInventoryReports/${id}`);
   if(res){
    setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.inventoryReport}}))
   }
  }
  catch (error) {


  }
}else{
  try {
    const res = await APIEndpoint.get(`sandboxing/inventoryReports/${id}`);
   if(res){
    setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.inventoryReport}}))
   }
  }
  catch (error) {


  }
}


}

else if(type==='ordersEditDoc'){

if(editDocDetails){
  const filterData={
    documentId:editDocDetails?.id,
    page: 1,
    limit: 20,
    source: "editDocument"
  }

  let filters = [ {
    "sectorId": data.id,
    "sortBySaletype": saleTypeCheck
  }];

  filterData.filters = filters;

  try {
    const res = await APIEndpoint.post(`sandboxing/isolatedOrders/listing`,filterData);
   if(res.count){
    setState((prev)=>({...prev,data,showOrders:true,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.items}}))
   }
   else{
    setState((prev)=>({...prev,data,showOrders:true,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.items}}))

   }
  }
  catch (error) {


  }
}else{

  const filterData={
    page: 1,
    limit: 20,
    source: "isolate"
  }

  let filters = [ {
    "sectorId": data.id,
    "sortBySaletype": saleTypeCheck
  }];

  filterData.filters = filters;

  try {
    const res = await APIEndpoint.post(`sandboxing/isolatedOrders/listing`,filterData);
   if(res.count){
    setState((prev)=>({...prev,data,showOrders:true,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.items}}))
   }
   else{
    setState((prev)=>({...prev,data,showOrders:true,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res?.items}}))

   }
  }
  catch (error) {


  }

}


  }
else if(type==='stockMvmnt'){
  const {id}=data;
  if(editDocDetails){

    try {
      const res = await APIEndpoint.get(`sandboxing/editDocuments/${editDocDetails.id}/sectors/${id}/inventorySnapshot`);
     if(res){

      setState((prev)=>({...prev,data,selected2:{flightDetails:obj,action:type,data:res.inventorySnapshot}}))
     }
    }
    catch (error) {


    }

  }else{
    try {
      const res = await APIEndpoint.get(`sandboxing/isolatedSectors/${id}/inventorySnapshot`);

     if(res){

      setState((prev)=>({...prev,data,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:res.inventorySnapshot}}))
     }
    }
    catch (error) {


    }
  }



}

else if(type==='createOffloads'){
    try {
      const res = await APIEndpoint.get(`sandboxing/editDocuments/${editDocDetails.id}/sectors/${data.id}/inventoryItems`);

     if(res){
      const encodedName = encodeURIComponent(name);
      navigate(`/sales/dynamic/sector/containerIsolate/offload/${encodedName}/${id}/${editDocDetails.id}/${data.id}`,{ state: {data,res, mode: 'createOffloads',docId:editDocDetails.id,orderIdOffload:orderId }});
      setState((prev)=>({...prev,rows:res,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:data}}))

     }
    }
    catch (error) {
      const errData=JSON.parse(error.message)
      if(errData.data?.message){
      setState((prev)=>({...prev,rows:{},error:errData.data?.message,selected2:{flightDetails:obj,action:type,data:data}}))

      }

    }

  }

  else if(type==='offloads'){

        try {
           const res = await APIEndpoint.get(`sandboxing/editableInventoryReports/${editDocDetails.offloadReport.id}`);
           setState((prev)=>({...prev,data,selected2:{flightDetails:obj,action:type,data:res.inventoryReport}}))

        }
        catch (error) {
          const errData=JSON.parse(error.message)
          if(errData.data?.message){
          setState((prev)=>({...prev,rows:{},error:errData.data?.message,selected2:{flightDetails:obj,action:type,data:data}}))
          }

        }

      }
      else if(type==='editOffloads'){
        try {
          const res = await APIEndpoint.get(`sandboxing/editableInventoryReports/${data.offloadReport.id}`);

         if(res){
          const encodedName = encodeURIComponent(name);
          navigate(`/sales/dynamic/sector/containerIsolate/offload/${encodedName}/${id}/${data.offloadReport.id}/${data.id}`,{ state: {data,res, mode: 'editOffloads',docId:offldData.id,orderIdOffload:orderId}});
          setState((prev)=>({...prev,rows:res,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:data}}))

         }
        }
        catch (error) {
          const errData=JSON.parse(error.message)
          if(errData.data?.message){
          setState((prev)=>({...prev,rows:{},error:errData.data?.message,selected2:{flightDetails:obj,action:type,data:data}}))

          }

        }

      }
      else if(type==='deleteOffloads'){
        setModalTitle('Discard Offload Report of - ' +data.name );
        updatedEditFields = [
          {
              label: 'This operation is irreversible. Are you sure you want to continue?',
              attribute: 'alertMessage', type: 'alertMessageRemove'
          }
      ];
      setModalCustomButtons([
                           {label:'Proceed',attribute:'button',type:"button",disabled:state.disabled,className:'submit-button',action:()=>onEnableDisableAction()}])

         setState((prev)=>({...prev,selected: {instance,action: 'deleteOffloads' },flightDetails:obj,selected2:{flightDetails:obj,data:{...data,docId:offldData.id,instance:instance}}}))

      setEditFieldsCess(updatedEditFields);

      }
  else if(type==='createWastages'){

    try {
      const res = await APIEndpoint.get(`sandboxing/editDocuments/${editDocDetails.id}/sectors/${data.id}/inventoryItems`);

     if(res){
      const encodedName = encodeURIComponent(name);
      navigate(`/sales/dynamic/sector/containerIsolate/wastage/${encodedName}/${id}/${editDocDetails.id}/${data.id}`,{ state: {data,res, mode: 'createWastages',docId:editDocDetails.id,orderIdOffload:orderId }});
      setState((prev)=>({...prev,rows:res,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:data}}))

     }
    }
    catch (error) {
      const errData=JSON.parse(error.message)
      if(errData.data?.message){
      setState((prev)=>({...prev,rows:{},error:errData.data?.message,selected2:{flightDetails:obj,action:type,data:data}}))

      }

    }

    }
    else if(type==='deleteWastages'){

      setModalTitle('Discard Wastage Report of - ' +data.name );
      updatedEditFields = [
        {
            label: 'This operation is irreversible. Are you sure you want to continue?',
            attribute: 'alertMessage', type: 'alertMessageRemove'
        }
    ];
    setModalCustomButtons([
                         {label:'Proceed',attribute:'button',type:"button",disabled:state.disabled,className:'submit-button',action:()=>onEnableDisableAction()}])

       setState((prev)=>({...prev,selected: {instance,action: 'deleteWastages' },flightDetails:obj,selected2:{flightDetails:obj,data:{...data,docId:offldData.id,instance:instance}}}))

    setEditFieldsCess(updatedEditFields);

      }
      else if(type==='editWastages'){

        try {
          const res = await APIEndpoint.get(`sandboxing/editableInventoryReports/${data.wastageReport.id}`);

         if(res){
          const encodedName = encodeURIComponent(name);
          navigate(`/sales/dynamic/sector/containerIsolate/wastage/${encodedName}/${id}/${data.wastageReport.id}/${data.id}`,{ state: {data,res, mode: 'editWastages',docId:offldData.id,orderIdOffload:orderId}});
          setState((prev)=>({...prev,rows:res,flightDetails:obj,selected2:{flightDetails:obj,action:type,data:data}}))

         }
        }
        catch (error) {
          const errData=JSON.parse(error.message)
          if(errData.data?.message){
          setState((prev)=>({...prev,rows:{},error:errData.data?.message,selected2:{flightDetails:obj,action:type,data:data}}))

          }

        }

        }
        else if(type==='wastages'){

          try {
            const res = await APIEndpoint.get(`sandboxing/editableInventoryReports/${editDocDetails.wastageReport.id}`);
            setState((prev)=>({...prev,data,selected2:{flightDetails:obj,action:type,data:res.inventoryReport}}))

         }
         catch (error) {
           const errData=JSON.parse(error.message)
           if(errData.data?.message){
           setState((prev)=>({...prev,rows:{},error:errData.data?.message,selected2:{flightDetails:obj,action:type,data:data}}))
           }

         }

          }

    else if(type==='cancelOrder'){
      setState((prev)=>({...prev,flightDetails:obj,selected3:{action:type,data:data}}))
      setModalCustomButtons([{label:'Back',attribute:'button',type:"button",disabled:false,className:'close-button',action:()=>(setState((prev)=>({...prev,selected3:false})))},
      {label:'Cancel Order',attribute:'button',type:"button",disabled:false,className:'cancel-button',action:(e)=>handleCancelOrder(e,data)}])

      }
      else if(type==='editOrder'){
        setState((prev)=>({...prev,flightDetails:obj,selected3:{action:type,data:data}}))
        setModalCustomButtons([{label:'Back',attribute:'button',type:"button",disabled:false,className:'close-button',action:()=>(setState((prev)=>({...prev,selected3:false})))},
        {label:'Cancel Order',attribute:'button',type:"button",disabled:false,className:'cancel-button',action:(e)=>handleCancelOrder(e,data)}])

        }
    else{

    }


}



const fetchOperationsPanel = useCallback(async (page = 1, perPage = 10) => {
  const filterData = {
    action: 4,
    filters: [{ name: name }],
    limit: perPage,
    page: page,
    sort: [{ field: "startDate", order: "desc" }],
    searchParam: ""
  };

  try {
    const res = await DOMAIN.post('sandboxing/containerIsolates/listing', JSON.stringify(filterData));

    if (res?.items?.length > 0) {
      setInstance((prevInstance) => {
        if (!prevInstance || prevInstance.id !== res.items[0].id) {
          return res.items[0];
        }
        return prevInstance;
      });
    }

    return res;
  } catch (error) {

  }
}, [name]);

useEffect(() => {
  if (id?.length > 0) {
    fetchOperationsPanel();
  }
  // eslint-disable-next-line react-hooks/exhaustive-deps
}, [id, fetchOperationsPanel]);


          const getSectorData = useCallback(async (data) => {
            const { id } = data;

            try {
              const res = await APIEndpoint.get(`sandboxing/containerIsolates/${id}`);

              if (res.isolate) {
                setSectorIsolateData((prev) => {
                  if (JSON.stringify(prev) !== JSON.stringify(res.isolate)) {
                    return { ...prev, ...res.isolate };
                  }
                  return prev;
                });


                const sectData = res.isolate.sectors.map((sec) =>
                  APIEndpoint.get(`sandboxing/isolatedSectors/${sec.id}?mutableOnly=true`)
                    .then((sectorRes) => sectorRes.sector || null)
                );
                const resolvedSectors = await Promise.all(sectData);


                setSectors((prev) => {
                  const newSectors = resolvedSectors.filter((sector) => sector !== null);
                  if (JSON.stringify(prev) !== JSON.stringify(newSectors)) {
                    return newSectors;
                  }
                  return prev;
                });
              }
            } catch (error) {

            }
          }, []);

          useEffect(() => {
            if (instance?.id) {
              getSectorData(instance);
            }
          }, [instance, lockInstance, getSectorData]);


  const showEventLogs=async(event,data,editDoc)=>{
  const {id}=event;
  const filterData={
    "page": 1,
    "limit": 20,
    "filters": []
}
if(editDoc){
  try {
    const res = await APIEndpoint.post(`sandboxing/editDocuments/${editDoc.id}/sectors/${id}/events`,filterData);
    if (res.items) {
      setState((prev)=>({...prev,selected2:{action:'viewEventLogs',data:res.items}}))
    }
  } catch (error) {
  }
}else{
  try {
    const res = await APIEndpoint.post(`sandboxing/isolatedSectors/${id}/events`,filterData);
    if (res&&res.items) {
      setState((prev)=>({...prev,selected2:{action:'viewEventLogs',data:res.items}}))
    }
  } catch (error) {
  }

}
          }

const getEditStatus=(text)=>{
if(sorting){
 return(<div className='flexRow justifyContentSpaceBetween' style={{whiteSpace:'nowrap'}}>
  <MdEdit style={{margin:'2px'}}/><span>{text.header}</span><div style={{position:'relative'}}>
        <p><span class="asterik-edit">*</span></p>
    </div>
 </div>

  )
}
else{
  return(<span><MdEdit style={{margin:'2px'}}/>{text.header}</span>)
}
}


const onEnableDisableAction=async ()=>{
setState((_)=>({..._,disabled:true}))
const delData = stateRef.current;
if(delData && delData.selected2 && delData.selected){

    const data={
    "isolateId": delData.selected2.data.instance.id,
    "documentId": delData.selected2.data.docId,
    "edits": [
        {
            "name": "DiscardInventoryReport",
            "params": {
                "target": {
                    "id": delData.selected2.data.id,
                    "key": delData.selected2.data.key,
                    "name": delData.selected2.data.name
                },
                "type":delData.selected.action==="deleteOffloads"?"offload":"wastage",
                "reportId":delData.selected.action==="deleteOffloads"? delData.selected2.data.offloadReport.id: delData.selected2.data.wastageReport.id
            }
        }
    ]
}
  try {
    const res = await DOMAIN.post('sandboxing/containerIsolates/editDocument/edits', data);
if(res){
  setModalFormStatusObj({text:`Sucessfully deleted ${delData.selected.action==="deleteOffloads"?"offload":"wastage"} report.`})
handleOnReset();
}
  } catch (error) {
    const errData=JSON.parse(error.message)
    if(errData.data?.message){
  setModalFormStatusObj({error:errData.data?.message||`Failed to delete ${delData.selected.action==="deleteOffloads"?"offload":"wastage"}  report.`})


    }
  }
}

}
const onClickTabs=(index)=>{
  setActiveTab(index)
  setIsOpen(false);
}
const getSectorStatus = (sec) => {
            switch (sec) {
              case 'open':
                return <div className="status-consolidate" style={{ backgroundColor: '#03a5fc' }}>Active</div>;
              case 'locked':
                return <div className="status-consolidate" style={{ backgroundColor: '#d9534f' }}>Locked</div>;
              case 'committed':
                return <div className="status-consolidate" style={{ backgroundColor: '#09ab16' }}>Committed</div>;
              case 'commitScheduled':
                return <div className="status-consolidate" style={{ backgroundColor: '#f28016' }}>Commit Scheduled</div>;
              default:
                return "";
            }
          };


          const tabsInstances={
            instance,
            sectorIsolateData,
            sectors,
            inventoryDetails
          }
    const config=[{key:0,header:"General",component:<GeneralContainer  data={tabsInstances}/>},
    {key:1,header:"Schedule",component:<Schedulecontainer id={id} />},
    {key:2,header:"Event Log",component:<EventLogContainer id={id} />},
    {key:3,header:"Sectors",component:<SectorInstanceContainer eventLogs={showEventLogs} inventoryDetails={inventoryDetails} reload={reload} header={state.selected} sectorId={id}/>},
    {key:4,header:"Editor",component:<EditContainer setNavigateInstanceId={setNavigateInstanceId} navigateInstanceId={navigateInstanceId} eventLogs={showEventLogs} scrollContainer={setIsOpen} fetchOperationsPanel={fetchOperationsPanel} inventoryDetails={inventoryDetails} setLockInstance={setLockInstance} setSorting={setSorting} setEditDetailsContainer={setEditDetailsContainer} sorting={sorting} instance={instance} id={id} delOffld={state.deleted}/>},
    ]

    const orderListChange={
      status,
      setStatus,
     paymentType, setPaymentType,
    saleType, setSaleType,
    searchKeyOrders, setSearchKeyOrders,
    inventoryDetails,
    saleTypeCheck,setSaleTypeCheck
    }

  return (
    <div className="op-aircraft-container ng-grid-right flexCol full-flex ag-theme-alpine-dark">

    {  !instance ?
          <Loading />: <>
         <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
            <div className='floatLeft flexRow justifyContentFlexStart fontSemibold'>
              Crew Reported
            </div>
            {state && state.error? <div className={`padding4 alert alert-${!state.error ? 'success' : 'danger'}`}>
                                  {state.error}
                                  </div>:null}
         {instance&&instance.name? <div className='floatRight flexRow justifyContentFlexEnd fontExtrabold'>
            {instance&&instance.name}
            </div>:<><img src={LoadingIn}  className="loading-img" alt="Loading" /></>}

          </div>
          <hr/>
   <div className='flexRow justifyContentSpaceBetween'>
  {instance&&instance.aircraft?<div className='flexCol'>
  <div className='flexRow'>
  <PiAirplaneTiltFill size={20}/>

  {instance&&instance.aircraft?.aircraftNumber}

</div>
<div className='flexRow'>
  {instance&&instance.aircraft?.name}

</div>

  </div>:<><img src={LoadingIn}  className="loading-img" alt="Loading" /></>}
  {instance&& getSectorStatus(instance.state)?<div className='flexRow'>
  {instance&& getSectorStatus(instance.state)}
</div>:<><img src={LoadingIn}  className="loading-img" alt="Loading" /></>}

{instance && instance.startDate ?<div className='flexRow'>
<SlCalender  size={15} style={{marginRight:"5px"}}/>{instance&&moment(instance.startDate).format('DD-MM-YYYY')}

</div>:<><img src={LoadingIn}  className="loading-img" alt="Loading" /></>}

  </div>

<div className="horizontal-tab-view">
      <div className="tab-buttons">
        {config.map((tab, index) => (
          <button
            key={index}
            onClick={()=>onClickTabs(index)}
            className={index === activeTab ? 'active' : ''}
          >
            {tab.header!=="Editor"?tab.header:<>{getEditStatus(tab)}</>}
          </button>
        ))}
      </div>
      <div className="tab-content" style={{height:"65vh",bottom: '20px',overflow:`${!isOpen?'scroll':'hidden'}`}}>
        {config[activeTab].component}
      </div>
    </div>
          </>}

          {
       state&&state.selected2&&state.selected2.action === 'viewEventLogs' &&
        <Modal
        className='cash-deposit-summary'
        title={``}
        TitleComponent={sectorTitle}
        onSubmit={handleModalCancelEvent}
        onClose={handleModalCancelEvent}>
        {<EventLogContainer id={null} reload={reload} data={state.selected2.data}/> }
      </Modal>
      }

{
       state&&state.selected2&&state.selected2.action === 'ordersEditDoc' &&
        <Modal
        className='cash-deposit-summary'
        title={``}
        TitleComponent={sectorTitle}
        onSubmit={handleModalCancelEvent}
        onClose={handleModalCancelEvent}>
        {<OrderListing id={null} {...orderListChange}  reload={reload} flightDetails={state?.flightDetails||{}} dataOrder={state.selected2}/> }
      </Modal>
      }

{
       state&&state.selected2&&(state.selected2.action === 'transferOut'||state.selected2.action === 'transferIn'||state.selected2.action === 'stockMvmnt'||state.selected2.action === 'offloads' ||state.selected2.action === 'wastages' ||state.selected2.action === 'uplifts') &&
        <Modal
        className='cash-deposit-summary'
        title={``}
        TitleComponent={sectorTitle}
        onSubmit={handleModalCancelEvent}
        onClose={handleModalCancelEvent}>
          <div className='flexCol full-flex' style={{height:'90vh'}}>
             <div className='fitcontent justifyContentSpaceBetween display wrapper-sector-dynamic'>
              <div className='flexRow sector-banner justifyContentSpaceBetween'>
                <div className='flexRow justifyContentFlexStart'>
                   <div className='sector-number fontMedium'>{state.selected2.flightDetails?.flightNumber}</div>
                   <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{state.selected2.flightDetails?.origin}</div>
                <div className='flexRow fontMedium'>{state.selected2.flightDetails?.originName}</div>
                </div>
                </div>

                <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow'> <IoArrowForwardOutline size={17} color={'#F06548'}/></div>
                <div className='flexRow fontMedium'>{state.selected2.flightDetails?.key?.split("/")[1]?state.selected2.flightDetails?.key?.split("/")[1]:state.selected2.flightDetails?.key?.split("T")[0]}</div>
                </div>
               <div className='flexCol justifyContentCenter alignItemsCenter'>
                <div className='flexRow sector-tag'>{state.selected2.flightDetails?.destination}</div>
                <div className='flexRow fontMedium'>{state.selected2.flightDetails?.destinationName}</div>
                </div>
              </div>
              </div>
            <DataGrid
            rowData={state.selected2.action === 'transferIn'?state?.selected2.data?.items:state.selected2.action === 'transferOut'?state?.selected2.data?.items:state.selected2.action === 'uplifts'?state?.selected2?.data?.items:state?.selected2.data?.items}
            columnResize
            ref={tableRef}
            className='full-flex'
            showDefaultFilters={false}
            enableGridSearch={false}
            columns={(state.selected2.action === 'transferIn'||state.selected2.action==='transferOut')?TransferIn:state.selected2.action==='uplifts'?Uplifts:(state.selected2.action==='offloads'||state.selected2.action==='wastages')?Offloads:StockMvmnt}
            rowModelType='clientSide'
            />
              <div className='flexRow fitContent'>
                  <div style={{float:'left'}} className="margTop8 flexCol width50 justifyContentFlexStart">

                  <div className='margBottom10'>
                  <label className='fontExtrabold'>Created At</label>
                  <div>{moment(state.selected2.flightDetails?.createdAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Created By</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected2.flightDetails?.createdBy?.employeeName}
                    </div>
                    <div className='flexRow'>
                    {state.selected2.flightDetails?.createdBy?.employeeCode}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  {  state.selected2.action!=='uplifts'?<><label className='fontExtrabold'>Created Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected2.flightDetails?.userAgent?.appName}@ {state.selected2.flightDetails?.userAgent?.appVersion}
                  </div>
                  <div className='flexRow'>
                  {state.selected2.flightDetails?.userAgent?.deviceModel},MAC ID:{state.selected2.flightDetails?.userAgent?.macId}
                  </div>
                  </div>

                  </>:<>{state.selected2.flightDetails?.warehouseCode?<><label className='fontExtrabold'>Warehouse</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {`${state.selected2.flightDetails?.warehouseCode} (${state.selected2.flightDetails?.warehouseName})`}
                  </div>

                  </div></>:<></>}</> }
                </div>
              </div>

 {state.selected2.action!=='uplifts'?<div style={{float:'right'}} className="margTop8 flexCol width50">
 <label className='fontExtrabold'>Offloaded At</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {`${state.selected2.flightDetails?.destination} (${state.selected2.flightDetails?.destinationName})`}
                  </div>

                  </div>{
                    state.selected2.data?.warehouse?<><label className='margTop8 fontExtrabold'>Warehouse</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {`${state.selected2.data?.warehouse?.warehouseCode} (${state.selected2.data?.warehouse?.warehouseCode})`}
                  </div> </div></>:<></>
                  }



       </div>:<></>}

              </div>
          </div>
      </Modal>
      }



{
       state&&state.selected3&&(state.selected3.action === 'cancelOrder'&&state.selected3.data) &&
        <Modal
        className='cash-deposit-summary'
        title={`Cancel Order - ${state.selected3.data.orderNo}`}
        // TitleComponent={sectorTitle}
        // onSubmit={handleCancelOrder}
        modalCustomButtons={modalCustomButtons}
        onClose={(e)=>handleModalCancelEvent(e,'cancelOrder')}>
          <div className='flexCol full-flex' style={{height:'50vh'}}>

             <div className='width100 justifyContentSpaceBetween display'>
              <div className='flexRow'>
                  <div style={{float:'left'}} className="margTop8 flexCol width50 justifyContentFlexStart">

                  <div className='margBottom10'>
                  <label className='fontExtrabold'>Taken By</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected3.data.createdBy.employeeName}
                    </div>
                    <div className='flexRow'>
                    {state.selected3.data.createdBy.employeeCode}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Sale Type</label>
                  <div>{ORDER_SALE_TYPE_LIST_DYNAMIC.find(ord=>ord.key===state.selected3.data.saleType)?.value}</div>
                </div>



                <div className='margBottom10'>
                  <label className='fontExtrabold'>Ordered Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected3.data.userAgent.appName}@ {state.selected3.data.userAgent.appVersion}
                    </div>
                    <div className='flexRow'>
                    {state.selected3.data.userAgent.deviceModel},MAC ID:{state.selected3.data.userAgent.macId}
                    </div>
                    </div>
                </div>


                <div className='margBottom10'>
                  <label className='fontExtrabold'>Customer Info</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected3.data.customerInfo.customerName}
                    </div>
                    <div className='flexRow'>
                    Seat NO:{state.selected3.data.customerInfo.seatNumber}
                    </div>
                    <div className='flexRow'>
                    PNR:{state.selected3.data.customerInfo.pnr}
                    </div>
                    </div>
                </div>

              </div>
              <div  className="flexCol width50 justifyContentFlexEnd">


              <div className='margBottom10'>
                  <label className='fontExtrabold'>Date and Time</label>
                  <div>{moment(state.selected3.data.createdAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Payment Type</label>
                  <div>{state.selected3.data.paymentType==='none'?"SSR":state.selected3.data.paymentType.charAt(0).toUpperCase()+state.selected3.data.paymentType.slice(1)}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Gross</label>
                   <div>{state.selected3.data.netAmount} </div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>GST</label>
                   <div>{state.selected3.data.gstAmount}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Grand Total</label>
                  <div>{state.selected3.data.totalAmount}</div>
                </div>

              </div>


              </div>
            </div>
<br/>
<br/>

<h6>Items</h6>
            <DataGrid
            rowData={state?.selected3.data?.items}
            columnResize
            ref={tableRef}
            className=''
            showDefaultFilters={false}
            enableGridSearch={false}
            columns={orderCancel}
            rowModelType='clientSide'
            />

          </div>
      </Modal>
      }

{
       state&&state.selected3&&(state.selected3.action === 'editOrder'&&state.selected3.data) &&
        <Modal
        className='cash-deposit-summary'
        title={`Edit Order - ${state.selected3.data.orderNo}`}
        // TitleComponent={sectorTitle}
        // onSubmit={handleCancelOrder}
        onClose={(e)=>handleModalCancelEvent(e,'cancelOrder')}>
          <div className='flexCol full-flex' style={{height:'60vh'}}>

             <div className='width100 justifyContentSpaceBetween display'>
              <div className='flexRow'>
                  <div style={{float:'left'}} className="margTop8 flexCol width50 justifyContentFlexStart">

                  <div className='margBottom10'>
                  <label className='fontExtrabold'>Taken By</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected3.data.createdBy.employeeName}
                    </div>
                    <div className='flexRow'>
                    {state.selected3.data.createdBy.employeeCode}
                    </div>
                    </div>
                </div>
                <div className='margBottom10'>
                  <label className='fontExtrabold'>Sale Type</label>
                  <div>{ORDER_SALE_TYPE_LIST_DYNAMIC.find(ord=>ord.key===state.selected3.data.saleType)?.value}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Payment Type</label>
                  <div>{state.selected3.data.paymentType==='none'?"SSR":state.selected3.data.paymentType.charAt(0).toUpperCase()+state.selected3.data.paymentType.slice(1)}</div>
                </div>
              </div>

              <div  className="flexCol width50 justifyContentFlexEnd">

              <div className='margBottom10'>
                  <label className='fontExtrabold'>Gross</label>
                   <div>{state.selected3.data.netAmount} </div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>GST</label>
                   <div>{state.selected3.data.gstAmount}</div>
                </div>

                <div className='margBottom10'>
                  <label className='fontExtrabold'>Grand Total</label>
                  <div>{state.selected3.data.totalAmount}</div>
                </div>
</div>

              </div>
            </div>
<OrderCreateIsolate handleEditOrder={handleEditOrder} stateSpreadSheet={state.selected3}/>
<br/>
<div className='flexRow'>
                  <div style={{float:'left'}} className="margTop8 flexCol width50 justifyContentFlexStart">


                <div className='margBottom10'>
                  <label className='fontExtrabold'>Ordered Using</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected3.data.userAgent.appName}@ {state.selected3.data.userAgent.appVersion}
                    </div>
                    <div className='flexRow'>
                    {state.selected3.data.userAgent.deviceModel},MAC ID:{state.selected3.data.userAgent.macId}
                    </div>
                    </div>
                </div>


                <div className='margBottom10'>
                  <label className='fontExtrabold'>Customer Info</label>
                  <div className='flexCol'>
                  <div className='flexRow'>
                  {state.selected3.data.customerInfo.customerName}
                    </div>
                    <div className='flexRow'>
                    Seat NO:{state.selected3.data.customerInfo.seatNumber}
                    </div>
                    <div className='flexRow'>
                    PNR:{state.selected3.data.customerInfo.pnr}
                    </div>
                    </div>
                </div>

              </div>
              <div  className="flexCol width50 justifyContentFlexEnd">


              <div className='margBottom10'>
                  <label className='fontExtrabold'>Date and Time</label>
                  <div>{moment(state.selected3.data.createdAt).format('DD-MM-YYYY hh:mm A')}</div>
                </div>


              </div>


              </div>
              <br/>
              <br/>
          </div>
      </Modal>
      }
      {
         Boolean(state?.selected) && (state?.selected?.action==='deleteOffloads'||state?.selected?.action==='deleteWastages') ? (
        <div className='flexRow width100'>
          <EditModal
            key={state.selected.action}
            title={ modalTitle }
            onClose={onClose}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={onEnableDisableAction}
            modalCustomButtons={modalCustomButtons}
            fields={EditFieldsCess.map( (elt) => {
              return { ...elt, initialValue: state.selected.instance[elt.attribute] }
              })
            }
          />
          </div>
        )
        : null
      }


    {
    state&&state.selected2&&state.selected2.action === 'salesSummary' &&
    <SalesSummary sectorTitle={sectorTitle} handleModalCancelEvent={handleModalCancelEvent} state={state} paymentTypeDist={paymentTypeDist} saleTypeDist={saleTypeDist} currencyDist={currencyDist} />
    }

    {
    t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
    }
          </div>
  )
}