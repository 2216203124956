import React, { useEffect, useState } from 'react';
import { DataGrid, Loading, Modal } from "../../../common";
import { useTranslation } from 'react-i18next';
import { CashDepositSectorCard, fetchCashDeposit, DenominationColumns } from "./depositSectorCard";
import moment from 'moment';

export const CashDepositDetailsModal = (props) => {
  const { detail } = props;
  const [deposit, setDeposit] = useState(null);
  const { t } = useTranslation();
  useEffect(() => {
    _fetchCashDeposit();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const _fetchCashDeposit = async () => {
    let deposit = await fetchCashDeposit(detail)
    setDeposit(deposit)
  };


  return (
    <Modal
      showDone={false}
      className='cash-deposit-edit'
      onSubmit={props.onClose}
      onClose={props.onClose}
      title={`${t("pages.cashRegisterContent.cashDeposits.detailsModalTitle")}${detail.code}`}>
      {
        Boolean(deposit) ?
          <div className='flexCol full-flex'>
            {
              Array.isArray(deposit.sectorDeposits) &&
              deposit.sectorDeposits.map((sector, index) => {
                return (
                  <CashDepositSectorCard
                    key={index}
                    sector={sector}
                    depositId={deposit.id}
                    cashDrawerId={deposit.cashDrawer.id}
                    flightDate={moment(deposit.createdAt).startOf("day").format()}
                    sectorsInfo={deposit?.sectorsInfo[index]} />
                )
              })
            }
            <div className='large-button-text-medium margBottom10'>{t("pages.cashRegisterContent.cashDeposits.denominationsTitle")}</div>
            <DataGrid
              columnResize
              showDefaultFilters={false}
              enableGridSearch={false}
              className='full-flex'
              rowModelType='clientSide'
              columns={DenominationColumns}
              rowData={deposit?.denominations}
            />
          </div>
          :
          <Loading />
      }
    </Modal>
  )
}