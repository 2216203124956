/* eslint-disable no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import ReactSpreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import * as ReactDOM from 'react-dom';
import '../../../../assets/styles/styles.scss'
import { APIEndpoint } from "../../../../utils/axios";

import { updateButtonTxtStatus, validateCellAndGetData } from "../../../../utils/commonFunctions";
import { DEFAULT_SHEET_ROWS_PLIFERAGE } from "../../../../utils/configs";
import { useTranslation } from "react-i18next";

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_PRICE: 2,
    QUANTITY: 3,
    UNIT: 4,
    TOTAMOUNT: 5,

};
const sheetLabels = ['Item Code', 'Item Name', 'Item Price','Quantity','Unit','Total Amount'];

const defaultCellFieldData = {
    id: 'id',
    value: '',
    defaultValue: '',
    className: 'cellClass',
    width: '200px',
    readOnly: false,
    type: 'string',
    required: true,
    showPopup: false
};

const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS_PLIFERAGE) => {
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                ...defaultCellFieldData,
                id: 'itemCode',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_CODE,
                showPopup: true
            },
            {
                ...defaultCellFieldData,
                id: 'itemName',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_NAME,
                showPopup: true
            },
            {
                ...defaultCellFieldData,
                id: 'itemPrice',
                type:'number',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_PRICE,
                showPopup: true
            }, {
                ...defaultCellFieldData,
                id: 'quantity',
                type:'number',
                defaultValue: 1,
                className: 'cellClass cellClass_' + i + '_' + iIndexes.QUANTITY,
                showPopup: true
            },
            {
                ...defaultCellFieldData,
                id: 'unit',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.UNIT,
                width: '400px',
                required:false,
                readOnly: false,
            },
            {
                ...defaultCellFieldData,
                id: 'totalAmount',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.TOTAMOUNT,
                width: '400px',
                type:'number',
                required:true,
                readOnly: true,
            }
        ]);
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
const initialData = createEmptySheetRows( DEFAULT_SHEET_ROWS_PLIFERAGE );

export const OrderCreateIsolate = (props) => {
    const {stateSpreadSheet}=props;
    const { t } = useTranslation();
    const [warehouseItemsFull, setWarehouseItemsFull] = useState([]);
    const [warehouseItemsFiltered, setWarehouseItemsFiltered] = useState([]);
    const [, setEditTransferDetails] = useState({});
    const [state, setState] = useState({ isInvalidAccess: false, message: '', isEditForm: false });
    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const [activeCell, setActiveCell] = useState({});
    const [selectedCellsArr, setSelectedCellsArr] = useState([]);

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];
    useEffect( () => {

      if ( stateSpreadSheet?.data?.items.length ) {
            setState( { ...state, isEditForm: true } );
            getWarehouseItems(stateSpreadSheet?.data.id);
            // setState((_)=>{return{..._,data:urlParams.id}})
        }
    }, [ stateSpreadSheet ]);

    const getWarehouseItems = async(segment) => {
        APIEndpoint.get(`sandboxing/isolatedOrders/${segment}/priceListItems`)
        .then( resp => {
            if (resp) {
                const warehouseItems = resp?.items;
                setWarehouseItemsFull(warehouseItems);
                setWarehouseItemsFiltered(warehouseItems);
            }
        })
        .catch( error => {
            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Segment Items are not found, On click OK, you will be redirected to Uplift Segment page." });
        });

            if (stateSpreadSheet.data.items.length) {
                const {items}=stateSpreadSheet.data;
                setEditTransferDetails(items);
                setEditTransferItemsToSheet(items);
                setState( { ...state, isEditForm: true } );
            }


    };


    const setEditTransferItemsToSheet = (transferDetails) => {
        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
        const items = transferDetails;
        items.map( (item, i) => {
            updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = item.itemCode;
            updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = item.itemName;
            updatedSpreadsheetData[i][iIndexes.ITEM_PRICE].value = item.itemPrice;
            updatedSpreadsheetData[i][iIndexes.QUANTITY].value = Number(item.quantity)||1;
            updatedSpreadsheetData[i][iIndexes.UNIT].value = item.unit;
            updatedSpreadsheetData[i][iIndexes.TOTAMOUNT].value = item.itemPrice*(parseInt(item.quantity)||1);



            return item;
        });

        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);

        });

    };

    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = (e) => {

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let inSheetData = JSON.parse(jsonSpreadsheetData);

        selectedCellsArr.map( selectedCell => {

            const defaultVal = inSheetData[selectedCell.row][selectedCell.column].defaultValue;

            inSheetData[selectedCell.row][selectedCell.column].value = defaultVal || '';

            return selectedCell;
        });

        ReactDOM.flushSync( () => {
            setSheetData([ ...inSheetData ]);
        });

		setSelectedCellsArr([]);

    };
    const showValidationErrors = (e, inSheetData, errorClass='cellDataErrorCls' ) => {
        let haveErrors = false;
        const postItems = [];

        inSheetData.map( (cellRow, rowIdx) => {
            let isRowHaveError = false;

            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);

            if (!itemCode || !itemName) {
                return cellRow;
            }

            for (let colIdx = 0; colIdx < Object.keys(iIndexes).length; colIdx++) {

                if ( cellRow[colIdx].required === true) {

                    const fieldValue = validateCellAndGetData(cellRow[colIdx]);
                    if ( !fieldValue ) {
                        haveErrors = true;
                        isRowHaveError = true;
                    }
                }
            }
            if ( !isRowHaveError&& warehouseItemsFull.length ) {

                const item = warehouseItemsFull?.find( row => row.itemCode === cellRow[iIndexes.ITEM_CODE].value );
                if (item) {
                    postItems.push({
                        // _id: item._id,
                        index: rowIdx,
                        itemCode: item.itemCode, // cellRow[iIndexes.ITEM_CODE].value,
                        itemName: item.itemName,
                        itemPrice: item.itemPrice, // cellRow[iIndexes.ITEM_NAME].value,
                        quantity: parseInt(item.quantity)||1,
                        unit: item.unit,
                        totalAmount: item.itemPrice*(parseInt(item.quantity)||1), // cellRow[iIndexes.ITEM_NAME].value,
                    });
                }

            }
            return cellRow;
        });

        if (haveErrors) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
        }

        return {
            sheetData: inSheetData,
            validRows: postItems,
            haveErrors
        };

    };
    const saveSheetData = async(e) => {
        updateButtonTxtStatus(true, e, 'Saving...', true);
        setSpreadsheetErrors([]);

        let inSheetData = Object.assign([], sheetData);
        const respArrayData = showValidationErrors(e, inSheetData);

        if (!spreadsheetErrors.length && respArrayData.validRows.length === 0 &&!respArrayData.haveErrors) {
            // show global alert message
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'We did not find anything to save!' } ]);
            return false;
        }

        if ( respArrayData.haveErrors ) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }

        if ( !respArrayData.validRows.length ) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }


        // const data = {
        //     items: respArrayData.validRows || []
        // }

        if((state.isEditForm||!state.isEditForm)&&warehouseItemsFull.length){
            const filteredItems = warehouseItemsFull.filter(wItem => {
                return respArrayData.validRows.some(vId => wItem.itemCode === vId.itemCode);
            }).map(wItem => ({
                itemId: wItem.itemId,
                itemCode: wItem.itemCode,
                quantity: respArrayData.validRows.find(vId => vId.itemCode === wItem.itemCode)?.quantity || 1
            }));

           const resp=await props.handleEditOrder(filteredItems,stateSpreadSheet.data)

        //     APIEndpoint.post(`sandboxing/containerIsolates/editDocument/edits/modifyOrder`, data)
        // .then( apiResp => {
        //     setSheetData(initialData);
        //     updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );
        //     setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiResp.msg || 'Successfully saved Items.' } ]);

        // })
        // .catch( (error) => {
        //     const apiData = error.data;
        //     setSpreadsheetErrors([]);

        //     ReactDOM.flushSync( () => {
        //         if ( apiData.errors ) {
        //             const errorMsg = apiData.errors.errors[0].message;
        //             setSpreadsheetErrors([ ...spreadsheetErrors, { message: errorMsg, error: 1 } ]);
        //             updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save'} );
        //         }
        //         else if (apiData.msg) {
        //             setSpreadsheetErrors([ ...spreadsheetErrors, { message: apiData.msg, error: 1 } ]);
        //             updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save'} );
        //         }
        //         else {
        //             setSpreadsheetErrors([ ...spreadsheetErrors, { message: 'Something went wrong, please try again later!', error: 1 } ]);
        //             updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save'} );
        //         }
        //     });

        // });

    }


        return false;
    };


    const handleActiveCellChange = (activeCell) => {
        setActiveCell({});
        hideItemListPopup();
        setActiveCell(activeCell);

        if ( sheetData[activeCell.row][activeCell.column] ) {

            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];

                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();
                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }

    }
    const handleCellBlur = () => {
        // setActiveCell({});
    }

    const handleCellKeyDown = (keyboardEvent) => {

        const searchText = keyboardEvent.target.value;
        if (activeCell && Object.keys(activeCell).length > 0) {

            if (sheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }

        // const eltPosition = keyboardEvent.target.getBoundingClientRect();
        // showItemListPopup(eltPosition);

        /**
         * Filter poup items
         */
        const searchPattern = new RegExp((searchText || '').replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'),'gi');
        const filteredList = warehouseItemsFull.filter( row => row.itemCode.search( searchPattern ) !== -1 );
        setWarehouseItemsFiltered([ ...filteredList ]);
    }

    const showItemListPopup = (eltPosition, activeCellIn) => {
        const screenHeight = window.innerHeight

        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + (eltPosition.top-70) + 2 ).toFixed() + 'px';
                targetEltCodes.style.left =( screenHeight-eltPosition.left-75).toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + (eltPosition.top-70) + 2 ).toFixed() + 'px';
                targetEltNames.style.left = ( screenHeight-eltPosition.left+210).toFixed() + 'px';
            }
        }
    }

    const hideItemListPopup = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // }
    }

    const onClickPopupListItem = (e, row) => {
        setWarehouseTransferItemToSheet(row);
    }

    const setWarehouseTransferItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }
        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.ITEM_PRICE].value = item.itemPrice;
                sheetRow[iIndexes.QUANTITY].value= parseInt(item.quantity)||1;
                sheetRow[iIndexes.UNIT].value = item.unit;
                sheetRow[iIndexes.TOTAMOUNT].value = item.itemPrice*(parseInt(item.quantity)||1);
            }

            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hideItemListPopup();
    }


    const handleModeChange = (mode) => {
        // if (mode === 'edit') {
        //     setEditingCell
        // }
    };

    const handleCellSelect = (selectedCell) => {
        if (selectedCell.length !== 0) {
            setSelectedCellsArr([ ...selectedCell ]);
        }
    };

    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className={ "flexRow alignItemsCenter margBot10 justifyContentSpaceBetween" } >
                </div>
                <div className="sheet-container sm-scrollbar">
                    <div className="header m-2 mb-2 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button m-3">{ t('buttons.save') }</button> &nbsp;&nbsp;
                            <button onClick={ (e) => removeRow(e) }
                                className="remove-button">{ t('buttons.deleteRows') }</button>
                        </div>
                        <div className="rightBlock alignItemsCenter">
                            {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0].message : null}
                        </div>
                    </div>
                    <div className="se-titlebar">
               {<span>{stateSpreadSheet.data.orderNo} Items*</span>}
                   </div>

            <ReactSpreadsheet
                data={sheetData}
                columnLabels={sheetLabels}
                darkMode={true}
                onActivate={handleActiveCellChange}
                onBlur={handleCellBlur}
                onKeyDown={handleCellKeyDown}
                onModeChange={handleModeChange}
                onSelect={handleCellSelect}
                onCellCommit={onCellCommitHandle}
                />
                    <div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button">{ t('buttons.addRows') }</button> &nbsp;<label>10 row at bottom</label>
                    </div>

                </div>
                <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row) }>{ row.itemCode }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>
                <div className = "cellSelectionBlockItemNames sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row) }>{ row.itemName }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>


            </div>
        </>
    );
}