import { useEffect, useState } from "react";
import { useTranslation } from 'react-i18next';
import moment from "moment";
import { DataGrid,NGODatePicker, EditModal } from '../../../common';
import { APIEndpoint, DFPAPIEndpoint } from '../../../utils/axios';
import { updateButtonTxtStatus, checkDownloadStatus, objectToQueryString } from "../../../utils/commonFunctions";
import { MIMETypes, ORDER_PAYMENT_TYPE_LIST, ORDER_SALE_TYPE_LIST, ORDER_STATUS_LIST } from "../../../utils/configs";
import * as ReactDOM from 'react-dom';
import usePermissions from '../../../utils/userPermissions';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import searchIcon from '../../../assets/icons/search.svg';
import viewIcon from "../../../assets/view.svg";
import { SalesReportsDownloadComponent, SectorRenderer, OrderStatusRenderSel, PaymentTypeRenderSel } from './renderers';
// import editIcon2 from "../../../assets/edit2.svg";


let axiosAbortCtrl = [];
axiosAbortCtrl['orderListAPI'] = new AbortController();
// axiosAbortCtrl['sectorListAPI'] = new AbortController();
// axiosAbortCtrl['reportTypesAPI'] = new AbortController();

const newAbortSignal = (apiName, timeoutMs=5000) => {
  // const abortController = new AbortController();
  axiosAbortCtrl[apiName] = new AbortController();
  // setTimeout(() => axiosAbortCtrl[apiName].abort(), timeoutMs || 0);

  return axiosAbortCtrl[apiName].signal;
};
const abortEarlierRequest = (apiName = 'orderListAPI') => {
  axiosAbortCtrl[apiName].abort(apiName + ': Cancelling API running earlier...');
}

const DATE_FORMAT = 'DD-MM-YYYY';// hh:mm A

const filterData = {
  action: 4,
  collection: '',
  filterOperation: 'or',
  filters: [],
  limit: 10,
  page: 1,
  searchParam: '',
  sort: [
    { field: 'timestampDateTime', order: 'desc' }
  ]
};

const OrderByFilters = [
  // { field: 'timestampDateTime', order: 'asc' }
  { value: 'orderDateDesc', label: 'Order Date - Desc' },
  { value: 'orderDateAsc', label: 'Order Date - Asc' },
];
// let currDate = moment().toDate();

// const getOrderStatus = (orderStatus) => {
//   if (orderStatus) {
//     const ordSts = ORDER_STATUS_LIST.find( _ => _.key === orderStatus );
//     if (ordSts) {
//       return ordSts.value;
//     }
//     return orderStatus[0].toUpperCase() + orderStatus.substring(1);
//   }
//   return orderStatus;
// }

const getOrderSaleType = (saleType) => {
  if (saleType) {
    const saleTypeObj = ORDER_SALE_TYPE_LIST.find( _ => _.key === saleType);
    if (saleTypeObj) {
      return saleTypeObj.value;
    }
  }
  return saleType;
};

const Columns = [
  { field: 'orderNo',
      headerName: 'Sector', minWidth: 164, tooltipField: 'flightNumber', autoHeight: true, pinned: 'left',
      cellRendererSelector: (params) => {
        return {
          component: SectorRenderer
        };
      }
  },
  { field: 'orderNo', headerName: 'Order Number', sortable: false, filter: false, minWidth: 240,
    tooltipField: 'orderNo', autoHeight: true
  },
  { field: 'timestampDateTime', headerName: 'Order Date', sortable: false, filter: false, minWidth: 150,
      tooltipField: 'timestampDateTime', autoHeight: true,
      valueGetter: params => {
          return params.data?.timestampDateTime ? moment(params.data?.timestampDateTime).format(DATE_FORMAT) : '';
      }
  },
  { field: 'orderStatus', headerName: 'Order Status', sortable: false, filter: false,
      minWidth: 140, tooltipField: 'orderStatus', autoHeight: true,
      cellRendererSelector: (params) => {
        return {
          component: OrderStatusRenderSel
        }
      }
  },
  { field: 'saleTypeRaw', headerName: 'Sale Type', sortable: false, filter: false,
      tooltipField: 'saleTypeRaw', minWidth: 150, autoHeight: true,
      valueGetter: params => {
          return params.data ? getOrderSaleType(params.data.saleTypeRaw) : '';
      }
  },
  { field: 'paymentTypeRaw', headerName: 'Payment Type', sortable: false, filter: false,
      minWidth: 160, tooltipField: 'paymentTypeRaw', autoHeight: true,
      cellRendererSelector: (params) => {
        return {
          component: PaymentTypeRenderSel
        }
      }
  },
  { field: 'totalAmount', headerName: 'Total Amount', sortable: false, filter: false,
      minWidth: 160, autoHeight: true,
      valueGetter: params => {
          return params.data ? params.data.currencyInfo.currencyCode + ' ' + params.data.totalAmount : '';
      }
  },
];
const actionColumnProperties = { pinned: 'right', minWidth: 500, width: 500, autoHeight: true, tooltipField: 'View Items' };

export const SalesPage = (props) => {
  const { t } = useTranslation();
  const [isDownloading, ] = useState(false);
  // const [isSectorsLoading, setSectorsLoading] = useState(false);
  const [state, setState] = useState({});
  // const tableRef = useRef();
  const [perPageLimit, ] = useState(10);
  const [ isReloadTableData, setIsReloadTableData ] = useState(false);

  const [actionItems, setActionItems] = useState([]);
  const [ modalTitle, setModalTitle ] = useState('Order Details');
  const [modalCustomButtons, setModalCustomButtons] = useState(null);
  const modelStyle = { maxWidth: '80%' };
  const [modalFormStatusObj, ] = useState({ text: '' });
  const [, setUpdatedFormFieldValues] = useState({});
  const [modalFormData, setModalFormData] = useState({});

  const [sectorList, setSectorsList] = useState([]);

  const [searchKey, setSearchKey] = useState('');
  const { hasPermission } = usePermissions();
  const [ salesFilter, setSalesFilter ] = useState({
    sectorVal: '',
    orderByVal: OrderByFilters[0].value,
    orderDateVal: '',
    searchKey: '',
    orderStatusVal: '',
    saleType: '',
    paymentType: ''
  });

  const [sectorPageObj, setSectorPageObj] = useState({
    page: 1,
    size: 50,
    q: ''
  });

  const [, setReportTypes] = useState([]);
  // const [routes, setRoutes] = useState([]);
  const [editFormFields, setEditFormFields] = useState([]);

  const checkPermission = (action, sub) => {
    if (action && sub) {
       return hasPermission(action, sub)

    };
  };
  useEffect( () => {
    // {[{ title: 'Edit', detail: [{src: editIcon2, value:"EditItem"}] }]}
    setActionItems([
      {
          title: 'Actions',
          attribute: 'viewItems',
      },
      {
        title: '',
        attribute: 'invoices'
      },
      // {
      //     title: 'Actions',
      //     attribute: 'Suspend',
      // },
      // {
      //     title: '',
      //     attribute: 'purchaseOrders',
      // }
    ]);

    getSectorList();

    loadReportTypes();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect( () => {
    fetchOrders();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesFilter]);

  const getActionCellIcons = (cellDef, data) => {

    if (cellDef.attribute === 'viewItems') {
        // if (data && data.status === 'created') {
        //     return [{src: editIcon2, value:"edit", title: 'Edit'}];
        // } else {}
        return [
            {src: viewIcon, value:"viewItems", title: 'View Items'},
        ];
    }
    else if (cellDef.attribute === 'invoices') {
      if (data && data.saleTypeRaw === 'preBooked' && data.orderStatus !== 'Cancelled Order') {
        return [
          {src: downloadMasterIcon, value: 'downloadInvoices', title: 'Invoices' }
        ]
      }
    }


    return [];
  };

  const fetchOrders = async (page=1, perPage=10) => {
    /**
     * Pagination
     */
    filterData.page = page;
    filterData.limit = perPage;

    /**
     * Filters
     */
    filterData.filters = [];
    // if ( salesFilter.searchKey ) {
      filterData.searchParam = salesFilter.searchKey;
      filterData.filters.push({ orderNo: salesFilter.searchKey });
    // }

    if (salesFilter.sectorVal) {
      filterData.filters.push({ storeId: salesFilter.sectorVal });
    }
    if ( salesFilter.orderDateVal ) {
      const dt = moment(salesFilter.orderDateVal);
      if ( dt.isValid() ) {
        filterData.filters.push({ orderDate: dt.format('YYYY-MM-DDT00:00:00Z') })
      }
    }
    if (salesFilter.orderStatusVal) {
      filterData.filters.push({ orderStatus: salesFilter.orderStatusVal });
    }
    if (salesFilter.saleType) {
      filterData.filters.push({ saleTypeRaw: salesFilter.saleType })
    }
    if (salesFilter.paymentType) {
      filterData.filters.push({ paymentTypeRaw: salesFilter.paymentType })
    }

    /**
     * Sort
     */
    if ( salesFilter.orderByVal ) {
      let sortOrder = 'desc';
      if (salesFilter.orderByVal === 'orderDateAsc') {
        sortOrder = 'asc';
      } else if ( salesFilter.orderByVal === 'orderDateDesc' ) {
          sortOrder = 'desc';
      }

      filterData.sort.map( _ => {
        if ( _.field === 'timestampDateTime' ) {
          _.order = sortOrder
        }
        return null
      });
    }

try {
  const res = await APIEndpoint.post('orders/process', JSON.stringify(filterData), { signal: newAbortSignal('orderListAPI', 5000) })
  setIsReloadTableData( !isReloadTableData );

  return res;
} catch (error) {

}

  }

  const getSectorList = () => {
    // let nnn = [ ...sectorList ];
    // nnn.push( { id: '', label: 'Sectors - Loading...', value: ''} );
    // setSectorsList(nnn);

    // e.target.setAttribute('disabled', true);
    // setSectorsLoading(true); // , { signal: newAbortSignal('sectorListAPI', 5000) }
    try {
   APIEndpoint.get('stores/byPage?'+ objectToQueryString(sectorPageObj) ).then( resp => {
      // e.target.removeAttribute('disabled');
      // setSectorsLoading(false);

      setSectorPageObj({ ...sectorPageObj, page: sectorPageObj.page + 1 });
      const list = [];
      if ( resp.result.length > 0) {
        resp.result.map( _ => {
          list.push({ value: _.id, id: _.id, label: _.value });
          return null
        });

        setSectorsList(list);
      }
    });
    } catch (error) {

    }


    return false;
  };

  const onSectorChange = (e) => {
    setSalesFilter( { ...salesFilter, sectorVal: e.target.value } );
    abortEarlierRequest('orderListAPI');
  };
  const onOrderByChange = (e) => {
    setSalesFilter( { ...salesFilter, orderByVal: e.target.value } );
    abortEarlierRequest('orderListAPI');
  };
  const onSearch = (e) => {
    const searchTxt = e.target.value;
    setSearchKey(searchTxt);

    // if (searchTxt.length >= 3) {
    setSalesFilter( { ...salesFilter, searchKey: searchKey } );
    // }
    abortEarlierRequest('orderListAPI');
  };
  const handleFilterOrderDateChange = (e) => {
    setSalesFilter( { ...salesFilter, orderDateVal: e.target.value } );
    abortEarlierRequest('orderListAPI');
  };
  const handleFilterOrderStatusChange = (e) => {
    setSalesFilter( { ...salesFilter, orderStatusVal: e.target.value } );
    abortEarlierRequest('orderListAPI');
  };
  const onSaleTypeChange = (e) => {
    setSalesFilter( { ...salesFilter, saleType: e.target.value } );
    abortEarlierRequest('orderListAPI');
  }
  const onPaymentTypeChange = (e) => {
    setSalesFilter( { ...salesFilter, paymentType: e.target.value } );
    abortEarlierRequest('orderListAPI');
  };

  const loadReportTypes = async () => {
    //, { signal: newAbortSignal('reportTypesAPI', 5000) }
    try {
     const resp = await DFPAPIEndpoint.get('reports/reportTypes');
    if (resp.result.length > 0) {
        const options = [{key: 0, label: 'Select', value: '', id: ''}];
        resp.result.map( (fieldValue, index) => {
          options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.id}`, id: `${fieldValue.id}`});

          return fieldValue;
        });

        setReportTypes(options);
        // updateEditFormInputs(options, null);
    }
    } catch (error) {

    }

  }

  const onDownloadReports = async (e) => {
    const action = { value: 'downloadReportPopup' };
    onActionInner(e, action, {});
  }

  const onAction = (e, action, row) => {
    onActionInner(e, action, row);
  };

  const onActionInner = (e, action, row) => {
    console.info('Log: Clicked on ' + action.value + ' action link.', row);

    ReactDOM.flushSync( () => {

      let updatedEditFields = [];

      if (action.value === 'viewItems') {
          setModalTitle('Order No - ' + row.orderNo );

          updatedEditFields = [{ label: 'View Order Details', type: 'viewSalesDetails', attribute: 'items' }];
          updatedEditFields = updatedEditFields.filter( row => {
              if (row.type === 'viewSalesDetails') {
                row.value = row;
              }
              return row;
          });

          // setModalCustomButtons([[]]);
          setModalCustomButtons([
            /**
             * Buttons
             */
            { label: 'Close', attribute: 'button', type: 'button', disabled: false, className: 'close-button', action: (e) => {
                    console.log('ModalClosed.');
                    onClose();
                    return false;
                }
            }
          ]);

          setEditFormFields( [ ...updatedEditFields ] );

          setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action.value} };
          });

      }
      else if (action.value === 'downloadReportPopup') {
        setState((_prev) => {
          return { ..._prev, selected: { ...row, action: action.value} };
        });
      }
      else if (action.value === 'downloadInvoices') {
        console.log('Log: Download invoice option selected.');

        // onDownloadPDFFile('downloads/invoice', 'order_invoice_' + row.id, { method: 'POST', payload: { orderId: row.id } } );

        const linkTxt = action.title;
        updateButtonTxtStatus(false, e, '...', true);
        APIEndpoint.post('downloads/invoice', { orderId: row.id }, { Accept: MIMETypes.pdf }).then( resp => {

          if (resp.status === 'scheduled') {

            console.info("Log: Download status check: Initial");
            checkDownloadStatus(resp.id, e);

          } else {
            updateButtonTxtStatus(true, e, linkTxt, false);
          }

        })
        .catch( error => {
            console.error('Log: Error occured while downloading invoice:', error);
            updateButtonTxtStatus(false, e, 'Failed!', false, { defaultButtonText: linkTxt, className: 'failedCls' });
        });

      }

    });

  };

  const onClose = () => {

    setState((_prev) => {
      return { ..._prev, selected: false }
    })
  }

  const updateEditFormValues = async (e, updatedFormObj) => {

    // ReactDOM.flushSync( () => {
    //     if (e) {
    //         const selectedReportType = reportTypes.find( item => item.value === e);
    //         console.log('selectedReportType:', selectedReportType);

    //         /** Set to respective fields */
    //         // const updatedEditFields = editFormFields.filter( (field) => {

    //         //     if (selectedReportType ) {
    //         //         if ( field.attribute === 'reportType' ) {
    //         //             field.value = selectedReportType.label;
    //         //         }
    //         //     }

    //         //     return field;

    //         // });

    //         // setEditFormFields( _prev => {
    //         //     return [ ...updatedEditFields ] ;
    //         // });
    //     }
    // })
  };

  const onModalFormInputChange = (e, newFormData) => {
    const { name, value } = e.target;

    let newUpdates = modalFormData ? { ...modalFormData } : {}
    newUpdates[name] = value ?? '';

    setModalFormData( { ...newFormData } );

  }

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
         {checkPermission('download','sales.reconciled')?  <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight">
            {/* <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadTaxMaster(true)}> <span><img  className="icon-size mt-1" alt='uploadItem' src={uploadItemIcon} /><u>{t('filters.uploadItems')}</u></span></button> */}
            <button className='download dropdownStyle1-TextMedium flexRow justifyContentFlexEnd mb-0 ' onClick={ (e) => { onDownloadReports(e) }}><span><img className="icon-size mt-1" alt='Download Reports' src={downloadMasterIcon} /><u className={`download__banner_${isDownloading ? "active" : ""}`}>{t('filters.downloadReports')}</u></span></button>
          </div>:<></>}
          <div className="flexRow width100 justifyContentFlexStart alignItemsCenter">

            <button className='sector-div m-2 margLeft'>
                <select className="select-item darkBlue-bg" onChange={onSectorChange} value={salesFilter.sectorVal} id="sectorId">
                  <option key='' value=''>Select Sector</option>
                  {
                    sectorList.map((_) => {
                        return (
                          <option key={_.value} value={_.value}>{_.label}</option>
                        )
                    })
                  }
                </select>
            </button>

            <button className='orderBy-div m-2 margLeft'>
                <select className="select-item darkBlue-bg" onChange={onOrderByChange} value={salesFilter.orderByVal} id="orderById">
                  {
                    OrderByFilters.map((_) => {
                        return (
                          <option key={_.value} value={_.value}>{_.label}</option>
                        )
                    })
                  }
                </select>
            </button>

            <button className='orderStatus-div m-2'>
                <select className="select-item darkBlue-bg" onChange={handleFilterOrderStatusChange} value={salesFilter.orderStatusVal} id="orderStatusId">
                  <option key={''} value={''}>Order Status</option>
                  {
                    ORDER_STATUS_LIST.map((_) => {
                        return (
                          <option key={_.key} value={_.key}>{_.value}</option>
                        )
                    })
                  }
                </select>
            </button>

            <button className='saleType-div m-2'>
                <select className="select-item darkBlue-bg" onChange={onSaleTypeChange} value={salesFilter.saleType} id="saleTypeId">
                  <option key='' value=''>Sale Type</option>
                  {
                    ORDER_SALE_TYPE_LIST.map((_) => {
                        return (
                          <option key={_.key} value={_.key}>{_.value}</option>
                        )
                    })
                  }
                </select>
            </button>

            <button className='paymentType-div m-2'>
                <select className="select-item darkBlue-bg" onChange={onPaymentTypeChange} value={salesFilter.paymentType} id="paymentTypeId">
                  <option key='' value=''>Payment Type</option>
                  {
                    ORDER_PAYMENT_TYPE_LIST.map((_) => {
                        return (
                          <option key={_.key} value={_.key}>{_.value}</option>
                        )
                    })
                  }
                </select>
            </button>

          </div>

          <div className='flexRow width100 justifyContentSpaceBetween alignItemsCenter'>
              {/* <button className='page-button'>
                  <select className="darkBlue-bg" onChange={onPerLimitChange} value={perPageLimit} id="page-size">
                      {
                      PageSizes.map((_) => {
                          return (
                          <option key={_.value} value={_.value}>{_.label}</option>
                          )
                      })
                      }
                  </select>
              </button> */}

              <div className="flexRow m-2 margLeft">
                <NGODatePicker
                  className="orderByDateInp"
                  value={ salesFilter.orderDateVal }
                  name='filterOrderDate'
                  placeholder={"DD-MM-YYYY"}
                  onChange={ handleFilterOrderDateChange }
                  dateformat="dd-mm-yyyy"
                />
              </div>

              <div className="flexRow underline m-2">
                  <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                  <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText') + ' Order Number '} value={searchKey}/></span>
              </div>

              {/* <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
                  <button className="add-item-button" onClick={ onAddItemHandler }>
                  {t('buttons.addItem')}
                  </button>
                  <button className='item-button'>
                  <select className='select-item' onChange={onItemTypeChange} value={selItemType} id="item-type">
                      <option value="">{t('selectButtons.selItemType')}</option>
                      {itemTypeList.map( (item, index) => (
                          <option key={index} value={item.value}>{item.label}</option>
                      ))
                      }
                  </select>
                  </button>
              </div> */}
          </div>

          <DataGrid
            // ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            // searchKey={searchKey}
            // selItemType={selItemType}
            getRows={fetchOrders}
            rowSelection="multiple"
            actionProperties = { actionColumnProperties }
            // actions={[{ title: 'Edit', detail: [{src: editIcon2, value:"EditItem"}] }]}
            actions={actionItems}
            getActionCellIcons={getActionCellIcons}
            onAction={ (e, action, data) => { return onAction(e, action, data) } }
            // onAddItemHandler={() => onAddItemHandler()}
            columnResize={true}
            resetDataSource={isReloadTableData}
          />
        </>
      }
      {
        state && Boolean(state.selected) && state.selected?.action === 'downloadReportPopup'&& checkPermission('download','sales.reconciled') ?
        (
          <>
            <SalesReportsDownloadComponent sectorList={ sectorList } onClose={ () => { onClose() } }/>
          </>
        ) : null
      }
      {
        state && Boolean(state.selected) && checkPermission('viewDetails','sales.reconciled') &&
          ( state.selected?.action === 'viewItems' ) ?
          (
            <EditModal
                className='salesPageModal'
                title={ modalTitle }
                customModalStyle={ modelStyle }
                onClose={onClose}
                // onSubmit={onSubmit}
                modalFormStatusObj={modalFormStatusObj}
                modalCustomButtons={modalCustomButtons}
                fields={ editFormFields.map( (elt) => {

                        if ( state.selected.action === 'viewItems' ) { // [elt.attribute]
                            return {...elt, initialValue: state.selected ?? '' }
                        }
                        else {
                            return {...elt, initialValue: '' }
                        }
                  })
                }
                showFieldsInColumn={true}

                onChange={ (e, updated) => {

                        setUpdatedFormFieldValues(updated);

                        if (typeof e === 'string') { // Search select
                            updateEditFormValues(e, updated);
                        }
                        else {
                            onModalFormInputChange(e, updated);
                        }

                    }
                }
            />
        )
        : null
      }
    </div>
  );
};