/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import { DataGrid ,EditModal} from "../../../common";
import { useTranslation } from 'react-i18next';
import {Permission} from '../inner-components/Permission';
import Edit2 from "../../../assets/edit2.svg";
import { FaLock } from 'react-icons/fa';
import usePermissions from '../../../utils/userPermissions';
const EditFormField = [
  { label: 'Name', attribute: 'name', type: 'text', disabled: false ,required: true,placeholder: 'Role Name'},
  {label: 'Scope', attribute: 'scope', type: 'select', disabled: false, options: [], required: true },
  { label: 'Description', attribute: 'description', type: 'textarea', disabled: false ,rows: 4,placeholder: 'Description'},
]

export const ManageRolesPage = (props) => {
  const [state, setState] = useState({})
  const [permMod,setPermMod]=useState(false);
  // const [, setPerPageLimit] = useState(10);
  const { t } = useTranslation();
  const [searchKey, setSearchKey] = useState('');
  const [, setScopeList] = useState([]);
  const [permList,setPermList]=useState([]);
  const [userPermission,setUserPermission]=useState([]);
  const [permView,setPermView]=useState([]);
  const [userPermLength,setUserPermLength]=useState('');
  const [emptyUserPerm,setEmptyUserPerm]=useState([]);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [show, setShow] = useState(false);
  const [userRoleName,setUserRoleName]=useState('');
  const [idArray,setIdArray]=useState([]);
  const [isLength]=useState('');
  const[permCheck,setPermCheck]=useState(false);

  const { hasPermission: createRoles } = usePermissions('create', "users.roles");
  const { hasPermission: editRoles } = usePermissions('edit', "users.roles");
  const { hasPermission: viewDetailsRoles } = usePermissions('viewDetails', "users.roles");



  const Columns = [
    { field: 'name', headerName:"Name", sortable: true, suppressSizeToFit:true, resizable:true, flex:1,minWidth: 215 },
    { field: 'scope',headerName:"Scope", sortable: true, suppressSizeToFit:true, resizable:true,  flex:1,minWidth: 215},
    { field: 'description',headerName:"Description", sortable: true, suppressSizeToFit:true, resizable:true, flex:1, minWidth: 215 },
    { field: 'actions',headerName:"Actions", flex:1,cellRenderer: ActionComponent },
]

function ActionComponent(props){
  if(!props.data) return null
  return (
    <div className='flexRow' style={{gap:"35px",padding:"10px"}}>
      <><img src={Edit2} style={{width:15,cursor:"pointer"}} alt='edit' title='Edit Role' onClick={e=>onAction(e,"Edit",props.data)}/>
     <FaLock title='Edit Permissions' color='#fff' size={15} style={{cursor:"pointer"}} onClick={e=>onAction(e,"Permissions",props.data)}/></>
    </div>
  )
}

  const preCompile = {
    scope: t('selectButtons.selScope'),
    scopeWarning:t('warningTexts.roles.nameScope'),
  }

  React.useEffect(() => {
    fetchScopesList();
    fetchPermList();
  }, [t]);

  React.useEffect(()=>{
    if(userPermLength){
      getUserPermId(userPermission);
    }
  },[userPermission])

  React.useEffect(() => {
    if(show){
        const timeId = setTimeout(() => {
        setShow(false);
      setModalFormStatusObj({ text: '' });
      if(modalFormStatusObj.text){
       setPermCheck(false);
      }
      }, 3000)
  return () => {
        clearTimeout(timeId)
      }
    }
    }, [show]);

  React.useEffect(() => {
    setChangePermState()
  }, [permView]);

const handleReset = () => {
  setState((_) => {
    return { ..._, resetDataSource:true};
  })
  let timerId = setTimeout(() => {
    clearTimeout(timerId);
    onClose();
  }, 700)
}

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };
  const fetchRoles = async (page=1, perPage=10) => {
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
    const res = await APIEndpoint.post('roles/process', filterData);
    return res;
  }

  const fetchScopesList = async () => {
    try {
        const resp = await APIEndpoint.get('scopes');
    if (resp.result.length > 0) {
      const options = [{key: 0, label: preCompile.scope, value: ''}];
      resp.result.filter( (fieldValue, index) => {
        options.push({key: index+1, label: fieldValue, value: fieldValue});
        return fieldValue;
      });
      setScopeList( options );
      /**
       * Set to edit form itemType list
       */
       EditFormField.map( row => {
        if (row.attribute === 'scope' ) {
          row.options = options;
        }
        return row;
      });
    }
    else {
      console.log('Log: Error while fetching scope types API');
    }
    } catch (error) {

    }

  };



const setChangePermState=(perm,l)=>{
if(perm!==undefined&&l!==undefined){
  setPermCheck(true);
  for(let i = 0; i < perm?.length; i++){
    if(perm[i].id===l?.id){
      l.selected?perm[i]['selected']=false:perm[i]['selected']=true;
    if(typeof l?.id !== 'undefined'){
      if(l.selected){
        setIdArray([...idArray,l.id]);
      }
      }

      if(!l.selected){
        let index;
        index=idArray.indexOf(l.id);
        if(index>=0){
          idArray.splice(index,1)
          setIdArray(idArray)
        }
      }
    }
    if(!userPermLength){
      setEmptyUserPerm(perm);
    }
    setPermView(perm);
  }
}
  }

  const fetchPermList = async () => {
    try {
       const resp = await APIEndpoint.get('permissions');
    if (resp.result.length > 0) {
      resp.result.forEach(p => {
        p.selected = false;
      });
      setPermList( resp.result );
    }
    else {
      console.log('Log: Error while fetching scope types API');
    }
    } catch (error) {

    }

  };

const onAction = (e, action, row) => {
if(action==="Permissions" && row.permissions.length>0){
  setUserPermLength(row.permissions.length)
  row?.permissions.forEach(p => {
    p.selected = true;
  });
  setUserPermission(row?.permissions);
  setIdArray(userPermission.length>0?userPermission.map(p=>p.id):[])
  setUserRoleName(row?.name);
  setPermMod(true);
}
if(action==="Permissions" && row.permissions.length===0){
  const noUserPerm=permList.map(p => ({
    ...p,
    selected:false
  }));
  setUserPermission(noUserPerm);
  setIdArray(noUserPerm.length>0?noUserPerm.map(p=>p.id):[])
  setUserRoleName(row?.name);
  setPermMod(true);
  setState( (_prev) => {
    return { ..._prev, selected: {...row,  action: 'editPermissions' }}
  });
  setUserPermLength(noUserPerm.length)
let assnPerm;
if(row.scope==='Cluster'){
  assnPerm =permList.filter((p)=>p.scope==="Cluster"||p.scope==='Warehouse'||p.scope==='Store')
}
else{
  assnPerm =permList.map((p)=>p.scope===row.scope)
}

 setEmptyUserPerm(assnPerm);
if(assnPerm.length>0){
  setUserRoleName(row?.name);
  setPermMod(true);
}

}
else{
  setState( (_prev) => {
     return { ..._prev, selected: {...row,  action: 'editRole' }}
   });
}
  }

  const onAddItemHandler = () => {
    setState( (_prev) => {
      return { ..._prev, selected: { action: 'AddNewRole' } };
    });
  }
const getUserPermId=(user)=>{
  const res=user.map(usr=>usr.id);
  setIdArray(res);
  return res;
}
  const onClose = () => {
  setIdArray([]);
    fetchPermList();
    setPermCheck(false);
    setPermMod(false);
    setModalFormStatusObj({ text:'' });
    setState((_prev) => {
      return { ..._prev, selected: false ,resetDataSource:false}
    })
  }

  const onSubmit =async(e) => {
    let body={};
    if(permCheck){
      body={permissions:idArray}
    }else{
      if(userPermission.length){
       let res= getUserPermId(userPermission);
      body={permissions:res};
      }
    }
    try {
      const res = await APIEndpoint.put(`roles/${state.selected.id}/permissions`,body);
     if(res.success){
      setModalFormStatusObj({ text: res.msg });

      handleReset();
     }
   }
   catch (error) {
    let {data}=JSON.parse(error.message);
    setModalFormStatusObj({ error:data.msg });
   }
  //  onClose();
  }

  const onEditSubmit = async (e, updated) => {
    let updatedBody={};
  if(state.selected.action==="editRole"){
  if(updated=== null || !Object.keys(updated).length){
  const {selected}=state
    updatedBody.name=selected.name
    updatedBody.id=selected.id
    updatedBody.scope=selected.scope
    updatedBody.description=selected.description;
    try {
      const res = await APIEndpoint.put(`roles/${state.selected.id}`, updatedBody);
     if(res.success){
      setModalFormStatusObj({ text: res.msg });
      handleReset();
     }
   }
   catch (error) {
    setModalFormStatusObj({ error: error.data.msg });

   }
  }
  if(updated&&(updated.hasOwnProperty('description')||updated.hasOwnProperty('name'))){
    const {selected}=state
      updatedBody.name=updated?.name===undefined?selected.name:updated?.name
      updatedBody.id=selected.id
      updatedBody.scope=selected.scope
      updatedBody.description=updated?.description===undefined?selected.description:updated?.description;
      try {
        const res = await APIEndpoint.put(`roles/${state.selected.id}`, updatedBody);
       if(res.success){
        setModalFormStatusObj({ text: res.msg });
        handleReset();
       }
     }
     catch (error) {
      setModalFormStatusObj({ error: error.data.msg });
     }
    }
}
  if(state.selected.action==="AddNewRole"){
    if(updated===null || updated.name===''|| updated.scope===''){
      setModalFormStatusObj({ error: preCompile.scopeWarning});
    }
    else{
      try {
        const res = await APIEndpoint.post(`roles`, updated);
       if(res.success){
        setModalFormStatusObj({ text: res.msg });
        handleReset();
       }
     }
     catch (error) {
      let {data}=JSON.parse(error.message);
      setModalFormStatusObj({ error: data.msg });
   }
    }
  }

}

  const onSearch = (e) => {
    setSearchKey(e.target.value);
  };

  // const onPerLimitChange = (e) => {
  //   setPerPageLimit(Number(e.target.value));
  // };
  let permProps={
   isLength,
   onSubmit,
    permView,
    permCheck,
    userPermLength,
    emptyUserPerm,
    setChangePermState,
    modalFormStatusObj,
    setShow,
    show,
    onClose,
    userRoleName,
    userPermission,
    permList,
  }
  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
      <div></div>
     { createRoles? <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
        <button className="add-item-button" onClick={ onAddItemHandler }>
                {t('buttons.createRole')}
                </button>
          </div>:<></>}
      </div>
       {
         <>
          <DataGrid
            className='full-flex'
            page="role"
            columns={[...Columns]}
            showDefaultFilters={false}
            initialPageLimit={10}
            searchKey={searchKey} setSearchKey={(e) => onSearch(e) }
            columnResize={true}
            resetDataSource={state.resetDataSource}
            onAddItemHandler={() => onAddItemHandler()}
            getRows={fetchRoles}
            />
        </>
      }
{
        state && Boolean(state.selected) && editRoles && createRoles? (
          <EditModal
            key={state.selected.action}
            title={(state.selected.action === 'editRole') ? 'Edit Role' : 'Create Role'}
            onClose={onClose}
            buttonLabel={'Save'}
            showFieldsInColumn={true}
            onSubmit={onEditSubmit}
            modalFormStatusObj={modalFormStatusObj}
            fields={EditFormField.map((_) => {
              return { ..._, initialValue: state.selected[_.attribute] }
            })}
          />
        )
        : null
      }
      {
       permMod && viewDetailsRoles && <Permission {...permProps} />
      }
 {
                  t('selectButtons').length<=13&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}

