/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import Spreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import * as ReactDOM from 'react-dom';
import closeIcon from "../../../images/icons/Icon2.png"
import { APIEndpoint } from "../../../utils/axios";
import {AlertModal,Loading } from "../../../common";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../utils/commonFunctions";
import { DEFAULT_SHEET_ROWS, defaultCellFieldData } from "../../../utils/configs";
import { useTranslation } from "react-i18next";
let spreadSheetErrTimout;

const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_UNIT: 2,
    ITEM_AVAILABLE_STOCK: 3,
    ITEM_QTY: 4,
    ITEM_REMARKS: 5
};
const sheetLabels = ['Item Code', 'Item Name', 'Unit', 'Available Stock', 'Quantity', 'Remarks'];

const createEmptySheetRows = (noOfRows = DEFAULT_SHEET_ROWS,sheetData) => {
    let len = sheetData ? +sheetData.length:0
    const rows = [];
    for( var i=0; i < noOfRows; i++) {
        rows.push([
            {
                ...defaultCellFieldData,
                id: 'itemCode',
                className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_CODE,
                showPopup: true
            },
            {
                ...defaultCellFieldData,
                id: 'itemName',
                className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_NAME,
                showPopup: true
            },
            {
                ...defaultCellFieldData,
                id: 'unit',
                className: 'cellClass cellClass_' + len + '_' + iIndexes.ITEM_UNIT,
                width: '400px',
                readOnly: true,
            },
            {
                ...defaultCellFieldData,
                id: 'availableStock',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_AVAILABLE_STOCK,
                readOnly: true,
                required: false
            },
            {
                ...defaultCellFieldData,
                id: 'quantity',
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_QTY,
            },
            {
                ...defaultCellFieldData,
                id: 'remarks',
                readOnly: false,
                required: false,
                className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_REMARKS,
            }
        ]);
        len++
    }
    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
const initialData = createEmptySheetRows( DEFAULT_SHEET_ROWS );

export const WarehouseTransfersCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const { t } = useTranslation();

    const [warehouseItemsFull, setWarehouseItemsFull] = useState([]);
    const [warehouseItemsFiltered, setWarehouseItemsFiltered] = useState([]);
    const [editTransferDetails, setEditTransferDetails] = useState({});
    const [errorDetails,setErrorDetails]=useState([]);
    const [state, setState] = useState({ isInvalidAccess: false, message: '', isEditForm: false });

    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [activeCell, setActiveCell] = useState({});
    const [, setSelectedCellsArr] = useState([]);

    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];

    useEffect(() => {
        let timeout = setTimeout(() => {
          const errorCells = document.querySelectorAll(".errorCellData");
          if (errorCells.length === 0) return;
          errorCells.forEach((cell, i) => {
            const rowIndex = [...cell.classList][3].split("_")[1];
            const error = errorDetails.find((err) => err.row == rowIndex);
            if (!cell.querySelector(".sheetErrDiv") && error) {
              const errorDiv = document.createElement("div");
              errorDiv.classList.add("sheetErrDiv");
              errorDiv.textContent = error?.message;
              cell.appendChild(errorDiv);
            }
          });
        }, 100);
        return () => {
          clearTimeout(timeout);
        };
      }, [errorDetails, sheetData, activeCell]);

      useEffect(() => {
        let timeout = setTimeout(() => {
            const errorCells = document.querySelectorAll(".errorCellData");
            if (errorCells.length === 0) return;
            errorCells.forEach((cell, i) => {
                cell.scrollIntoView({behavior:"smooth"})
            });
          }, 1000);
          return () => {
            clearTimeout(timeout);
          };
      }, [errorDetails])

      useEffect(() => {
        if (errorDetails.length) {
            const updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    const error = errorDetails.find(err => parseInt(err.row) === rowIndex);
                    if (error && Object.values(cell).includes(error.field)) {
                        return {
                            ...cell,
                            className: `errorCellData cellClass cellClass_${rowIndex}_${colIndex}`
                        };
                    }
                    return { ...cell };
                });
            });
            setSheetData(updatedSheetData);
        }
    }, [errorDetails]);


    useEffect( () => {
        if ( urlParams.hasOwnProperty('transferId') ) {
            setState( { ...state, isEditForm: true } );
            getEditTransferDetailsById(urlParams.transferId);
        }
        else if ( urlParams.hasOwnProperty('sourceWarehouseId') ) {
            setState( { ...state, isEditForm: false } );
            getWarehouseItems(urlParams.sourceWarehouseId);
        }
    }, [ urlParams ]);

    useEffect( () => {
        if (Object.keys(editTransferDetails).length > 0) {
            setEditTransferItemsToSheet(editTransferDetails);
            getWarehouseItems(editTransferDetails.source.id);
        }
    }, [editTransferDetails]);

    const getWarehouseItems = (sourceWarehouseId) => {
        setState((_)=>({ ..._, loading: true}));
        APIEndpoint.post('warehouses/items', { warehouseId: sourceWarehouseId })
        .then( resp => {
            if (resp) {
                const warehouseItems = resp.items;
                setWarehouseItemsFull(warehouseItems);
                setWarehouseItemsFiltered(warehouseItems);
                setState((_)=>({ ..._, loading: false,
                }));
            }
        })
        .catch( error => {
            console.log('Invalid page access!, Warehouse transfer details not found for given ID.', error.message);

            setState({ ...state, isInvalidAccess: true,loading:false,
                message: "Invalid page access!, Warehouse transfer details are not found, On click OK, you will be redirected to Warehouse Transfer page." });
        });
    };

    const getEditTransferDetailsById = (transferId) => {
        APIEndpoint.get('warehouseTransfers/findById/' + transferId )
        .then( resp => {
            if (resp) {
                const warehouseTransferDetails = resp.data;
                setEditTransferDetails(warehouseTransferDetails);
            }
        })
        .catch( error => {
            console.log('Invalid page access!, Warehouse transfer details not found for given ID.', error.message);

            setState({ ...state, isInvalidAccess: true,
                message: "Invalid page access!, Warehouse transfer details are not found, On click OK, you will be redirected to Warehouse Transfer page." });
        });
    };

    const setEditTransferItemsToSheet = (transferDetails) => {
        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);
        const items = transferDetails.items;

        items.map( (item, i) => {
            updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = item.itemCode;
            updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = item.itemName;
            updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = item.unit;
            updatedSpreadsheetData[i][iIndexes.ITEM_AVAILABLE_STOCK].value = item.availableStock;
            updatedSpreadsheetData[i][iIndexes.ITEM_QTY].value = parseInt(item.quantity);
            updatedSpreadsheetData[i][iIndexes.ITEM_REMARKS].value = item.remarks;

            return item;
        });

        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);

        });

    };

    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(rowsToAdd,sheetData);
        setSheetData( [...sheetData, ...newRows ]);
    };

    const removeRow = (e) => {

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let inSheetData = JSON.parse(jsonSpreadsheetData);
        let row = activeCell.row;

        if(row || row === 0){
          inSheetData.splice(row,1)
          setActiveCell({})
        }else{
          inSheetData.pop()
        }
        hideItemListPopup();

        /**
         * Remove a last row from the sheet
         */
        // setSheetData( (data) => {
        //     return data.slice(0, data.length -1)
        // });

        /**
         * Clear the content of the cell content
         */
        // console.log('selectedCellsArr:', selectedCellsArr);
        // selectedCellsArr.map( selectedCell => {
        //     const defaultVal = inSheetData[selectedCell.row][selectedCell.column].defaultValue;

        //     inSheetData[selectedCell.row][selectedCell.column].value = defaultVal || '';

        //     return selectedCell;
        // });

        ReactDOM.flushSync( () => {
            setSheetData([ ...inSheetData ]);
        });

		setSelectedCellsArr([]);

    };

    const showValidationErrors = (e, inSheetData, errorClass='cellDataErrorCls' ) => {
        let haveErrors = false;
        const postItems = [];

        inSheetData.map( (cellRow, rowIdx) => {
            let isRowHaveError = false;
            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE]);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME]);

            if (!itemCode || !itemName ) {
                return cellRow;
            }

            for (let colIdx = 0; colIdx < Object.keys(iIndexes).length; colIdx++) {


                if ( cellRow[colIdx].required === true) {

                    const fieldValue = validateCellAndGetData(cellRow[colIdx]);
                    if ( !fieldValue ) {

                        // cellRow[colIdx].className = errorClass;
                        haveErrors = true;
                        isRowHaveError = true;

                    }

                }

            }

            if ( !isRowHaveError && warehouseItemsFull.length) {

                const item = warehouseItemsFull?.find( row => row.itemCode === cellRow[iIndexes.ITEM_CODE].value );
                if (item) {
                    postItems.push({
                        _id: item._id,
                        index: rowIdx,
                        itemId: item.itemId,
                        itemCode: item.itemCode, // cellRow[iIndexes.ITEM_CODE].value,
                        itemName: item.itemName, // cellRow[iIndexes.ITEM_NAME].value,
                        unit: item.unit, // cellRow[iIndexes.ITEM_UNIT].value,
                        availableStock: item.availableStock, // parseInt(cellRow[iIndexes.ITEM_AVAILABLE_STOCK].value),
                        quantity: parseInt(cellRow[iIndexes.ITEM_QTY].value),
                        remarks: cellRow[iIndexes.ITEM_REMARKS].value,
                    });
                }

            }

            return cellRow;
        });

        if (haveErrors) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors(prev => {
                const message = 'Please fill all required item details.';
                if (!prev.some(error => error.message === message)) {
                  return [...prev, { message:message }];
                }
                return prev;
              });
        }

        // setSheetData( [ ...inSheetData ]);

        // console.log('inSheetData after update:', inSheetData, postItems, haveErrors);
        return {
            sheetData: inSheetData,
            validRows: postItems,
            haveErrors
        };

    };

    const saveSheetData = (e) => {
        clearTimeout(spreadSheetErrTimout)
        updateButtonTxtStatus(true, e, 'Saving...', true);
        setSpreadsheetErrors([]);

        let inSheetData = Object.assign([], sheetData);
        const respArrayData = showValidationErrors(e, inSheetData);

        if (!spreadsheetErrors.length && respArrayData.validRows.length === 0) {
            // show global alert message
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors(prev => {
                const message = 'We did not find anything to save!';
                if (!prev.some(error => error.message === message)) {
                  return [...prev, { message }];
                }
                return prev;
              });
            return false;
        }

        if ( respArrayData.haveErrors ) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }

        if ( !respArrayData.validRows.length ) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            return false;
        }


        const data = {
            sourceId: null,
            destinationId: null,
            transferId: null,
            items: respArrayData.validRows || []
        }

        if (state.isEditForm === true) {
            data.transferId = editTransferDetails.id;

            data.sourceId = editTransferDetails.source.id;
            data.destinationId = editTransferDetails.destination.id;
        } else {
            data.sourceId = parseInt(urlParams.sourceWarehouseId) || '';
            data.destinationId = parseInt(urlParams.destinationWarehouseId) || '';
        }

        APIEndpoint.post('warehouseTransfers/save', data)
        .then( apiResp => {
            /**
             * Update success response
             */
            setSheetData(initialData);
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save'} );

            setSpreadsheetErrors(prev => {
                const message =  apiResp.msg || 'Successfully created Warehouse Transfer.';
                if (!prev.some(msg => msg.message === message)) {
                  return [...prev, { message:message }];
                }
                return prev;
              });
            setTimeout( () => {
                goToModuleHome();
            }, 4000);
        })
        .catch( (error) => {
            const apiData = JSON.parse(error.message);
            const { data } = apiData;

            setSpreadsheetErrors([]);
            updateButtonTxtStatus(false, e, 'Failed to save...', true, { defaultButtonText: 'Save' });

            ReactDOM.flushSync(() => {
                if (data && data.errors) {


                    setSpreadsheetErrors(prev => {
                        const errorMsg = data.errors.message || "Something went wrong, please try again later!";
                        if (!prev.some(msg => msg.message === errorMsg)) {
                          return [...prev, { message:errorMsg }];
                        }
                        return prev;
                      });

                    setErrorDetails(data.errors.errors || []);
                    updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save' });
                } else if (data && data?.message) {

                    setSpreadsheetErrors(prev => {
                        const errorMsg =  data?.message
                        if (!prev.some(msg => msg.message === errorMsg)) {
                          return [...prev, { message:errorMsg }];
                        }
                        return prev;
                      });
                } else {
                    setSpreadsheetErrors(prev => {
                        const errorMsg = 'Something went wrong, please try again later!'
                        if (!prev.some(msg => msg.message === errorMsg)) {
                          return [...prev, { message:errorMsg }];
                        }
                        return prev;
                      });
                    updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save' });
                }
            });

        });

        return false;
    };


    const handleActiveCellChange = (activeCell) => {
        setActiveCell({});
        hideItemListPopup();
        setActiveCell(activeCell);

        if ( sheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();

                    showItemListPopup(eltPosition, activeCell);
                }

            }

        }

    }
    const handleCellBlur = () => {

        // setActiveCell({});
    }

    const handleCellKeyDown = (keyboardEvent) => {

        const searchText = keyboardEvent.target.value;

        if (activeCell && Object.keys(activeCell).length > 0) {
            if (sheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }

        // const eltPosition = keyboardEvent.target.getBoundingClientRect();
        // showItemListPopup(eltPosition);

        /**
         * Filter poup items
         */
        const searchPattern = new RegExp(searchText, 'gi');
        const filteredList = warehouseItemsFull?.filter( row => row.itemCode.search( searchPattern ) !== -1 );
        setWarehouseItemsFiltered([ ...filteredList ]);
    }

    const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }

    const hideItemListPopup = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // }
    }

    const onClickPopupListItem = (e, row) => {
        setWarehouseTransferItemToSheet(row);
    }

    const setWarehouseTransferItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.ITEM_UNIT].value = item.unit;
                sheetRow[iIndexes.ITEM_AVAILABLE_STOCK].value = item.availableStock;
            }

            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hideItemListPopup();
    }

    const handleModeChange = (mode) => {

        // if (mode === 'edit') {
        //     setEditingCell
        // }
    };

    const handleCellSelect = (selectedCell) => {

        if (selectedCell.length !== 0) {
            setSelectedCellsArr([ ...selectedCell ]);
        }
    };

    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {

    }
    const goToModuleHome = () => {
        navigate('/procurement/warehouse-transfers');
    };
    const handleOnContinue = async () => {
        goToModuleHome();
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className={ "flexRow alignItemsCenter margBot10 justifyContentSpaceBetween" } >

                    {/* <button className='download dropdownStyle1-TextMedium margRight' onClick={(e) => onDonwloadReportsClick(e)}><span><img className="icon-size" alt='download reports' src={downloadMasterIcon} /><u>{t('filters.downloadReports')}</u></span></button> */}
                </div>
                {state.loading?<Loading/>:<div className="sheet-container sm-scrollbar">
                    <div className="header mb-3 flexRow justifyContentSpaceBetween">
                        <div className="leftBlock alignItemsCenter">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="add-item-button mr-2">{ t('buttons.save') }</button> &nbsp;&nbsp;
                            <button onClick={ (e) => removeRow(e) }
                                className="remove-button ml-2">{ t('buttons.deleteRows') }</button>
                        </div>


                        {spreadsheetErrors.length? <div className={`padding4 margTop9-5 alert alert-${spreadsheetErrors[0].message.includes('Successfully') ? 'success' : 'danger'}`}>
                        {spreadsheetErrors.length === 1 ? spreadsheetErrors[0]?.message : (
                                <ul>
                                    {spreadsheetErrors?.map(err=>{
                                    return <li>{err.message}</li>
                                    })}
                                </ul>
                            )}
                        </div>:null}


                        <button onClick={goToModuleHome}>
                            <img src={closeIcon} alt=""/>
                        </button>
                    </div>
                <div className="se-titlebar">  <h6>Warehouse Transfer - {state.isEditForm === true ? editTransferDetails.code : 'New*' }</h6></div>
                 <Spreadsheet
                        // ref={sheetRef}
                        data={sheetData}
                        columnLabels={sheetLabels}
                        darkMode={true}

                        // getBindingsForCell={[]}
                        onChange={setSheetData}
                        onActivate={handleActiveCellChange}
                        onBlur={handleCellBlur}
                        onKeyDown={handleCellKeyDown}
                        onModeChange={handleModeChange}
                        onSelect={handleCellSelect}
                        onCellCommit={onCellCommitHandle}
                        // Cell={LOICELLComponent}
                        // DataViewer={LOIViewComponent}

                    />

                    <div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button" title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button>
                    </div>

                </div>}
                <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row) }>{ row.itemCode }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>
                <div className = "cellSelectionBlockItemNames sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row) }>{ row.itemName }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>
                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

            </div>
        </>
    );
}