/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState, useCallback } from "react";
import { useTranslation } from "react-i18next";
// import moment from "moment";
import { DataGrid, EditModal,Modal } from "../../../common";
import { FilterBodyTemplate } from "../../../utils/configs";
import { APIEndpoint, DFPAPIEndpoint,DFPAPIEndpointGalley } from "../../../utils/axios";
import {downloadFileCaterer} from '../../../utils/commonFunctions';
import catererdata from "../../../assets/json/caterer_inv.json";
import DownloadVector from '../../../assets/icons/svg/download-vector.svg';

import { AiFillQuestionCircle } from "react-icons/ai";
import {
  updateButtonTxtStatus,
  checkDownloadStatus
} from "../../../utils/commonFunctions";
import {
 CateringStatusRenderSel,
} from "../../sales/inner-components/renderers";
import {
  MIMETypes
} from "../../../utils/configs";
import editIcon2 from "../../../assets/edit2.svg";
import viewIcon from "../../../assets/view.svg";
import cancelIcon from "../../../assets/icons/cancel.svg";
import confirmIcon from "../../../assets/icons/confirm.svg";
import * as ReactDOM from "react-dom";
import usePermissions from "../../../utils/userPermissions";
import searchIcon from "../../../assets/icons/search.svg";
import { CreatecatererInvoice } from "./create-caterer-invoice";


let axiosAbortCtrl = [];
axiosAbortCtrl["orderListAPI"] = new AbortController();

const abortEarlierRequest = (apiName = "orderListAPI") => {
  axiosAbortCtrl[apiName].abort(
    apiName + ": Cancelling API running earlier..."
  );
};

const OrderByFilters = [
  { value: "orderDateDesc", label: "Order Date - Desc" },
  { value: "orderDateAsc", label: "Order Date - Asc" },
];


export const CatererInvoicePage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const [perPageLimit] = useState(10);
  const [EditFieldsCess,setEditFieldsCess]=useState([]);
  const [ isReloadTableData, setIsReloadTableData ] = useState(false);
  const [modalTitle, setModalTitle] = useState("Order Details");
  const [modalCustomButtons, setModalCustomButtons] = useState(null);
  const [modalFormStatusObj] = useState({ text: '' });
  const [caterer,setCaterer]=useState([]);
  const [sectorList, setSectorsList] = useState([]);
  const [sectors, setSectors] = useState([]);
  const [searchKey, setSearchKey] = useState("");

  const { hasPermission: createcatInv } = usePermissions('create', "procurement.caterer-invoice");
  const { hasPermission: editcatInv } = usePermissions('edit', "procurement.caterer-invoice");
  const { hasPermission: viewDetailscatInv } = usePermissions('viewDetails', "procurement.caterer-invoice");
  const { hasPermission: approvecatInv } = usePermissions('approve', "procurement.caterer-invoice");



  const [salesFilter, setSalesFilter] = useState({
    sectorVal: "",
    orderByVal: OrderByFilters[0].value,
    orderDateVal: "",
    searchKey: "",
    orderStatusVal: "",
    saleType: "",
    paymentType: "",
  });

  const EditFormFieldsInv = [
    {label: 'Warehouse', attribute: 'warehouseName', type: 'select',options:sectorList, disabled: false,required:true },
    {label: 'Invoice Id', attribute: 'invoiceId', type: 'text', disabled: false,required:true },
    {label: 'Age', attribute: 'age', type: 'number', disabled: false,required:true },
    {label: 'Catering Group', attribute: 'catererGroup', type: 'select',options:caterer, disabled: false,required:true },
    {label: 'Caterer', attribute: 'catererName', type: 'select',options:caterer, disabled: false,required:true },
    {label: 'Address', attribute: 'address', type: 'text',options:caterer, disabled: false,required:true },
    {label: 'GSTIN', attribute: 'gstIn', type: 'text',options:caterer, disabled: false,required:true },
    {label: 'Value', attribute: 'value', type: 'text',options:caterer, disabled: false,required:true },
    {label: 'Payment Terms', attribute: 'paymentTerms', type: 'select',options:caterer, disabled: false,required:true },
    {label: 'Total', attribute: 'total', type: 'number',options:caterer, disabled: false,required:true },
    {label: 'Sub Total', attribute: 'subTotal', type: 'number',options:caterer, disabled: false,required:true },
    {label: 'Quantity', attribute: 'quantity', type: 'number',options:caterer, disabled: false,required:true },
    {label: 'Type of Meal', attribute: 'typeOfMeal', type: 'select',options:caterer, disabled: false,required:true },
    {label: 'Sector', attribute: 'sector', type: 'select',options:caterer, disabled: false,required:true },
    {label: 'Date', attribute: 'date', type: 'date', disabled: false,required:true },
    {label: 'Status', attribute: 'status', type: 'select',options:[{key:0,label:"Select Status",value:""},{key:1,label:"Accepted",value:"Accepted"},{key:2,label:"Rejected",value:"Rejected"},{key:0,label:"Clarification",value:"Clarification"}],disabled: false,required:true },
  ];

  const [sectorPageObj] = useState({
    page: 1,
    size: 50,
    q: "",
  });


  const [, setEditFormFields] = useState([]);

  useEffect(() => {
    fetchOrders();
    getSectorList();
    getSectors();
    loadCaterers()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [salesFilter,catererdata]);


  const loadCaterers = async () => {
    const filterData = { ...FilterBodyTemplate, filters: [] };
    /**
     * Pagination
     */
    filterData.page = 1;
    filterData.limit = 10000;
    try {
    await DFPAPIEndpointGalley.post('galley/caterers/process', JSON.stringify(filterData) )
      .then( resp => {
          const options=[]
          if(resp.items){
              resp.items.map(item=>(options.push({key:item.id,label:`${item.code}-${item.name2}`,value:`${item.code}`})))
          }
          setCaterer(options)
      }).catch( error => {
          console.log('Log: Error occured while fetching caterer list:', error.message);
      } );
    } catch (error) {

    }

}

  const  getSectors=async(src=null,dst=null)=>{
    try{
  const res=await  DFPAPIEndpoint.get('stores');
  if(res.success){
    const options = [{key: 0, label:`Select sector`,label2:'Select sector', value: ''}];
    res.result.filter( (fieldValue, index) => {
      options.push({key: index+1, label:`${fieldValue.value}`,label2:fieldValue.originName, value: fieldValue.origin});
      return fieldValue;
    })
    setSectors(options)

  }
    }
    catch(err){

    }
    }

  const AddFormFields=[ {label: 'Warehouse', attribute: 'warehouse', type: 'select',options:sectorList, disabled: false,required:true },
  {label: 'Caterer Name', attribute: 'catererName', type: 'text', disabled: false,required:true },
  {label: 'Address', attribute: 'address', type: 'textArea', disabled: false,required:true },
  {label: 'GSTIN', attribute: 'gstIn', type: 'number',disabled: false,required:true },
  {label: 'Invoice Number', attribute: 'invoiceNumber', type: 'number', disabled: false,required:true },
  {label: 'Value', attribute: 'value', type: 'number', disabled: false,required:true },
  {label: 'Payment Terms', attribute: 'paymentTerms', type: 'number', disabled: false,required:true },
  {label: 'Date', attribute: 'date', type: 'date', disabled: false,required:true },
  {label: 'Catering Group', attribute: 'cateringGroup', type: 'select',options:[{key:38,label:'Select Catering Group',value:''},...caterer], disabled: false,required:true },
  {label: 'Sector', attribute: 'sector', type: 'select', disabled: false, options: sectors },
  {label: 'Type of meal', attribute: 'typeofMeal', type: 'select',options: [{key:0,label:"Select Meal Type",value:""},{key:1,label:"Corporate Meal",value:"Corporate Meal"},{key:1,label:"Web meal",value:"Web meal"},{key:2,label:"Group Meal",value:"Group Meal"},{key:3,label:"Crew Meal",value:"Crew Meal"}],disabled: false,required:true },
  {label: 'Quantity', attribute: 'quantity', type: 'number', disabled: false,required:true },
  {label: 'Sub Total', attribute: 'subTotal', type: 'number', disabled: false,required:true },
  {label: 'Total', attribute: 'total', type: 'number', disabled: false,required:true },
]

   const fetchOrders = useCallback(
    async (page = 1, perPage = 10) => {
      setIsReloadTableData(!isReloadTableData);
if(searchKey||salesFilter.sectorVal||salesFilter.orderStatusVal){
  let catererDataFil=[];

     if(salesFilter.sectorVal.length){
      const warehouseUncase=salesFilter.sectorVal.toLowerCase().split(" ")[0];
       catererDataFil = catererdata.filter(ct=>ct.warehouseName.toLowerCase().split(" ")[0].includes(warehouseUncase))
      return {count:catererDataFil.length,items:catererDataFil};
    }
    else if(searchKey.length||salesFilter.orderStatusVal){
      const searchKeyUncase=searchKey.toLowerCase();
      const salseFilterCaterer=salesFilter.orderStatusVal.toLowerCase().split("-")[0];
      catererDataFil = catererdata.filter(ct=>ct.catererGroup.toLowerCase().includes(searchKeyUncase||salseFilterCaterer))
     return {count:catererDataFil.length,items:catererDataFil};}

  }else{
    return {count:catererdata.length,items:catererdata};
  }

}
,

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      searchKey,salesFilter

    ]
  );

  const getSectorList = useCallback(() => {
    APIEndpoint.get("warehouses").then(
      (resp) => {

        const list = [];
        if (resp.result.length > 0) {
          // eslint-disable-next-line array-callback-return
          resp.result.map((fieldValue, index) => {
            list.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`});
          });

          setSectorsList(list);
        }
      },



    );
    const list = [];

    if (1) {
      // eslint-disable-next-line array-callback-return
      caterer.map((fieldValue, index) => {
        list.push({key: index+1, label: `${fieldValue.label}`, value: fieldValue.value});
      });

      setCaterer(list);
    }

    return false;
  }, [sectorPageObj]);


  const handleAction=(type,data)=>{
let updatedEditFields = [];
    if(type==='view' && viewDetailscatInv){
      setState((_prev) => {
        return { ..._prev, selected: { items:[data],catData:data, action: type } };
      });
    }
    else if(type==='edit' && editcatInv){

      EditFormFieldsInv.forEach(edv=>{
        if(edv.attribute==='warehouseName'){
          edv.options=sectorList
        }
        if(edv.attribute==='status'){
          edv.options=[{key:0,label:"Select Status",value:""},{key:1,label:"Accepted",value:"Accepted"},{key:2,label:"Rejected",value:"Rejected"},{key:0,label:"Clarification",value:"Clarification"}]
        }
      })
      setState((_prev) => {
        return { ..._prev, selected: { ...data, action: type } };
      });
    }
    else if(type==='accept' && approvecatInv){

      setModalTitle('Accept Invoice -' + data.warehouseName+"-"+data.invoiceId);
      updatedEditFields = [
        {
            label: 'You are about to accept this invoice details. Are you sure you want to continue?',
            attribute: 'alertMessage', type: 'alertMessage'
        }
    ];
    setModalCustomButtons([
                         {label:'Confirm',attribute:'button',type:"button",disabled:false,className:'submit-button',action:()=>handleModalCancel()}])
    setState( (_prev) => {
      return { ..._prev,selected: {data, action:type }}
       });
    setEditFieldsCess(updatedEditFields);

    }
    else if(type==='reject' && editcatInv){
      setModalTitle('Reject Invoice -' + data.warehouseName+"-"+data.invoiceId);
      updatedEditFields = [
        {
            label: 'You are about to reject this invoice details. Are you sure you want to continue?',
            attribute: 'alertMessage', type: 'alertMessage'
        }
    ];
    setModalCustomButtons([
                         {label:'Confirm',attribute:'button',type:"button",disabled:false,className:'submit-button',action:()=>handleModalCancel()}])
    setState( (_prev) => {
      return { ..._prev,selected: {data, action:type }}
       });
    setEditFieldsCess(updatedEditFields);
    }
    else if(type==='clarfy'){
      setModalTitle('Clarification of Invoice -' + data.warehouseName+"-"+data.invoiceId);
      updatedEditFields = [
        {
            label: 'You are about to ask clarification for this invoice details. Are you sure you want to continue?',
            attribute: 'alertMessage', type: 'alertMessage'
        }
    ];
    setModalCustomButtons([
                         {label:'Confirm',attribute:'button',type:"button",disabled:false,className:'submit-button',action:()=>handleModalCancel()}])
    setState( (_prev) => {
      return { ..._prev,selected: {data, action:type }}
       });
    setEditFieldsCess(updatedEditFields);
    }
  }
     function actionSets(props) {
      return (
        props.data?
        <div>
            <span title={"View"} className="margRight10" onClick={()=>handleAction("view",props.data)}><img  className="icon-size" alt={"view"} src={viewIcon} /></span>
            <span  title={"Edit"} className="margRight10" onClick={()=>handleAction("edit",props.data)}><img  className="icon-size" alt={"edit"} src={editIcon2} /></span>
        { ( props.data.status==="Rejected")?  <span  title={"Accept"} className="margRight10" onClick={()=>handleAction("accept",props.data)}><img  className="icon-size" alt={"accept"} src={confirmIcon} /></span>:<></>}
           {(props.data.status==="Accepted")?  <span  title={"Reject"} className="margRight10" onClick={()=>handleAction("reject",props.data)}><img  className="icon-size" alt={"reject"} src={cancelIcon} /></span>:<></>}
           {( props.data.status==="Rejected")? <span title={"Clarification"} onClick={()=>handleAction("clarfy",props.data)}><AiFillQuestionCircle size={19} style={{marginBottom:'6px',cursor:'pointer'}} /></span>:<></>}
        </div> : <div></div>
      );
    }



    const Columns = [
      {
        field: "warehouseName",
        headerName: "Warehouse",
        minWidth: 140,
        autoHeight: true,
        pinned: "left",

      },
      {
        field: "invoiceId",
        headerName: "Invoice ID",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 140,
        autoHeight: true,
      },
      {
        field: "catererGroup",
        headerName: "Caterer",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 140,
        autoHeight: true,
      },
      {
        field: "age",
        headerName: "Age",
        sortable: false,
        filter: false,
        minWidth: 110,
        flex:1,
        autoHeight: true,
        valueGetter: (params) => {
          if(params.data&&params.data.age){
            let ageLim=null
            if(params.data.age>=30){
              ageLim=Math.round(params.data.age/30);

              return ageLim+" month"
            }else if(params.data.age<=30){
            return params.data.age+ " days"
            }else{
              return null
            }


          }

        }

      },
      {
        field: "status",
        headerName: "Status",
        sortable: false,
        filter: false,
        flex:1,
        minWidth: 100,
        autoHeight: true,
        cellRendererSelector: (params) => {
          return {
            component: CateringStatusRenderSel,
          };
        },

      },

      {
        headerName:'Actions',
        pinned:'right',
          width:170,
          minWidth:150,
          resizable: false,
          flex:1,
          suppressSizeToFit:true, sortable: true,
          cellRenderer: actionSets,
          valueGetter: function(params) {
            return params.sectors;
          }
        }
    ];

    const downloadCaters=(data)=>{
      downloadFileCaterer(data)
    }

    const sectorTitle=()=>{
      const {catData}=state.selected;
        const excelSheetTitles = {};
        for (const key in catData) {
            const newKey = key.replace(/([A-Z])/g, " $1")
            .replace(/^./, function(key){ return key.toUpperCase(); })
            .replace(/\s+/g, ' ');
            excelSheetTitles[newKey] = catData[key];
        }

      return(<div className='flexRow justifyContentCenter'>
        <div className='flexRow'>
          <div className='flexRow'>Caterer Invoice<div className='instance-member-name'>{catData?.warehouseName}</div></div>
            <button className='small-body-text-regular fontExtrabold key-value btn-sync'>
            <img style={{margin:'0.1rem 0rem 0rem 0rem',width:'20px',height:'20px'}} className="icon-charts" src={DownloadVector} alt="Download icon" onClick={()=>downloadCaters(excelSheetTitles)} />
            </button>
        </div>
      </div>)
      }

  const onSectorChange = (e) => {
    setSalesFilter({ ...salesFilter, sectorVal: e.target.value });
    abortEarlierRequest("orderListAPI");
  };
  const onOrderByChange = (e) => {
    setSalesFilter({ ...salesFilter, orderStatusVal: e.target.value });
    abortEarlierRequest("orderListAPI");
  };
  const onSearch = (e) => {
    const searchTxt = e.target.value;
    setSearchKey(searchTxt);

    // if (searchTxt.length >= 3) {
    // setSalesFilter({ ...salesFilter, searchKey: searchKey });
    // }
    abortEarlierRequest("orderListAPI");
  };

  const onAction = (e, action, row) => {
    onActionInner(e, action, row);
  };

  const onActionInner = (e, action, row) => {

    ReactDOM.flushSync(() => {
      let updatedEditFields = [];

      if (action.value === "viewItems") {
        setModalTitle("Order No - " + row.orderNo);

        updatedEditFields = [
          {
            label: "View Order Details",
            type: "viewSalesDetails",
            attribute: "items",
          },
        ];
        updatedEditFields = updatedEditFields.filter((row) => {
          if (row.type === "viewSalesDetails") {
            row.value = row;
          }
          return row;
        });

        // setModalCustomButtons([[]]);
        setModalCustomButtons([
          /**
           * Buttons
           */
          {
            label: "Close",
            attribute: "button",
            type: "button",
            disabled: false,
            className: "close-button",
            action: (e) => {
              onClose();
              return false;
            },
          },
        ]);

        setEditFormFields([...updatedEditFields]);

        setState((_prev) => {
          return { ..._prev, selected: { ...row, action: action.value } };
        });
      } else if (action.value === "downloadReportPopup") {
        setState((_prev) => {
          return { ..._prev, selected: { ...row, action: action.value } };
        });
      } else if (action.value === "downloadInvoices") {

        // onDownloadPDFFile('downloads/invoice', 'order_invoice_' + row.id, { method: 'POST', payload: { orderId: row.id } } );

        const linkTxt = action.title;
        updateButtonTxtStatus(false, e, "...", true);
        APIEndpoint.post(
          "downloads/invoice",
          { orderId: row.id },
          { Accept: MIMETypes.pdf }
        )
          .then((resp) => {
            if (resp.status === "scheduled") {
              checkDownloadStatus(resp.id, e);
            } else {
              updateButtonTxtStatus(true, e, linkTxt, false);
            }
          })
          .catch((error) => {

            updateButtonTxtStatus(false, e, "Failed!", false, {
              defaultButtonText: linkTxt,
              className: "failedCls",
            });
          });
      }
    });
  };
  const handleModalCancel=()=>{
setState((prev)=>({...prev,selected:false}))
  }

  const onClose = () => {
    setState((_prev) => {
      return { ..._prev, selected: false };
    });
  };
const onAddInvoice=()=>{
  setState((prev)=>({...prev,dialogData:{title:"Add Invoice*"},selected:{action:"addInvoice"}}))
}

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>



       <div className="flexRow width100 margBot10 justifyContentSpaceBetween">
          <div className="flexRow width100 justifyContentSpaceBetween alignItemsCenter">
          <div className="flexRow width100 justifyContentFlexStart alignItemsCenter"><button className="sector-div m-2 margLeft">
               <select
                  className="select-item select-item-cater darkBlue-bg"
                  onChange={onSectorChange}
                  value={salesFilter.sectorVal}
                  id="sectorId"
                >
                  <option key="" value="">
                    Select Warehouse
                  </option>
                  {sectorList.map((_) => {
                    return (
                      <option key={_.value} value={_.value}>
                        {_.label}
                      </option>
                    );
                  })}
                </select>
              </button>



              <button className="orderStatus-div m-2">
                <select
                  className="select-item darkBlue-bg"
                 onChange={onOrderByChange}
                  value={salesFilter.orderStatusVal}
                  id="orderStatusId"
                >
                  <option key={""} value={""}>
                    Select Caterer
                  </option>
                  {caterer.map((_) => {
                    return (
                      <option key={_.key} value={_.value}>
                        {_.label}
                      </option>
                    );
                  })}
                </select>
              </button>
            </div>



            <div className="flexRow width100 alignItemsCenter">
            <div className="underline"> <span>
                <img
                  className="icon-size"
                  alt={t("filters.searchText")}
                  src={searchIcon}
                />
              </span>
              <span>
                <input
                  className="search"
                  onChange={onSearch}
                  type="search"
                  placeholder={t("filters.searchText") + " Caterer "}
                  value={searchKey}
                />
              </span></div>

            </div>
          { createcatInv? <div className="flexRow width100 justifyContentFlexEnd">
            <div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
                  <button className="add-item-button" onClick={onAddInvoice}>
                 + Add
                  </button>
              </div>
          </div>:<></>}

          </div>
        </div>
          <DataGrid
            // ref={tableRef}
            className="full-flex"
            columns={[...Columns]}
            initialPageLimit={perPageLimit}
            showDefaultFilters={false}
            searchKey={searchKey}
            // selItemType={selItemType}
            getRows={fetchOrders}
            rowSelection="multiple"

            onAction={(e, action, data) => {
              return onAction(e, action, data);
            }}
            columnResize={true}
            resetDataSource={isReloadTableData}
          />
        </>
      }

{state && Boolean(state.selected) &&state.selected?.action === "addInvoice"&& createcatInv && (
          <CreatecatererInvoice
            key={state.selected?.action}
            title={ `Create Invoice`}
            onClose={onClose}
            onSubmit={handleModalCancel}
            fields={ AddFormFields.map( (elt) => {
                  return {...elt, initialValue:""}
                })
            }
          />
        )
     }
     {state && Boolean(state.selected) &&state.selected?.action === "view"&&  (


          <Modal
          className='cash-deposit-summary'
          key={state.selected?.action}
            title={ ``}
            onClose={onClose}
            TitleComponent={sectorTitle}
            onSubmit={handleModalCancel}>
         <>
         <div className="flexRow alignItemsCenter"  id="caterer-table" style={{ width:'100%',overflow: "scroll" }}>
        {/* <img className="icon-charts" src={DownloadVector} alt="Download icon" onClick={onBtExport}/> */}

        <table>
          <thead>
            <tr style={{width:'100%'}} className='sticky'>
            <th >Warehouse</th>
              <th>Caterer Name</th>
              <th>Address</th>
              <th>GSTIN</th>
              <th>Invoice Number</th>
              <th>Value</th>
              <th>Payment Terms</th>
              <th>Date</th>
              <th>Sector</th>
              <th>Type of Meal</th>
              <th>Quantity</th>
              <th>Catering Group</th>
              <th>Subtotal</th>
              <th>Total</th>
              <th>Age</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {state && state.selected && state.selected?.items.map((_, index) => (
              <tr key={_.id}>
                <td>{_.warehouseName}</td>
                <td>{_.catererName}</td>
                <td>{_.address}</td>
                <td>{_.gstIn}</td>
                <td>{_.invoiceId}</td>
                <td>{_.value}</td>
                <td>{_.paymentTerms}</td>
                <td>{_.date}</td>
                <td>{_.sector}</td>
                <td>{_.typeOfMeal}</td>
                <td>{_.quantity}</td>
                <td>{_.catererGroup}</td>
                <td>{_.subTotal}</td>
                <td>{_.total}</td>
                <td>{_.age}</td>
                <td>{_.status}</td>
              </tr>
            ))}
          </tbody>
        </table>

      </div>
         </>
        </Modal>

        )
     }

{
        state && Boolean(state.selected)&& state.selected.action==='edit' ? (
          <EditModal
            key={state.selected.action}
            title={"Edit Caterer"}
            onClose={handleModalCancel}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={handleModalCancel}
            fields={EditFormFieldsInv.map((_) => {
              return { ..._, initialValue: _.attribute==='date'?new Date(state.selected[_.attribute]): state.selected[_.attribute] }
            })}
          />
        )
        : null
      }

{
        (state?.selected?.action==='accept' || state?.selected?.action==='reject'|| state?.selected?.action==='clarfy')&& Boolean(state?.selected) ? (
          <EditModal
            key={state.selected.action}
            title={ modalTitle }
            onClose={handleModalCancel}
            modalFormStatusObj={modalFormStatusObj}
            onSubmit={handleModalCancel}
            modalCustomButtons={modalCustomButtons}
            // onChange = {onFieldChangeHandler}
            fields={EditFieldsCess.map( (elt) => {
              return { ...elt, initialValue:state.selected[elt.attribute] }
              })
            }
          />
        )
        : null
      }

    </div>
  );
};