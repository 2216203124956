/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState } from 'react';
import { APIEndpoint } from "../../../utils/axios";
import {TooltipLabel} from '../../../common/TooltipLabel';
import { toolTipInfo } from '../../../utils/itemSel';
import { useTranslation } from 'react-i18next';
import {translationLength} from '../../../utils/commonFunctions';
import usePermissions from '../../../utils/userPermissions';
import moment from 'moment';
export const ConfigurationPage = (props) => {
  const { t } = useTranslation();
  const preCompile = {
    title: t('pages.configurationContent.title'),
    save:t('buttons.save')
  }

  const [configResp, setConfigResp] = useState({});
  const [configSet,setConfigSet]=useState(null);
  const [updated,setUpdated]=useState(false);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [show, setShow] = useState(false);
  const [state, setState] = useState({mswipeEnabled:false,mandatoryBillPrintPrompt:false,mandatoryScheduleService:false,autoConfirmUplift:false,ensureWarehouseStockDuringUplift:false,msg91Enabled:false})
  const { hasPermission: editSettings } = usePermissions('edit', "configuration.settings");
  const { hasPermission: viewSettings } = usePermissions('view', "configuration.settings");


  React.useEffect(() => {
    if(viewSettings){
      fetchConfig();
    }
  }, [viewSettings]);
  React.useEffect(() => {
    if(editSettings){
      setInputConfig();
    }
  }, [configResp,editSettings]);

  React.useEffect(() => {
    if(show){
        const timeId = setTimeout(() => {
        setShow(false);
      setModalFormStatusObj({ text: '' });
      if(modalFormStatusObj.text){

      }
      }, 3000)
  return () => {
        clearTimeout(timeId)
      }
    }
    }, [show]);

    let isError = false;
    let updateStatusText = '';

    if (modalFormStatusObj) {
      if (('text' in modalFormStatusObj)) {
        updateStatusText = modalFormStatusObj.text;
      }
      if (('error' in modalFormStatusObj)) {
        isError = modalFormStatusObj.error;
      }
    };
  const setInputConfig=()=>{
    setConfigSet(configResp);
    if(configResp){
      setState((_prev) => {
        return { ..._prev, mswipeEnabled:configResp.mswipeEnabled,mandatoryBillPrintPrompt:configResp.mandatoryBillPrintPrompt,mandatoryScheduleService:configResp.mandatoryScheduleService,autoConfirmUplift:configResp.autoConfirmUplift,ensureWarehouseStockDuringUplift:configResp.ensureWarehouseStockDuringUplift,msg91Enabled:configResp.msg91Enabled };
    });
    }
  }
  const fetchConfig = async() => {
        try {
           const resp = await APIEndpoint.get(`customer/app/config`);
           if(resp.success){
            const {result}=resp;
            setConfigSet(result);
            setConfigResp(result);
       }
           }
            catch (error) {
             
            }
     }

     const onChange = (e) => {
      const { name } = e.target;
      let newState = { ...state };
    
      if (editSettings) {
        switch (name) {
          case "mswipeEnabled":
            newState.mswipeEnabled = !state.mswipeEnabled;
            break;
          case "mandatoryBillPrintPrompt":
            newState.mandatoryBillPrintPrompt = !state.mandatoryBillPrintPrompt;
            break;
          case "mandatoryScheduleService":
            newState.mandatoryScheduleService = !state.mandatoryScheduleService;
            break;
          case "autoConfirmUplift":
            newState.autoConfirmUplift = !state.autoConfirmUplift;
            break;
          case "ensureWarehouseStockDuringUplift":
            newState.ensureWarehouseStockDuringUplift = !state.ensureWarehouseStockDuringUplift;
            break;
          case "msg91Enabled":
            newState.msg91Enabled = !state.msg91Enabled;
            break;
          default:
            break; 
        }
      }

      setState(newState);
    
      let newUpdates = configSet ? { ...configSet } : {};
      newUpdates[name] = e.target.value;
      setUpdated(true);
      setConfigSet(newUpdates);
    };
    
  const getDate=()=>{
    return moment().subtract(1, 'days').format('YYYY-MM-DD')+"T18:30:00.000Z"
  }

const onEditSubmit=async()=>{
  const date={startdate:getDate(),endDate:getDate()};
  let body
  if(!updated){
body={...configResp,...state,...date}
  }
  else{
    body={...configSet,...state,...date}
  }

try {
  const resp = await APIEndpoint.post(`customer/app/config`,body);
  if(resp.success){
    setModalFormStatusObj({ text: resp.message||'Configurations saved successfully' });
    setShow(true);
    setUpdated(false);
  }
} catch (error) {
  setModalFormStatusObj({ error: error.resp.message||'Error saving' });
    setShow(true);
}
  }

  return (
<div className='op-aircraft-container flexCol full-flex ag-theme-alpine-dark'>
<div className="overlay" role="dialog" >
<div className="dialog" style={{minWidth:'77vw',margin:'3%'}}>
<div className="dialog__content">
<h2 className="dialog__title subheading-text-medium fontMedium">{preCompile.title}</h2>
<hr />
<div style={{display:'flex',width:'60%',margin:'auto'}} class="flexCol justifyContentSpaceBetween flexWrap">
<div class="flexRow justifyContentSpaceBetween">
<div class=" dialog__container_item input-container">
<div class="dialog__description_config margTop11">
<label>Account Alias :<span><TooltipLabel content={toolTipInfo.accountAlias} direction="top">
      <span className="info-label" role="img">
      <div class="bg flexRow">
        <a href="#"><span className="info margBottom10">i</span></a>
      </div>
      </span>
    </TooltipLabel>
    </span> &nbsp;
    </label>

</div>
</div>




<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom18">
  <input
    id={''}
    name="accountAlias"
    onChange={(e)=>onChange(e)}
    value={configSet?configSet.accountAlias:""}
    type={'text'}
    className='edit-input-galley1'
    />
</div>
</div>



</div>




<div class="flexRow justifyContentSpaceBetween">
<div class=" dialog__container_item input-container">
<div class="dialog__description_config margTop11">
  <label >Carrier Code :</label>

</div>
</div>



<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom18">

  <input
    id={''}
    name='carrierCode'
    onChange={(e)=>onChange(e)}
    value={configSet?configSet.carrierCode:""}
    type={'text'}
    className='edit-input-galley1'
    />
</div>
</div>



</div>




<div class="flexRow justifyContentSpaceBetween">
<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom28">
  <label >mSwipe :</label>

</div>
</div>

<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label className="control control-radio" for="1">
  <input  id="1" type="radio"
    name="mswipeEnabled"
    value= {state.mswipeEnabled}
    checked={state.mswipeEnabled}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span className='margRight80'>Enable</span>

  <label className="control control-radio" for="2">
  <input  id="2" type="radio"
  name="mswipeEnabled"
  value={!state.mswipeEnabled}
  checked={!state.mswipeEnabled}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span>Disable</span>

  </div>
  </>
</div>
</div>




</div>

<div class="flexRow justifyContentSpaceBetween">
<div class=" dialog__container_item input-container">
<div class="dialog__description_config margTop11">
  <label >GSTIN :</label>

</div>
</div>



<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom18">
  <input  id={''}
    name='tin'
    onChange={(e)=>onChange(e)}
    value={configSet?configSet.tin:""}
    type={'text'}
    className='edit-input-galley1' />
</div>
</div>






</div>

{configSet?.timezone && (
  <div class="flexRow justifyContentSpaceBetween">
  <div class=" dialog__container_item input-container">
    <div class="dialog__description_config margTop11">
      <label>Timezone :</label>
    </div>
  </div>

  <div class=" dialog__container_item input-container">
    <div class="dialog__description_config margBottom18">
      <input
        id={""}
        name="tin"
        value={configSet ? configSet.timezone?.formattedName : ""}
        type={"text"}
        className="edit-input-galley1"
        disabled
      />
    </div>
  </div>
</div>
)}


<div class="flexRow justifyContentSpaceBetween">
<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom28">

    <label>Mandatory Bill Print Prompt : <span><TooltipLabel content={toolTipInfo.mandBillPrompt} direction="top">
  <span className="info-label" role="img">
      <div class="bg">
        <a href="#"><span className="info">i</span></a>
          </div>
      </span>
    </TooltipLabel></span>
    </label>

</div>
</div>


<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label  className="control control-radio" for="3">
  <input  id="3" type="radio"
    name="mandatoryBillPrintPrompt"
    value= {state.mandatoryBillPrintPrompt}
    checked={state.mandatoryBillPrintPrompt}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span className="margRight80">Enable</span>

  <label  className="control control-radio" for="4">
  <input  id="4" type="radio"
  name="mandatoryBillPrintPrompt"
  value={!state.mandatoryBillPrintPrompt}
  checked={!state.mandatoryBillPrintPrompt}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span>Disable</span>

  </div>
  </>
</div>
</div>






</div>

<div class="flexRow justifyContentSpaceBetween">
<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom28">
    <label>Schedule service mandatory :<span><TooltipLabel content={toolTipInfo.schServMand} direction="top">
  <span className="info-label" role="img">
      <div class="bg">
        <a href="#"><span className="info">i</span></a>
          </div>
      </span>
    </TooltipLabel></span>
    </label>
</div>
</div>

<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label  className="control control-radio" for="5">
  <input  id="5" type="radio"
    name="mandatoryScheduleService"
    value= {state.mandatoryScheduleService}
    checked={state.mandatoryScheduleService}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span className="margRight80">Enable</span>

  <label  className="control control-radio" for="6">
  <input  id="6" type="radio"
  name="mandatoryScheduleService"
  value={!state.mandatoryScheduleService}
  checked={!state.mandatoryScheduleService}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span>Disable</span>

  </div>
  </>
</div>
</div>








</div>

<div class="flexRow justifyContentSpaceBetween">
<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom28">
    <label>Auto-Confirm Uplift : <span><TooltipLabel content={toolTipInfo.autoConfUplift} direction="top">
  <span className="info-label" role="img">
      <div class="bg">
        <a href="#"><span className="info">i</span></a>
          </div>
      </span>
    </TooltipLabel></span>
    </label>
</div>
</div>



<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label  className="control control-radio" for="7">
  <input  id="7" type="radio"
    name="autoConfirmUplift"
    value= {state.autoConfirmUplift}
    checked={state.autoConfirmUplift}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span className="margRight80">Enable</span>

  <label  className="control control-radio" for="8">
  <input  id="8" type="radio"
  name="autoConfirmUplift"
  value={!state.autoConfirmUplift}
  checked={!state.autoConfirmUplift}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span>Disable</span>
  </div>
  </>
</div>
</div>
</div>


<div class="flexRow justifyContentSpaceBetween">
<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom28">
<>
    <label>Ensure Stock In Warehouse During Uplift : <span><TooltipLabel content={toolTipInfo.ensStockDuringUplift} direction="top">
      <span className="info-label" role="img">
      <div class="bg">
        <a href="#"><span className="info">i</span></a>
          </div>
      </span>
    </TooltipLabel></span>
    </label>
  </>
</div>
</div>


<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom18">

  <>
  <div className={`dialog__description__radio`}>
  <label  className="control control-radio" for="9">
  <input  id="9" type="radio"
    name="ensureWarehouseStockDuringUplift"
    value= {state.ensureWarehouseStockDuringUplift}
    checked={state.ensureWarehouseStockDuringUplift}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span className="margRight80">Enable</span>

  <label  className="control control-radio" for="10">
  <input  id="10" type="radio"
  name="ensureWarehouseStockDuringUplift"
  value={!state.ensureWarehouseStockDuringUplift}
  checked={!state.ensureWarehouseStockDuringUplift}
  onChange={onChange} />
  <div class="control-indicator"></div>
  </label>
  <span>Disable</span>

  </div>
  </>
</div>
</div>







</div>

<div class="flexRow justifyContentSpaceBetween">
<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom28">
  <label >MSG91 :</label>

</div>
</div>



<div class=" dialog__container_item input-container">
<div class="dialog__description_config margBottom18">
  <>
  <div className={`dialog__description__radio`}>
    <label  className="control control-radio" for="11">
    <input  id="11" type="radio"
    name="msg91Enabled"
    value= {state.msg91Enabled}
    checked={state.msg91Enabled}
    onChange={onChange} />
  <div class="control-indicator"></div>
    </label>
    <span className="margRight80">Enable</span>

    <label  className="control control-radio" for="12">
    <input  id="12" type="radio"
    name="msg91Enabled"
    value={!state.msg91Enabled}
    checked={!state.msg91Enabled}
    onChange={onChange} />
  <div class="control-indicator"></div>
    </label>
    <span>Disable</span>
  </div>
  </>
</div>
</div>




</div>












</div>
{updateStatusText.length>0&&show ?
<>
<div className={ "dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
{updateStatusText}
</div>
</> : null }
</div>
{editSettings?<div className="dialog__footer flexCol alignItemsCenter">
<button className="done-button" onClick={onEditSubmit}>{props.title === 'Edit Item' ? 'Update' : 'Save'}</button>
</div>:<></>}
</div>
{
t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
}
</div></div>

);
}