/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { useParams, useNavigate,useLocation } from 'react-router-dom';
import ReactSpreadsheet from "react-spreadsheet"; // , { createEmptyMatrix }
import * as ReactDOM from 'react-dom';
import { APIEndpoint } from "../../../../utils/axios";
import { FaRegCheckSquare ,FaSave} from "react-icons/fa";
import { BiSolidSelectMultiple } from "react-icons/bi";
import {AlertModal } from "../../../../common";
import { RiDeleteBin6Line } from "react-icons/ri";
import { updateButtonTxtStatus, validateCellAndGetData } from "../../../../utils/commonFunctions";
import { DEFAULT_SHEET_ROWS_PLIFERAGE } from "../../../../utils/configs";
import { useTranslation } from "react-i18next";
import close from "../../../../images/icons/Icon2.png"


const iIndexes = {
    ITEM_CODE: 0,
    ITEM_NAME: 1,
    ITEM_QTY: 2,
    ITEM_UNIT: 3,
    ITEM_REMARKS: 4
};
const sheetLabels = ['Item Code', 'Item Name', 'Quantity', 'Unit', 'Remarks'];


const defaultCellFieldData = {
    id: 'id',
    value: '',
    defaultValue: '',
    className: 'cellClass',
    width: '200px',
    readOnly: false,
    type: 'string',
    required: true,
    showPopup: false
};

const createEmptySheetRows = (addtionalRows=0,noOfRows = DEFAULT_SHEET_ROWS_PLIFERAGE) => {
    const rows = [];
    if(addtionalRows>0){
        i=0;
        while(i<10) {
            rows.push([
                {
                    ...defaultCellFieldData,
                    id: 'itemCode',
                    className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.ITEM_CODE,
                    showPopup: true
                },
                {
                    ...defaultCellFieldData,
                    id: 'itemName',
                    className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.ITEM_NAME,
                    showPopup: true
                },
                {
                    ...defaultCellFieldData,
                    id: 'quantity',
                    className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.ITEM_QTY,
                    width: '400px',
                    required:true,
                    readOnly: false
                },

                {
                    ...defaultCellFieldData,
                    id: 'unit',
                    showPopup: true,
                    className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.ITEM_UNIT,
                    readOnly: true,
                    required: true
                },
                {
                    ...defaultCellFieldData,
                    id: 'remarks',
                    required:false,
                    readOnly:false,
                    className: 'cellClass cellClass_' + addtionalRows + '_' + iIndexes.ITEM_REMARKS,
                },

            ]);
            i++;
            addtionalRows++;
        }

    }else{
        for( var i=0; i < noOfRows; i++) {
            rows.push([
                {
                    ...defaultCellFieldData,
                    id: 'itemCode',
                    className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_CODE,
                    showPopup: true
                },
                {
                    ...defaultCellFieldData,
                    id: 'itemName',
                    className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_NAME,
                    showPopup: true
                },
                {
                    ...defaultCellFieldData,
                    id: 'quantity',
                    type:'number',
                    className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_QTY,
                    width: '400px',
                    required:true,
                    readOnly: false,
                },
                {
                    ...defaultCellFieldData,
                    id: 'unit',
                    className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_UNIT,
                    readOnly: true,
                },
                {
                    ...defaultCellFieldData,
                    id: 'remarks',
                    required:false,
                    readOnly:false,
                    className: 'cellClass cellClass_' + i + '_' + iIndexes.REMARKS,
                },

            ]);
        }
    }

    return rows;
}

/**
 * Automatically creating rows
 */
// const initialData = createEmptyMatrix(10, sheetLabels.length);
/**
 * Manually creating rows
 */
const initialData = createEmptySheetRows(null, 10);

export const OffloadReportCreate = (props) => {
    const urlParams = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const { mode,data,docId,res } = location.state || {};
    const { t } = useTranslation();
    const {id,name}=urlParams;
    const [warehouseItemsFull, setWarehouseItemsFull] = useState([]);
    const [warehouseItemsFiltered, setWarehouseItemsFiltered] = useState([]);
    const [editTransferDetails, setEditTransferDetails] = useState({});
    const [state, setState] = useState({ isInvalidAccess: false, message: '', isEditForm: false });
    const [sheetData, setSheetData] = useState(initialData);
    const [spreadsheetErrors, setSpreadsheetErrors] = useState([]);
    const [errorDetails,setErrorDetails]=useState([]);
    const modelStyle = { maxWidth: '50%', margin: 'auto 10% 100% 10%' };
    const [activeCell, setActiveCell] = useState({});
    const [selectedCellsArr, setSelectedCellsArr] = useState([]);
    const [count, setCount] = useState(1);
    const targetEltCodes = document.getElementsByClassName("cellSelectionBlockItemCodes")[0];
    const targetEltNames = document.getElementsByClassName("cellSelectionBlockItemNames")[0];
    const targetEltReasonTypes = document.getElementsByClassName("cellSelectionBlockReasonTypes")[0];

    useEffect(() => {
        let timeout = setTimeout(() => {
          const errorCells = document.querySelectorAll(".errorCellData");
          if (errorCells.length === 0) return;
          errorCells.forEach((cell, i) => {
            const rowIndex = [...cell.classList][3].split("_")[1];
            const error = errorDetails.find((err) => err.row == rowIndex);
            if (!cell.querySelector(".sheetErrDiv") && error) {
              const errorDiv = document.createElement("div");
              errorDiv.classList.add("sheetErrDiv");
              errorDiv.textContent = error?.message;
              cell.appendChild(errorDiv);
            }
          });
        }, 100);
        return () => {
          clearTimeout(timeout);
        };
      }, [errorDetails, sheetData, activeCell]);

      useEffect(() => {
        let timeout = setTimeout(() => {
            const errorCells = document.querySelectorAll(".errorCellData");
            if (errorCells.length === 0) return;
            errorCells.forEach((cell, i) => {
                cell.scrollIntoView({behavior:"smooth"})
            });
          }, 1000);
          return () => {
            clearTimeout(timeout);
          };
      }, [errorDetails])

    useEffect(() => {

      if (mode ==='createOffloads'||mode ==='createWastages'){
        getWarehouseItems();
        setState( { ...state, isEditForm: false } );
        }
        else{
            setEditTransferDetails(res.inventoryReport)
            setState((_)=>({..._,isEditForm:true}))
           getWarehouseItems();
    }

    }, [ urlParams,mode ]);

    useEffect( () => {
        if (editTransferDetails?.items && editTransferDetails?.items.length) {
           const options=[];
            editTransferDetails?.items.filter((item,i)=>{
                options.push([
                    {
                        id: 'itemCode',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_CODE,
                        value:item.itemCode,
                        showPopup: true,
                        required:true,
                        readOnly:false
                    },
                    {
                        id: 'itemName',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_NAME,
                        value:item.itemName,
                        showPopup: true,
                        required:true,
                        readOnly:false
                    },
                    {
                        id: 'quantity',
                        type:'number',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_QTY,
                        width: '400px',
                        value:item.quantity,
                        showPopup: false,
                         required:true,
                        readOnly:false
                    },

                    {
                        id: 'unit',
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.ITEM_UNIT,
                        width: '400px',
                        value:item.unit,
                        required:true,
                        readOnly: true,
                        showPopup: false,
                    },

                  {
                        id: 'remarks',
                        required:false,
                        readOnly:false,
                        value:item.remarks,
                        showPopup: false,
                        className: 'cellClass cellClass_' + i + '_' + iIndexes.REMARKS,
                    },

                ])
            })

if(options){
    setEditTransferItemsToSheet(editTransferDetails,options);
    const newRows = createEmptySheetRows(null,10);
    setSheetData( [...options,...newRows.slice(options.length)]);
}
        }
    }, [editTransferDetails]);

useEffect(() => {
    if (errorDetails.length) {
        const updatedSheetData = sheetData.map((row, rowIndex) => {
            return row.map((cell, colIndex) => {
                const error = errorDetails.find(err => parseInt(err.row) === rowIndex);
                if (error && Object.values(cell).includes(error.field)) {
                    return {
                        ...cell,
                        className: `errorCellData cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                }
                return { ...cell };
            });
        });
        setSheetData(updatedSheetData);
    }
}, [errorDetails]);


    const checkSheetData=()=>{
        let dataCheck=false;
        sheetData?.map(upl=>{
            upl?.forEach((val,ind)=>{
                if(val&&val?.value){
                    dataCheck=true
                }
            })

        })
        return dataCheck
    }



    const getWarehouseItems = async() => {

        try {
            APIEndpoint.get(`sandboxing/editDocuments/${docId}/sectors/${data.id}/inventoryItems`)
            .then( resp => {
                if (resp) {
                    const warehouseItems = resp.items;
                    setWarehouseItemsFull(warehouseItems);
                    setWarehouseItemsFiltered(warehouseItems);
                }
            })
            .catch( error => {

                setState({ ...state, isInvalidAccess: true,
                    message: "Invalid page access!, Inventory Items are not found, On click OK, you will be redirected to crew report details page." });
            });
        } catch (error) {

        }


    };

    const setEditTransferItemsToSheet = (transferDetails,options) => {
        let jsonSpreadsheetData = JSON.stringify( options );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData)
            const items = transferDetails.items;
        if(transferDetails.items &&updatedSpreadsheetData.length){
            items.map( (item, i) => {
                if (updatedSpreadsheetData[i]) {
                    updatedSpreadsheetData[i][iIndexes.ITEM_CODE].value = items[i].itemCode;
                    updatedSpreadsheetData[i][iIndexes.ITEM_NAME].value = items[i].itemName;
                    updatedSpreadsheetData[i][iIndexes.ITEM_QTY].value = parseInt(items[i].quantity);
                    updatedSpreadsheetData[i][iIndexes.ITEM_UNIT].value = items[i].unit;
                    updatedSpreadsheetData[i][iIndexes.ITEM_REMARKS].value = items[i].remarks;
                    return item;
        }});
        }

        ReactDOM.flushSync( () => {
           let newRows=createEmptySheetRows(null,10);
             setSheetData( [ ...updatedSpreadsheetData,...newRows.slice(sheetData.length) ]);

        });

    };


    const addRowsToSheet = (rowsToAdd = 10) => {
        const newRows = createEmptySheetRows(sheetData.length,rowsToAdd);
        setSheetData( [...sheetData, ...newRows ]);
        setWarehouseItemsFiltered( [...warehouseItemsFiltered, ...newRows ]);
    };

    const removeRow = (e) => {
        if(count%2===0){
            let updatedSheetData = initialData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
            setSheetData(updatedSheetData);
        }else{
      let jsonSpreadsheetData = JSON.stringify( sheetData );
        let inSheetData = JSON.parse(jsonSpreadsheetData);
        let selectedRowIndex = activeCell.row
        if(selectedRowIndex){
         let updatedSheetData= inSheetData.splice(selectedRowIndex,1).map((row, rowIndex) => {
                return row.filter((cell, colIndex) =>cell.value!=='');
            })
            setSheetData([...updatedSheetData]);

        }else{
            inSheetData.pop()
        }

        if(inSheetData.length > selectedRowIndex){

            selectedCellsArr.map( selectedCell => {
                const defaultVal = inSheetData[selectedCell.row][selectedCell.column].defaultValue;

                inSheetData[selectedCell.row][selectedCell.column].value = defaultVal || '';

                return selectedCell;
            });

        }
        ReactDOM.flushSync( () => {
            setSheetData([ ...inSheetData ]);
            setActiveCell({});
        });

		setSelectedCellsArr([]);
        }


    };

    const showValidationErrors = (e, inSheetData, errorClass='cellDataErrorCls' ) => {
        let haveErrors = false;
        const postItems = [];

        inSheetData.map( (cellRow, rowIdx) => {
            let isRowHaveError = false;
            const itemCode = validateCellAndGetData(cellRow[iIndexes.ITEM_CODE],true);
            const itemName = validateCellAndGetData(cellRow[iIndexes.ITEM_NAME],true);
            if (!itemCode || !itemName) {
                return cellRow;
            }

            for (let colIdx = 0; colIdx < Object.keys(iIndexes).length; colIdx++) {
                if ( cellRow[colIdx].required === true) {
                    const fieldValue = validateCellAndGetData(cellRow[colIdx],false);
                    if (!fieldValue.length &&(typeof fieldValue ==='string' && !fieldValue.length) ) {
                        haveErrors = true;
                        isRowHaveError = true;
                    }
                }
            }
            if (!isRowHaveError && warehouseItemsFull.length ) {
                const item=warehouseItemsFull?.find((row, rowIndex) =>row.itemCode.includes(cellRow[iIndexes.ITEM_CODE].value))
                if (item) {
                    postItems.push({
                        _id: item._id,
                        index: rowIdx,
                        itemId: item.itemId,
                        itemCode: item.itemCode, // cellRow[iIndexes.ITEM_CODE].value,
                        itemName: item.itemName, // cellRow[iIndexes.ITEM_NAME].value,
                        quantity: parseInt(cellRow[iIndexes.ITEM_QTY].value),
                        unit: item.unit, // parseInt(cellRow[iIndexes.ITEM_AVAILABLE_STOCK].value),
                        remarks: cellRow[iIndexes.ITEM_REMARKS].value,
                    });
                }

            }
            return cellRow;
        });

        if (haveErrors) {
            updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' } );
            setSpreadsheetErrors([ { message: 'Please fill all required item details.' } ]);
        }

        return {
            sheetData: inSheetData,
            validRows: postItems,
            haveErrors
        };

    };
    const saveSheetData = async (e) => {
        try {
            updateButtonTxtStatus(true, e, 'Saving...', true);
            setSpreadsheetErrors([]);

            let inSheetData = [...sheetData];
            const respArrayData = showValidationErrors(e, inSheetData);

            if (!spreadsheetErrors.length && respArrayData.validRows.length === 0 && !respArrayData.haveErrors) {
                // show global alert message
                updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' });
                setSpreadsheetErrors([...spreadsheetErrors, { message: 'We did not find anything to save!' }]);
                return false;
            }

            if (respArrayData.haveErrors) {
                updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' });
                return false;
            }

            if (!respArrayData.validRows.length) {
                updateButtonTxtStatus(true, e, 'Save', false, { defaultButtonText: 'Save' });
                return false;
            }

            const isEditForm = state.isEditForm === true;
            const isCreateOffloads = mode === 'createOffloads';
            const isCreateWastages = mode === 'createWastages';
            const isEditWastages = mode === 'editWastages';

            const reportName = isCreateWastages || isEditWastages ? "UpdateWastageReport" : "UpdateOffloadReport";

            const reportId = (isEditForm && !isCreateOffloads && !isCreateWastages)
                ? (isEditWastages ? data.wastageReport.id : data.offloadReport.id)
                : null;

            let payload = {
                "isolateId": id,
                "documentId": docId,
                "edits": [
                    {
                        "name": reportName,
                        "params": {
                            "target": {
                                "id": data.id,
                                "key": data.key,
                                "name": data.name
                            },
                            "reportId": reportId,
                            "items": respArrayData.validRows || []
                        }
                    }
                ]
            };


            const apiResp = await APIEndpoint.post('sandboxing/containerIsolates/editDocument/edits', payload);


            setSheetData(initialData);
            updateButtonTxtStatus(true, e, 'Redirecting...', true, { defaultButtonText: 'Save' });
            setErrorDetails([]);
            setSpreadsheetErrors([...spreadsheetErrors, { message: apiResp.msg || apiResp.message || `Successfully ${mode === 'createOffloads' ? 'created offload' : mode === 'editOffloads'?'edited offload':mode === 'createWastages'?'created wastage' : mode === 'editWastages'?'edited wastage':''} report.` }]);

            setTimeout(() => {
                goToModuleHome();
            }, 1500);

        } catch (error) {
            const apiData = JSON.parse(error.message);
            const { data } = apiData;

            setSpreadsheetErrors([]);
            updateButtonTxtStatus(false, e, 'Failed to save...', true, { defaultButtonText: 'Save' });

            ReactDOM.flushSync(() => {
                if (data && data.errors) {
                    const errorMsg = data.errors.message || "Something went wrong, please try again later!";
                    setSpreadsheetErrors([...spreadsheetErrors, { message: errorMsg, error: 1 }]);
                    setErrorDetails(data.errors.errors || []);
                    updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save' });
                } else if (data && data?.message) {
                    setSpreadsheetErrors([{ message: data?.message, error: 1 }]);
                } else {
                    setSpreadsheetErrors([...spreadsheetErrors, { message: 'Something went wrong, please try again later!', error: 1 }]);
                    updateButtonTxtStatus(false, e, 'Please wait...', true, { defaultButtonText: 'Save' });
                }
            });
        }

        return false;
    };

    const selectAllCells = (e) => {
        setCount((prev)=>prev+1)
        let updatedSheetData=[]
        if(count%2!==0){
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `selectcell-class cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }else{
            updatedSheetData = sheetData.map((row, rowIndex) => {
                return row.map((cell, colIndex) => {
                    return {
                        ...cell,
                        className: `cellClass cellClass_${rowIndex}_${colIndex}`
                    };
                });
            });
        }

    setSheetData(updatedSheetData);

    };


    const handleActiveCellChange = (activeCell) => {
        calculateBacktoWh()
        setActiveCell({});
        hidePopupList();
        setActiveCell(activeCell);
        if ( sheetData[activeCell.row][activeCell.column] ) {
            const cellDetailsObj = sheetData[activeCell.row][activeCell.column];
            if ( cellDetailsObj.hasOwnProperty('showPopup') && cellDetailsObj.showPopup === true ) {

                const sourceElt = document.getElementsByClassName('cellClass_' + activeCell.row + '_' + activeCell.column)[0];
                if ( sourceElt ) {
                    const eltPosition = sourceElt.getBoundingClientRect();
                    showItemListPopup(eltPosition, activeCell);
                }
            }
        }
    }
    const handleCellBlur = () => {
        // setActiveCell({});
    }

    const handleCellKeyDown = (keyboardEvent) => {

        const searchText = keyboardEvent.target.value;
        if (activeCell && Object.keys(activeCell).length > 0) {
            if (sheetData[activeCell.row][activeCell.column].type === 'number' &&  keyboardEvent.target.value ) {
                keyboardEvent.target.value = keyboardEvent.target.value.replace(/[^0-9]$/g, '');
            }
        }

        // const eltPosition = keyboardEvent.target.getBoundingClientRect();
        // showItemListPopup(eltPosition);

        /**
         * Filter poup items
         */
        const searchPattern = new RegExp((searchText || '').replace(/[.*+?^=!:${}()|\[\]\/\\]/g, '\\$&'),'gi');
        const filteredList = warehouseItemsFull.filter( row => row.itemCode.search( searchPattern ) !== -1 );
        setWarehouseItemsFiltered([ ...filteredList ]);
    }

    const showItemListPopup = (eltPosition, activeCellIn) => {
        if (activeCellIn.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'block';
                targetEltCodes.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltCodes.style.left = eltPosition.left.toFixed() + 'px';
            }
        } else if (activeCellIn.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'block';
                targetEltNames.style.top = ( eltPosition.height + eltPosition.top + 2 ).toFixed() + 'px';
                targetEltNames.style.left = eltPosition.left.toFixed() + 'px';
            }
        }
    }

    const hidePopupList = () =>{
        // if (activeCell.column === iIndexes.ITEM_CODE) {
            if (targetEltCodes) {
                targetEltCodes.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if (targetEltNames) {
                targetEltNames.style.display = 'none';
            }
        // } else if (activeCell.column === iIndexes.ITEM_NAME) {
            if ( targetEltReasonTypes ) {
                targetEltReasonTypes.style.display = 'none';
            }
        // }
    }

    const onClickPopupListItem = (e, row, type='reason') => {

        if (type === 'reason') {
            setReasonField(row);
        }
        else {
            setWarehouseTransferItemToSheet(row);
        }
    }

    const setReasonField = (reason) => {
        if (Object.keys(reason).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_REASON].value = reason.label;
            }

            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            setSheetData( [ ...updatedSpreadsheetData ]);
        });

        setActiveCell({});
        hidePopupList();
    }

    const setWarehouseTransferItemToSheet = (item) => {
        if (Object.keys(item).length === 0) {
            return 0;
        }

        let jsonSpreadsheetData = JSON.stringify( sheetData );
        let updatedSpreadsheetData = JSON.parse(jsonSpreadsheetData);

        updatedSpreadsheetData.map( (sheetRow, i) => {
            if (i === activeCell.row) {
                sheetRow[iIndexes.ITEM_CODE].value = item.itemCode;
                sheetRow[iIndexes.ITEM_NAME].value = item.itemName;
                sheetRow[iIndexes.ITEM_QTY].value = item.quantity;
                sheetRow[iIndexes.ITEM_UNIT].value = item.unit;
                sheetRow[iIndexes.ITEM_REMARKS].value = item.remarks;
            }

            return sheetRow;
        } );


        ReactDOM.flushSync( () => {
            let newRows=createEmptySheetRows(null,10);
         // setSheetData( [...sheetData,...newRows.slice(sheetData.length)]);

              setSheetData( [ ...updatedSpreadsheetData,...newRows.slice(sheetData.length) ]);

         });

        setActiveCell({});
        hidePopupList();
    }

    const handleCellSelect = (selectedCell) => {
        if (selectedCell.length !== 0) {
            setSelectedCellsArr([ ...selectedCell ]);
        }
    };
    const onCellCommitHandle = (prevCell, nextCell, coordinates) => {
    }
    const goToModuleHome = () => {
        const encodedName = encodeURIComponent(name);
        navigate(`/sales/dynamic/sector/containerIsolate/${encodedName}/${id}`,{ state:{orderIdOffload:data.id}});
    };
    const handleOnContinue = async () => {
        goToModuleHome();
    }

    function calculateBacktoWh(){
        const updatedSheetData = sheetData.map((data,rowIndex) => {
            const singleData = data.map((item, index) => {
                if (index === 8) {
                    if(Number(data[3].value) === 0){
                        return {...item}
                    }
                    const backWh = Number(data[3].value) - (
                        Number(data[4].value) +
                        Number(data[5].value) +
                        Number(data[6].value) +
                        Number(data[7].value)
                    );
                    if(backWh < 0){
                        return { ...item, value: backWh,className:`cellClass cellClass_${rowIndex}_${index} bhNegetive` };
                    }
                    return { ...item, value: backWh,className:`cellClass cellClass_${rowIndex}_${index}` };
                }
                return { ...item };
            });

            return [...singleData];
        });
        const sheetDataOld = JSON.stringify(sheetData)
        const sheetDataNew = JSON.stringify(updatedSheetData)

        if(sheetDataOld === sheetDataNew){

        }else{
            setSheetData([...updatedSheetData])
        }
    }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                <div className={ "flexRow alignItemsCenter margBot10 justifyContentSpaceBetween" } >
                <div className="leftBlock alignItemsCenter flexRow">
                            <button onClick={ (e) => saveSheetData(e) }
                                className="alignItemsCenter add-item-button"><FaSave style={{marginRight:'5px'}}/>{ t('buttons.save') }</button> &nbsp;
                                 {checkSheetData()?<><button onClick={ (e) => selectAllCells(e) }
                                className="alignItemsCenter flexRow add-item-button-all m-3">{count%2===0?<BiSolidSelectMultiple  style={{marginRight:'5px'}} />:<FaRegCheckSquare  style={{marginRight:'5px'}} />}Select All</button>   <button onClick={ (e) => removeRow(e) }
                                className="alignItemsCenter flexRow remove-button"><RiDeleteBin6Line style={{marginRight:'5px'}} />{ t('buttons.deleteRows') }</button></>:<></> }&nbsp;

                        </div>
                       {spreadsheetErrors.length >= 1? <div className={`padding4 margTop9-5 alert alert-${spreadsheetErrors[0].message.includes('Successfully') ? 'success' : 'danger'}`}>
                        {spreadsheetErrors.length >= 1 ? spreadsheetErrors[0]?.message : null}
                        </div>:null}
                        <button onClick={goToModuleHome}>
                            <img src={close} alt="close" />
                        </button>
                </div>
                <div className="sheet-container sm-scrollbar">
                    <div className="se-titlebar">
                    <span>
  {mode === 'createOffloads'
    ? `New Offload Report for - ${data.name}`
    : mode === 'editOffloads'
    ? `Edit Offload Report for - ${data.name}`
    : mode === 'createWastages'
    ? `New Wastage Report for - ${data.name}`
    : mode === 'editWastages'
    ? `Edit Wastage Report for - ${data.name}`
    : ''
  }
</span>

                   </div>


            <><ReactSpreadsheet
                className='spread-sheet'
                data={sheetData}
                columnLabels={sheetLabels}
                darkMode={true}
                onChange={setSheetData}
                onActivate={handleActiveCellChange}
                onBlur={handleCellBlur}
                onKeyDown={handleCellKeyDown}
                onSelect={handleCellSelect}
                cellClassName="custom-cell"
                onCellCommit={onCellCommitHandle}
                /></>
                    <div className="flexRow alignItemsCenter margTop8">
                        <button onClick={ () => addRowsToSheet() }
                            className="add-item-button" title={ t('spreadsheet.addmoreRows') }>{ t('buttons.addRows') }</button>
                    </div>

                </div>
                <div className = "cellSelectionBlockItemCodes sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row, 'item') }>{ row.itemCode }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>
                <div className = "cellSelectionBlockItemNames sm-scrollbar">
                {
                    warehouseItemsFiltered.length ? (
                    <ul>
                        {warehouseItemsFiltered.length && warehouseItemsFiltered.map( (row, i) => (
                            <li key={ i } onClick={ (e) => onClickPopupListItem(e, row, 'item') }>{ row.itemName }</li>
                        ) )
                        }
                    </ul>
                    )
                    : null
                }
                </div>

                {
                    Boolean(state.isInvalidAccess) && (
                    <AlertModal
                        title={'Alert: Invalid Access'}
                        customModalStyle={ modelStyle }
                        description={ state.message }
                        onContinue={handleOnContinue}
                        onClose={handleOnContinue}
                    />
                    )
                }

            </div>
        </>
    );
}