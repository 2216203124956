import React, { useState } from 'react';
import { DataGrid, EditModal, UploadButton, UploadModal, DownloadButton } from "../../../common";
import { onDownloadXlsFile, translationLength,fetchCulture } from '../../../utils/commonFunctions';
import { APIEndpoint} from '../../../utils/axios';
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import usePermissions from '../../../utils/userPermissions';
const Columns = [
  { field: 'code', headerName: 'Vendor Code', sortable: true, filter: true, minWidth: 120,flex:1, suppressSizeToFit:true },
  { field: 'name', headerName: 'Name', tooltipField: 'name',flex:1, suppressSizeToFit:true,  valueGetter: (params) => {
    if (params.data&&params.data.name) {
       return params.data.name.length>21? params.data.name.slice(0,22)+"...": params.data.name;
    }
    return null;
  }, sortable: true, filter: true, minWidth: 190 },
  { field: 'name2', headerName: 'Name 2', tooltipField: 'name2',flex:1, suppressSizeToFit:true, valueGetter: (params) => {
    if (params.data&&params.data.name2) {
       return params.data.name2.length>21? params.data.name2.slice(0,22)+"...": params.data.name2;
    }
    return null;
  },sortable: true, filter: true, minWidth: 190 },
  { field: 'sapVendorCode', headerName: 'SAP Vendor Code', sortable: true, filter: true, minWidth: 160 ,flex:1, suppressSizeToFit:true,},
  { field: 'mobile', headerName: 'Mobile', sortable: true, filter: true, minWidth: 160, flex:1, suppressSizeToFit:true, },
  { field: 'email', headerName: 'Email', sortable: true, filter: true, minWidth: 200,
    tooltipField: 'email',
    tooltipComponentParams: { field: 'email' },
    tooltipComponent: 'customTooltip',flex:1, suppressSizeToFit:true,
    valueGetter: (params) => {
      if (params.data&&params.data.email) {
         return params.data.email.length>21? params.data.email.slice(0,22)+"...": params.data.email;
      }
      return null;
    },
  },
  { field: 'address', headerName: 'Address', sortable: true, filter: true, minWidth: 200,
    tooltipField: 'address',flex:1, suppressSizeToFit:true,
    tooltipComponentParams: { field: 'address' },
    tooltipComponent: 'customTooltip',
     valueGetter: (params) => {
      if (params.data&&params.data.address) {
         return params.data.address.length>21? params.data.address.slice(0,22)+"...": params.data.address;
      }
      return null;
    },
  },
  { field: 'gstin', headerName: 'GSTIN', sortable: true, filter: true, minWidth: 200 ,flex:1, suppressSizeToFit:true,},
];

const FormFields = [
  { label: 'Name 1', attribute: 'name', type: 'text', required: true, },
  { label: 'Name 2 ', attribute: 'name2', type: 'text', required: true },
  { label: 'Vendor Code', attribute: 'code', type: 'text', required: true },
  { label: 'SAP Vendor Code', attribute: 'sapVendorCode', type: 'text' },
  { label: 'Email', attribute: 'email', type: 'email',required: true },
  { label: 'Mobile', attribute: 'mobile', type: 'text' },
  { label: 'PAN', attribute: 'pan', type: 'text' },
  { label: 'GSTIN', attribute: 'gstin', type: 'text' },
  { label: 'Address', attribute: 'address', type: 'textarea', rows: 3 },
  { label: 'Street 1', attribute: 'street1', type: 'text' },
  { label: 'Street 2', attribute: 'street2', type: 'text' },
  { label: 'Street 3', attribute: 'street3', type: 'text' },
  { label: 'Postal Code', attribute: 'postalCode', type: 'text', required: true },
  { label: 'City', attribute: 'city', type: 'text', required: true },
  { label: 'State', attribute: 'stateCode', type: 'select', required: true },
  { label: 'Country', attribute: 'country', type: 'text', required: true, disabled: true, initialValue: 'IN' },
]

export const VendorsPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({ isListReady: false, filterBase: '', resetDataSource: false, })
  const [statesIN, setStatesIN] = useState([]);
  const[countryDetails,setCountryDetails]=useState({});
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [ uploadedFile, setUploadedFile ] = useState([])

  const { hasPermission: createvendors } = usePermissions('create', "configuration.fsc.vendors");
  const { hasPermission: editvendors } = usePermissions('edit', "configuration.fsc.vendors");
  const { hasPermission: uploadvendors } = usePermissions('upload', "configuration.fsc.vendors");
  const { hasPermission: downloadvendors } = usePermissions('download', "configuration.fsc.vendors");

  
  React.useEffect(() => {
    const  fetchCountryData=async()=>{
     try {
       const res = await fetchCulture();
       if (res) {
         setCountryDetails(res);
       }
     } catch (error) {
       console.error('Error fetching country data:', error);
     }
     }
     fetchCountryData();
   }, []);
 
    React.useEffect(() => {
 if(Object.keys(countryDetails).length){
   const statesMod = countryDetails.stateCodes.map((obj, index) => ({
     key: index + 1,
     label: obj.name,
     value: obj.code,
   }));
   setStatesIN(statesMod);
 
 }
   }, [countryDetails]);

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };
  const fetchVendors = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    let res = await APIEndpoint.post('vendors/process', filterData);
    return res;
  }
  const handleModalClose = () => {
    setModalFormStatusObj({text:""})
    setState((currentState) => {
      return { ...currentState, showAddForm: false, editRow: null }
    })

  }
  const handleAddVendor = () => {
    setState((_) => {
      return { ..._, showAddForm: createvendors?true:false }
    })
  }
  const handleReset = () => {
    setState((_) => {
      return { ..._, resetDataSource: true, showAddForm: false, editRow: null };
    })
    let timerId = setTimeout(() => {
      clearTimeout(timerId);
      setModalFormStatusObj({text:""})
      setState((_) => {
        return { ..._, resetDataSource: false };
      })
    }, 500)
  }
  const handleWarehouseFormSubmit = async (e, values) => {
    let isValid = true, isEdit = Boolean(state.editRow);
    if (values != null) {
      isValid = FormFields.every(((field) => {
        if (
          field.required && (
            (isEdit && !(values[field.attribute] || state.editRow[field.attribute])) ||
            (!isEdit && !values[field.attribute] && field.attribute !== 'country')
          )
        ) {
          setModalFormStatusObj({error: "Please fill all mandatory fields"});
          return false;
        }
        return true
      }));
      if (isValid) {
        try {
          let res;
          if (isEdit) {
            values = { ...state.editRow, ...values };
            res = await APIEndpoint.post('vendors/update/' + state.editRow.id, values);
          } else {
            values.country = 'IN'
            res = await APIEndpoint.post('vendors/new', values);
          }
          if (res && res.success) {
            handleReset()
          }
          alert(res && res.msg);

        } catch (error) {
          let {data}=JSON.parse(error.message);
          setModalFormStatusObj({error:data?.msg})
        }
      }
    }
  }
  const handleRowAction = async (e, action, row) => {
    let editRow = null;
    const vendor = await APIEndpoint.get('vendors/' + row.itemId);
    if (vendor.success) {
      editRow = vendor.result;
      setState((currentState) => {
        return { ...currentState, editRow: editRow }
      })
    } else {
      alert('Unable to fetch vendor')
    }
  }

  const onUploadClose = () => {
    setUploadedFile([]);
    setUploadFileStatusBlock('');
    setState((_) => ({ ..._, showUpload: false }))
  };

  const handleUploadVendors = async (e) => {
    try {
    const formData = new FormData()
    formData.append('file', e.target.files[0]);
    setUploadedFile(e.target.files[0])
    const res = await APIEndpoint.post('vendors/master/upload', formData, { 'Content-Type': "multipart/form-data" })
    } catch (error) {
      e.target.value = null;
      let { status, data } = JSON.parse(error.message);
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400 && data?.errors) {
        data.errors.forEach((errorItem) => {
          const lineNum = errorItem.lineNumber;
          const errorMsgArr = [];

          errorItem.errors.forEach((erObj) => {
            errorMsgArr.push(
              `<strong>${erObj.field}:</strong> ${erObj.message} <br>`
            );
          });

          msgContent += `<br><strong>Line ${lineNum}:</strong> <br>` + errorMsgArr.join(" ");
        });
      } else if (status === 0 && !data) {
        msgContent += `Error occurred while uploading vendors!<br>Please try again.`;
      } else {
        msgContent += `Error occurred while uploading vendors!<br>Please fix the errors and re-upload.`;
      }

      // Displaying the message with proper structure
      setUploadFileStatusBlock(
        `<p class='listErrorMessagesCls'><strong>${msgHeading}</strong></p>` +
        `<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>${msgContent}</p>`
      );


    }
  }
  const onDownloadSampleFile = async (e) => {
    onDownloadXlsFile('samples/downloads/Vendor_Master_Upload_Sample', 'Vendor_Master_Upload_Sample')
  }
  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
     { uploadvendors && downloadvendors ?<div className="flexRow justifyContentFlexEnd alignItemsCenter margBot10">
        <UploadButton
        className="buttonWithMargin"
          onClick={() => setState((_) => ({ ..._, showUpload: true }))}
          label={<u>{t('pages.pagesContent.vendors.uploadBtn')}</u>} />
        <DownloadButton
        className="buttonWithMargin"
          downloadLink='downloads/vendorMaster'
          label={<u>{t('pages.pagesContent.vendors.downloadBtn')} </u>}/>
      </div>:<></>}
      <DataGrid
        columnResize
        resetDataSource={state.resetDataSource}
        showDefaultFilters
        className='full-flex'
        columns={Columns}
        getRows={fetchVendors}
        pageType={false}
        addButton={{
          title: t('pages.pagesContent.vendors.addButtonTitle'),
          onClick: handleAddVendor
        }}
        actions={[
          { detail: editvendors?[{ src: Edit2, value: "edit" ,title:'Edit'}]:[] }
        ]}
        onAction={handleRowAction}
      />
      {
        Boolean(state.showAddForm || state.editRow) &&
        <EditModal
          title={
            Boolean(state.editRow) ? t('pages.pagesContent.vendors.editModalTitle') :
              t('pages.pagesContent.vendors.addModalTitle')
          }
          onClose={handleModalClose}
          onSubmit={handleWarehouseFormSubmit}
          modalFormStatusObj={modalFormStatusObj}
          fields={
            (FormFields).map((_) => {
              const isEdit = Boolean(state.editRow);
              return {
                ..._,
                initialValue: isEdit ? state.editRow[_.attribute] : (_.initialValue || ''),
                options: _.attribute === 'stateCode' ?
                  [{ label: t('selectState'), value: '' }, ...statesIN.map(st=>({ label: st.label, value: st.value }))] : []
              }
            })
          }
        />
      }
      {
        state.showUpload &&
        <UploadModal
          onUploadFileChangeHandler={handleUploadVendors}
          modelTitle={t('pages.pagesContent.vendors.uploadBtn')}
          onUploadClose={onUploadClose}
          onDownloadSampleFile={onDownloadSampleFile}
          uploadFileStatusBlock = { uploadFileStatusBlock }
          uploadedFile={uploadedFile}
        />
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}