/* eslint-disable react-hooks/exhaustive-deps */
import React, { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import searchIcon from '../../../assets/icons/search.svg';
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import loadingIcon from '../../../assets/loadingIn.gif';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import { DataGrid, UploadModal } from "../../../common";
import { APIEndpoint , DFPAPIEndpoint } from "../../../utils/axios";
import { onDownloadXlsFile, translationLength, truncateString } from "../../../utils/commonFunctions";
import CustomSelect from "../../../common/CustomSelect";
import usePermissions from "../../../utils/userPermissions";
const Columns = [
    { field: 'itemCode', headerName: 'Item Code', sortable: true, flex:1, minWidth: 220,valueGetter:params=>truncateString(params?.data?.itemCode,25) },
    { field: 'itemName', headerName: 'Item Name', sortable: true, flex:1, minWidth: 220,tooltipField: 'itemName',valueGetter: (params) => {
        if (params.data&&params.data.itemName) {
           return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
        }
        return null;
      },
    },
    { field: 'hsn', headerName: 'HSN', sortable: true, flex:1, minWidth: 110 },
    { field: 'vendorCode', headerName: 'Vendor Code', sortable: true, flex:1, minWidth: 130 },
    { field: 'vendorName', headerName: 'Vendor Name', sortable: true, flex:1, minWidth: 200
    ,tooltipField: 'vendorName',valueGetter: (params) => {
        if (params.data&&params.data.vendorName) {
           return params.data.vendorName.length>21? params.data.vendorName.slice(0,22)+"...": params.data.vendorName;
        }
        return null;
      },
    },
    { field: 'costPrice', headerName: 'Price', sortable: true, flex:1, minWidth: 80 },
    { field: 'cgst', headerName: 'CGST', sortable: true, flex:1, minWidth: 80 },
    { field: 'sgst', headerName: 'SGST', sortable: true, flex:1, minWidth: 80 },
    { field: 'igst', headerName: 'IGST', sortable: true, flex:1, minWidth: 80 },
    { field: 'utgst', headerName: 'UTGST', sortable: true, flex:1, minWidth: 80 },
    { field: 'cess', headerName: 'Cess', sortable: true, flex:1, minWidth: 80 },
];

export const VendorMappings = (props) => {
    const { t } = useTranslation();
    const perPageLimit = 10;
    const [uploads, setUploads] = useState({showUpload:false,showUploadVendorSpec:false});
    const [warehouses, setWarehouses] = useState([]);
    const [vendors, setVendors] = useState([]);
    const [wareHouseArr,setWareHouseArr]=useState([]);
    const [selWarehouse, setSelWarehouse] = useState('');
    const [isDownloading, setIsDownloading] = useState(false);
    const [searchKey, setSearchKey] = useState('');
    const [ uploadedFile, setUploadedFile ] = useState([]);
    const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
    const [ isReloadTableData, setIsReloadTableData ] = useState(false);
    const [warehouseUpload,setWarehouseUpload]=useState([]);
    const [selFiltersMessage, setSelFiltersMessage] = useState('');
    const [noResultsMessage, setNoResultsMessage] = useState("Please select Warehouse")
    const sType = localStorage.getItem('sType');
    const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

    const { hasPermission: downloadVendormappings } = usePermissions('download', "inventory.vendor-mappings");
    const { hasPermission: viewVendormappings } = usePermissions('view', "inventory.vendor-mappings");
    const { hasPermission: uploadVendormappings } = usePermissions('upload', "inventory.vendor-mappings");

    React.useEffect(() => {
        getWarehouses();
	}, [t]);
    React.useEffect(() => {
        if(uploads.showUpload && warehouseUpload.length>0 && !warehouseUpload.includes(null)){
            getWarehousesDetails();
        }
	}, [warehouseUpload]);

    // React.useEffect(() => {
    //     fetchVendorMappings();
	// }, [selWarehouse,t]);
    const preCompile = {
        warehouse: t('selectButtons.selWarehouse')
      }
      React.useEffect(() => {
        setSelFiltersMessage('Please select a warehouse')
        if( selWarehouse && selWarehouse.length>0) {
            setSelFiltersMessage('');
            fetchVendorMappings();
        }
	}, [selWarehouse]);

    const getWarehouses = async () => {
        try {
        const resp = await DOMAIN.get('warehouses');
        if (resp.result.length > 0) {
            setWareHouseArr(resp.result);
            const options = [{key: 0, label: preCompile.warehouse, value: ''}];
            resp.result.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`});
              return fieldValue;
            });
            setWarehouses(options);
        }
        } catch (error) {

        }

}
const getWarehousesDetails=()=>{
    if(selWarehouse?.length>0 ){
        const wareHs=  wareHouseArr.find(w=>w.value===selWarehouse)
        return wareHs?.id;
      }
      if(warehouseUpload && warehouseUpload.length>0 && !uploads.showUpload){
        const wareHs=  wareHouseArr.find(w=>w.value=== warehouseUpload[0].value)
        return wareHs?.id;
      }else{
        const wareHsArr=warehouseUpload.map(wr=>wr.key)
        return wareHsArr;
      }
};

    const filterData = {
        action: 4,
        collection: '',
        filterOperation: 'or',
        filters: [],
        limit: 10,
        page: 1,
        searchParam: ''
    };
    const fetchVendorMappings =useCallback(
        async (page=1, perPage=10) => {
            if(!viewVendormappings){
                return{items:[],count:0};
            }
            // selWarehouse?.length>0?setIsDownloading(false):setIsDownloading(true);
             if(selWarehouse&&selWarehouse.length>0){
                 setNoResultsMessage(undefined)
                  filterData.page = page;
             filterData.limit = perPage;
             filterData.searchParam = searchKey;
             filterData.filters=[{warehouseId: getWarehousesDetails()}];
             const res = await DOMAIN.post('vendorMappings', JSON.stringify(filterData));
             if(res?.items?.length){
                   const options = [{key: 0, label: 'Select Vendor', value: ''}];
              res.items.filter( (fieldValue, index) => {
              options.push({key: index+1, label: `${fieldValue.vendorName}`, value: `${fieldValue.vendorId}`});
              return fieldValue;
            });
            setVendors(options);
             }

             return res;
             }else{
                 setNoResultsMessage("Please select Warehouse")
                 setIsReloadTableData(!isReloadTableData)
                 return {
                     items:[],count:0
                 }
             }
         },[ selWarehouse?.length,searchKey]
    )


    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onUploadDistributorMappingClick=()=>{
        getWarehouses();
        setUploads((_)=>({..._,showUpload:!uploads.showUpload}))

    }
    const onItemTypeChange = (e) => {
        setSelWarehouse(e?.value);
    }

    const onUploadClose = () => {
        setWarehouseUpload("");
        setUploadedFile([]);
        setUploadFileStatusBlock('');
        setUploads((_)=>({..._,showUpload:false,showUploadVendorSpec:false}))
    };
    const onDownloadSampleFile = () => {
        onDownloadXlsFile('samples/downloads/Vendor_Mappings_Upload_Sample', 'Vendor_Mappings_Upload_Sample');
    }
    const onUploadFileChangeHandler = async (e) => {

        const file=e.target.files[0];
        setUploadedFile(e.target.files[0]);
        setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');
        const formData = new FormData();
        formData.append('scheduleLater', false);
        formData.append('listName', undefined);
        formData.append('targetSectorType', undefined);
        formData.append('stores', []);
        formData.append('file', file);
        if(uploads.showUploadVendorSpec){
            formData.append('vendors', [warehouseUpload]);

        }
        else{
            formData.append('warehouses', [getWarehousesDetails()]);
        }

        try {
            await DOMAIN.post('vendorMappings/warehouses/upload', formData);
            setUploadedFile([]);
            setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
            setIsReloadTableData(true);

          }
          catch(error) {
            e.target.value = null;
            setUploadedFile([]);
            let { status, data } = JSON.parse( error.message );
            const msgHeading = data?.Error || data?.message || error.message;

            let msgContent = "";
            if (status === 400) {
                  data.errors.forEach( _ => {
                  const lineNum = _.lineNumber;
                  const errorMsgArr = [];
                  _.errors.forEach(erObj => {
                    errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
                  });

                  msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
              });
            }
            else if(status===0 && !data){
                msgContent += `Error occurred while uploading vendor-mapping sheet!<br>Please try again.`;
            }
            else {
              msgContent += `Error occurred while uploading vendor-mapping sheet!<br>Please fix the errors and re-upload.`;
            }

            setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                      "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                                    );
          }

    };
    const onAddVendorMapping=()=>{
       setUploads((_)=>({..._,showUploadVendorSpec:!uploads.showUploadVendorSpec}));
    }
    const onDownloadItemMaster=async()=>{
        let header={
          'Accept':'text/csv'
        }
       let body={
            "warehouseId": getWarehousesDetails(),
            "storeId": null
        }
            try {
            setIsDownloading(true)
            const res= await DOMAIN.post(`downloads/vendorMasterMappings`, body, header);
            if(res.status==='scheduled'){

                setTimeout(() => {
                    // setIsDownloading(true)
                    const dowId = DOMAIN.get(`downloads/status/${res.id}`);
                    dowId.then((val) => {
                      if (val.status === "completed") {
                          DOMAIN.get(`downloads/analytics/${val.fileName}${val.fileExtension}`)
                              .then((ItemMasterDwnld) => {
                                const url = window.URL.createObjectURL(new Blob([ItemMasterDwnld]));
                                const link = document.createElement('a');
                                link.href = url;
                                link.setAttribute('download', `${val.fileName}.${val.fileExtension}`); //or any other extension
                                document.body.appendChild(link);
                                link.click();
                                setIsDownloading(false)
                              })
                              .catch((error) => {

                                  console.error('Error downloading CSV:', error);
                                  setIsDownloading(false);
                              });
                      }
                  });
                    }, 5000);
            }
            }
             catch (res) {
               console.log(res.message)
               setIsDownloading(false)
             }
          }

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>
                        <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
                        {   uploadVendormappings? <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadDistributorMappingClick(true)}> <span><img  className="icon-size" alt='uploadItem' src={uploadItemIcon} /><u>{t('filters.uploadMappings')}</u></span></button>:<></>}
                    {   downloadVendormappings?     <button className='download dropdownStyle1-TextMedium'title={!selWarehouse?'Please select a warehouse':null} disabled={!selWarehouse} onClick={onDownloadItemMaster}>
                            {isDownloading ? (
                                    <span>
                                    <img  className='cell-loading' alt='downloadMaster' src={loadingIcon} />
                                    <u className={`download__banner_`}>Downloading...</u>
                                </span>
                                ):(
                                    <span>
                                    <img className="icon-size" alt='downloadMaster' src={downloadMasterIcon} />
                                    <u className={`download__banner_`}>{isDownloading ? "Downloading...":t('filters.downloadVendorMap')}</u>
                                </span>
                                )}
                            </button>:<></>}
                        </div>
                        <div className='flexRow width100 margBot10 margTop8 justifyContentFlexStart'>
                            <div className="flexRow  alignItemsBaseline margRight21">
                                <button className='saleType-div m-2'>
                                {/* <select style={{fontSize:'14px'}} className='select-item darkBlue-bg' onChange={onItemTypeChange} value={selWarehouse} id="saleTypeId">
                                    {warehouses.map( (item, index) => (
                                        <option key={index} value={item.value}>{item.label}</option>
                                    ))
                                    }
                                </select> */}
                                <CustomSelect
                                  options={warehouses}
                                  placeHolder="Select Warehouse"
                                  onChange={onItemTypeChange}
                                />
                                </button>
                            </div>
                            <div className="margBot10 underline m-2">
                                <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
                                <span><input className="search" onChange={onSearch} type="search" placeholder="Search" value={searchKey}/></span>
                            </div>
                            {  selWarehouse? <div className='flexRow justifyContentFlexEnd' style={{ marginLeft: 'auto' }}>
                            <button className="add-item-button m-2" onClick={ onAddVendorMapping }>
                            <span><img  className="icon-size" alt='uploadItem' src={uploadItemIcon} />Vendor Mapping</span>
                            </button>
                            </div>:<></>}
                        </div>





                        <>
                            <DataGrid
                            className="full-flex"
                            columns={Columns}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            searchKey={searchKey}
                            selWarehouse={selWarehouse}
                            getRows={fetchVendorMappings}
                            rowSelection="multiple"
                            columnResize={true}
                            resetDataSource={isReloadTableData}
                            noResultsMessage={ noResultsMessage }
                        />
                        </>




                    </>
                }

                {uploads.showUpload &&
                <UploadModal
                    modelTitle={'Upload Warehouse Mappings'}
                    onUploadClose={onUploadClose}
                    onDownloadSampleFile={onDownloadSampleFile}
                    uploadedFile = { uploadedFile }
                    setWarehouseUpload={setWarehouseUpload}
                    warehouseUpload={warehouseUpload}
                    warehouse={warehouses}
                    onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
                    uploadFileStatusBlock = { uploadFileStatusBlock }
                />}
              {uploads.showUploadVendorSpec &&
                <UploadModal
                    modelTitle={'Upload Vendor Mappings'}
                    onUploadClose={onUploadClose}
                    onDownloadSampleFile={onDownloadSampleFile}
                    uploadedFile = { uploadedFile }
                    setWarehouseUpload={setWarehouseUpload}
                    warehouseUpload={warehouseUpload}
                    warehouse={vendors.filter((item,index,self)=>index===self.findIndex(t=>t.value===item.value))}
                    onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
                    uploadFileStatusBlock = { uploadFileStatusBlock }
                />}

                {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
            </div>
        </>
    );
}