import React, { useEffect, useState } from "react";
import { FiMinus,FiPlus } from "react-icons/fi"
import { truncateString } from "../utils/commonFunctions";

const ExpandableTable = (props) => {
  const [activeRow, setActiveRow] = useState(null);
  const [expanded, setExpanded] = useState(null);

  useEffect(() => {
    if(activeRow !== null){
      if(expanded){
        expanded.style.height = `0px`;
      }
      const selectedTr = document.querySelector(`tr[data-rowid="${activeRow}"]`);
      const nextTr = selectedTr.nextElementSibling;
      const rowDetailDiv = nextTr.querySelector('.rowDetail')
      setExpanded(rowDetailDiv)
      rowDetailDiv.style.height = `${rowDetailDiv.scrollHeight+5}px`;
    }else if(expanded){
      expanded.style.height = `0px`;
    }
  }, [activeRow])
  

  function changeActiveRow(e) {
    const tr = e.target.closest('tr');
    const nextTr = tr.nextElementSibling;
    const rowDetailDiv = nextTr.querySelector('.rowDetail')
    if(activeRow !== null){
      rowDetailDiv.style.height = `${rowDetailDiv.scrollHeight}px`;
    }else{
      rowDetailDiv.style.height = `0px`;
    }
    const rowId = e.currentTarget.dataset.rowid;
    setActiveRow((prev) => {
      if (rowId === prev) {
        return null;
      }
      return rowId;
    });
  }
  function handleViewItems(crewId,cat){
    props.setState(prev=>({...prev,selectedCrew:crewId,selectedCat:cat}))
  }
  return (
    <div className="exp_table__wrapper">
      <div className="exp_table__head"></div>
      <table className="exp_table">
        <thead className="mainHead">
          <tr>
            <th style={{ width: "2%" }}></th>
            {props.columns.map((column) => {
              return <th style={{ width: column.field == "aoc" ? "3%":"10%" }}>{column.headerName}</th>;
            })}
          </tr>
        </thead>
        <tbody>
          {props.data.map((d, i) => {
            return (
              <React.Fragment key={i}>
                {/* Parent row */}
                <tr
                  className={`baseRow${parseInt(activeRow) === i ? " active" : ""}`}
                  data-rowid={i}
                  onClick={changeActiveRow}
                  style={{ backgroundColor: i % 2 === 0 ? "#1e3544" : "" }}
                >
                  <td>
                    {parseInt(activeRow) === i ? (
                      <FiMinus color="#70ceea" size={18} />
                    ) : (
                      <FiPlus color="#fff" size={18} />
                    )}
                  </td>
                  {props.columns.map((column) => {
                    return <td>{column.field === "flightDate"?truncateString(d[column.field],30,true):d[column.field]}</td>;
                  })}
                </tr>

                {/* Child rows */}
                <tr
                  className={`childRow ${parseInt(activeRow) === i ? "show" : ""}`}
                >
                  <td colSpan={8} style={{ padding: 0 }}>
                    <div
                      className={`rowDetail ${parseInt(activeRow) === i ? "show" : ""}`}
                    >
                      <table id="groupedItemTbl">
                        <thead>
                          <tr>
                            {props.childTableHeadings.map((head,hI) => {
                              return <th style={{width:hI === 0 ? "20%":"8.8%"}}>{head.headerName}</th>;
                            })}
                            <th style={{width:"10%"}}>Actions</th>
                          </tr>
                        </thead>
                        <tbody>
                          {d.groupDetails.map((item) => {
                            return (
                              <tr style={{borderBottom:"1px solid red"}}>
                                <td>{item["itemCategory"]}</td>
                                <td>{item["estCount"]}</td>
                                <td>{item["upliftCount"]}</td>
                                <td>{item["salesCount"]}</td>
                                <td>{item["wastageCount"]}</td>
                                <td>{item["offloadCount"]}</td>
                                <td>{item["crewHCount"]}</td>
                                <td>{item["retrievelCount"]}</td>
                                <td>{item["varianceCount"]}</td>
                                <td>
                                  <button onClick={()=>handleViewItems(d.crewId,item["itemCategory"])} className="grid-download-btnStyle1">View Items</button>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </div>
                  </td>
                </tr>
              </React.Fragment>
            );
          })}
        </tbody>
      </table>
</div>

  );
};

export default ExpandableTable;
