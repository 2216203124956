import React, { useRef, useState } from 'react';
import { APIEndpoint,DFPAPIEndpoint } from "../../../utils/axios";
import { DataGrid,MappingModal , UploadModal } from "../../../common";
import { MIMETypes } from '../../../utils/configs';
import { onDownloadXlsFile, translationLength } from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import LoadingGIF from "../../../assets/loadingIn.gif"
import usePermissions from '../../../utils/userPermissions';
const Columns = [
  { field: 'itemCode', headerName: 'Item Code', sortable: true, flex:1,suppressSizeToFit:true,resizable:true, },
  { field: 'itemName', headerName: 'Name', sortable: true ,flex:1,suppressSizeToFit:true,resizable:true,},
  { field: 'category', headerName: 'Category', sortable: true, flex:1,suppressSizeToFit:true,resizable:true },
  { field: 'subCategory', headerName: 'Sub Category', sortable: true, flex:1, suppressSizeToFit:true,resizable:true },
];


export const ManageCombosPage = (props) => {
  const priceListOptions = useRef(null);
  const [isDownloading, setIsDownloading] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [showMapping, setShowMapping] = useState(false);
  const [mappingData, setMappingData] = useState({});
  const [ uploadedFile, setUploadedFile ] = useState([])
  const [actionItems, setActionItems] = useState([]);
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const { hasPermission: createCombos } = usePermissions('create', "inventory.combos");
  const { hasPermission: editCombos } = usePermissions('edit', "inventory.combos");
  const { hasPermission: viewCombos } = usePermissions('viewDetails', "inventory.combos");
  const { hasPermission: uploadCombos } = usePermissions('upload', "inventory.combos");
  const { hasPermission: downloadCombos } = usePermissions('download', "inventory.combos");

  const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

  const Fields = useRef([]);
  const { t } = useTranslation();
  const [state, setState] = useState({ isListReady: false, filterPrice: '', resetDataSource: false, })
  React.useEffect(() => {
    setActionItems([
      {
          title: '',
          attribute: 'edit',
      },
  ]);
    fetchPriceList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };
  const fetchCombos = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    if (state.filterPrice) {
      filterData.filters = [{ listName: state.filterPrice }]
    }
    const res = await APIEndpoint.post('combos/items', filterData);
    return res;
  }

  const fetchServings = async (itemId) => {
    const servingList = await APIEndpoint.get('recipe/'+itemId+'/servings');
    if (servingList.result) {
      return servingList.result;
    }else{
      return null;
    }
  };

  const fetchPriceList = async () => {
    setState((_) => {
      return { ..._, isListReady: true }
    })
    try {
      const priceList = await APIEndpoint.get('items/price-list');
    if (priceList.result.length) {
      priceListOptions.current = priceList.result.map((_pl) => {
        return { value: _pl, label: _pl }
      })
    }
    } catch (error) {

    }

    Fields.current = getFormFields()
  };

  const getFormFields = () => {
    return 0
  }

  const getActionCellIcons = (cellDef, data) => {
    if (cellDef.attribute === 'edit') {
      return [
          {src: Edit2, value:"edit", title: 'Mappings'},
      ];
    }
    return [];
};

  const handleRowAction = async (e, action, row) => {
    let editRow = null, detailsRow = null;
    if (action.value === 'edit') {
      const servingData = await fetchServings(row.itemId);
      if(servingData.items.length === 0){
        setModalFormStatusObj({ text: t('pages.productsContent.manageCombos.noMappings'), error: 1 });
      }
      setMappingData({
        title: row,
        map_data: servingData,
      })
      setShowMapping(true)
    } else {
      detailsRow = JSON.parse(JSON.stringify(row))
    }
    setState((currentState) => {
      return { ...currentState, editRow: editRow, detailsRow: detailsRow }
    })
  }

  const handleMappingSubmit = async (e, values) => {
    const itemId = e.target.value;
    setModalFormStatusObj({ text: '' });
    try{
      if (values != null) {
        const res = await APIEndpoint.put('recipe/'+itemId+'/servings', {servings: values});

        if (res.success) {
          setModalFormStatusObj({ text: res.msg });
        }
      }
    } catch (error) {
      setModalFormStatusObj({ text: error.data.msg, error: 1 });
    }

  }

  const onUploadFileChangeHandler = async (e) => {
    const file=e.target.files[0];
    setUploadedFile(e.target.files[0]);
    setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

    const formData = new FormData();
    formData.append('scheduleLater', false);
    formData.append('listName', undefined);
    formData.append('targetSectorType', undefined);
    formData.append('stores', []);
    formData.append('warehouses', []);
    formData.append('file', file);

    try {
      await DOMAIN.post('combos/upload', formData);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      setState( (_prev) => {
        return { ..._prev,resetDataSource:true}
      });
      setUploadedFile([]);
    }
    catch(error) {
      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400) {
            data.errors.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
      } else if(status===0 && !data){
        msgContent += `Error occurred while uploading commbos sheet!<br>Please try again.`;
    }
      else {
        msgContent += `Error occurred while uploading combos sheet!<br>Please fix the errors and re-upload.`;
      }

      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
};

  const onDownloadCombo=async()=>{
    let header={
      'Accept':'text/csv'
    }
    try {
      setIsDownloading(true)
    const res= await APIEndpoint.post(`sales/reports`,{reportType: "comboItemMaster"},header);
    if(res.status==='scheduled'){
      setTimeout(() => {
        setIsDownloading(true)
        const dowId = APIEndpoint.get(`downloads/status/${res.id}`);
        dowId.then((val)=> {
          if(val.status==="completed"){
            const ItemMasterDwnld = APIEndpoint.get(`downloads/analytics/${val.fileName}${val.fileExtension}`);
            ItemMasterDwnld.then((res) => {
            const url = window.URL.createObjectURL(new Blob([res]));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', val.fileName+'.csv'); //or any other extension
            document.body.appendChild(link);
            link.click();
            setIsDownloading(false)
            })
          }
        })
        }, 5000);
    }
    }
      catch (res) {
        console.log(res.message)
        setIsDownloading(false)
      }
  }

  const onUploadTaxMaster=()=>{
    setShowUpload(true)
  }
  const onUploadClose = () => {
    setUploadedFile([]);
    setUploadFileStatusBlock('');
    setShowUpload(false);
  }
  const handleModalClose = () => {
    setModalFormStatusObj({ text: '' });
    setShowMapping(false);
  }

  const onDownloadSampleFile = () => {
    onDownloadXlsFile('samples/downloads/Servings_Upload_Sample','Servings_Upload_Sample')
  }

  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        Boolean(state.isListReady) &&
        <>
          <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
       { uploadCombos?    <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadTaxMaster(true)}> <span><img  className="icon-size mt-1" alt='uploadItem' src={uploadItemIcon} /><u>{t('pages.productsContent.manageCombos.uploadMappings')}</u></span></button>:<></>}
       { downloadCombos? <button className='download dropdownStyle1-TextMedium' onClick={onDownloadCombo}><span>{isDownloading?<img src={LoadingGIF}  alt='loading' className='cell-loading' />:<img className="icon-size mt-1" alt='downloadMaster' src={downloadMasterIcon} />}<u className={`download__banner_${isDownloading ? "active" : ""}`}>{isDownloading?t('filters.downloadingMaster'):t('filters.downloadMaster')}</u></span></button>:<></>}
          </div>
          <DataGrid
            columnResize
            resetDataSource={state.resetDataSource}
            showDefaultFilters
            className='full-flex'
            columns={Columns}
            getRows={fetchCombos}
            onAction={handleRowAction}
            getActionCellIcons={getActionCellIcons}
            pageType={false}
            actions={actionItems}
          />
          {
            showMapping && createCombos && editCombos && viewCombos &&
            <MappingModal
              data={mappingData}
              onClose={handleModalClose}
              onSubmit={handleMappingSubmit}
              modalFormStatusObj={modalFormStatusObj}
            />
          }
          {
            showUpload &&
            <UploadModal
              modelTitle={t('pages.productsContent.manageCombos.uploadMappings')}
              onUploadClose={onUploadClose}
              onDownloadSampleFile={onDownloadSampleFile}
              uploadedFile = { uploadedFile }
              onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
              uploadFileStatusBlock = { uploadFileStatusBlock }
              allowedExtensions={ MIMETypes.excel }
            />
          }
        </>
      }
      {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}