import React, { useState, useEffect } from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import { Input } from "./Input"
import { HOST_SERVER } from '../utils/configs';

export const Modal = (props) => {
  const [showFieldsInColumn, setShowFieldsInColumn] = useState(false);

  useEffect(() => {
    setShowFieldsInColumn(props.showFieldsInColumn);
  }, [props.showFieldsInColumn]);

  const setSubmit=()=>{
    props.onSubmit();
    props.setEditSubmitCheck(true);
  }
  let isError = false;
  let updateStatusText = '';

  if (props.modalFormStatusObj) {
    if ( ('text' in props.modalFormStatusObj)) {
      updateStatusText = props.modalFormStatusObj.text;
    }
    if ( ('error' in props.modalFormStatusObj)) {
      isError=props.modalFormStatusObj.error;
    }
  }


  return (
    <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar" style={props.customModalStyle}>
        <div className="dialog__content">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.title}</h2>
          <hr />

          {!showFieldsInColumn ?
            <div className='dialog__body_container flexRow justifyContentSpaceBetween flexWrap'>{props.children}</div>
            :
            <div className='dialog__body_container flexCol alignItemsCenter'>{props.children}</div>
          }

          {props.blobFile || props.imageUrl ? <div className="formGroup__formHorizontal"><img src={props.blobFile ? props.blobFile : `${HOST_SERVER}/${props.imageUrl}`} className="img-thumbnail" alt='' width="90" height="60" /> <img className="image__close__icon" alt='Close' src={closeIcon} onClick={() => props.resetImageSelection()} /><p className="pvm__text-center">{props.fileUpload?.name ? props.fileUpload?.name : ''}</p></div> : <></>}

          {updateStatusText ?
          <>
            <div className={ "dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
              {updateStatusText}
            </div>
          </> : null }
                <div className="dialog__footer flexRow justifyContentSpaceEvenly">
                   <button className="done-button submit-button" onClick={setSubmit}>{props.buttonLabel?props.buttonLabel:"Save"}</button>
                </div>

        </div>
      </div>
    </div>
  );
}
export const AddConditionModal = (props) => {

const onChange = (e) => {
    const { name, value } = e.target;

if(value==='region'|| value==='totalTaxPercentage' || value ==='hsn' || value ==='gstNumber' || value ==='invoiceDate'){
  props.setEditConditionsVariable(value);
}
else{
props.setConditionsSelections(value);
}
    let newUpdates =props.updated ? { ...props.updated } : {}
    newUpdates[name] = value;
    props.setUpdated({...newUpdates });
    typeof (props.onChange) === 'function' && props.onChange(e, props.updated)
  }

  const onChangeSelectSearch = (e, data) => {

    if(data.length && props.fields[2]?.attribute==='regions'){

      props.setUpdated( { ...props.updated,data } );
      typeof (props.onChange) === 'function' && props.onChange(data.value, props.updated)
    }
    if(data.length && props.fields[2]?.attribute==='hsn'){
      props.setUpdated( { ...props.updated,data } );
      typeof (props.onChange) === 'function' && props.onChange(data.value, props.updated)
    }
  };

  const onSubmit = (e) => {
    props.setEditSubmitCheck(true);
    removeContainerOverlay();
  props.onSubmit(e, props.updated);
  }

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <Modal
      title={props.title}
      customModalStyle={props.customModalStyle ? props.customModalStyle : {} }
      onClose={onClose}
      encodeImageFileAsURL={props.encodeImageFileAsURL}
      blobFile={props.blobFile}
      fields={props.fields}
      fileUpload={props.fileUpload}
      updated={props.updatedCond}
      setUpdated={props.setUpdatedCond}
      imageUrl={props.imageUrl}
      setEditSubmitCheck={props.setEditSubmitCheck}
      buttonLabel={props.buttonLabel}
      setEditConditionsVariable={props.setEditConditionsVariable}
      setConditionsSelections={props.setConditionsSelections}
      modalCustomButtons={props.modalCustomButtons || []}
      onChange={onChange}
      resetImageSelection={props.resetImageSelection}
      showFieldsInColumn={props.showFieldsInColumn}
      onSubmit={(e)=>onSubmit(e)}
      modalFormStatusObj={props.modalFormStatusObj}>
      {
        props.fields.map((_) => {
          const { initialValue,...rest } = _;
          return (
            <Input
              key={_.attribute}
              name={_.attribute}
              value={props.updated? props.updated[_.attribute] :initialValue}
              data={initialValue}
              onChange={(e,selected) => {
                if (_.type === 'rcSelectAll') {
                  onChangeSelectSearch(e,selected);
                }
                else {
                  onChange(e, _);
                }
              }
              }
              {...rest}
            />
          )
        })
      }
    </Modal>
  )
}