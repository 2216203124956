import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import editIcon2 from "../../../assets/edit2.svg";
import searchIcon from '../../../assets/icons/search.svg';
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import { DataGrid, EditModal, UploadModal } from "../../../common";
import { APIEndpoint , DFPAPIEndpoint} from "../../../utils/axios";
import { onDownloadXlsFile, translationLength } from "../../../utils/commonFunctions";
import usePermissions from "../../../utils/userPermissions";
// import { PageSizes } from "../../../utils/configs";

const Columns = [
    { field: 'itemCode', headerName: 'Item Code', sortable: true, flex:1,minWidth: 120, },
    { field: 'itemName', headerName: 'Item Name', sortable: true,  flex:1,minWidth: 200,
        tooltipField: 'itemName'
       ,valueGetter: (params) => {
            if (params.data&&params.data.itemName) {
               return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
            }
            return null;
          }, },
    { field: 'itemType', headerName: 'Item Type', sortable: true,  flex:1,minWidth: 140, },
    { field: 'distributorCode', headerName: 'Distributor Code', sortable: true,  flex:1, minWidth: 140, },
    { field: 'distributorName', headerName: 'Distributor Name', sortable: true,  flex:1, minWidth: 170, },
];

const EditFormFields = [

    /**
     * The options array should contain objects.
     * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
     */
    {key: 1, label: 'Search by Item Code', attribute: 'itemCode', name: 'itemCode', type: 'selectSearch'
        , defaultValue: ''
        , value: ''
        , className: 'select-search-cls'
        , options: []
        // , getOptions: () => {
        //     return
        // }
        /**
         * multiple(false): true/false
         * search(false): true/false
         * closeOnSelect(true): true/false
         * debounce(0): Number of ms to wait until calling get options when searching.
         * autoComplete(off): Options are on/off
         * autoFocus(false): true/false
         */
        , multiple: false
        , search: true
        // , closeOnSelect: false
        // , debounce: 1000
        , autoComplete: 'on'
        , autoFocus: false
        , disabled: false, placeholder: 'Start typing...'
    },
    {key: 2, label: 'Search by Item Name', attribute: 'itemName', name: 'itemName', type: 'selectSearch'
        , defaultValue: ''
        , value: ''
        , className: 'select-search-cls'
        , options: []
        // , getOptions: () => {
        //     return
        // }
        /**
         * multiple(false): true/false
         * search(false): true/false
         * closeOnSelect(true): true/false
         * debounce(0): Number of ms to wait until calling get options when searching.
         * autoComplete(off): Options are on/off
         * autoFocus(false): true/false
         */
        , multiple: false
        , search: true
        // , closeOnSelect: false
        // , debounce: 1000
        , autoComplete: 'on'
        , autoFocus: false
        , disabled: false, placeholder: 'Start typing...'
    },

    /**
     * The options array should contain objects.
     * Required keys are "name" and "value" but you can have and use any number of key/value pairs.
     */
    {key: 3, label: 'Search by distributor code', attribute: 'distributorId', name: 'distributorId', type: 'selectSearch'
        , defaultValue: ''
        , value: ''
        , className: 'select-search-cls'
        , options: []
        // , getOptions: () => {
        //     return
        // }
        /**
         * multiple(false): true/false
         * search(false): true/false
         * closeOnSelect(true): true/false
         * debounce(0): Number of ms to wait until calling get options when searching.
         * autoComplete(off): Options are on/off
         * autoFocus(false): true/false
         */
        , multiple: false
        , search: true
        // , closeOnSelect: false
        // , debounce: 1000
        , autoComplete: 'on'
        , autoFocus: false
        , disabled: false, placeholder: 'Start typing...'
    },
    {key: 4, label: 'Distributor Name', attribute: 'distributorName', type: 'text', required: true, disabled: true, value: '' ,style: { width: '200px' }},
];

export const DistributorMappings = (props) => {
    const { t } = useTranslation();

    const [state, setState] = useState({});
    const [editFormFields, setEditFormFields] = useState(EditFormFields);
    const [showUpload, setShowUpload] = useState(false);
    const [perPageLimit, ] = useState(10);
    const [searchKey, setSearchKey] = useState('');
    const [ uploadedFile, setUploadedFile ] = useState([])
    const [ uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
    const [ isReloadTableData, setIsReloadTableData ] = useState(false);
    const [distributors, setDistributors] = useState([]);
    const [items, setItems] = useState([]);
    const [updatedFormFieldValues, setUpdatedFormFieldValues] = useState({});
    const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
    const modelStyle = { maxWidth: '40%',margin:'15%' };
    const sType = localStorage.getItem('sType');
    const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

    const { hasPermission: createBpamappings } = usePermissions('create', "inventory.bpa-mappings");
    const { hasPermission: editBpamappings } = usePermissions('edit', "inventory.bpa-mappings");
    const { hasPermission: uploadBpamappings } = usePermissions('upload', "inventory.bpa-mappings");

    useEffect( () => {
        fetchItemsList();
        fetchDistributorList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [] );


    useEffect( () => {
        if (updatedFormFieldValues) {

            if ( state.selected?.action === 'editItem' || state.selected?.action === 'AddNewItem') {
                const extraFields = {};
                if (updatedFormFieldValues.distributorId) {
                    const selectedDistributor = distributors.find( item => item.value === updatedFormFieldValues.distributorId );
                    extraFields.distributorName = selectedDistributor.label;
                }

                setState((_prev) => {
                    return { ..._prev, selected: { ..._prev.selected, ...updatedFormFieldValues, ...extraFields } };
                });
            }

        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updatedFormFieldValues] );

    const onAction = (e, action, row) => {
        setState((_prev) => {
            return { ..._prev, selected: { ...row, action: action.value} };
        });

        fetchItemsList();
        fetchDistributorList();
    };

    const onAddItemHandler = () => {

        resetEditFormFields()

        setUpdatedFormFieldValues({});

        setState( (_prev) => {
            delete _prev.selected;
            return { ..._prev, selected: { action: 'AddNewItem' } };
        });

        fetchItemsList();
        fetchDistributorList();
    }

    const onClose = (e, action, row) => {
        setState((_prev) => {
            return { ..._prev, selected: false };
        });
        setModalFormStatusObj({ text: '' });
    };

    const validateFormInputs = (body) => {
        setModalFormStatusObj({ text: '' });
        if (!body) {
            setModalFormStatusObj({ error: 'Input validation failed!' });
            return false;
        }
        if ( !body.itemCode && !body.itemName ) {
            setModalFormStatusObj({ error: 'Search an item by item code or item name!'});
            return false;
        }
        if (!body.distributorId) {
            setModalFormStatusObj({ error: 'Search distributor by distributor code!',  });
            return false;
        }
        return true;
    };

    const onEditSubmit = async (e, updated) => {
        setModalFormStatusObj({ text: '' });

        try {
            if ( state.selected?.action === 'editItem') {
                const body = {
                    distributorId: state.selected.distributorId,
                    itemCode: state.selected.itemCode || state.selected.itemName,
                    ...updated,
                    mappingId: state.selected._id };
                if (!validateFormInputs(body) ) {
                    return false;
                }

                const res = await DOMAIN.post('distributorMappings/warehouses/update', body);
                if (res.success) {
                    console.log("edit success")
                    setModalFormStatusObj({ text: res.msg });
                    fetchDistributorMappingRows();
                    resetModal();
                    setTimeout(() => {
                        setModalFormStatusObj({text:''})
                        onClose();
                      }, 2000)
                }
                else {
                    setModalFormStatusObj({ error: JSON.stringify(res),  });
                }
            }
            else if ( state.selected?.action === 'AddNewItem') {
                const body = {
                    ...state.selected,
                    ...updated,
                    itemCode: updated?.itemCode || updated?.itemName };
                if (!validateFormInputs(body) ) {
                    return false;
                }
                delete body.action;
                delete body.itemName;

                const res = await DOMAIN.post('distributorMappings/warehouses/new', body);
                if (res.success) {
                    console.log("add success")
                    setModalFormStatusObj({ text: res.msg });
                    fetchDistributorMappingRows();
                    resetModal();
                    setTimeout(() => {
                        setModalFormStatusObj({text:''})
                        onClose();
                      }, 2000)
                }
                else {
                    setModalFormStatusObj({ error: JSON.stringify(res) });
                }
            }
            // onClose();
        }
        catch(err) {
            if (err.hasOwnProperty('data')) {
                setModalFormStatusObj({ error: 'Error: ' + err.data.msg});
            }
            else {
                let {data}=JSON.parse(err.message)
                setModalFormStatusObj({ error:data.msg})
                // setModalFormStatusObj({ text: 'Error: ' + err.message, error: 1 });
            }
        }
    };

    const resetModal = () => {
        setState( (_prev) => {
            delete _prev.selected;
            return { ..._prev, selected: { action: 'AddNewItem' } };
        });

        // setUpdatedFormFieldValues({});

        resetEditFormFields()
    }

    function resetEditFormFields() {
        const formFieldsvalueReset = EditFormFields.map(field=>{
            return {...field, value: ""}
        })

        setEditFormFields( _prev => {
            return [ ...formFieldsvalueReset ] ;
        });
    }

    const filterData = {
        action: 4,
        collection: '',
        filterOperation: 'or',
        filters: [],
        limit: 10,
        page: 1,
        searchParam: ''
    };
    const fetchDistributorMappingRows = async (page=1, perPage=10) => {
        filterData.page = page;
        filterData.limit = perPage;
        filterData.searchParam = searchKey;
        const res = await DOMAIN.post('distributorMappings', JSON.stringify(filterData));
        return res;
    };

    // const onPerLimitChange = (e) => {
    //     setPerPageLimit(Number(e.target.value));
    // };

    const onSearch = (e) => {
        setSearchKey(e.target.value);
    };

    const onUploadDistributorMappingClick=()=>{
        setShowUpload(true)
    }
    const onUploadClose = () => {
        setUploadedFile([]);
        setUploadFileStatusBlock('');
        setShowUpload(false);
    };
    const onDownloadSampleFile = () => {
        onDownloadXlsFile('samples/downloads/Distributor_Mappings_Upload_Sample', 'Distributor_Mappings_Upload_Sample');
    }

    const onUploadFileChangeHandler = async (e) => {
        const file=e.target.files[0];
        setUploadedFile(e.target.files[0]);
        setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');

        const formData = new FormData();
        formData.append('scheduleLater', false);
        formData.append('listName', undefined);
        formData.append('targetSectorType', undefined);
        formData.append('stores', []);
        formData.append('warehouses', []);
        formData.append('file', file);


        try {
            await DOMAIN.post('distributorMappings/warehouses/upload', formData);
            setUploadedFile([]);
            setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
            setIsReloadTableData(true);

          }
          catch(error) {
            e.target.value = null;
            setUploadedFile([]);
            let { status, data } = JSON.parse( error.message );
            const msgHeading = data?.Error || data?.message || error.message;

            let msgContent = "";
            if (status === 400) {
                  data.errors.forEach( _ => {
                  const lineNum = _.lineNumber;
                  const errorMsgArr = [];
                  _.errors.forEach(erObj => {
                    errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
                  });

                  msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
              });
            }
            else if(status===0 && !data){
                msgContent += `Error occurred while uploading distributor-mapping sheet!<br>Please try again.`;
            }
            else {
              msgContent += `Error occurred while uploading distributor-mapping sheet!<br>Please fix the errors and re-upload.`;
            }

            setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                      "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                                    );
          }


    };

    const fetchItemsList = async () => {
        if (items.length > 0) {
            return false;
        }
        const filterData = {
            action: 4,
            collection: '',
            filterOperation: 'or',
            filters: [],
            limit: 1000,
            page: 1,
            searchParam: ''
        };
        setModalFormStatusObj({ text: 'Please wait, Loading items...', error: 1 });
        /**
         * POST master/process
         */
        try {
        const itemsRes = await DOMAIN.get('items/enumerate', filterData);
        const itemIdList = [];
        const itemNameList = [];

        if (itemsRes) {
            itemsRes.items.filter( row => {
                itemIdList.push({ key: row.itemId, name: row.itemCode, value: row.itemCode, label: row.itemCode });
                itemNameList.push({ key: row.itemId, name: row.itemName, value: row.itemCode, label: row.itemCode });
                return row;
            });

            setItems(itemIdList);
            updateEditFormInputs({id: 'itemCode', data: itemIdList}, null);
            updateEditFormInputs({id: 'itemName', data: itemNameList}, null);
            setModalFormStatusObj({ text: ''});
        }
        } catch (error) {

        }

    };

    const fetchDistributorList = async () => {
        if (distributors.length > 1) {
            return false;
        }

        const filterData = {
            action: 4,
            collection: '',
            filterOperation: 'or',
            filters: [],
            limit: 1000,
            page: 1,
            searchParam: ''
        };
        setModalFormStatusObj({ error: 'Please wait, Loading distributors...', });
        /**
         * POST distributors/process
         */
        try {
        const itemsRes = await DOMAIN.get('warehouses/distributors/enumerate', filterData);
        const distributorList = [];

        if (itemsRes) {
            itemsRes.items.filter( row => {
                distributorList.push({ key: row.id, label: row.name, name: row.code, value: row.id });
                return row;
            });
            setDistributors(distributorList);
            updateEditFormInputs(null, distributorList);
        }
        } catch (error) {

        }

    };

    const updateEditFormInputs = (itemsArr=null, distributors=null) => {
        const updatedEditFields = editFormFields.filter( (field) => {
            if (itemsArr && field.attribute === itemsArr['id'] ) {
                field.disabled = false;
                field.options = itemsArr['data'];
                field.getOptions = () => {
                    return itemsArr['data'];
                }
                setModalFormStatusObj({ text: '' });
            }

            if (distributors && field.attribute === 'distributorId') {
                field.disabled = false;
                field.options = distributors;
                field.getOptions = () => {
                    return distributors;
                }
                setModalFormStatusObj({ text: '' });
            }

            return field;
        });

        setEditFormFields( updatedEditFields );
    };



    const updateEditFormValues = async (e, updatedFormObj) => {

        if (e) {
            const selectedItem = items.find( item => ( item.name === e ) );
            const selectedDistributor = distributors.find( item => item.value === e);

            /** Set to respective fields */
            const updatedEditFields = await editFormFields.filter( (field) => {

                if (selectedItem && ( field.attribute === 'itemCode' || field.attribute === 'itemName' ) ) {
                    field.value = selectedItem.value;
                }

                if (selectedDistributor ) {
                    if ( field.attribute === 'distributorId' ) {
                        field.value = selectedDistributor.value;
                    }
                    else if ( field.attribute === 'distributorName' ) {
                        field.value = selectedDistributor.label;
                    }
                }

                return field;

            });

            setEditFormFields( _prev => {
                return [ ...updatedEditFields ] ;
            });
        }

    };

    return (
        <>
            <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
                {
                    <>
                     {uploadBpamappings?  <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
                            <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadDistributorMappingClick(true)}> <span><img  className="icon-size" alt='uploadItem' src={uploadItemIcon} /><u>{t('filters.uploadMappings')}</u></span></button>
                        </div>:<></>}
                        <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
                            <div className="margBot10 underline margLeft40">
                                <span><img  className="icon-size" alt='search' src={searchIcon} /></span>
                                <span><input className="search" onChange={onSearch} type="search" placeholder="Search" value={searchKey}/></span>
                            </div>

                           { createBpamappings?<div className="margLeft flexRow justifyContentFlexEnd alignItemsBaseline">
                                <button className="add-item-button" onClick={ onAddItemHandler }>
                                    {t('buttons.addItem')}
                                </button>
                            </div>:<></>}
                        </div>

                        <DataGrid
                            className="full-flex"
                            columns={Columns}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            searchKey={searchKey}
                            getRows={ fetchDistributorMappingRows }
                            rowSelection="multiple"
                            actions={[{ title: 'Actions', detail: editBpamappings?[{src: editIcon2, value:"editItem",title:'Edit Item'}]:[] }]}
                            onAction={onAction}
                            onAddItemHandler={() => onAddItemHandler()}
                            columnResize={true}
                            resetDataSource={isReloadTableData}
                        />

                    </>
                }
                {
                    state && Boolean(state.selected) ? (
                        <EditModal
                            title={(state.selected.action === 'editItem') ? 'Edit Distributor Mapping' : 'Add Distributor Mapping'}
                            customModalStyle={ modelStyle }
                            onClose={onClose}
                            onSubmit={onEditSubmit}
                            buttonLabel={'Save'}
                            onChange={ (e, updated) => {
                                setModalFormStatusObj({text:''})
                                    updateEditFormValues(e, updated);
                                    setUpdatedFormFieldValues(updated)
                                }
                            }
                            modalFormStatusObj={modalFormStatusObj}
                            fields={ editFormFields.map( (elt) => {

                                    if (state.selected.action === 'editItem') {
                                        const editFrm = {...elt,
                                            initialValue: state.selected[elt.attribute] || '',
                                            value: state.selected[elt.attribute] || '',
                                        };

                                        if (elt.attribute === 'itemName') {
                                            editFrm.initialValue = state.selected['itemCode'];
                                            editFrm.value = state.selected['itemCode'];
                                        }
                                        if (elt.attribute === 'distributorName') {
                                            editFrm.initialValue = state.selected['distributorName'];
                                            editFrm.value = state.selected['distributorName'];
                                        }

                                        return editFrm;
                                    }
                                    else {
                                        return {...elt, initialValue: ''}
                                    }
                                })
                            }
                            showFieldsInColumn={true}
                        />
                    )
                    : null
                }

                {showUpload &&
                <UploadModal
                    modelTitle={'Upload Distributor Mappings'}
                    onUploadClose={onUploadClose}
                    onDownloadSampleFile={onDownloadSampleFile}
                    uploadedFile = { uploadedFile }
                    onUploadFileChangeHandler = { (e) => onUploadFileChangeHandler(e) }
                    uploadFileStatusBlock = { uploadFileStatusBlock }
                />}
                {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
            </div>
        </>
    );
}