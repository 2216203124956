/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */


import React, { useState ,useEffect,useRef} from 'react';
import '../../../assets/styles/modal.scss';
import closeIcon from '../../../images/icons/Icon2.png';
import deleteIcon from '../../../assets/icons/deleteRole.svg';
import Edit2 from "../../../assets/edit2.svg";
import {DeleteConf} from "../../../common";
import LoadingGIF from "../../../assets/loadingIn.gif";
import { useTranslation } from 'react-i18next';
import {DFPAPIEndpointGalley } from "../../../utils/axios";


export const ConfigGalley = (props) => {
  const {airCraft}=props;
  const { t } = useTranslation();
  const [state,setState]=useState({name:'',fullCarts:null,halfCarts:null,smu:null,stowage:null,firstClass:false,businessClass:false,premEconomy:false,economy:false});
  const [editState,setEditState]=useState({show:false});
  const [carts,setCarts]=useState([]);
  const [updatedAircraftGalley,setUpdatedAircraftGalley]=useState([])
  const [galleyLists,setGalleyLists]=useState([]);
  const [selectedGalley,setSelectedGalley]=useState({});
  const [classCodeMap,setClassCodeMap]=useState({});
  const [classCapacityConfig,setClassCapacityConfig]=useState([]);
  const [buttonStatus,setButtonStatus]=useState({fields:false,save:false});
  const[modalFormStatusObj,setModalFormStatusObj]=useState({text:''})
  const [inputFields,setInputFields]=useState(false);
  const [warningText,setWarningText]=useState("");
  const [buttonClicked,setButtonClicked]=useState(false);

const inputRef0=useRef(null);
const handleReset = () => {
  let timerId = setTimeout(() => {
    setState((prevState) => {
      return { ...prevState, name: '', fullCarts: null, halfCarts: null, smu: null, stowage: null, firstClass: false, businessClass: false, premEconomy: false, economy: false };
    });
    checkEmptyFields(state);
    clearTimeout(timerId);
  }, 100);
  let closeConfig=setTimeout(()=>{
    props.onUploadClose();
    clearTimeout(closeConfig);
  },1170)
};


useEffect(() => {
if(classCapacityConfig.length>0){
  setClassCodeMap({
    firstClass:classCapacityConfig[0].classCode,
    businessClass: classCapacityConfig[1].classCode,
    premEconomy: classCapacityConfig[2].classCode,
    economy: classCapacityConfig[3].classCode,
  })
}
}, [classCapacityConfig])

  useEffect(() => {
    checkEmptyFields(state);
    if(!checkEmptyFields(state)){
      setButtonStatus((_)=>{return{..._,fields:false}});

    }
    else{
      setButtonStatus((_)=>{return{..._,fields:true}});

    }
    if(props.airCraft){
      setCarts(props.airCraft.galleys);
      setInputFields(checkEmptyFields(state))
    }
    if(updatedAircraftGalley&&updatedAircraftGalley.length){
      const selectedAircraft = updatedAircraftGalley.find(ac => ac._id === props.stateAircraft?.selected._id);
    if (selectedAircraft?.galleys?.length) {
      setGalleyLists(selectedAircraft.galleys);
    }else{
      setGalleyLists([]);
    }
    }
    setWarningText(t('warningTexts.confguration.cartEdit'));
  }, [props,state,updatedAircraftGalley])

  useEffect(() => {
    const currentRef= inputRef0.current;
  if(buttonClicked && currentRef){
    currentRef.focus()
  }
  }, [buttonClicked])


  useEffect(() => {
    if(props.airCraft?.galleys){
      setGalleyLists(props.airCraft?.galleys);
      fetchConfigClassCodes();
    }
  }, [])
  const checkEmptyFields=(state)=>{

       for(let i in state) {
        if(i==='name'||i==='halfCarts'||i==='fullCarts'||i==='smu'||i==='stowage'){
            if (state['name'] !== '' && (state['halfCarts'] || state['halfCarts'] === 0) &&
            (state['fullCarts'] || state['fullCarts'] === 0) &&
            (state['smu'] || state['smu'] === 0) &&
            (state['stowage'] || state['stowage'] === 0))
            return false;
        }
        }
        return true;
  }

  const fetchConfigClassCodes=async()=>{
    try {
      const resp = await DFPAPIEndpointGalley.get('galley/mealMenuPlan/configs');
      if(resp.data&&resp.data.classCodes?.list?.length>0){
      setClassCapacityConfig(resp.data.classCodes?.list?.map(cl=>({
        classCode: cl.id,
        seatCapacity: 0,
        className: cl.label
    })))
      }
      }
      catch (error) {

      }
  }

  const onChange = (e) => {
    const { name, value } = e.target;
    setState((prev) => {
      switch (name) {
        case "name":
          return { ...prev, name: value };
        case "fullcarts":
          return { ...prev, fullCarts: value };
        case "halfcarts":
          return { ...prev, halfCarts: value };
        case "smu":
          return { ...prev, smu: value };
        case "stowage":
          return { ...prev, stowage: value };
        case "firstClass":
          return {
            ...prev,
            firstClass: true,
            businessClass: false,
            premEconomy: false,
            economy: false,
          };
        case "businessClass":
          return {
            ...prev,
            firstClass: false,
            businessClass: true,
            premEconomy: false,
            economy: false,
          };
        case "premEconomy":
          return {
            ...prev,
            firstClass: false,
            businessClass: false,
            premEconomy: true,
            economy: false,
          };
        case "economy":
          return {
            ...prev,
            firstClass: false,
            businessClass: false,
            premEconomy: false,
            economy: true,
          };
        default:
          return {
            name: "",
            fullCarts: null,
            halfCarts: null,
            smu: null,
            stowage: null,
            firstClass: false,
            businessClass: false,
            economy: false,
            premEconomy: false,
          };
      }
    });
  }
  let isError = false,
  updateStatusText="";
 if (modalFormStatusObj) {
   if (('text' in modalFormStatusObj)) {
     updateStatusText= modalFormStatusObj.text;
   }
 }


const getClassCodeEdit=(ob)=>{

  switch (ob?.classCode) {
  case classCapacityConfig[0].classCode:
    setState((_prev) => {
      return {firstClass:true,businessClass:false,premEconomy:false,economy:false};
  });
    break;
    case classCapacityConfig[1].classCode:
      setState((_prev) => {
        return {firstClass:false,businessClass:true,premEconomy:false,economy:false};
    });
    break;
    case classCapacityConfig[2].classCode:
      setState((_prev) => {
        return {firstClass:false,businessClass:false,premEconomy:true,economy:false};
    });
    break;
    case classCapacityConfig[3].classCode:
      setState((_prev) => {
        return {firstClass:false,businessClass:false,premEconomy:false,economy:true};
    });
    break;
  default:
    setState((_prev) => {
      return { ..._prev};
  });
    break;
}
}

const onConfigClick=async(e,r)=>{
let mod=r.galleyCartConfig.userDefined.slice(-4).reduce((acc,obj)=>{
  for(let i in obj){
  acc[i]=obj[i]
  }
  return acc
  },{});
  if(e.target.alt==='editIcon'){
  setSelectedGalley(r)
  getClassCodeEdit(r)
    setState((_prev) => {
        return { ..._prev,name:r.name,fullCarts:mod.full,halfCarts:mod.half,smu:mod.smu,stowage:mod.stowage};
    });
    setEditState((_prev) => {
      return { ..._prev,id:r._id,name:r.name,fullCarts:mod.full,halfCarts:mod.half,smu:mod.smu,stowage:mod.stowage,show:true};
  })
    setInputFields(false);
  }
  if(e.target.alt==='deleteIcon'){
  setUpdatedAircraftGalley([]);
  setSelectedGalley(r)
  if(r){
     try {
  const resp = await DFPAPIEndpointGalley.delete(`galley/aircraftTypes/${props.airCraft.id}/galleys/${r._id}`);
  setButtonStatus((_)=>{return{..._,fields:true,save:true}});

  if(resp.success){
  const res= await props.onUpdateGalley(1,50);
  if(res){
  setUpdatedAircraftGalley(res.items);
  setModalFormStatusObj({text:resp.message})
   setButtonStatus((_)=>{return{..._,fields:true,save:false}});

   }
  }
  } catch (error) {
  setModalFormStatusObj({error: error.data.message})
  }
  handleReset()
  }
  }

}

const onCloseDelete=()=>{
  setEditState({show:false});
  setState({});
}

const onContinue=async()=>{
  setEditState((_prev) => {
    return { ..._prev,show:false}
});
}
const delProps={
onContinue,
onCloseDelete
}
const onEditSubmit=async()=>{
  setUpdatedAircraftGalley([]);
  setButtonStatus((_)=>{return{..._,fields:true,save:true}});

  const createCartItem = (id, name, quantity) => ({
    id,
    name,
    weight: 0,
    volume: 0,
    dimensions: {
      length: 0,
      breadth: 0,
      height: 0,
    },
    quantity: parseInt(quantity),
  });

  const cartsObj = [
    createCartItem("full", "Full Cart", state.fullCarts),
    createCartItem("half", "Half Cart", state.halfCarts),
    createCartItem("smu", "SMU", state.smu),
    createCartItem("stowage", "Misc. Stowage", state.stowage),
  ];


  const activeKey = Object.keys(state).find(key => state[key] === true);
  const classCode = classCodeMap[activeKey] || 'ecy';

let temp= {
  "name": state.name,
  classCode,
  carts: cartsObj
}

const updatedBody = (temp.name.length ? [temp, ...carts] : [...carts]).map((item) => {
  if (item.name === editState.name) {
    return {
      ...item,
      carts: cartsObj,
    };
  }
  return item;
});

let removeIndex = updatedBody.map(item => item.name).indexOf(selectedGalley.name);
removeIndex>=0 && updatedBody.splice(removeIndex, 1);
    if(!checkEmptyFields(state)){
      if( !Object.entries(editState).length){
        delete updatedBody[0]['_id'];
    try {
     const resp = await DFPAPIEndpointGalley.post(`galley/aircraftTypes/${props.airCraft.id}/galleys`,updatedBody[0]);
     if(resp.success){
        setModalFormStatusObj({text:resp.message})
        const res= await props.onUpdateGalley(1,50);
        if(res){
          setUpdatedAircraftGalley(res.items);
         }
        setEditState({});
        handleReset();
         setButtonStatus((_)=>{return{..._,fields:false,save:false}});
;
    }
  } catch (error) {
    setModalFormStatusObj({error: error})
        handleReset()
  }
      }

      else{
        let tempEdit= {
          "name": state.name,
          "_id":editState.id,
          classCode,
          carts: cartsObj
        }

            let body=[];
        if(tempEdit.name.length){
          body.push(tempEdit,...carts);

        }
        else{
          body.push(...carts);
        }


            const updatedBodyEdit = body.map((item) => {
              if (item.name === editState.name) {
                return {
                  ...item,
                  carts: cartsObj,
                };
              }
              return item
            })

        let removeIndex = updatedBodyEdit.map(item => item.name).indexOf(selectedGalley.name);

        removeIndex>=0 && updatedBodyEdit.splice(removeIndex, 1);

        const ed = updatedBodyEdit.find(u => u._id === editState.id || (u.name && u.name === editState.name));;

        if(ed){
          ed['galleyId'] = ed['_id'];
          delete ed['_id'];

  try {
          const resp = await DFPAPIEndpointGalley.post(`galley/aircraftTypes/${props.airCraft.id}/galleys`,ed);
          if(resp.success){
             setModalFormStatusObj({text:resp.message})
             setEditState({});
             const resUpdated= await props.onUpdateGalley(1,50);
             if(resUpdated){
              setUpdatedAircraftGalley(resUpdated.items);
             }
             handleReset()
              setButtonStatus((_)=>{return{..._,fields:false,save:false}});
;
         }
       } catch (error) {
         setModalFormStatusObj({error: error.data.message||'something went wrong!'})
             handleReset()
       }
        }
      }
    }
    }
    const galleyCartsItems=(g)=>{
      const cartsMp={
        full:'Full Cart',
        half:'Half Cart',
        smu:'SMU',
        stowage:'Misc.Stowage'
      }
      let res
if(g.galleyCartConfig?.userDefined.length>4){
   res= g.galleyCartConfig?.userDefined?.slice(-4).map((item,index)=>{
    const key=Object.keys(item)[0]
    const name=cartsMp[key];
    const quantity=item[key];
    return(
      <li className='list-group-item-role'style={{display:"inline"}} key={index} >
      {<><button className='cart_display_galley'>
      {name}<span className='galley_cart_count'>{quantity}</span>
      </button>
      </>}
      </li>)
          })
}
else if(g.galleyCartConfig?.userDefined.length===4){
  res= g.galleyCartConfig?.userDefined?.map((item,index)=>{
    const key=Object.keys(item)[0]
    const name=cartsMp[key];
    const quantity=item[key];
    return(
      <li className='list-group-item-role'style={{display:"inline"}} key={index} >
      {<><button className='cart_display_galley'>
      {name}<span className='galley_cart_count'>{quantity}</span>
      </button>
      </>}
      </li>)
          })
}
else{

}
      return res
    }
  const showUserRoles=()=>{
      const result=galleyLists?.map(gal=>{
      return(<div className="form-group galley-modal"  id="one"  key={gal._id}>
      <ul className='list-group' style={{padding: "0 15px"}}>
      <div className='role_card'>
      <div style={{display:'inline-block'}}>
      <h6  style={{   marginInlineStart: '12px'}} className="subheading-text-medium-title fontLight">{gal.name}</h6>
      {galleyCartsItems(gal)}
      <div className='flexRow floatRight justifyContentSpaceBetween'>
        <span className='remove_role_icon'>
      <button style={{float:'right'}} id="two" className='mt-4 remove-button-galley' onClick ={(e)=>onConfigClick(e,gal)}>
      <img style={{margin:'3px 0px 0px 3px'}} className="icon-size-role" alt='editIcon' src={Edit2} />
      </button>
      </span>
      <span className='remove_role_icon'  onClick ={(e)=>onConfigClick(e,gal)}>
      <button style={{float:'right'}} id="two"  disabled={false} className='mt-4 remove-button-galley'>
      <img className="icon-size-role" alt='deleteIcon' src={deleteIcon} />
      </button>
      </span>
      </div>

      </div>

      </div>
      </ul>
      </div>)
      }

    )

   return result

   }


   const onClickClose=()=>{
    props.onUploadClose();
    setUpdatedAircraftGalley([]);
   }
   const onClickNewGalley=()=>{
    setState((_prev) => {
        return { ..._prev,name:'',fullCarts:null,halfCarts:null,smu:null,stowage:null,firstClass:false,businessClass:false,premEconomy:false,economy:false};
    });
    setEditState({});
    setInputFields(false);
    setButtonClicked(!buttonClicked)
    setUpdatedAircraftGalley([]);
   }


  return (<>
    <div className="overlay" role="dialog">
      <div className="dialog sm-scrollbar">
        <div className="dialog__content margBottom48">
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={onClickClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">{props.modelTitle}</h2>
          <hr />
          <div style={{marginRight:'1%'}} className='floatRight flexRow margBottom10 width40 justifyContentFlexEnd'>
          <button  style={{width:'1rem',whiteSpace:'nowrap'}} id='newGalleyAdd' className="add-item-button " onClick={onClickNewGalley}>+ Galley</button>
          </div>
       <div className="container" style={{display:'flex',height:'400px'}}>
        <div className='width50'>
        <div style={{overflowY:'auto',maxHeight:'460px'}}>
         <div>
          {props.airCraft &&galleyLists.length?showUserRoles():<><div className='flex textAlignCenter justifyContentCenter alignItemsCenter galley_add'><strong >Add Galleys To Show Up</strong></div></>}
         </div>
        </div>
        </div>
        <div className='width10'></div>
        <div className='width40'>
         <div className=" dialog__container_item input-container">
              <div className="dialog__description_config">
               <label className='margBottom10'>Name *</label>
                 <input
                   id={''}
                   name="name"
                   placeholder='Galley Name'
                   ref={inputRef0}
                   onChange={(e)=>onChange(e)}
                   style={{width:'100%'}}
                   disabled={false}
                   value={state.name !== null && state.name !== undefined ? state.name : state.name === 0 ? state.name : ''}
                   type={'text'}
                   className='edit-input'
                   />
               </div>
            </div>
            <div className="flexCol justifyContentSpaceEvenly ">
            <div className=" dialog__container_item input-container">
              <div className="dialog__description_config">
                <label className='marginTop30' htmlFor='fullcarts' >Full Carts</label>
                <input
                  id={'fullcarts'}
                  name='fullcarts'
                  placeholder='Full Carts'
                  onChange={(e)=>onChange(e)}
                  disabled={false}
                  value={state.fullCarts !== null && state.fullCarts !== undefined ? state.fullCarts : state.fullCarts === 0 ? state.fullCarts : ''}
                  type={'number'}
                  className='edit-input-galley'
                  />
              </div>
            </div>
          </div>
          <div className="flexCol justifyContentSpaceEvenly ">
            <div className=" dialog__container_item input-container">
              <div className="dialog__description_config">
                <label  className='marginTop30'>Half Carts</label>
                <input
                  id={'halfcarts'}
                  name='halfcarts'
                  placeholder='Half Carts'
                  onChange={(e)=>onChange(e)}
                  disabled={false}
                  min={0}
                  value={state.halfCarts !== null && state.halfCarts !== undefined ? state.halfCarts : state.halfCarts === 0 ? state.halfCarts : ''}
                  type="number"
                  className='edit-input-galley'
                  />
              </div>
            </div>
          </div>
          <div className="flexCol justifyContentSpaceEvenly ">
            <div className=" dialog__container_item input-container">
              <div className="dialog__description_config">
                <label className='marginTop30'>SMU</label>
                 <input
                   id="smu"
                   name='smu'
                   placeholder='SMU'
                   onChange={(e)=>onChange(e)}
                   disabled={false}
                   value={state.smu !== null && state.smu !== undefined ? state.smu : state.smu === 0 ? state.smu : ''}
                   type={'number'}
                   className='edit-input-galley' />
              </div>
            </div>
          </div>

          <div className="flexCol justifyContentSpaceEvenly ">
            <div className=" dialog__container_item input-container">
              <div className="dialog__description_config">
                <label className='marginTop30'>Misc. Stowage</label>
                 <input
                   id="stowage"
                   name='stowage'
                   placeholder='Stowage'
                   onChange={(e)=>onChange(e)}
                   disabled={false}
                   value={state.stowage !== null && state.stowage !== undefined ? state.stowage : state.stowage === 0 ? state.stowage : ''}
                   type={'number'}
                   className='edit-input-galley' />
              </div>
            </div>
          </div>




{ airCraft?.aircraftFor!=='akasa'?   <div style={{marginTop:'2.5rem',marginRight:0}} className='flexRow justifyContentSpaceEvenly'>

          <div className="flexCol justifyContentSpaceEvenly ">
            <div className=" dialog__container_item input-container">
              <div className='flexRow'>
                <div className={`dialog__description__radio`}>
                <label  className="control control-radio margRight30" htmlFor="1">
                <div className='horizontal-flex'>
               <input  id="1" type="radio"
                 name="firstClass"
                 value= {state.firstClass}
                 checked={state.firstClass}
                 disabled={false}
                 onChange={onChange} />
                <div className="control-indicator"></div>
                <span>First Class</span>
                </div>
                </label>
                </div>
            </div>

            </div>
          </div>

          <div className="flexCol justifyContentSpaceEvenly ">
            <div className=" dialog__container_item input-container">
            <div className='flexRow'>
                <div className={`dialog__description__radio`}>
                <label  className="control control-radio margRight30" htmlFor="2">
                  <div className='horizontal-flex'> <input  id="2" type="radio"
                 name="businessClass"
                 value= {state.businessClass}
                 checked={state.businessClass}
                 disabled={false}
                 onChange={onChange} />
                <div className="control-indicator"></div>
                <span>Business Class</span>
                </div>
                </label>
                </div>
            </div>
            </div>
          </div>
          <div className="flexCol justifyContentSpaceEvenly ">
            <div className=" dialog__container_item input-container">
              <div className='flexRow'>
                <div className={`dialog__description__radio`}>
                <label  className="control control-radio margRight30" htmlFor="3">
                <div className='horizontal-flex'>
               <input  id="3" type="radio"
                 name="premEconomy"
                 value= {state.premEconomy}
                 checked={state.premEconomy}
                 disabled={false}
                 onChange={onChange} />
      <div className="control-indicator"></div>
      <span>Premium Economy</span>

    </div>


                </label>
                </div>
            </div>

            </div>
          </div>

          <div className="flexCol justifyContentSpaceEvenly ">
            <div className=" dialog__container_item input-container">
              <div className='flexRow'>
                <div className={`dialog__description__radio`}>
                <label  className="control control-radio margRight30" htmlFor="4">
                <div className='horizontal-flex'>
               <input  id="4" type="radio"
                 name="economy"
                 value= {state.economy}
                 checked={state.economy}
                 disabled={false}
                 onChange={onChange} />
                <div className="control-indicator"></div>
                <span>Economy</span>
</div>
                </label>
                </div>
            </div>

            </div>
          </div>

</div>: <div style={{marginTop:'2.5rem',marginRight:0}} className='flexRow'>
<div className="flexCol justifyContentSpaceEvenly">
  <div className=" dialog__container_item input-container">
    <div className='flexRow'>
      <div className={`dialog__description__radio`}>
      <label  className="control control-radio margRight30" htmlFor="4">
      <div className='horizontal-flex'>
     <input  id="4" type="radio"
       name="economy"
       value= {state.economy}
       checked={true}
       disabled={false}
       onChange={onChange} />
      <div className="control-indicator"></div>
      <span>Economy</span>
      </div>
      </label>
      </div>
  </div>

  </div>
</div>

</div>}

<div className='dialog_buttons margTop50 flexRow justifyContentSpaceBetween alignItemsEnd'>
            <button className="save-button" disabled={buttonStatus.fields||buttonStatus.save} onClick={onEditSubmit}>{buttonStatus.save?<div className='flexRow justifyContentCenter alignItemsCenter'><img src={LoadingGIF} className="icon-size" style={{width:'20px',height:'20px'}} alt="Loading" />Saving...</div>:'Save'}</button>
            <button className="cancel-button-aircraft"  disabled={buttonStatus.fields}  onClick={onClickNewGalley}>Cancel</button>
          </div>
          <div className='flexRow textAlignCenter justifyContentCenter hor-ctr margBottom20'>
            {Boolean(updateStatusText) ?
          <>
            <div className={ "hor-ctr dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success') }>
              {updateStatusText}
             </div>
           </> : null }
           </div>
        </div>
    </div>
        </div>
      </div>
    </div>
  {editState&&Object.keys(editState).length>0 &&editState.show&& <DeleteConf {...delProps}  modalFormStatusObj={modalFormStatusObj} description={`${warningText} of ${state.name} in ${airCraft.model}`} />}
</>
  );
}
export const ConfigureGalley = (props) => {
  const onUploadClose = (e) => {
    removeContainerOverlay();
    props.onUploadClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
   <ConfigGalley
      modelTitle={props.modelTitle}
      airCraft={props.airCraft}
      stateAircraft={props.state}
      onUpdateGalley={(a,b)=>props.onUpdateGalley(a,b)}
      onUploadClose={onUploadClose}
      />
     )
}

