import React,{useState} from 'react'
import { DataGrid,UploadButton, UploadModal } from '../../../common'
import { useTranslation } from 'react-i18next'
import { onDownloadXlsFile } from '../../../utils/commonFunctions'
import { APIEndpoint } from '../../../utils/axios'
import closeIcon from "../../../images/icons/Icon2.png"
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import moment from 'moment'
import { MdOutlineDone } from "react-icons/md";

const filters = {
  action: 4,
  collection: '',
  filterOperation: 'or',
  filters: [],
  limit: 5000,
  page: 1,
  searchParam: ''
};



const rowData = [
  { slno: 1, ssrcode: "BBML", mealname: "baby" },
  { slno: 2, ssrcode: "BLML", mealname: "bland" },
  { slno: 3, ssrcode: "CHML", mealname: "child" },
  { slno: 4, ssrcode: "DBML", mealname: "diabetic" },
  { slno: 5, ssrcode: "FPML", mealname: "fruit Platter" },
  { slno: 6, ssrcode: "GFML", mealname: "gluten-free" },
  { slno: 7, ssrcode: "HNML", mealname: "Hindu" },
  { slno: 8, ssrcode: "KSML", mealname: "kosher" },
  { slno: 9, ssrcode: "LCML", mealname: "low Calorie" },
  { slno: 10, ssrcode: "LFML", mealname: "low Fat" },
  { slno: 11, ssrcode: "LPML", mealname: "low Protein" },
  { slno: 12, ssrcode: "LSML", mealname: "low Sodium" },
  { slno: 13, ssrcode: "MOML", mealname: "Muslim" },
  { slno: 14, ssrcode: "NLML", mealname: "non-lactose" },
  { slno: 15, ssrcode: "ORML", mealname: "Oriental" },
  { slno: 16, ssrcode: "RVML", mealname: "Raw Veg" },
  { slno: 17, ssrcode: "SFML", mealname: "seafood" },
  { slno: 18, ssrcode: "VGML", mealname: "vegetarian" },
  { slno: 19, ssrcode: "VLML", mealname: "vegetarian Lacto" }
]

export const Ssrcodes = () => {
  const { t } = useTranslation();
  const [state, setState] = useState({showUpload:false,selected:null})
  const [uploadFileStatusBlock, setUploadFileStatusBlock ] = useState('');
  const [uploadedFile, setUploadedFile] = useState([]);
  const [isReloadTable, setIsReloadTable] = useState(false)
  const [searchText, setSearchText] = useState("")
  const [message, setMessage] = useState("")

  const ActionCell = (params)=>{
    if(!params?.data) return null;
    return <button onClick={()=>setState(prev=>({...prev,selected:params?.data}))} className='grid-download-btnStyle1'>Edit</button>
  }

  const Columns = [
    {field:"code",headerName:"SSR Code"},
    {field:"description",headerName:"Description"},
    {field:"seatUpgradeEnabled",headerName:"Seat Upgrade Enabled",valueGetter:params=>{
      return params?.data?.seatUpgradeEnabled ? "Yes":params?.data ? "No":null
    }},
    {field:"createdAt",headerName:"Created At",valueGetter:params=>moment(params.data?.createdAt).format('DD-MM-YYYY hh:mm A')},
    {field:"modifiedAt",headerName:"Modified At",valueGetter:params=>moment(params.data?.modifiedAt).format('DD-MM-YYYY hh:mm A')},
    {field:"actions",headerName:"Actions",cellRenderer:ActionCell},
  ]

  
  async function fetchSSR(iPageNo = 1, iPageLimit = 10,search){
    console.log(search)
    filters.searchParam = ""
    filters.page = iPageNo;
    filters.limit = iPageLimit
    if(search){
      filters.searchParam = search
    }
    try {
      const res = await APIEndpoint.post("ssr/process",JSON.stringify(filters))
      return res
    } catch (error) {
      
    }
  }
  function onUploadClose(){
    setState(prev=>({...prev,showUpload:false,selected:null}))
    setMessage("")
    setUploadFileStatusBlock("")
  }
  const onDownloadSampleFile = async (e) => {
    onDownloadXlsFile('samples/downloads/SSR_Upload_Sample', 'SSR_Upload_Sample')
  }
  async function handleUploadSSR(e){
    try {
      const file=e.target.files[0];
      setUploadedFile(e.target.files[0]);
      setUploadFileStatusBlock('<p>Please wait while we process your data...</p>');
      const formData = new FormData();
      formData.append('scheduleLater', false);
      formData.append('listName', undefined);
      formData.append('targetSectorType', undefined);
      formData.append('stores', []);
      formData.append('warehouses', []);
      formData.append('file', file);
      const res = await APIEndpoint.post("ssr/upload",formData)
      setUploadedFile([]);
      setUploadFileStatusBlock('<p>File Imported Successfully.</p>');
      setTimeout(() => {
        setUploadFileStatusBlock('');
        setState(prev=>({...prev,showUpload:false}))
        setIsReloadTable(!isReloadTable)
      }, 2000);
    } catch (error) {
      e.target.value = null;
      setUploadedFile([]);
      let { status, data } = JSON.parse( error.message );
      const msgHeading = data?.Error || data?.message || error.message;

      let msgContent = "";
      if (status === 400) {
        if(data.errors){
                data.errors?.forEach( _ => {
            const lineNum = _.lineNumber;
            const errorMsgArr = [];
            _.errors.forEach(erObj => {
              errorMsgArr.push( erObj.field + ' | ' + erObj.message + ' \n<br>' );
            });

            msgContent += '<br>Line ' + lineNum + ': \n<br>' + errorMsgArr.join(' ');
        });
        }else{
          msgContent += `Failed to upload ssr.`;
        }
      } else if(status===0 && !data){
        msgContent += `Error occurred while uploading ssr sheet!<br>Please try again.`;
    }
      else {
        msgContent += `Error occurred while uploading ssr sheet!<br>Please fix the errors and re-upload.`;
      }
      setUploadFileStatusBlock("<p class='listErrorMessagesCls'>"+ msgHeading + "</p>" +
                                "<p class='listErrorMessagesCls sm-scrollbar border p-2 pt-1'>" + msgContent +"</p>"
                              );
    }
  }
  async function onSaveSSR(){
    try {
      const updated = {
        description:state.selected?.description,
        seatUpgradeEnabled:state.selected?.seatUpgradeEnabled
      }
      const res = await APIEndpoint.put(`ssr/${state.selected.id}`,updated)
      if(res.success){
        setIsReloadTable(!isReloadTable)
        setMessage(res.message)
        setTimeout(() => {
          setState((prev)=>({...prev,selected:null}))
          setMessage("")
        }, 2000);
      }
    } catch (error) {
      console.log(error)
    }
  }
  return (
    <>
    <div className="flexCol full-flex ag-theme-alpine-dark">
      <div className="flexRow justifyContentFlexEnd alignItemsCenter margBot10">
      <button className='upload dropdownStyle1-TextMedium' onClick={() => setState((_) => ({ ..._, showUpload: true }))}>
        <span>
          <img  className="icon-size mt-1" alt='uploadItem' src={uploadItemIcon} />
          <u>Upload SSR</u>
        </span>
      </button>
      </div>
      {/* <div className='flexRow margTop8 margBot10'>
        <select className='select-item' name="errorType" id="" onChange={errorTypeChangeHandler}>
          <option value="">Error Type</option>
          <option value="IROP">IROP</option>
          <option value="API">API Failure</option>
          <option value="SSR">SSR</option>
        </select>
      </div> */}
      <div className='flexCol full-flex'>
        <DataGrid
        className="full-flex"
        columns={[...Columns]}
        showDefaultFilters={true}
        getRows={fetchSSR}
        columnResize={true}
        rowSelection="multiple"
        resetDataSource={isReloadTable}
      />
      </div>
      
    </div>
    {
      state.showUpload &&
      <UploadModal
        onUploadFileChangeHandler={handleUploadSSR}
        modelTitle={"Upload SSR"}
        onUploadClose={onUploadClose}
        onDownloadSampleFile={onDownloadSampleFile}
        uploadFileStatusBlock = { uploadFileStatusBlock }
        uploadedFile={uploadedFile}
      />
    }
    {
      state.selected && (
        <div className="overlay mt-4" role="dialog">
            <div className="dialog sm-scrollbar">
              <div className="dialog__content">
                <img
                  className="modal__close_icon"
                  alt="Close"
                  src={closeIcon}
                  onClick={onUploadClose}
                />
                <h2 className="dialog__title subheading-text-medium fontMedium">
                  Edit SSR - {state.selected?.code}
                </h2>
                <hr />
                <div className="dialog__body_container flexRow mt-4 justifyContentCenter flexWrap">
                  <div className={`dialog__container_item input-container`}>
                    <div className="flexCol margBot10">
                      <label htmlFor="">Description</label>
                      <textarea className='edit-input' name="" id="" value={state.selected?.description} onChange={(e)=>{
                        setState((prev)=>({...prev,selected:{...prev.selected,description:e.target.value}}))
                      }}></textarea>
                    </div>
                    <div className="flex alignItemsCenter margBot10">
                      <input className='margRight10' id='seatUpgradeEnabled' type="checkbox" checked={state.selected?.seatUpgradeEnabled} onChange={e=>{
                        setState((prev)=>({...prev,selected:{...prev.selected,seatUpgradeEnabled:e.target.checked}}))
                      }} />
                      <label htmlFor="seatUpgradeEnabled">Seat upgrade enabled</label>
                    </div>
                    <div className='flex justifyContentCenter'>
                      <button onClick={onSaveSSR} className='done-button'>Save</button>
                    </div>
                    {message && (
                      <div className='successMsg'>
                        <MdOutlineDone/>{message}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
        </div>
      )
    }
    </>
  )
}
