import React, { useState} from "react";
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/icons/search.svg';
import downloadMasterIcon from '../../../assets/icons/download-master.svg';
import uploadItemIcon from '../../../assets/icons/upload-items.svg';
import { onDownloadXlsFile, translationLength } from '../../../utils/commonFunctions';
import { DataGrid,UploadModal } from "../../../common";
import { APIEndpoint } from "../../../utils/axios";
import { PageSizes } from "../../../utils/configs";
import moment from "moment";

    const Columns = [
    {field: 'ssrCode', headerName: 'SSR Code', sortable: true, filter: true,suppressSizeToFit:true, resizable:true,flex:1,tooltipField: 'code'},
    {field: 'description', headerName: 'Description', sortable: true,suppressSizeToFit:true, resizable:true,flex:1, filter: true},
    {field: 'seatUpgradeEnabled', headerName: 'Seat Upgrade Enabled', sortable: true, suppressSizeToFit:true, resizable:true,filter: true},
    {field: 'createdAt',valueGetter: (params) => {
    return params.data?.createdAt?moment(params.data?.createdAt).format('MM-DD-YYYY'):'';
    }, headerName: 'Created At', sortable: true,suppressSizeToFit:true, resizable:true, filter: true},
    {field: 'lastModifiedAt',valueGetter: (params) => {
    return params.data?.lastModifiedAt.employeeName;
    }, headerName: 'Last Modified At', sortable: true,suppressSizeToFit:true, resizable:true, filter: true},
    ];

export const SSR = (props) => {
 const { t } = useTranslation();
   const[state,setState]=useState({date:null});
   const [pageLimit, setPageLimit] = useState(10);
   const [searchKey, setSearchKey] = useState('');
   const [actionItems, setActionItems] = useState([]);
   const [isDownloading,setIsDownloading]=useState(false);
   const [isReloadTableData, setIsReloadTableData ] = useState(false);
   const[showUpload,setShowUpload]=useState(false);

   React.useEffect(() => {
       setActionItems([
           {
               title: '',
               attribute: 'Download',
           },

       ]);
       setIsReloadTableData(true);
   }, []);


   const getActionCellIcons = (cellDef, data) => {

       if (cellDef.attribute === 'Download') {
           if(data && state?.selected && data.code === state?.selected?.code){
               return [
                   {src: downloadMasterIcon, value:"Download", title: `${isDownloading?"please wait...":"Download"}`},
               ];
           }
           else{
               return [
                   {src: downloadMasterIcon, value:"Download", title: `Download`},
               ];
           }
       }
       return [];
     };
     const onUploadClose = () => {
       setShowUpload(false);
   };

   const onDownloadSampleFile = async() => {
    onDownloadXlsFile('samples/downloads/SSR_Upload_Sample','SSR_Upload_Sample')
   }


   const filterData = {
       action: 4,
       collection: '',
       filterOperation: 'or',
       filters: [],
       limit: 10,
       page: 1,
       searchParam: ''
   };
   const fetchSsrProcess = async (page=1, perPage=10) => {

           filterData.page = page;
           filterData.limit = perPage;
           filterData.searchParam = searchKey;
           const res = await APIEndpoint.post('ssr/process', JSON.stringify(filterData));
           return res;

   };

   const onPerLimitChange = (e) => {
       setPageLimit(Number(e.target.value));
   };

   const onSearch = (e) => {
       setSearchKey(e.target.value);
   };
   const onAction = async(e, action, selectedRowData) => {
       setState( (_prev) => {
         return { ..._prev, selected: {...selectedRowData, code:selectedRowData.code,action: action.value }}
       });
       let header={
           'Accept':'text/csv'
         }

            try {
               const res = await APIEndpoint.post(`warehouses/${selectedRowData.warehouseId}/stockCorrections/${selectedRowData.id}/report`,{},header);
               if(res.status==='scheduled'){
                setIsDownloading(true)
                 const dowId=await APIEndpoint.get(`downloads/status/${res.id}`);
                 if(dowId.status==="completed"){
                 APIEndpoint.get(`downloads/analytics/${dowId.fileName}${dowId.fileExtension}`).then((physicalInvDownload) => {
                    const url = window.URL.createObjectURL(new Blob([physicalInvDownload]));
                    const link = document.createElement('a');
                    link.href = url;
                    link.setAttribute('download', 'physical_inventory.csv'); //or any other extension
                    document.body.appendChild(link);
                    link.click();
                     setIsDownloading(false)
                });
               
                 }
               }
               }
                catch (error) {
                  console.log(error)
                 setIsDownloading(false)
                }

     };
     const onUploadDistributorMappingClick=()=>{
       setShowUpload(true)
   }

   return (
       <>
           <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
               {
                   <>
                    <div className="flexRow justifyContentFlexEnd alignItemsCenter margRight margBot10">
                       <button className='upload dropdownStyle1-TextMedium' onClick={() => onUploadDistributorMappingClick(true)}> <span><img  className="icon-size mt-2" alt='uploadItem' src={uploadItemIcon} /><u>{t('filters.uploadSsr')}</u></span></button>
                       </div>
                       <div className='flexRow width100 margBot10 margTop8 justifyContentSpaceBetween'>
                            <button className='page-button'>
                               <select className="darkBlue-bg" onChange={onPerLimitChange} value={pageLimit} id="page-size">
                               {
                                   PageSizes.map((_) => {
                                       return (
                                       <option key={_.value} value={_.value}>{_.label}</option>
                                       )
                                   })
                               }
                               </select>
                           </button>

                           <div className="mt-2 underline">
                               <span><img  className="icon-size" alt={t('filters.searchText')} src={searchIcon} /></span>
                               <span><input className="search" onChange={onSearch} type="search" placeholder={t('filters.searchText')} value={props.searchKey}/></span>
                           </div>
                       </div>


                       <>
                           <DataGrid
                               className="full-flex"
                               columns={Columns}
                               initialPageLimit={pageLimit}
                               showDefaultFilters={false}
                               searchKey={searchKey}
                               date={state.date}
                               getRows={fetchSsrProcess}
                               noResultsMessage={ "No Result found" }
                               getActionCellIcons={getActionCellIcons}
                               rowSelection="multiple"
                               actions={actionItems}
                               resetDataSource={isReloadTableData}
                               onAction={onAction}
                               columnResize={true}
                           />
                       </>
                   </>
               }
               {showUpload &&
               <UploadModal
                   modelTitle={'Upload SSR'}
                   onUploadClose={onUploadClose}
                   onDownloadSampleFile={onDownloadSampleFile}
               />}
               {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
           </div>
       </>
   );
}

