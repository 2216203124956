import React, { useRef, useState } from 'react'
import { APIEndpoint } from '../../../utils/axios';
import { DataGrid, SubCategoryModal } from '../../../common';
import { useTranslation } from 'react-i18next';
import Edit2 from "../../../assets/edit2.svg";
import { translationLength } from '../../../utils/commonFunctions';
import usePermissions from '../../../utils/userPermissions';
const Columns = [
  { field: 'name', headerName: 'Name', sortable: true,suppressSizeToFit:true, flex:1,resizable:true, minWidth: 165 },
  { field: 'description', headerName: 'Description', sortable: true, flex:1,suppressSizeToFit:true,resizable:true, minWidth: 200 },
  { field: 'catName', headerName: 'Category', sortable: true,  flex:1,suppressSizeToFit:true,resizable:true,minWidth: 165 },
];

const FormFields = [
  { label: 'Category', attribute: 'catName', type: 'select',  disabled: false, options: [], required: true, },
  { label: 'Name', id: 'category-name-add', attribute: 'name', type: 'text', required: true},
]

const EditFormFields = [
  { label: 'Category', attribute: 'catName', type: 'select',  disabled: false, options: [], required: true, },
  { label: 'Name', attribute: 'name', type: 'text', required: true},
  { label: 'Description', attribute: 'description', type: 'textarea', required: true, rows: 4},
]

export const ManageSubCategoriesPage = (props) => {
  const { t } = useTranslation();
  const [state, setState] = useState({});
  const tableRef = useRef();
  const [modalFormStatusObj, setModalFormStatusObj] = useState({ text: '' });
  const { hasPermission: createSubCategory } = usePermissions('create', "inventory.sub-categories");
  const { hasPermission: editSubCategory } = usePermissions('edit', "inventory.sub-categories");
  React.useEffect(() => {
    filterCategories();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchSubCategories = async (iPageNo = 1, iPageLimit = 10, iSearchText = '') => {
    filterData.page = iPageNo;
    filterData.limit = iPageLimit;
    filterData.searchParam = iSearchText;
    const res = await APIEndpoint.post('subcategories/process', filterData);
    return res;
  }

  const fetchCategories = async () => {
    try {
     const resp = await APIEndpoint.get('categories');
    if (resp.result.length > 0) {
      const options = [{key: 0, label: 'Select Category', value: ''}];
      resp.result.filter( (fieldValue, index) => {
        options.push({key: fieldValue.id, label: fieldValue.value, value: fieldValue.value});
        return fieldValue;
      });
      return options
    } else {
      console.log('Log: Error while fetching category');
    }
    } catch (error) {

    }

  };

  const filterCategories = async () => {
    let options = await fetchCategories();
    FormFields.map( editRow => {
      if (editRow.attribute === 'catName' ) {
        editRow.options = options;
      }
      return editRow;
    });
    EditFormFields.map( editRow => {
      if (editRow.attribute === 'catName' ) {
        editRow.options = options;
      }
      return editRow;
    });
  };

  const handleAction = (e, action, row) => {
    setState((_prev) => {
      return { ..._prev, editRow: row }
    })
  }

  const handleFormSubmit = async (e, values) => {
    setModalFormStatusObj({ text: '' });

    if(values[0].category.id === 0){
      setModalFormStatusObj({ text: 'Please Select Category', error: 1 });
      return false;
    }
    if(values[0].sub_categories[0].subCategory === ''){
      setModalFormStatusObj({ text: 'Please Enter Sub Category Name(s)', error: 1 });
      return false;
    }

    let isEdit = Boolean(state.editRow);
    let res;
    try {
      if (isEdit) {
        let options = await fetchCategories();
        options.filter( (fieldValue, index) => {
          if(fieldValue.value === values[0]?.category.value){
            options = fieldValue.key;
            return fieldValue.key;
          }
          return "null"
        });
        let cat_id = Number(options)

        values = { ...state.editRow, ...values };
        let edit_filter = {subCatId: values.subCatId, name: values[0]?.sub_categories[0]?.subCategory, description: values[0].description, catId: cat_id};
        res = await APIEndpoint.put('subcategories/' + values.subCatId, edit_filter);
      }
      else {
        let filter = values[0]
        res = await APIEndpoint.post('subcategory', filter);
      }
      if (res && res.success) {
        setModalFormStatusObj({ text: res.msg });
        setTimeout(()=>{
          handleReset(e, true)
        },1500)
      }
    } catch (error) {
      let {data}=JSON.parse(error.message)
      setModalFormStatusObj({ text:data.msg, error:1})
      return false;
    }
  };

  const handleReset = (e, reset = false) => {
    setModalFormStatusObj({ text: '' });
    setState((_) => {
      return { ..._, resetDataSource: reset ? true : state.resetDataSource, showAddForm: false, editRow: null };
    })
    if (reset) {
      let timerId = setTimeout(() => {
        clearTimeout(timerId);
        setState((_) => {
          return { ..._, resetDataSource: false };
        })
      }, 500)
    }
    setModalFormStatusObj({ text: '' });
  }
  const handleAddSubCategory = () => {
    setState((_) => {
      return { ..._, showAddForm: true }
    })
  }
  return (
    <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      <DataGrid
        ref={tableRef}
        columnResize
        showDefaultFilters
        className="full-flex"
        columns={Columns}
        resetDataSource={state.resetDataSource}
        getRows={fetchSubCategories}
        rowSelection="multiple"
        pageType={false}
        actions={[
          { detail: [{ src: Edit2, value: "edit",title:'Edit' }] }
        ]}
        onAction={handleAction}
        addButton={{
          title: t('pages.productsContent.manageSubCategories.addBtnTitle'),
          onClick: handleAddSubCategory,
        }}
      />
      {
        Boolean((state.showAddForm && createSubCategory) || (state.editRow && editSubCategory)) && (
          <SubCategoryModal
            title={
              Boolean(state.editRow) ? t('pages.productsContent.manageSubCategories.editModalTitle') :
                t('pages.productsContent.manageSubCategories.addModalTitle')
            }
            onClose={handleReset}
            onSubmit={handleFormSubmit}
            modalFormStatusObj={modalFormStatusObj}
            buttonLabel={Boolean(state.editRow) ? 'Save': 'Add'}
            fields={ Boolean(state.editRow) ?
              EditFormFields.map((elt) => {
                return { ...elt, initialValue: state.editRow[elt.attribute] || '' }
              }) :
              FormFields.map((elt) => {
                return { ...elt, initialValue: '' }
              })
            }
            showFieldsInColumn={true}
            editRow={state.editRow}
          />
        )
      }
       {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }
    </div>
  )
}