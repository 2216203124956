/* eslint-disable no-unused-vars */
/* eslint-disable default-case */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef,useCallback } from "react";
import { useTranslation } from 'react-i18next';
import { Input, EditModal } from "../../../common"
import {getLocalTimezoneFormatted} from '../../../utils/commonFunctions'
import ResizeObserver from 'resize-observer-polyfill';
import moment from "moment-timezone";
import "../../../assets/styles/controlpanel.scss";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { DFPAPIEndpoint, APIEndpoint, DFPCALNDREndpoint } from "../../../utils/axios";
import { CalendarCateringScreen } from "./Calender-Catering";
import { saveAs } from 'file-saver';
import Select from 'react-select';
import { BiReset } from "react-icons/bi";
import usePermissions from "../../../utils/userPermissions";

const GroupingModes = [
  { id: 'station', label: 'Catering Airport' },
  { id: 'tail', label: 'Tail Number' },
]
const TimeZones = [
  { key:0,value: getLocalTimezoneFormatted('localTimezone'), label: 'Local Time Zone' },
  { key:1,value: getLocalTimezoneFormatted('gmt'), label: 'GMT' },
]

const TailLegDetails = [
  { label: 'Time of Uplift' }, { label: 'Total Uplifted' },
  { label: 'Total Confirmed' }, { label: 'Total Sales' },
  { label: 'Reported Wastage' }, { label: 'Offload' },
  { label: 'Received Warehouse' }, { label: 'Crew Details' },
  { label: 'No. of Economy Class' }, { label: 'Premium Economy' },
  { label: 'Prebook' }, { label: 'Card Sale' },
  { label: 'Cash Sales' }, { label: 'Declined' },
]
const CateringDetails = [
  { label: 'Total Requested' }, { label: 'Total Uplifted' },
  { label: 'Total Verified' }, { label: 'Warehouse Return' }
]
const editAirports=[ {label: 'Catering Airport', attribute: 'cateringAirport', type: 'selectSearch2', disabled: false, options: [], required: true ,width:'15rem'},]

const sType = localStorage.getItem('sType');
  const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);

export const CalendarControlHomePanelInner = (props) => {
  const { t } = useTranslation();
  const tblRef = useRef(null)
  const detachAttach=useRef(false);
  const calenderRef = useRef([]);
  const extraHrs = useRef(null);
  const [toolbar, setToolbar] = useState({tailNo:[{key: 0, label: "Select Tail Number", value: 'Select Tail Number'}],baseStn:[{key: 0, label: "Select Catering Airport", value: 'Select Catering Airport'}] ,timezone: 'Local Time Zone', date: new Date(), groupBy: "Catering Airport" });
  const [message,setMessage]=useState({text:""});
  const [storesList,setStoresList]=useState([]);
  const [airportFields]=useState(editAirports);
  const [modalFormStatusObj,setModalFormStatusObj]=useState({text:""});
  const [tailNumber,setTailNumbers]=useState([]);
  const [flightIndex,setFlightIndex]=useState({prevTailNumber:null});
  const [flightGroups,setFlightGroups]=useState([]);
  const [state, setState] = useState({ lastAction: null,isOpenMenu:false,saveDfp:false,showAirportSelect:false, isEdited:0,showDialog: '',flightsRows: [],catAirp:"", catAirports:[],contextData: null,showNoData:false,noDataShow:"",timeZone:[...TimeZones] });
  const { hasPermission: editDFpcalender } = usePermissions('edit', "dfp-calender");
  const { hasPermission: viewcalender } = usePermissions('view', "dfp-calender");

useEffect(() => {

  if (state && state.flightsRows) {
    const defaultOption = {key: 0, label: 'Catering Airport',value: ''};
    let options = [defaultOption];
    let optionsTailNo = [];
    let cateringStation = null;

    if (state.lastAction?.item) {
      if (state.lastAction.contextData) {
        const { flight } = state.lastAction.contextData;
        const tailNumber = state.flightsRows[flight.i]?.tail;
        if (tailNumber && tailNumber.flights.length > 0) {
          cateringStation = tailNumber.flights[0].startAirport?.locationCode;
        }
      }

      state.flightsRows.forEach((flightRow, index) => {
        if (flightRow.primary && flightRow.primary.label !== cateringStation ) {
          options.push({
            key: index + 1,
            label: flightRow.primary.label,
            value: flightRow.primary.label
          });
        }
        if (flightRow.tail || flightRow.catGroups) {
          optionsTailNo.push({
            tail: flightRow.tail,
            primary:flightRow?.primary?flightRow?.primary:null,
            catGroups: flightRow.catGroups
          });
        }
      });

      setState(prevState => ({
        ...prevState,
        catAirports: options,
        tailNos: optionsTailNo
      }));

      airportFields.forEach(field => {
        if (field.attribute === "cateringAirport") {
          field.options = options;
          field.disabled=state?.catAirp.length?false:true
        }
      });
    }
  }

}, [state.lastAction]);


  useEffect(() => {
    showToast();
    const timer = setTimeout(() => {
      setMessage({text:""});
    }, 3000);
    return () => clearTimeout(timer);
  }, [message]);

  useEffect(() => {
    if (state.flightsRows && state.lastAction && state.catAirports && state.tailNos) {
        if (detachAttach.current) {
          return;
        }
    detachAttach.current = true;
    let catApt=false;
    const tailNumber = getCorrectedTailNo(state.lastAction?.item?.flightDetails?.sector?.item?.tailNumber);
    const lastAction = JSON.parse(JSON.stringify(state.lastAction));
    const flightDetails = lastAction?.item?.flightDetails;
    const sectorIndex = lastAction?.item?.flightDetails?.sectorIndex;
    const catGroup = flightDetails?.catGrp;
    let cateringStation;
    let modCatAirports=[];

    const { flight } = state.lastAction?.contextData;
    const action = lastAction?.item.action;
    const flightSegment = state.flightsRows[flight.i].flights[flight.j];

    if (state.tailNos && flightSegment) {
       const tailNumber = state.flightsRows[flight.i]?.tail;
      if (tailNumber && tailNumber.flights.length) {
         cateringStation = tailNumber?.flights[0]?.startAirport?.locationCode;
      }
    }

    if (flightIndex.prevTailNumber !== tailNumber) {
      setFlightIndex((_) => ({ ..._, prevTailNumber: tailNumber }));
    }


    if (flightSegment) {
      const { item } = flightSegment;
      if (sectorIndex >= 0 && tailNumber && cateringStation) {
        for(let flight of state.flightsRows){
          if(flight.primary && flight.primary.label && flight.primary.label === item.origin){
            catApt=true
          }
        }

          state.flightsRows.forEach((flightRow, index) => {
            if (flightRow.primary && flightRow.primary.label !== cateringStation ) {
              modCatAirports.push({
                key: index + 1,
                label: flightRow.primary.label,
                value: flightRow.primary.label
              });
            }
          });


        if (modCatAirports.length && !modCatAirports.some((apt) => apt.label === item.origin) &&
          action !== "attach"
        ) {
          setModalFormStatusObj({ text: item.key });
          setState((prevState) => ({
            ...prevState,
            catAirports:modCatAirports,
            showAirportSelect: true,
          }));
        } else {
          const {detachedSector, tailNos } = state;
          const flightsRows = JSON.parse(JSON.stringify(state.flightsRows));
          const { key ,origin} = detachedSector.item;
           let targetIndex=null,modObjIndex,modObj={};
          if (cateringStation !== origin) {
            const indexCat = flightsRows.findIndex(
              (fl) => fl.primary && fl.primary.label === origin
            );

            const storeName = `${flightDetails.sector.item.origin}-${flightDetails.sector.item.destination}`;
            const sectorWidth = flightDetails.sector.width;
            const sectorLeft = flightDetails.sector.left;
            const tailNumber = getCorrectedTailNo(state.detachedSector.item.tailNumber);
            let catGrName = catGroup.item?.name.split("/");
            let startAirport;
            let newCatName = `${catGrName[0]}/${storeName}`;
            catGroup.item = { ...catGroup.item, name: newCatName };
            catGroup.width = sectorWidth;
            catGroup.left = sectorLeft;

            if (indexCat !== -1 && action !== "attach") {
              let cateringSegmentArr = flightsRows.slice(indexCat, indexCat + (flightsRows[indexCat].primary?.count || 0));
              startAirport=cateringSegmentArr[0]?.tail?.flights[0]?.startAirport;
                cateringSegmentArr.forEach((ct) => {
                  const { group } = ct.tail;
                  if (group === tailNumber) {
                    ct.flights.push({
                      width: sectorWidth,
                      ...state.detachedSector,
                    });
                    ct.catGroups.push(catGroup);
                  } else {
                    let catTailDetails=null;
                    let startIndex = -1;

                    for (let i = 0; i < state.tailNos.length; i++) {
                        const item = state.tailNos[i];

                        if (item.primary && item.primary.label === cateringStation) {
                            startIndex = i;
                            if (item.tail.group === tailNumber) {
                              item.tail.flights[0].startAirport=startAirport;
                              catTailDetails= item.tail;
                            }
                            break;
                        }
                    }

                    if (startIndex !== -1) {
                        for (let i = startIndex + 1; i < state.tailNos.length; i++) {
                            const item = state.tailNos[i];
                            if (item.tail.group === tailNumber) {
                              item.tail.flights[0].startAirport=startAirport;
                              catTailDetails= item.tail;
                              break;
                            }
                        }
                    }

                    const updatedCateringSegmentArr = cateringSegmentArr.map((item) => {
                      if (item.primary) {
                        return {
                          ...item,
                          primary: {
                            ...item.primary,
                            count: (item.primary.count || 0) + 1,
                          },
                        };
                      }
                      return item;
                    });

                    updatedCateringSegmentArr.push({
                      flights: [
                        {
                          endTime: state.detachedSector.endTime._i,
                          startTime: state.detachedSector.startTime._i,
                          width: sectorWidth,
                          ...state.detachedSector,
                        },
                      ],
                      catGroups: [catGroup],
                      tail: catTailDetails,
                    });

                    cateringSegmentArr = [
                      ...updatedCateringSegmentArr.reduce((acc, segment) => {
                        const existingSegment = acc.find(
                          (seg) => seg.tail.group === segment.tail.group
                        );

                        if (!existingSegment) {
                          acc.push(segment);
                        }
                        return acc;
                      }, []),
                    ];
                  }
                });

                const labelsToReplace = cateringSegmentArr[0].primary?.label;

                const updatedArray = flightsRows.reduce((acc, item) => {
                  if (item.primary?.label === labelsToReplace) {
                    return [...acc, ...cateringSegmentArr];
                  }
                  return [...acc, item];
                }, []);


                const filteredArray = removeDuplicatesInSections(updatedArray);

                const primaryLabelIndex = filteredArray.findIndex(
                  (item) => item.primary?.label === cateringStation
                );

                const targetGroupExist =
                filteredArray[primaryLabelIndex]?.tail?.group === tailNumber

                if (targetGroupExist) {
                  modObj = filteredArray[primaryLabelIndex];
                  targetIndex = primaryLabelIndex;
                } else {
                  const primaryIndices = filteredArray
                    .map((item, index) => (item.primary?.label ? index : -1))
                    .filter((index) => index !== -1);

                  const nextPrimaryIndex = primaryIndices.find(
                    (index) => index > primaryLabelIndex
                  );

                  if (nextPrimaryIndex !== undefined) {
                    const targetObjectIndex = filteredArray.findIndex(
                      (item, index) =>
                        item.tail?.group === tailNumber &&
                        index > primaryLabelIndex &&
                        index < nextPrimaryIndex
                    );

                    if (targetObjectIndex !== -1) {
                      targetIndex = targetObjectIndex;
                      modObj = filteredArray[targetObjectIndex];
                    }
                  }else{
                    const arrayGroup= filteredArray.slice(primaryLabelIndex);
                    if(arrayGroup){
                     modObj=arrayGroup.find(aObj=>aObj.tail.group === tailNumber)
                     modObjIndex=arrayGroup.findIndex(aObj=>aObj.tail.group === tailNumber)
                     targetIndex = modObjIndex+primaryLabelIndex;
                    }
                   }
                }


let updatedAirportObj = {};

if (modObj?.flights) {
const catGroupNewDetails= getModifedCatgroupWidth(
  modObj?.flights
);

let catGroupNew= {...catGroup,left:catGroupNewDetails.catLeft,width:catGroupNewDetails.catWdth,item:{...catGroup.item,name:catGroupNewDetails.storeNames,sectors:catGroupNewDetails.sectorsNew}}

if(catGroupNew){
  if(catGroup.item.sectors && catGroup.item.sectors.length === 1){
    updatedAirportObj = {
      ...modObj,
      flights: modObj.flights.filter(
        (flight) => flight.item.id !== flightDetails.sector.item.id
      ),
      catGroups: modObj.catGroups
        .filter((catGroup) =>
          catGroup.item?.name !== catGroupNew.item?.name

      )
        .filter((catGroup) => catGroup.width > 0)
    };
  }else{
    updatedAirportObj = {
      ...modObj,
      flights: modObj.flights.filter(
        (flight) => flight.item.id !== flightDetails.sector.item.id
      ),
      catGroups: modObj.catGroups
        .map((catGroup) => {
          if (catGroup.item?.name === catGroupNew.item?.name) {
            return catGroupNew;
          }
          return catGroup;
        })
        .filter((catGroup) => catGroup.width > 0)
    };
  }
}

const updatedArrayRemObj = filteredArray.map((item, index) =>
  index === targetIndex ? updatedAirportObj : item
);

if (updatedArrayRemObj) {
  setState((prevState) => ({
    ...prevState,
    showAirportSelect: false,
    saveDfp:true,
    flightsRows: updatedArrayRemObj,
  }));

  setMessage({text:`${key} moved to ${detachedSector.item.origin}`});
}
}
if (indexCat !== -1 && calenderRef.current[indexCat]) {
  calenderRef.current[indexCat].scrollIntoView({ behavior:'auto', block: 'start' });
}

            }
          }

        }
      }
    }
  }


}, [state.lastAction, state.catAirports])



  useEffect(() => {
    const currentRef = tblRef.current;
    let resizeTimeout;
    fetchFlighSchedule()
    fetchDataFilters()
    const resizeObserver = new ResizeObserver((entries) => {
      cancelAnimationFrame(resizeTimeout);
      resizeTimeout = requestAnimationFrame(() => {
        // Handle the resize completed here
        const { width, height } = entries[0].contentRect
        setState((_) => ({ ..._, layout: { height: height, width: width } }))
      });
    });
    if (currentRef) {
      resizeObserver.observe(currentRef);
    }
    const handleClick = () => {
      setState((_) => ({ ..._, contextData: null, cateringContext: null }))
    };
    document.addEventListener("click", handleClick);
    return () => {
      if (currentRef) {
        resizeObserver.unobserve(currentRef);
      }
      cancelAnimationFrame(resizeTimeout);
      document.removeEventListener("click", handleClick);
    };
  }, [])

  useEffect(() => {
    fetchFlighSchedule();
  }, [toolbar])

  useEffect(() => {
    fetchTimeZone();
    fetchAirports();
  }, [])

  const showToast = () => {

    if (message.text) {
      toast.success(message.text, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: false,
        autoClose: 3500,
        style: {
          backgroundColor: '#4cae4c',
          color: '#fff',
          borderRadius: '8px',
          fontSize: '1rem',
        }
      });
    } else if (message.error) {
      toast.error(message.error, {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
        pauseOnHover: true,
        draggable: false,
        autoClose: 3500,
        style: {
          backgroundColor: '#d9534f', 
          color: '#fff',
          borderRadius: '8px',
          fontSize: '1rem',
        }
      });
    } else {
      
    }
  };

  async function fetchTimeZone() {
    try {
      const sType = localStorage.getItem('sType');
      const DOMAIN = (sType === 'dfp' ? DFPAPIEndpoint : APIEndpoint);
      const res = await DOMAIN.get(`customer/app/config`);
      if(res.success && res.result?.timezone){
        setState(prev => {
          const timezonArr = [{ key: 2, value: res.result.timezone.formattedName, label: res.result.timezone.formattedName }];
          const updatedTimeZone = [
              ...prev.timeZone.filter(item => item.key !== 2),
              ...timezonArr
          ];
      
          return {
              ...prev,
              timeZone: updatedTimeZone
          };
      });
        setToolbar(prev=>{
          return {...prev,timezone:res.result.timezone.formattedName}
        })
      }
    } catch (error) {
      console.log(error);
    }
  }

  const fetchAirports = async () => {
    try {
       const resp = sType === 'dfp' ? await DFPAPIEndpoint.get('airports') : await APIEndpoint.get('airports');
    if (resp.items.length > 0) {
      const options = [];
      resp.items.filter( (fieldValue, index) => {
        options.push({key: fieldValue.id, label: fieldValue.locationCode, value: fieldValue.locationCode});
        return fieldValue;
      });
      setStoresList(options);

    } else {
    
    }
    } catch (error) {

    }

  };

const fetchDataFilters = useCallback(
  async () => {
    try {
      const [resTailNo,respStores] = await Promise.all([
        DOMAIN.get('sandboxing/aircrafts'),
        APIEndpoint.get('stores'),
      ]);
      if (resTailNo.result.length ) {
            const options1 = [];
            resTailNo.result.filter( (fieldValue, index) => {
              options1.push({key: index+1, label: `${fieldValue.value}`, value: `${fieldValue.value}`});
              return fieldValue;
            });
       setTailNumbers(options1)
      }
      if(respStores.result.length){
        const options = [];
        const baseStn = respStores.result.filter((fieldValue, index, self) => {
          return self.findIndex((t) => t.origin === fieldValue.origin) === index;
        }).map((item,index)=>({key: index+1, label:item.origin, value:item.origin}));
        setStoresList([...options,...baseStn]);
      }
    } catch (error) {

    }


  },

  [],
)

const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    position: "relative",
    zIndex: state.isOpenMenu ? 9999 : null,
  }),
  menuPortal: (base) => ({
    ...base,
    zIndex: 9999, // Set a high z-index for the portal
  }),
  input: (defaultStyles) => ({
        ...defaultStyles,
        color: '#fff',
        }),
        control: (base) => ({
          ...base,
          minHeight: '40px',
          height: '40px',
          borderColor: '#fff',
          border:'0.7px solid #ffff',
          borderRadius: '3px',
          backgroundColor: '#162c3b',
          '&:hover': {
            borderColor: '#fff'
          },
        }),
        placeholder: (provided,state) => ({
          ...provided,
          whiteSpace: 'nowrap',
          overflow:state.isFocused ? '' : 'hidden',
          fontSize:state.isFocused ? '0.9rem' : '',
          textOverflow: 'ellipsis',
          color:  state.isFocused ? '#6e7c84' : '#fff',
        }),
        indicatorSeparator: (provided) => ({
          ...provided,
          display: 'none', // Remove the indicator separator
        }),
        dropdownIndicator: (provided) => ({
          ...provided,
          color: '#fff',
        }),
};


  const getTimeColumns = () => {
    let hours = 25
    if (toolbar.date && extraHrs.current) {
      // hours += extraHrs.current;
      // hours += 1;
    }
    return new Array(hours).fill('').map((_, i) => {
      let date = moment().set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
      return { label: date.add(i, "hours").format('HH:mm'), value: i }
    })
  }

  const getEncodedTimezone=(timezoneString)=> {
    const utcRegex = /UTC([+-]\d{2}:\d{2})/;
    const gmtRegex = /GMT([+-]\d{2}:\d{2})/;
    let match = timezoneString.match(utcRegex) || timezoneString.match(gmtRegex);
    if (match) {
        const timezoneOffset = match[1];  
        const encodedTime = encodeURIComponent(`T00:00:00${timezoneOffset}`);
        return encodedTime;
    } else {
       
        return null;
    }
}

  const fetchFlighSchedule = async () => {

    extraHrs.current = 0
    const date = moment(toolbar.date).format('YYYY-MM-DD')
  const localIsoString = getEncodedTimezone(toolbar.timezone);

    if(toolbar.timezone && localIsoString){
    try {
   
      const plans = await DFPAPIEndpoint.get(`operations/flightPlans/dates/${date}${localIsoString}`)      // const res = await AssetEndpoint.post('flightSchedule/getSchedules', { targetDate, dontReturnCrewInfo: true })
      let rows = groupSchedules(plans.flightPlan.groups);
      if(toolbar.tailNo[0]&&!toolbar.tailNo[0].label.includes("Select")){
         let filteredGroups=plans.flightPlan.groups.filter(tNo=>getCorrectedTailNo(tNo.tailNumber)===toolbar.tailNo[0].value)
          if(filteredGroups.length){
            rows= groupSchedules(filteredGroups);
            setState((_) => ({ ..._,showNoData:false,noDataShow: "" }))

          }else{
          rows=[]
          setState((_) => ({ ..._,flightsRows: rows,showNoData:false,noDataShow: "No Data" }))
          return
          }
         }
        if(toolbar.baseStn[0]&&!toolbar.baseStn[0].label.includes("Select")){
          let filteredGroups=plans.flightPlan.groups.filter(tNo=>tNo.startAirport.locationCode === toolbar.baseStn[0].value)
          if(filteredGroups.length){
            rows= groupSchedules(filteredGroups);
            setState((_) => ({ ..._,showNoData:false,noDataShow: "" }))

          }else{
          rows=[]
          setState((_) => ({ ..._,flightsRows: rows,showNoData:false,noDataShow: "No Data" }))
          return
          }
    }
      setState((_) => ({ ..._, flightsRows: rows, showNoData:false, noDataShow:rows.length>0? "":"No Data"}))
    } catch (error) {

    }

    }
  

  }
  
 const onCloseCatApt=()=>{
  const {item}=state.lastAction;
  const attachSector={...item,action:'attach',label:`Attach with ${item.label.split(" ")[2]}`}
  setModalFormStatusObj({text:``})
  setState((_) => ({ ..._,showAirportSelect:false}))
  handleMergeFlight(attachSector,null)
 }
 const getCorrectedTailNo = (tNo) => {
  const pattern = /-/;
  if (tNo && tNo.slice(0, 2) === 'VT' && !pattern.test(tNo)) {
    const indeWithT = tNo.indexOf("T");
    if (indeWithT !== -1) {
      return tNo.slice(0, indeWithT + 1) + '-' + tNo.slice(indeWithT + 1);
    }
  }
  return tNo;
};

  const getDateFromTimezone = (dateTimestr) => {
    let date;
    if (toolbar.timezone === 'gmt') {
      date = moment.utc(dateTimestr)
    } else {
      date = moment(dateTimestr).tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
    }
    return date
  }

  const getDateStart = (iDate) => {
    let date = moment(iDate).format("DD/MM/YYYY");
    date = date.split("/")
    let dateStart = moment.utc([Number(date[2]), Number(date[1]) - 1, Number(date[0]), 0, 0, 0])
    dateStart = getDateFromTimezone(dateStart).startOf("day");
    return dateStart;
  }
  const groupSchedules = (flights) => {

    let groups = {}, rows = [],
      sorter = (attribute, itemA, itemB) => {
        const valueA = itemA[attribute].toLowerCase();
        const valueB = itemB[attribute].toLowerCase();
        return valueA < valueB ? -1 : (valueA > valueB ? 1 : 0);
      },
      getWidthLeft = (startTime, endTime) => {
        let width, left, diff, offset;
        diff = endTime.diff(startTime, 'minutes');
        left = startTime.hour() + (startTime.minute() / 60);
        width = (diff / 60) * 72;
        left = (left * 72) + 162;
        return { width, left, diff, offset }
      };
      setFlightGroups(flights);
    flights.forEach((_) => {
      let mainGroup, secondaryCrp, startAirport = _.startAirport?.locationCode;
      mainGroup = toolbar.groupBy === "Catering Airport" ? startAirport : getCorrectedTailNo(_.tailNumber);
      secondaryCrp = toolbar.groupBy === "Catering Airport" ?  getCorrectedTailNo(_.tailNumber) : startAirport;

      if (!groups[mainGroup]) {
        groups[mainGroup] = {}
      }

      if (!groups[mainGroup][secondaryCrp]) {

        groups[mainGroup][secondaryCrp] = []
      }

      groups[mainGroup][secondaryCrp].push(_)

    })
    
    groups = Object.keys(groups).map((key) => {
     
      let group = { group: key, count: 0 }
      group.secondary = Object.keys(groups[key]).map((secKey) => {

        let flights = groups[key][secKey];
        flights.sort((flightA, flightB) => {
          let startA = getDateFromTimezone(flightA.startTime, true),
            startB = getDateFromTimezone(flightB.startTime, true);
          return startA.isBefore(startB) ? -1 : (startA.isAfter(startB) ? 1 : 0)
        })
        group.count += flights.length;
        return { group: secKey, flights }
      })
      group.secondary.sort(sorter.bind(null, 'group'))
      return group
    })

    groups.sort(sorter.bind(null, 'group'))
    groups.forEach((group, i) => {
      group.secondary.forEach((tail, j) => {
        let flights = [], catGroups = [], row;
        tail.flights.forEach((_) => {
          _.cateringGroups.forEach((g) => {
            let cateringStart, cateringEnd, resSectors = [];
            g.sectors.forEach((sector, i) => {
              let startTime, endTime;
              if (sector.etd && sector.eta) {
                startTime = getDateFromTimezone(sector.etd);
                endTime = getDateFromTimezone(sector.eta);
              } else if (sector.atd && sector.ata) {
                startTime = getDateFromTimezone(sector.atd);
                endTime = getDateFromTimezone(sector.ata);
              } else if (sector.std && sector.sta) {
                startTime = getDateFromTimezone(sector.std);
                endTime = getDateFromTimezone(sector.sta);
              }

              if (startTime && endTime) {
                let todayStart = getDateStart(toolbar.date);
                let startStart = getDateStart(startTime);
                if (startStart.isAfter(todayStart)) {
                  return;
                }
                if (startStart.isBefore(todayStart)) {
                  startTime = todayStart.clone();
                }
                let endend = getDateStart(endTime);
                if (endend.isAfter(todayStart)) {
                  endend.set({ hours: 1 })
                  endTime = endend;
                }
                let { width, left, offset } = getWidthLeft(startTime, endTime);
                resSectors.push({ width, left, item: sector, startTime, endTime });
                if (offset > extraHrs.current) {
                  extraHrs.current = offset;
                }
              }
            });
            flights = flights.concat(resSectors);
            if (resSectors.length > 0) {
              cateringStart = resSectors[0].startTime;
              cateringEnd = resSectors[resSectors.length - 1].endTime;
            }
            if (cateringStart && cateringEnd) {
              let { width, left } = getWidthLeft(cateringStart, cateringEnd);
              catGroups.push({ width, left, item: g })
            }
          })
          // _.cateringGroups.forEach((g) => {
          //   let { width, left } = getWidthLeft(cateringStart, cateringEnd);
          //   catGroups.push({ width, left, item: g })
          // })
        });
        row = { flights, catGroups, tail}
        if (j === 0) {
          row.primary = { label: group.group, count: group.secondary.length }
        }
        rows.push(row);
      })
    });
    return rows
  }
  const handleInputChange = (e) => {
    let { name, value } = e.target;
    if(!value && name==="date"){
      setToolbar((prev)=>{
        return {...prev,date:new Date()}
      })
    }
    if(!value) return;
    if (name === 'date' && value) {
      value = getDateFromTimezone(value).toDate()
    }

    setToolbar((_) => ({ ..._, [name]: value }));
    setState((_) => ({ ..._, lastAction: null,saveDfp:false }))
  }
const handletailNumberSel=(e)=>{
  setToolbar(_prev => {
    return {
      ..._prev,
      tailNo:[e],

    }
  })
}
const handleBaseStaationSel=(e)=>{
  setToolbar(_prev => {
    return {
      ..._prev,
      baseStn:[e],

    }
  })
}
  const handleDialog = (dialogId) => {
    setState((_) => ({ ..._, showDialog: dialogId }))
  }
  const getModalTitle = () => {
    let title = ''
    switch (state.showDialog) {
      case 'station': title = `Catering Airport - BLR`; break;
      case 'tail': title = `Tail - VT-LAP`; break;
      case 'leg': title = `Leg - S1 BLR-BOM`; break;
      case 'catering': title = `Catering - Catering Group 1`; break;
    }
    return title
  }
  const getModalFields = () => {
    let fields = []
    switch (state.showDialog) {
      case 'station': fields = []; break;
      case 'tail': fields = TailLegDetails; break;
      case 'leg': fields = TailLegDetails; break;
      case 'catering': fields = CateringDetails; break;
    }
    return fields
  }



  const handleContextMenu = (flight, e) => {
    e.preventDefault()
    let points = { x: e.pageX, y: e.pageY }
    setState((_) => ({ ..._, contextData: { points, flight }, cateringContext: null }))
  }
  const handleCateringContextMenu = (group, e) => {
    e.preventDefault()
    let points = { x: e.pageX, y: e.pageY }
    const screenHeight = window.innerHeight
    if(+screenHeight - +e.pageY < 250){
      points.y = +e.pageY - 280
    }
    setState((_) => ({ ..._, cateringContext: { points, group }, contextData: null }))
  }
  const getSelectedItem = (i, j) => {
    let sector, sectorIndex, catGrp, catGroupIndex;
    const flightsRows = state.flightsRows
    if (i === undefined && j === undefined) {
      i = state.contextData.flight.i;
      j = state.contextData.flight.j;
    }
    const row = flightsRows[i];
    sector = row.flights[j];
    catGroupIndex = flightsRows[i].catGroups.findIndex(({ item }) => {
      return item.sectors.findIndex((_) => (_.key === sector.item.store?.name)) > -1
    });

    catGrp = flightsRows[i].catGroups[catGroupIndex];
    sectorIndex = catGrp?.item?.sectors.findIndex((_) => (_.store?.name === sector.item?.store.name));
    return { sector, catGrp, sectorIndex, catGroupIndex };
  }

  const getContextMenuItems = () => {
    let menus = [], { i, j } = state.contextData.flight
    const flightsRows = state.flightsRows
    const { catGroupIndex } = getSelectedItem();
    // both attach or detach
    [-1, 1].forEach((index) => {
      if (flightsRows[i].flights[j + index]) {
        let flightDetails = getSelectedItem(i, j + index);
        let flightDetailsDetach = getSelectedItem(i, j );
        let direction = index === -1 ? 'left' : 'right'
        if (flightDetails.catGroupIndex === catGroupIndex) {
          menus.push({ action: 'detach', flightDetails:{...flightDetailsDetach,detachIndex:i}, direction: direction, label: `Detach from ${flightDetails.sector.item.store?.name}` })
        } else {
          menus.push({ action: 'attach', flightDetails:{...flightDetailsDetach,detachIndex:i}, direction: direction, label: `Attach with ${flightDetails.sector.item.store?.name}` })
        }
      }
    })
    return menus
  }
  const getCateringContextMenuItems = () => {
    const { group } = state.cateringContext;

    let mealOrder = group.estimates.find((est) => est.relativeExecutionOffset === 4);
    const menus = [
      { label: "Summary", action: 'summary' },
      { label: "Estimates", action: 'estimates' },
      // { label: "Meal Order", action: 'meal-order' },
      { label: "Uplifts", action: 'uplifts' },
      { label: "Sales", action: 'sales' },
      { label: "Wastage", action: 'wastage' },
      { label: "Offload", action: 'offload' },
      { label: "Retrieval", action: 'retrieval' },
      { label: "Reconciliation", action: 'reconciliation' }
    ];
    if (mealOrder) {
      menus.splice(2, 0, { label: "Meal Order", action: 'meal-order' })
      menus.splice(3, 0, { label: "Invoice", action: 'invoice-menu' })

    }
    return menus
  }

  const getGroupId=(id)=>{
    let gId=null;
    flightGroups?.forEach(_=>{
    if(_.sectors?.find((sec)=>sec?.id===id)){
      gId =_.id;
    }
    })
    return gId;
  }

  const editReleaseEnable = async (flightSel) => {
    const { item } = flightSel.flightDetails.sector;
    const { flightNumber, origin, destination, flightDate, id } = item;
  
    const groupId = getGroupId(id);
    if (!groupId) return false;
  
    try {
      const body = flightSel.action === 'detach'
        ? {
            command: flightSel.action,
            groupId,
            sector: { flightNumber, origin, destination, flightDate },
          }
        : {
            command: flightSel.action,
            groupId,
            sectorId: id,
          };
  
      const res = await DFPCALNDREndpoint.post(`flightPlan/editor/releaseLock`, body);
  
      if (res?.message?.includes('Edit lock released')) {
        return true;
      }
      setMessage({error:`Failed to ${flightSel.action}`});
    } catch (error) {
      setMessage({error:`Failed to ${flightSel.action}`});
    }
  
    return false;
  };
  
  const getCaterGroupWidthLeft = (cat, i) => {
    const flightsRows = JSON.parse(JSON.stringify(state.flightsRows));
    let startSector = cat.item.sectors[0], endSector = cat.item.sectors[cat.item.sectors.length - 1];
    startSector = flightsRows[i].flights.find(({ item }) => (item.store?.name === startSector.store?.name))
    endSector = flightsRows[i].flights.find(({ item }) => (item.store?.name === endSector.store?.name));
    cat.left = startSector?.left;
    cat.width = (endSector?.left + endSector?.width) - startSector?.left;
  }

  const cateringGroupNames=(sectData)=>{
    if(sectData&&sectData.length){
      const flightDate = new Date( sectData[0].flightDateUTC);
      const sequence =  sectData.map(flight => flight.origin).join('-') + '-' +  sectData[ sectData.length - 1].destination;
      return `${flightDate.getFullYear()}-${(flightDate.getMonth() + 1).toString().padStart(2, '0')}-${flightDate.getDate().toString().padStart(2, '0')}/${sequence}`;
    }
  }
  const handleMergeFlight = async(item, e) => {
   
    let contextData = state.contextData, lastAction = null;
    if (!contextData && state.lastAction) {
      contextData = state.lastAction.contextData
    } else {
      lastAction = { contextData, item }
    }

    const { i, j } = contextData.flight;
    const rLok = await editReleaseEnable(item);
    if(rLok){   
    if (e && typeof e.stopPropagation === 'function') {
    e.stopPropagation();
    setState((_) => ({ ..._, contextData: null ,detachedSector:item.flightDetails?.sector,isEdited:_.isEdited+1}))
    }else{
      setState((_) => ({ ..._, contextData: null ,detachedSector:item.flightDetails?.sector,isEdited:_.isEdited>0?_.isEdited-1:_.isEdited}))
    }
    const flightsRows = JSON.parse(JSON.stringify(state.flightsRows));
    const { action, direction } = item;
    const { sector, catGrp, catGroupIndex, sectorIndex } = getSelectedItem(i, j)
    if (action === 'detach') {
      detachAttach.current = false;
      let fromIndex, gap, nameCatGrNew, nameCatGr, detachFrom, newCatGroup = { item: JSON.parse(JSON.stringify(catGrp.item)) }, sectors = [...catGrp.item.sectors];
      if (direction === 'right') {
        fromIndex = j + 1;
        detachFrom = flightsRows[i].flights[fromIndex]
        gap = detachFrom.left - sector.left - sector.width;
        catGrp.item.sectors = sectors.slice(0, sectorIndex + 1);
        newCatGroup.item.sectors = sectors.slice(sectorIndex + 1);
        nameCatGrNew=cateringGroupNames(sectors.slice(sectorIndex + 1))
        nameCatGr=cateringGroupNames(sectors.slice(0,sectorIndex + 1))
        newCatGroup.item.name=nameCatGrNew;
        catGrp.item.name=nameCatGr;

      } else {
        fromIndex = j - 1;
        detachFrom = flightsRows[i].flights[fromIndex]
        gap = sector.left - detachFrom.left - detachFrom.width
        newCatGroup.item.sectors = sectors.slice(0, sectorIndex);
        catGrp.item.sectors = sectors.slice(sectorIndex);
        nameCatGrNew=cateringGroupNames(sectors.slice(0,sectorIndex))
        nameCatGr=cateringGroupNames(sectors.slice(sectorIndex))
        newCatGroup.item.name=nameCatGrNew;
        catGrp.item.name=nameCatGr;

      }
      getCaterGroupWidthLeft(catGrp, i)
      getCaterGroupWidthLeft(newCatGroup, i)
      flightsRows[i].catGroups[catGroupIndex] = catGrp;

      if (direction === 'right') {
        flightsRows[i].catGroups.splice(catGroupIndex, 0, newCatGroup)
      } else {
        if (catGroupIndex === 0) {
          flightsRows[i].catGroups.unshift(newCatGroup)
        } else {
          flightsRows[i].catGroups.splice(catGroupIndex - 1, 0, newCatGroup)
        }
      }
    } else if (action === 'attach') {
      detachAttach.current = false;
      let target = direction === 'right' ? j + 1 : j - 1;
      target = getSelectedItem(i, target)
      let targetCat = target.catGrp;
      let catrNames;
      if (direction === 'right') {
        targetCat.width = (targetCat?.width + targetCat?.left) - catGrp?.left;
        targetCat.left = catGrp?.left;
        targetCat.item.sectors = [...catGrp?.item?.sectors, ...targetCat?.item?.sectors,]
        catrNames= cateringGroupNames(targetCat?.item?.sectors);
         if(catrNames){
          targetCat.item.name=catrNames;
        }

      } else {

        targetCat.width = (catGrp?.width + catGrp?.left) - targetCat?.left;
        targetCat.item.sectors = [...targetCat.item?.sectors, ...catGrp.item?.sectors,]
        catrNames=cateringGroupNames(targetCat?.item?.sectors);
        if(catrNames){
          targetCat.item.name=catrNames;
        }
      }

      flightsRows[i].catGroups[target.catGroupIndex] = targetCat;
      flightsRows[i].catGroups.splice(catGroupIndex, 1);
    }

    setState((_) => ({ ..._, flightsRows: flightsRows,lastAction: lastAction }));}

  }

  const getEstimateTitleTop = (estimate) => {
    const date = moment(estimate.scheduledTimeOfExecution).tz(moment.tz.guess()).format("DD-MM-YYYY hh:mm");
    return `${date} - ${estimate.relativeExecutionTime}`
  }

  const removeDuplicatesInSections = (array) =>{
    let result = [];
    let section = [];

    for (const item of array) {

        if (item.primary) {
            if (section.length > 0) {

                const uniqueTailGroups = new Set();
                section = section.filter(sectionItem => {
                    const tailGroup = sectionItem.tail.group;
                    if (uniqueTailGroups.has(tailGroup)) {
                        return false;
                    }
                    uniqueTailGroups.add(tailGroup);
                    return true;
                });


                if (result.length > 0 && result[result.length - 1].primary) {
                    result[result.length - 1].primary.count = section.length+1;
                }


                result = result.concat(section);
                section = [];
            }

            result.push(item);
        } else {
            section.push(item);
        }
    }
    if (section.length > 0) {
        const uniqueTailGroups = new Set();
        section = section.filter(sectionItem => {
            const tailGroup = sectionItem.tail.group;
            if (uniqueTailGroups.has(tailGroup)) {
                return false;
            }
            uniqueTailGroups.add(tailGroup);
            return true;
        });

        if (result.length > 0 && result[result.length - 1].primary) {
            result[result.length - 1].primary.count = section.length+1;
        }

        result = result.concat(section);
    }

    return result;

}


const getModifedCatgroupWidth=(flightsArr)=>{
    let gap,catWdth,storeNames,sectorsNew,catLeft;
    const {catGrp}=state.lastAction.item.flightDetails;
    const {direction}=state.lastAction.item;
    const {sectors}=catGrp.item;
    if(sectors && sectors.length>=2){
        storeNames= cateringGroupNames(sectors);
        const resArr = flightsArr.reduce((acc, fl) => {
            if (fl.item.id === sectors[0].id || fl.item.id === sectors[1].id) {
                acc.push(fl);
            }
        return acc;
        }, []);
        sectorsNew=sectors.slice(1);
        if(resArr && resArr.length>1){
        gap=(resArr[1].left-resArr[0].left)-resArr[0]?.width;
        catWdth = direction === 'right' ? catGrp?.width-(gap+resArr[1]?.width) : catGrp?.width-(gap+resArr[0]?.width);
        catLeft = direction === 'right' ? resArr[0].left : resArr[1].left;
        }
    }
    else{
        storeNames= cateringGroupNames(sectors);
        sectorsNew=sectors
        catWdth=catGrp?.width
        catLeft=catGrp?.left;

    }
        return {catWdth,catLeft,storeNames,sectorsNew};
}

const onAirportSubmit= async (e, updated)=>{

  if (state.catAirp.length) {
    const lastAction = JSON.parse(JSON.stringify(state.lastAction));
    const flightDetails = lastAction?.item?.flightDetails;
    const catGroup = flightDetails?.catGrp;
    const flightsRows = JSON.parse(JSON.stringify(state.flightsRows));
    let cateringStation;
    const indexCat = flightsRows.findIndex(
      (fl) => fl.primary && fl.primary.label === state.catAirp
    );
    if (indexCat !== -1) {
      const count = flightsRows[indexCat].primary.count;
      let cateringSegmentArr = flightsRows.slice(indexCat, indexCat + count);
      const storeName = `${flightDetails.sector.item.origin}-${flightDetails.sector.item.destination}`;
      const sectorWidth = flightDetails.sector.width;
      const sectorLeft = flightDetails.sector.left;
      const tailNumber = getCorrectedTailNo(state.detachedSector.item.tailNumber);
      if (state.tailNos) {
        const { flight } = lastAction?lastAction?.contextData:null;
        const tailNumber = state.flightsRows[flight.i]?.tail;
        if (tailNumber && tailNumber.flights.length > 0) {
           cateringStation = tailNumber.flights[0].startAirport?.locationCode;
        }
      }
      let modObj = {},modObjIndex;
      let targetIndex = null,startAirport;
      let catGrName = catGroup.item?.name.split("/");
      let newCatName = `${catGrName[0]}/${storeName}`;
      catGroup.item = { ...catGroup.item, name: newCatName };
      catGroup.width = sectorWidth;
      catGroup.left = sectorLeft;
      startAirport=cateringSegmentArr[0]?.tail?.flights[0]?.startAirport;
      cateringSegmentArr.forEach((ct) => {
        const { group } = ct.tail;
        if (group === tailNumber) {
          ct.flights.push({
            width: sectorWidth,
            ...state.detachedSector,
          });
          ct.catGroups.push(catGroup);
        } else {

    let catTailDetails=null;
    let startIndex = -1;

    for (let i = 0; i < state.tailNos.length; i++) {
        const item = state.tailNos[i];
        if (item.primary && item.primary.label === cateringStation) {
            startIndex = i;
            if (item.tail.group === tailNumber) {
              item.tail.flights[0].startAirport=startAirport;
              catTailDetails= item.tail;
            }
            break;
        }
    }
    if (startIndex !== -1) {
        for (let i = startIndex + 1; i < state.tailNos.length; i++) {
            const item = state.tailNos[i];
            if (item.tail.group === tailNumber) {
              item.tail.flights[0].startAirport=startAirport;
              catTailDetails= item.tail;
              break;
            }
        }
    }

          const updatedCateringSegmentArr = cateringSegmentArr.map((item) => {
            if (item.primary) {
              return {
                ...item,
                primary: {
                  ...item.primary,
                  count: (item.primary.count || 0) + 1,
                },
              };
            }
            return item;
          });

          updatedCateringSegmentArr.push({
            flights: [
              {
                endTime: state.detachedSector.endTime._i,
                startTime: state.detachedSector.startTime._i,
                width: sectorWidth,
                ...state.detachedSector,
              },
            ],
            catGroups: [catGroup],
            tail: catTailDetails,
          });

          cateringSegmentArr = [
            ...updatedCateringSegmentArr.reduce((acc, segment) => {
              const existingSegment = acc.find(
                (seg) => seg.tail.group === segment.tail.group
              );

              if (!existingSegment) {
                acc.push(segment);
              }
              return acc;
            }, []),
          ];
        }
      });

      const labelsToReplace = cateringSegmentArr[0].primary?.label;

      const updatedArray = flightsRows.reduce((acc, item) => {
        if (item.primary?.label === labelsToReplace) {
          return [...acc, ...cateringSegmentArr];
        }
        return [...acc, item];
      }, []);

      const filteredArray = removeDuplicatesInSections(updatedArray);

//arranging the parent flight rows after detaching*********///
      const primaryLabelIndex = filteredArray.findIndex(
        (item) => item.primary?.label === cateringStation
      );

      if (primaryLabelIndex !== -1) {
        const targetGroupExist =
          filteredArray[primaryLabelIndex]?.tail?.group === tailNumber;

        if (targetGroupExist) {
          modObj = filteredArray[primaryLabelIndex];
          targetIndex = primaryLabelIndex;
        } else {
          const primaryIndices = filteredArray
            .map((item, index) => (item.primary?.label ? index : -1))
            .filter((index) => index !== -1);

          const nextPrimaryIndex = primaryIndices.find(
            (index) => index > primaryLabelIndex
          );

          if (nextPrimaryIndex !== undefined) {
            const targetObjectIndex = filteredArray.findIndex(
              (item, index) =>
                item.tail?.group === tailNumber &&
                index > primaryLabelIndex &&
                index < nextPrimaryIndex
            );

            if (targetObjectIndex !== -1) {
              targetIndex = targetObjectIndex;
              modObj = filteredArray[targetObjectIndex];
            }
          }else{
           const arrayGroup= filteredArray.slice(primaryLabelIndex);
           if(arrayGroup){
            modObj=arrayGroup.find(aObj=>aObj.tail.group === tailNumber)
            modObjIndex=arrayGroup.findIndex(aObj=>aObj.tail.group === tailNumber)
            targetIndex = modObjIndex+primaryLabelIndex;
           }
          }
        }

        let updatedAirportObj = {};
        if (modObj?.flights) {
          const catGroupNewDetails= getModifedCatgroupWidth(
            modObj?.flights
          );

        let catGroupNew= {...catGroup,left:catGroupNewDetails.catLeft,width:catGroupNewDetails.catWdth,item:{...catGroup.item,name:catGroupNewDetails.storeNames,sectors:catGroupNewDetails.sectorsNew}}

          if(catGroupNew){
            if(catGroup.item.sectors && catGroup.item.sectors.length === 1){
              updatedAirportObj = {
                ...modObj,
                flights: modObj.flights.filter(
                  (flight) => flight.item.id !== flightDetails.sector.item.id
                ),
                catGroups: modObj.catGroups
                  .filter((catGroup) =>
                    catGroup.item?.name !== catGroupNew.item?.name

                )
                  .filter((catGroup) => catGroup.width > 0)
              };
            }else{
              updatedAirportObj = {
                ...modObj,
                flights: modObj.flights.filter(
                  (flight) => flight.item.id !== flightDetails.sector.item.id
                ),
                catGroups: modObj.catGroups
                  .map((catGroup) => {
                    if (catGroup.item?.name === catGroupNew.item?.name) {
                      return catGroupNew;
                    }
                    return catGroup;
                  })
                  .filter((catGroup) => catGroup.width > 0)
              };
            }
          }



          const updatedArrayRemObj = filteredArray.map((item, index) =>
            index === targetIndex ? updatedAirportObj : item
          );

          if (updatedArrayRemObj) {
            setState((_) => ({
              ..._,
              flightsRows: updatedArrayRemObj,
              showAirportSelect: false,
              saveDfp: true,
            }));
          }
          if (indexCat !== -1 && calenderRef.current[indexCat]) {
            calenderRef.current[indexCat].scrollIntoView({ behavior: 'auto', block: 'start' });
          }
        }

        setState((_) => ({ ..._, catAirp: "" }));
      }
    }
  }

  }

  const onFieldChangeHandler = (e,updated) => {

    setState((_)=>({..._,catAirp:updated?.cateringAirport}))
  };

  const downloadTroleyPlan = async (estimateId) => {
    // const res = await DFPAPIEndpointGalley.postWithFullResp('galley/trolley/loading/plan/download', {aircraftTypeId: aircraftId}, {responseType: 'blob'})
    const { group } = state.cateringContext;
    let mealOrder = group.estimates.find((est) => est.relativeExecutionOffset === 4);

    // const path = DFPAPIEndpointGalley.getFullPath(
    //   `cateringGroups/${group.id}/estimates/${mealOrder.id}/download`
    // );
    // let headers = DFPAPIEndpointGalley.getHeaders();
    // headers = headers.headers;
    // try {
    //   const res = await fetch(path, {
    //     method: "GET",
    //     headers: headers,
    //   });
    //   const file = await res.blob();
    //   saveAs(file, `Summary.xlsx`);
    // } catch (error) {
    //   console.log(error);
    // }



    const path = DFPAPIEndpoint.getFullPath(
      `operations/cateringGroups/${group.id}/estimates/${mealOrder.id}/download`
    );
    let headers = DFPAPIEndpoint.getHeaders();
    headers = headers.headers;

    try {
      const res = await fetch(path, {
        method: "GET",
        headers: headers,
      });
      if (res.status === 200) {
        const file = await res.blob();
        saveAs(file, `${group.plan}_${getEstimateTitleTop(mealOrder)}.xlsx`);
      } else {
       setMessage({error:'Failed to Download Meal Order'})
       setTimeout(() => {
       setMessage({text:""})
       }, 5000);
      }
    } catch (error) {
      setMessage({error:'Failed to Download Meal Order'})
      setTimeout(() => {
      setMessage({text:""})
        }, 5000);
    }

  };
  const handleCateringClick = async (menu) => {
    const cateringGroup = state.cateringContext.group;
    try {
      const status = await DFPAPIEndpoint.post('operations/cateringGroups/status', { cateringGroupId: cateringGroup.id })
      if (status) {
        if (menu && menu.action === 'invoice-menu') {
          downloadTroleyPlan(4);
        }
        else
        {
          setState((_) => ({ ..._, prevLayout: _.layout, cateringContext: null, seletecCateringGroup: { item: menu.action, selectedView: menu.action, status, group: cateringGroup } }))
        }
      } else {
        setMessage({error:'Catering status not available'})
      setTimeout(() => {
        setMessage({text:''})
        }, 5000);

      }
    } catch (error) {

    }
  }
  const handleMenuOpen=(isOPen)=>{
    setState(prevState => ({
      ...prevState,
      isOpenMenu: isOPen,
    }));
  }

  const getCateringModelTitle = () => {
    const { latestEstimate, code } = state.seletecCateringGroup.group;
    let title = t(`pages.controlPanel.views.catering.estimates`) + ' - ' + code;
    if (latestEstimate) {
      title += ` - ${latestEstimate.relativeExecutionTime}`
    }
    return title;
  }

  // const handleConfirm = async () => {
  //   const { status } = state.seletecCateringGroup
  //   try {
  //     setState((_) => ({ ..._, seletecCateringGroup: null }))
  //     await DFPAPIEndpoint.post('operations/cateringGroups/estimates/cateringConfirm', { estimateId: status.id })
  //   } catch (error) {

  //   }
  // }


  // reset attach detach

  const handleDFPReset=()=>{
    setState((_) => ({ ..._, lastAction: null,saveDfp:false,isEdited:0}))
    setState((_) => ({ ..._, catAirp: ""}))
    detachAttach.current = false;
    setFlightIndex((_)=>({..._,prevTailNumber:null,ind:null,incrementRow:null}))
    fetchFlighSchedule()
  }

const onSaveDfp=async()=>{
setState((_)=>({..._,saveDfp:false,isEdited:0}))

}
  return (
    <div className="ctrl-calender-container flexCol full">
      {
        !Boolean(state.seletecCateringGroup) &&
        <section className="flexCol full o-hide">
          <div className="flexRow alignItemsEnd justifyContentSpaceBetween">
            <div className="flexRow alignItemsEnd" style={{paddingBottom:'2px'}}>
              <Input label='Date' type='date' value={toolbar.date} style={{width:'150px',marginLeft:'0.2rem'}} name='date' onChange={handleInputChange} />
              {/* <button title="Undo" onClick={handleUndoAction} className="icon-undo" disabled={Boolean(!state.lastAction)} >
                <img alt="undo" src={UndoIcon} />
              </button> */}
              <button title="Reset" className="icon-undo" onClick={handleDFPReset}  disabled={Boolean(!state.isEdited)} >
              <BiReset title={'Reset'} style={{ width: '24px', height: '24px', color: 'white' }} />
              </button>
            </div>
            {state.saveDfp?<span className="margBottom5">
            <button style={{margin:'0.5rem'}} className="textAlignCenter save-dfp-edits" onClick={ onSaveDfp }>
                 Save
             </button>
            </span>:<></>}
            {/* {(message.text || message.error) ? (
          <div className={`flexRow confirmation_popup_dfp ${message.text?'alert_success':'alert_error'}`}>
          {message.text || message.error}
          </div>
          ) : null} */}
            <div className="flexRow" style={{marginRight:"2px"}}>
            
             {toolbar && toolbar.tailNo? (<div  style={{paddingBottom:"12px"}} className='flexCol margLeft16'>
          <label className="req margBottom10">Tail Number</label>
            <Select
              options={tailNumber}
              defaultValue={toolbar.tailNo[0]?.label ? toolbar.tailNo[0]?.label : ''}
              value={tailNumber?.filter(function (option) {
                return (
                  toolbar.tailNo.length && toolbar.tailNo[0]&&
                  toolbar.tailNo.find((sc) => sc.label === option.label)
                );
              })}
              onChange={handletailNumberSel}
              styles={customStyles}
              isOpenMenu={state.isOpenMenu}
              onMenuOpen={() => handleMenuOpen(true)}
              onMenuClose={() => handleMenuOpen(false)}
              placeholder="Search Tail Number"
              isSearchable={true}
              isClearable={true}
              className="select-menu-dfp-filter"
            />
          </div>):<></>}
          {toolbar && toolbar.baseStn? (<div className='flexCol margLeft16'>
          <label className="req margBottom10">Catering Airport</label>
            <Select
              options={storesList}
              defaultValue={toolbar.baseStn[0]?.label ? toolbar.baseStn[0]?.label : ''}
              value={storesList?.filter(function (option) {
                return (
                  toolbar.baseStn.length && toolbar.baseStn[0]&&
                  toolbar.baseStn.find((sc) => sc.label === option.label)
                );
              })}
              onChange={handleBaseStaationSel}
              styles={customStyles}
              isOpenMenu={state.isOpenMenu}
              onMenuOpen={() => handleMenuOpen(true)}
              onMenuClose={() => handleMenuOpen(false)}
              placeholder="Search Catering Airport"
              isSearchable={true}
              isClearable={true}
              className="select-menu-dfp-filter"
            />
          </div>):<></>}
          
              <ToastContainer />
              <Input label='Group By' type='select' className="group-by" style={{width:'150px'}} value={toolbar.groupBy} name='groupBy' options={GroupingModes} onChange={handleInputChange} />
              <Input label='Timezone' type='select' className="group-by" value={toolbar.timezone} name='timezone' options={state.timeZone} onChange={handleInputChange} />
            </div>
          </div>
          <div className="flexCol full-flex o-hide" ref={tblRef}>
            {
              Boolean(state.layout) &&
              <div className="flexCol dfpTable full tabl-cntr dynamicBorder sm-scrollbar" style={{ width: `${state.layout.width}px`, height: `${state.layout.height}px` }}>
                {(state.flightsRows.length)&&(!state.noDataShow)?
                  <table className="tbl tblDfp">
                    <thead>
                      <tr>
                        <th className="fixed fixedHeader">
                          <div className="head-cell">{toolbar.groupBy==="Catering Airport"?"Catering Airport":"Tail No."}</div>
                        </th>
                        <th className="fixed tail">
                          <div className="head-cell">{toolbar.groupBy==="Catering Airport"?"Tail No.":"Catering Airport"}</div>
                        </th>
                        {
                          getTimeColumns().map((_) => {
                            return <th key={_.value}><div className="head-cell">{_.label}</div></th>
                          })
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {viewcalender && editDFpcalender?
                        state.flightsRows.map((row, i) => {
                          return (
                            <tr key={i} ref={el => (calenderRef.current[i] = el)}>
                              {
                                Boolean(row.primary) &&
                                <td className="fixed group-cell" rowSpan={row.primary.count}>
                                  <div className="cell flexCol alignItemsCenter justifyContentCenter station" onClick={() => handleDialog('station')}>{row.primary.label}</div>
                                </td>
                              }
                              {
                                Boolean(row.tail) &&
                                <td className="fixed group-cell tail">
                                  <div className="cell flexCol alignItemsCenter justifyContentCenter station" onClick={() => handleDialog('station')}>{row.tail.group}</div>
                                </td>
                              }
                              {
                                Boolean(row.flights) &&
                                <React.Fragment>
                                  {
                                    row.flights?.map((flight, j) => {
                                      return (
                                        <div
                                          key={j} className="flight" style={{ left: `${flight.left}px`, width: `${flight.width}px` }}
                                          onContextMenu={handleContextMenu.bind(null, { i, j })}
                                          onClick={() => handleDialog('leg')} title={flight.item?.key}>
                                          {flight.item?.key}
                                        </div>
                                      )
                                    })
                                  }
                                </React.Fragment>
                              }
                              {
                                Boolean(row.catGroups) &&
                                <React.Fragment>
                                  {
                                    row.catGroups.map((cat, j) => {
                                      return (
                                        <div key={j} className="flight cat-group"
                                          style={{ left: `${cat.left}px`, width: `${cat.width}px` }}
                                          onContextMenu={handleCateringContextMenu.bind(null, cat.item)}
                                          title={cat.item?.name}
                                          >
                                          {cat.item?.name}
                                        </div>
                                      )
                                    })
                                  }
                                </React.Fragment>
                              }

                            </tr>
                          )
                        }):null
                      }
                    </tbody>

                  </table>:<table className="tbl">
                    <thead>
                      <tr>
                        <th className="fixed">
                          <div className="head-cell">Catering Airport</div>
                        </th>
                        <th className="fixed tail">
                          <div className="head-cell">Tail No.</div>
                        </th>
                        {
                          getTimeColumns().map((_) => {
                            return <th key={_.value}><div className="head-cell">{_.label}</div></th>
                          })
                        }
                      </tr>
                    </thead>
                    <tbody>
                   <></>
                    </tbody>

                  </table>}

              </div>
            }
          </div>
        </section>
      }

      {
        Boolean(state.contextData) &&
        <div className="ctrl-container-merge-menu"
          style={{ top: state.contextData.points.y, left: state.contextData.points.x }}>
          <ul>
            {
              getContextMenuItems().map((_,index) => {
                return (
                  <li key={index} onClick={handleMergeFlight.bind(null, _)}>{_.label}</li>
                )
              })
            }
          </ul>
        </div>
      }
      {
        Boolean(state.cateringContext) &&
        <div className="ctrl-container-merge-menu"
          style={{ top: state.cateringContext.points.y, left: state.cateringContext.points.x }}>
          <ul>
            {
              getCateringContextMenuItems().map((_,index) => {
                return (
                  <li key={index} onClick={handleCateringClick.bind(null, _)}>{_.label}</li>
                )
              })
            }
          </ul>
        </div>
      }
          {
        state && Boolean(state.showAirportSelect) ? (
          <EditModal
            title={`Select Catering Airport to attach ${modalFormStatusObj.text.split(" ")[modalFormStatusObj.text.split(" ").length-1]}`}
            showFieldsInColumn={true}
            // onSubmit={onAirportSubmit}
            onChange = {onFieldChangeHandler}
            onClose={onCloseCatApt}
            modalCustomButtons={([
              { label: 'Confirm', attribute: 'button', type: 'button', disabled: state?.catAirp?.length?false:true, className: 'confirm-button', action: (e) => {
                      onAirportSubmit();
                      return false;
                  }
              }
            ])}
            fields={airportFields}
          />
        )
        : null
      }

      {
        Boolean(state.seletecCateringGroup) &&
        <CalendarCateringScreen
          {...state.seletecCateringGroup}
          timezone={toolbar.timezone}
          onBack={() => {
            setState((_) => ({
              ..._,
              layout: _.prevLayout,
              seletecCateringGroup: null
            }))
          }}
        />
      }
    </div>
  );
};











