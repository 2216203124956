

import React, { useState, useEffect, useRef } from 'react'
import '../assets/styles/modal.scss'
import closeIcon from '../images/icons/Icon2.png'
import { Input } from "./Input"
import downloadMasterIcon from '../assets/icons/download-master.svg';
import imageThumb from "../assets/imageThumb.svg"
import { HOST_SERVER } from '../utils/configs';
import ReactPdfViewer from '../utils/pdfViewer';
import LoadingIn from '../assets/loadingIn.gif';
import { FaChevronCircleLeft ,FaChevronCircleRight} from "react-icons/fa";

export const Modal = (props) => {

  const TitleComponent = props.TitleComponent;
  const fileInput = useRef(null)
  const showDone = props.showDone !== undefined ? props.showDone : true;
  const showFieldsInColumn = props.showFieldsInColumn || false;
  const modalCustomButtons = props.modalCustomButtons || [];
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);
  const [selectedPdf,setSelectedPdf]=useState(null)

  const handleImageUpload = (e,title) => {
    title!=='Create Invoice'?props.encodeImageFileAsURL(e.target.files[0]):handlePdfUpload(e)

  }
const handlePdfUpload=(e)=>{
  setSelectedPdf(e.currentTarget.files[0])
}

  let isError = false;
  let updateStatusText = '';

  if (props.modalFormStatusObj) {
    if (('text' in props.modalFormStatusObj)) {
      updateStatusText = props.modalFormStatusObj.text;
    }
    if (('error' in props.modalFormStatusObj)) {
      updateStatusText = props.modalFormStatusObj.error;
      isError = props.modalFormStatusObj.error;
    }
  };

  const decrementPageNo = () => {
    if (pageNumber > 1) {
      setPageNumber(pageNumber - 1);
    }
  };

  const incrementPageNo = () => {
    if (pageNumber < numPages) {
      setPageNumber(pageNumber + 1);
    }
  };
  const getDatasetAtEvent=(data)=>{
    const hasPropsValue = data[0].every(val => val?.props?.value);
    return !hasPropsValue;
  }
  const isButtonDefined = (button) => {
    if (typeof button === 'object' && Object.keys(button).length > 0) {
      return true;
    }
    else if (button.length > 0) {
      return true;
    }
    return false;
  };
  return (
    <div className={`overlay ${props.isSubModal ? 'sub' : ''} ${props.overlayClassName || ''}`} role="dialog">
      <div className="dialog sm-scrollbar" style={props.customModalStyle}>
        <div className={`dialog__content ${props.className || ''}`}>
          <img className="modal__close_icon" alt='Close' src={closeIcon} onClick={props.onClose} />
          <h2 className="dialog__title subheading-text-medium fontMedium">
            {typeof props.title === "string" && props.title}
            {Boolean(TitleComponent) && <TitleComponent />}
          </h2>
          <hr />
          {!showFieldsInColumn ?
           <div className={`dialog__body_container flexRow ${props.title?.includes('Pilferage')||props.title?.includes('-Items') ||props.title?.includes('Device Details') || props.mainContentCenter ? 'justifyContentCenter' : 'justifyContentSpaceBetween'} flexWrap`}>
          {props.children}
         </div>
            :
            <div className={`dialog__body_container flexCol ${!props.columnViewType?'alignItemsCenter':''}`}>{props.children}</div>
          }

          {props.blobFile || props.imageUrl ? <div className="formGroup__formHorizontal"><img src={props.blobFile ? props.blobFile : `${HOST_SERVER}/${props.imageUrl}`} className="img-thumbnail" alt='' width="90" height="60" /> <img className="image__close__icon" alt='Close' src={closeIcon} onClick={() => props.resetImageSelection(true)} /><p className="pvm__text-center">{props.fileUpload?.name ? props.fileUpload?.name : ''}</p></div> : <></>}
          {selectedPdf?<ReactPdfViewer fileHolder={selectedPdf} numPages={numPages} pageNumber={pageNumber} setNumPages={setNumPages} setPageNumber={setPageNumber} />:<></>}
          {selectedPdf?<div className='flexRow  alignItemsCenter justifyContentCenter margTop10'><span className='pointer'>{pageNumber!==1?<FaChevronCircleLeft size={25} onClick={decrementPageNo}/>:<></>}&nbsp;Page {pageNumber} of {numPages}</span>&nbsp;<span className='pointer'>{pageNumber!==numPages?<FaChevronCircleRight onClick={incrementPageNo} size={25}/>:<></>}</span></div>:<></>}

          {Boolean(updateStatusText) &&
            <div className={"dialog__statusBlock " + (isError ? 'alert_error' : 'alert_success')}>
              {updateStatusText}
            </div>}

          {modalCustomButtons.length > 0 ?
            <>
              <div className="dialog__footer flexRow justifyContentSpaceEvenly">
                {modalCustomButtons.map((button, i) => {
                  return isButtonDefined(button) ?
                  <button
                  key={i}
                  className={"done-button " + button.className}
                  disabled={button.disabled||false}
                  onClick={
                    typeof button.action === 'function' ?
                      button.action :
                      props.onSubmit
                  }
                >
                  {button.label !== "Cancel" && props.showLoadingBtn ? "Updating...":button.label || "Okay"}
                </button>
                    : null
                })
                }
              </div>
            </>
            :
            <>
              {props.title === "Edit Item" || props.title === "Add Item" || props.title === "Create Invoice" ?
                <div className="dialog__footer flexCol alignItemsCenter">
                  <>
                    <input
                      ref={fileInput}
                      onChange={(e)=>handleImageUpload(e,props.title)}
                      type="file"
                      accept={ props.title!=='Create Invoice'?"image/png, image/gif, image/jpeg":".pdf"}
                      className='input_display'
                      />
                  </>
                  <div className='dialog_buttons'>
                   {props.title!=='Create Invoice'? <button className="uploadImage-button" onClick={(e) => fileInput.current.click()}><img src={imageThumb} alt="upload" />&nbsp;&nbsp;Upload Image</button>:<></>}
                    { props.title==='Create Invoice'?<button className="uploadImage-button" onClick={(e) => fileInput.current.click()}><img src={imageThumb} alt="upload" />&nbsp;&nbsp;Upload Pdf</button>:<></>}
                    <button className="done-button" onClick={props.onSubmit}>Done</button>
                  </div>
                </div>
                :
                <>
                  {showDone ?
                   <div className="dialog__footer flexCol alignItemsCenter">
                       <button disabled={(props.disabled||props.showLoadingBtn)?(props.disabled||props.showLoadingBtn):false} className="done-button" onClick={props.onSubmit}>{props.buttonLabel ? props.buttonLabel : "Done"}</button>
                 </div>
                    : null}
                </>
              }
            </>
          }
        {props.title==='Download Cashier Reports'?
        <div className={`dialog__container_item margTop24`}>
        <div className={`dialog__description flexRow alignItemsCenter justifyContentCenter`}>
        <button type="button" disabled={getDatasetAtEvent(props.children)||props.showLoadingBtn} onClick={(e,updated)=>props.onSubmit(e,updated)} className="btn btn-info" style={{ color: "white" }}>
       {!props.showLoadingBtn?<><span><img className="icon-size" alt='downloadMaster' src={downloadMasterIcon} /></span> Download</> :<><span><img src={LoadingIn} alt='loading' className="icon-size" /> Processing...
       </span></> }

        </button>
 </div>
</div>
:<></>
          }
          {/* </form> */}
        </div>
      </div>
    </div>
  );
}
export const EditModal = (props) => {

  const [editHsn, setEditHsn] = useState("");
  const [updated, setUpdated] = useState(null);

  useEffect(() => {
    if (props.title === "Add HSN Mapping") {
      props.fields[0].disabled = false
    }
    if (props.title === "Edit Role") {
      props.fields[1].disabled = !props.fields[1].disabled
    }
  }, [props.title, props.fields])

  useEffect(() => {
    if (props.fields && props.fields.length) {
      setEditHsn(props.fields[0]?.initialValue)
        const disabledCheckFunction = field => typeof field['disabledCheck'] === 'function';
const updatedFields=[...props.fields]
          updatedFields.forEach(field => {
          if (disabledCheckFunction(field) && updated) {
            field['disabledCheck'](updated);
          }
        });
    }
  }, [props.fields,updated])

  const onChangeSelectSearch = (selectedValue, data, selectedObj) => {
    let newUpdates = updated ? { ...updated } : {}
    newUpdates[data.name] = selectedValue;
    newUpdates[data.name + '_id'] = selectedObj.id;
    setUpdated({ ...newUpdates });
    if(props.onChange){
    typeof (props.onChange) === 'function' && props.onChange(selectedValue, newUpdates, data)
    }
  };

  const onChangeMultiSelect = (selectedValue, data, selectedObj) => {
     let newUpdates = updated ? { ...updated } : {}
     newUpdates[data.attribute] = selectedValue?.label;
    setUpdated({ ...newUpdates });
    if(props.onChange){
      typeof (props.onChange) === 'function' && data && data.type==="rcSelectAll" ? props.onChange(null,selectedObj,null):props.onChange(selectedValue,data,null)
    }
  };

  function onChangeSelectSearch2(selectedValue, data){
    let newUpdates = updated ? { ...updated } : {}
    newUpdates[data.attribute] = selectedValue?.value;
    if(data?.attribute === "warehouse" && updated?.sector){
      newUpdates.sector=undefined
    }
    setUpdated({ ...newUpdates });
    typeof (props.onChange) === 'function' && props.onChange(null,newUpdates)
  }


  const onChange = (e, data) => {
    const { name, value, checked, type } = e.target;
    let newUpdates = updated ? { ...updated } : {}
    if (e.target.name === "itemType") {
      props.setItemTypeValue(e.target.value);
    };
    if (e.target.name === "img") {
      props.encodeImageFileAsURL(data)
      setUpdated({ ...newUpdates,...data });
    };
    if(e.target.name==='targetDate'){
      const {target}=e
    onChangeMultiSelect({},target,null)
    }

    newUpdates[name] = value;
    if ((checked || !checked) && (type === 'checkbox')) {
      newUpdates[name] = checked;
    }
    if ((type === 'radio')) {
      setUpdated({ ...newUpdates,...data });
    }
    else{
    setUpdated({ ...newUpdates });
    typeof (props.onChange) === 'function' && props.onChange(e, newUpdates)
    }
  }

  const onSubmit = (e) => {
    const mode = props.title;
    removeContainerOverlay();
    typeof (props.onSubmit) === 'function' && props.onSubmit(e, updated, editHsn, mode)
  }
  // const onFormSubmitHandle = (e) => {
  //   removeContainerOverlay();
  //   typeof (props.onFormSubmitHandle) === 'function' && props.onFormSubmitHandle(e);
  //   return false;
  // }

  const onClose = (e) => {
    removeContainerOverlay();
    props.onClose();
  }

  const removeContainerOverlay = () => {
    /**
     * Remove overlay to parent class
     */
    (document.querySelectorAll('.content-container')[0]).classList.remove('modal-overlay-bg2');
  };

  return (
    <Modal
      showDone={props.showDone}
      className={props.className}
      isSubModal={props.isSubModal}
      columnViewType={props.columnViewType}
      title={props.title}
      customModalStyle={props.customModalStyle ? props.customModalStyle : {}}
      onClose={onClose}
      encodeImageFileAsURL={props.encodeImageFileAsURL}
      blobFile={props.blobFile}
      fileUpload={props.fileUpload}
      imageUrl={props.imageUrl}
      selectedOptions={props.selectedOptions}
      setSlectedOptions={props.setSelectedOptions}
      buttonLabel={props.buttonLabel}
      disabled={props.disabled}
      modalCustomButtons={props.modalCustomButtons || []}
      onChange={onChange}
      resetImageSelection={props.resetImageSelection}
      showFieldsInColumn={props.showFieldsInColumn}
      onSubmit={(e) => onSubmit(e)}
      showLoadingBtn={props.showLoadingBtn || null}
      mainContentCenter={props.mainContentCenter || null}
      // onFormSubmitHandle={(e) => {
      //   return onFormSubmitHandle(e);
      // } }
      modalFormStatusObj={props.modalFormStatusObj}>
      {
       props && props.fields && props.fields.map((_) => {
          const { initialValue, ...rest } = _;
          return (
            <Input
            key={_.attribute}
            title={props.title}
            name={_.attribute}
            action={_.onSelectAction||null}
            value={updated ? updated[_.attribute] : initialValue !== undefined ? initialValue : ''}
            data={initialValue}
            onChange={(e, searchSelectObj = {},imgFile=null) => {
              if (_.type === 'selectSearch') {
                onChangeSelectSearch(e, _, searchSelectObj);
              } else if(_.type === 'multiSelect' || _.type === 'rcSelectAll'){
                onChangeMultiSelect(e, _, searchSelectObj)
              }else if(_.type === "selectSearch2"){
                onChangeSelectSearch2(e,_)
              }
              else if(_.type==='radio-split'){
                onChange(e, searchSelectObj);
              }
              else if(imgFile){
                onChange(e,imgFile );
              }
              else{
                onChange(e,_)
              }
            }}
            {...rest}
          />

          )
        })
      }
      {props.children}
    </Modal>
  )
}
