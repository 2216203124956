/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState } from 'react'
import { APIEndpoint } from '../../../utils/axios';
import { DataGrid,NGODatePicker,Modal } from '../../../common';
import { DEFAULT_DATE_TIME_FORMAT, customSelSearch } from "../../../utils/configs";
import {formatDateByTimezone, translationLength} from '../../../utils/commonFunctions';
import { useTranslation } from 'react-i18next';
import searchIcon from '../../../assets/icons/search.svg';
import Select from "react-select";
import moment from 'moment';


export const ManageStocks = (props) => {
  const { t } = useTranslation();
  const [perPageLimit, setPerPageLimit] = useState(10);
  const [searchKey, setSearchKey] = useState('');
  const[state,setState]=useState({date:new Date()});
  const [storesList, setStoresList] = useState([]);
  const [flightTime, setFlightTime] = useState([]);
  const [ flightTimeSelect, setFlightTimeSelect ] = useState('');
  const[,setStoreArray]=useState([])
  const[storeId,setStoreId]=useState(null)
  const [ isReloadTableData, setIsReloadTableData ] = useState(false);
  const [noResultsMessage, setNoResultsMessage] = useState("Please select a sector and date")

  const preCompile = {
    itemType: t('selectButtons.selSector'),
    flightTime: t('selectButtons.selFlightTime'),
  }
  React.useEffect(() => {
    fetchStores();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [t]);

  React.useEffect(() => {
    if(!storeId || state?.date===null){
      setIsReloadTableData((prev) => !prev);
      setNoResultsMessage('Please select a sector and date')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [storeId,state?.date]);
  React.useEffect(() => {
    setIsReloadTableData((prev) => !prev);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [noResultsMessage]);

  React.useEffect(() => {
    async function fetch() {
      if (storeId && state?.date) {
        const res = await fetchManageStock();
        if (res !== null && typeof res === 'object') {
          if (!res.count) {
            setNoResultsMessage('No Flights Found');
          } else {
            setNoResultsMessage('');
          }
        }
      } else {
        setNoResultsMessage('Please select a sector and date');
      }
      if (!storeId || state?.date === null) {
        setIsReloadTableData((prev) => !prev);
      }
    }

    fetch();
  }, [t, storeId, state?.date, flightTimeSelect]);



function LinkComponent(props) {
  const {data,colDef}=props;
  if(data){
     return (
    <span style={{fontWeight:( colDef.field==='uplift'&&data?.uplift>0)? 'bold' :( colDef.field==='placeOrder'&&data?.placeOrder>0)? 'bold':"" ,color:( colDef.field==='uplift'&&data?.uplift>0)? '#34cc34' :( colDef.field==='placeOrder'&&data?.placeOrder>0)? '#f06548':"" }} className='manage-stock-details' title={`${colDef.field} details`} onClick={()=>showStockDetails(colDef.field,data)}>
   {colDef.field==='uplift'?data?.uplift:colDef.field==='variance'?data?.variance:data.placeOrder}
    </span>
  );
  }
  else{
    return(<></>)
  }

}
const showStockDetails=(field,data)=>{
let stockArr=[];
if(field==='uplift'){
  stockArr.push({bob:data.upliftBoB,corporate:data.upliftCorporate,others:data.upliftOthers,webMeal:data.upliftWebMeal})
}
else if(field==='variance'){
  stockArr.push({uplift:data.varianceUplift,transfer:data.varianceTransfer})
}
else{
  stockArr.push({bob:data.placeOrderBoB,corporate:data.placeOrderCorporate,others:data.placeOrderOthers,webMeal:data.placeOrderWebMeal})


}
if(stockArr.length){
  setState((_)=>({..._,selected:{action:field,data:stockArr}}))
}

}
const Columns = [
  { field: 'itemName',headerName: 'Item Name', sortable: true, filter: true, minWidth: 165,tooltipField: 'itemName',valueGetter: (params) => {
    if (params.data&&params.data.itemName) {
       return params.data.itemName.length>21? params.data.itemName.slice(0,22)+"...": params.data.itemName;
    }
    return null;
  }, },
  { field: 'openingStock',headerName: 'Opening Stock',valueGetter: (params) => {
    if (params.data&&params.data.openingStock) {
       return params.data.openingStock
    }else if(params.data&&!params.data.openingStock){
      return 0;
    }
    else{
      return null;
    }
  }, sortable: true, filter: true, minWidth: 165 },
  { field: 'uplift', headerName: 'Uplift', cellRenderer: LinkComponent,valueGetter: (params) => {
    if (params.data&&params.data.uplift) {
       return params.data.uplift
    }else if(params.data&&!params.data.uplift){
      return 0;
    }
    else{
      return null;
    }
  },sortable: true, filter: true, minWidth: 125 },
  { field: 'transferIn',headerName: 'Handover (In)',valueGetter: (params) => {
    if (params.data&&params.data.transferIn) {
       return params.data.transferIn
    }else if(params.data&&!params.data.transferIn){
      return 0;
    }
    else{
      return null;
    }
  }, sortable: true, filter: true, minWidth: 165 },
  { field: 'wastage',headerName: 'Wastage', valueGetter: (params) => {
    if (params.data&&params.data.wastage) {
       return params.data.wastage
    }else if(params.data&&!params.data.wastage){
      return 0;
    }
    else{
      return null;
    }
  },sortable: true, filter: true, minWidth: 125 },
  { field: 'variance', headerName: 'Variance', cellRenderer:LinkComponent,valueGetter: (params) => {
    if (params.data&&params.data.variance) {
       return params.data.variance
    }else if(params.data&&!params.data.variance){
      return 0;
    }
    else{
      return null;
    }
  },sortable: true, filter: true, minWidth: 125 },
  { field: 'placeOrder', headerName: 'Orders',cellRenderer: LinkComponent,valueGetter: (params) => {
    if (params.data&&params.data.placeOrder) {
       return params.data.placeOrder
    }else if(params.data&&params.data.placeOrder){
      return 0;
    }
    else{
      return null;
    }
  },sortable: true, filter: true, minWidth: 125 },
  { field: 'offload', headerName: 'Offload',valueGetter: (params) => {
    if (params.data&&params.data.offload) {
       return params.data.offload
    }else if(params.data&&!params.data.offload){
      return 0;
    }
    else{
      return null;
    }
  },sortable: true, filter: true, minWidth: 125 },
  { field: 'transferOut', headerName: 'Handover (Out)',valueGetter: (params) => {
    if (params.data&&params.data.transferOut) {
       return params.data.transferOut
    }else if(params.data&&!params.data.transferOut){
      return 0;
    }
    else{
      return null;
    }
  },sortable: true, filter: true, cellStyle: params => ({
    color: params.value > 0 ? '#f06548' : '',
  }), minWidth: 165 },
  { field: 'currentStock', headerName: 'Current Stock',valueGetter: (params) => {
    if (params.data&&params.data.currentStock) {
       return params.data.currentStock
    }else if(params.data&&!params.data.currentStock){
      return 0;
    }
    else{
      return null;
    }
  },sortable: true, filter: true, minWidth: 165 },
  { field: 'unit', headerName: 'Unit',sortable: true, filter: true, minWidth: 125 },

];



  const fetchStores = async () => {
    try {
      const resp = await APIEndpoint.get('stores');
    if (resp.result.length > 0) {
      setStoreArray(resp.result)
      const options = [{key: 0, label: preCompile.itemType, value: null}];
      resp.result.filter( (fieldValue, index) => {
        options.push({key: index+1, label: `${fieldValue.flightNumber} (${fieldValue.originName} - ${fieldValue.destinationName})`, value: fieldValue.id });
        return fieldValue;
      });

      setStoresList( options );

    }
    else {
      console.log('Log: Error while fetching item types API');
    }
    } catch (error) {

    }

  };

  const setFlightTimeSessions = async (sessionArr = []) => {
      const options = [{key: 0, label: preCompile.flightTime, value: ''}];
      sessionArr.filter( (fieldValue, index) => {
        options.push({key: index+1, label: `${getStandardDateTime(fieldValue.startedAt) }`, value: `${fieldValue.id}` });
        return fieldValue;
      });

      setFlightTime( options );
  };

  const filterData = {
    action: 4,
    collection: '',
    filterOperation: 'or',
    filters: [],
    limit: 10,
    page: 1,
    searchParam: ''
  };

  const fetchManageStock = async (page=1, perPage=10) => {
    filterData.page = page;
    filterData.limit = perPage;
    filterData.searchParam = searchKey;
    if(storeId){

      if(!state?.date) {
        filterData.filters=[{storeId}]
      }
      else {
          filterData.filters=[{ targetDate: getEffectiveDate(state?.date)},{ storeId }]
      }

      if (flightTimeSelect) {
        filterData.filters.push({ sessionId: flightTimeSelect })
      }

      try {
        const res = await APIEndpoint.post('inventory/process', JSON.stringify(filterData));
      if ( res.sessions ) {
        setFlightTimeSessions(res.sessions);
      }
      return res;
      } catch (error) {
       let respError= JSON.parse(error.message)?.data
       setNoResultsMessage(respError.msg)
        return {count:0,items:[]}
      }

    }else{
      setNoResultsMessage("Please select a sector and date")
      return {count:0,items:[]}
    }
  }

  const onSearch = (e) => {
      setSearchKey(e.target.value);
  };

  const onStoreChange = (e) => {
        setStoreId(e?.value)
  }
  const onFLightTimeChange=(e)=>{
    setFlightTimeSelect(e?.value);
  }

  const getEffectiveDate=(dateChange)=>{
    if(dateChange!==undefined){
        let effTime = formatDateByTimezone(dateChange)
        return effTime;
    }
  }

  const getStandardDateTime = (userDate) => {
    return moment(userDate).format(DEFAULT_DATE_TIME_FORMAT);
  };

  const onDateChange=(e)=>{
    setState((_) => {
        return { ..._, date: e.target.value };
      })
      getEffectiveDate(e.target.value);

}

const customStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#0C5061" : "#fff",
    backgroundColor: state.isSelected ? "black" : "#162E3E",
  }),
  input: (base, state) => ({
    ...base,
    '[type="text"]': {
      fontSize: 13,
      fontWeight: 900,
      color: 'black',
    }
  }),
  control: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: "white",
    padding: "3px",
    width: '13rem',
    height: "2.5rem",
    border: "0.4px solid #FFFFFF",
    boxShadow: "none",
    fontColor: 'black',
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: 'black' }),
  
};

const customSectorListStyles = {
  option: (defaultStyles, state) => ({
    ...defaultStyles,
    color: state.isSelected ? "#02a8d1" : "#fff",
    backgroundColor: state.isSelected ? "black" : "#172f3e",
  }),
  input: (base, state) => ({
    ...base,
    '[type="text"]': {
      fontSize: 13,
      fontWeight: 900,
      color: 'white',
    },
    color:"#fff"
  }),
  control: (defaultStyles) => ({
    ...defaultStyles,
    backgroundColor: "#172f3e",
    padding: "3px",
    width: '12rem',
    height: "2.5rem",
    border: "0.4px solid #FFFFFF",
    boxShadow: "none",
    fontColor: 'white',
  }),
  singleValue: (defaultStyles) => ({ ...defaultStyles, color: 'white' }),
  placeholder:(defaultStyles)=>({
    ...defaultStyles,
  whiteSpace:'no-wrap',
  color:"#fff"
  })
};

const handleModalCancelEvent=()=>{
  setState((_)=>({..._,selected:false}))
}
  return (
      <div className="op-aircraft-container flexCol full-flex ag-theme-alpine-dark">
      {
        <>
        <div className="flexRow width100 margBot10 margTop8 justifyContentFlexStart">
          <div className="flexRow alignItemsBaseline margTop9-5">
            <Select
              options={storesList.map((it) => ({
                value: it.value,
                label: it.label,
              }))}
              styles={customSectorListStyles}
              isSearchable
              isClearable
              className="selectMenu-meals"
              placeholder="Select Sector"
              title="FlightNumber (Source - Destination)"
              onChange={(val) => onStoreChange(val)}
            />
          </div>
          <>
            <NGODatePicker
              isClearable={false}
              value={state?.date || null}
              placeholder={t("datePickerPlaceholder")}
              className={"marg0 margTop5"}
              placeholderText={t("datePickerPlaceholder")}
              onChange={onDateChange}
            />
          </>
          <div className="flexRow width100 margBot10 margTop8 justifyContentSpaceBetween">
            <div className="margLeft margBot10 underline margTop10">
              <span>
                <img
                  className="icon-size"
                  alt={t("filters.searchText")}
                  src={searchIcon}
                />
              </span>
              <span>
                <input
                  className="search"
                  onChange={onSearch}
                  type="search"
                  placeholder={t("filters.searchText")}
                  value={searchKey}
                />
              </span>
            </div>

            <div className="flexRow justifyContentFlexEnd alignItemsBaseline margTop10">
              <Select
                options={flightTime.map((it) => ({
                  value: it.value,
                  label: it.label,
                }))}
                styles={{
                  ...customSelSearch,
                  options: {
                    ...customSelSearch.control,
                    pointerEvents: `${Boolean(noResultsMessage.length) ? "none" : ""}`,
                  },
                }}
                isSearchable
                isDisabled={Boolean(noResultsMessage.length)}
                placeholder="Select Flight Time"
                className="selectMenu-meals"
                onChange={(val) => onFLightTimeChange(val)}
              />
            </div>
          </div>
        </div>

           {/* {selFiltersMessage ?
                        <>
                            <div className="selectFiltersBlock flexRow justifyContentSpaceEvenly alignItemsCenter">
                                <p className="alert_error">{selFiltersMessage}</p>
                            </div>
                        </> : */}
                        <>
                          <DataGrid
                            className="full-flex"
                            columns={[...Columns]}
                            initialPageLimit={perPageLimit}
                            showDefaultFilters={false}
                            storeId={storeId}
                            date={state?.date}
                            searchKey={searchKey}
                            getRows={fetchManageStock}
                            resetDataSource={isReloadTableData}
                            noResultsMessage={noResultsMessage}
                            rowSelection="multiple"
                            customCellRenderer={LinkComponent}
                            columnResize={true}
                          />
                        </>
                        {/* } */}


                        {
       state&&state.selected&&(state.selected.action === 'uplift'||state.selected.action === 'variance'||state.selected.action === 'placeOrder' )&&
        <Modal
        className='cash-deposit-summary'
        title={state.selected.action.toUpperCase()!=='PLACEORDER'?state.selected.action.toUpperCase():'ORDERS'}
        onClose={handleModalCancelEvent}
        onSubmit={handleModalCancelEvent}>
  <div className='width100 justifyContentCenter alignItemsCenter' >
     {state.selected.data.length? <table style={{padding:'1rem'}}>
      <thead style={{color:"#000",backgroundColor:"#fff"}}>
          <tr>
                <th>Type</th>
                <th>Amount</th>
          </tr>
        </thead>
        <tbody>
              {state.selected.action==='uplift'?state.selected.data.map((_, index) => (
<> <tr>
                <td>BoB</td>
                <td style={{whiteSpace:'nowrap'}}>{_.bob}</td>
              </tr>
                <tr>
                <td style={{backgroundColor:"#0d2536"}}>Corporate</td>
                <td style={{whiteSpace:'nowrap',backgroundColor:"#0d2536"}}>{_.corporate}</td>
              </tr>
              <tr>
              <td>Others</td>
              <td style={{whiteSpace:'nowrap'}}>{_.others}</td>
              </tr>
              <tr>
              <td style={{backgroundColor:"#0d2536"}}>WebMeal</td>
                <td style={{whiteSpace:'nowrap',backgroundColor:"#0d2536"}}>{_.webMeal}</td>
              </tr>

              </>


              )):state.selected.action==='variance'?state.selected.data.map((_, index) => (
                <> <tr>
                <td>Uplift</td>
                <td style={{whiteSpace:'nowrap'}}>{_.uplift}</td>
              </tr>
                <tr>
                <td style={{backgroundColor:"#0d2536"}}>Corporate</td>
                <td style={{whiteSpace:'nowrap',backgroundColor:"#0d2536"}}>{_.transfer}</td>
              </tr>

              </>
              )):state.selected.data.map((_, index) => (
                <> <tr>
                                <td>BoB</td>
                                <td style={{whiteSpace:'nowrap'}}>{_.bob}</td>
                              </tr>
                                <tr>
                                <td style={{backgroundColor:"#0d2536"}}>Corporate</td>
                                <td style={{whiteSpace:'nowrap',backgroundColor:"#0d2536"}}>{_.corporate}</td>
                              </tr>
                              <tr>
                              <td>Others</td>
                              <td style={{whiteSpace:'nowrap'}}>{_.others}</td>
                              </tr>
                              <tr>
                              <td style={{backgroundColor:"#0d2536"}}>WebMeal</td>
                                <td style={{whiteSpace:'nowrap',backgroundColor:"#0d2536"}}>{_.webMeal}</td>
                              </tr>

                              </>


                              ))}
            </tbody>
      </table>:<><div className="flexRow w-100 h-100 justifyContentCenter alignItemsCenter">
              No Data
            </div></>}
    </div>

      </Modal>
      }





        </>
      }
      {
                  t('pages').length<=translationLength&&<div className='overlay_hide sub'></div>
                }

    </div>
  )
}


